import {useEffect, useState} from "react";
import {ExpectedDocument} from "../../api/ExpectedDocumentAPI";
import {useAppDispatch} from "../../redux/Hooks";
import {
    removeExpectedDocument,
    setExpectedDocumentAvailable,
    setExpectedDocumentDelay1,
    setExpectedDocumentDelay2,
    setExpectedDocumentNotify,
    setExpectedDocumentTitle
} from "../../redux/Committee/CommitteeFormSlice";
import {Form} from 'react-bootstrap'

export default function ItemExpectedDocumentForm(props: {index: number, doc?:ExpectedDocument}) {
    const dispatch = useAppDispatch()
    const [showNotif, setShowNotif] = useState(false)
    const [pv, setPv] = useState(false)
    const [title, setTitle] = useState('')
    const [delay1, setDelay1] = useState('0')
    const [delay2, setDelay2] = useState('0')

    useEffect(() => {
        props.doc && props.doc.notify && setShowNotif(props.doc.notify)
        props.doc && props.doc.available && setPv(props.doc.available)
        props.doc && props.doc.title && setTitle(props.doc.title)
        props.doc && props.doc.delay1 && setDelay1(props.doc.delay1.toString())
        props.doc && props.doc.delay2 && setDelay2(props.doc.delay2.toString())
    }, [props.doc])

    if (props.index === 1) {
        return <>
            <Form.Check
                className="form-check-inline"
                type={'radio'}
                label='Oui'
                id={`input-available-1`}
                checked={pv}
                name="input-available"
                onChange={() => {
                    setPv(true)
                    dispatch(setExpectedDocumentAvailable({index: props.index, value: true}))
                }}
            />
            <Form.Check
                className="form-check-inline"
                type={'radio'}
                label='Non'
                id={`input-available-0`}
                checked={!pv}
                name="input-available"
                onChange={() => {
                    setPv(false)
                    dispatch(setExpectedDocumentAvailable({index: props.index, value: false}))
                }}
            />
        </>
    }

    let renderItemExpectedDocumentFormTitle = <></>
    if (props.index >= 2) {
        renderItemExpectedDocumentFormTitle = <Form.Group className="mb-4">
            <Form.Label htmlFor="input-title">Intitulé *</Form.Label>
            <Form.Control
                type="text"
                defaultValue={title}
                name={`input-title`}
                required
                onChange={(e) => {
                    setTitle(e.target.value)
                    dispatch(setExpectedDocumentTitle({index: props.index, value: e.target.value}))
                }}
            />
        </Form.Group>
    }

    return <>
        {renderItemExpectedDocumentFormTitle}
        <Form.Group className="mb-4">
            <Form.Check
                type="switch"
                id={`input-notification-${props.index}`}
                label="Notification"
                checked={showNotif}
                onChange={() => {
                    setShowNotif(!showNotif)
                    dispatch(setExpectedDocumentNotify({index: props.index, value: !showNotif}))
                }}
            />
        </Form.Group>
        <div className={(showNotif?`d-block`:`d-none`)} id={`displayNotifs-${props.index}`}>
            <div className="mb-4 row row-sm">
                <div className="col-3">
                    <Form.Label className="col-form-label">Délais alerte contribution</Form.Label>
                </div>
                <div className="col-auto">
                    <div className="input-group">
                        <span className="input-group-text pe-1 text-dark fw-medium">J-</span>
                        <Form.Control type="text" name={`input-delay1`}
                            value={delay1}
                            onChange={(e) => {
                                setDelay1(e.target.value)
                                dispatch(setExpectedDocumentDelay1({index: props.index, value: e.target.value}))
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4 row row-sm">
                <div className="col-3">
                    <Form.Label className="col-form-label">Délais alerte notification</Form.Label>
                </div>
                <div className="col-auto">
                    <div className="input-group">
                        <span className="input-group-text pe-1 text-dark fw-medium">J-</span>
                        <Form.Control type="text" name={`input-delay2`}
                            value={delay2}
                            onChange={(e) => {
                                setDelay2(e.target.value)
                                dispatch(setExpectedDocumentDelay2({index: props.index, value: e.target.value}))
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        <ItemExpectedDocumentDeleteButton doc={props.doc} index={props.index} />
    </>
}

function ItemExpectedDocumentDeleteButton(props: {doc?: ExpectedDocument, index: number}) {
    const dispatch = useAppDispatch();
    if (props.index < 2) {
        return <></>
    }

    function deleteElement() {
        if (props.doc !== undefined) {
            dispatch(removeExpectedDocument(props.index))
        }
    }

    return <button type="button" className="btn-less link text-danger text-hover-black" onClick={() => deleteElement()}>
        <i className="mai fs-md me-2">delete_outline</i> Supprimer
    </button>
}
