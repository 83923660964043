import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {deleteMeeting} from "../../api/MeetingAPI"
import {setModalMeetingSendEmail, setModalDeleteShow} from "../../redux/Meeting/MeetingSlice"
import {Modal, Form} from "react-bootstrap"

export default function ConfirmDeleteModal(props: { meetingId: number }) {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.meeting.modalDeleteShow)
    const sendEmail = useAppSelector((state) => state.meeting.modalMeetingSendEmail)

    function delMeeting(meetingId: number) {
        dispatch(deleteMeeting({id: meetingId, email: sendEmail}))
        closeModal()
    }

    function closeModal() {
        dispatch(setModalMeetingSendEmail(false))
        dispatch(setModalDeleteShow(false))
    }

    return <Modal size="sm" className="modal-confirm" show={show} onHide={() => closeModal()}
                  backdropClassName="modal-backdrop-confirm">
        <Modal.Header>
            <Modal.Title as="h3" className="text-danger">
                <i className="mai me-2">warning</i> Confirmation requise
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="fw-bold text-danger">Êtes vous sûr de vouloir supprimer cet élément ?</p>
            <Form.Check type="switch" name="input-documentForAll" checked={sendEmail}
                        label="Je souhaite avertir les commissaires-administrateurs de la suppression de la réunion"
                        onChange={() => {
                            dispatch(setModalMeetingSendEmail(!sendEmail))
                        }}
            />
        </Modal.Body>
        <Modal.Footer>
            <div className="row row-sm row-sm-h align-items-center">
                <div className="col-auto">
                    <button type="button" className="btn btn-light" onClick={() => closeModal()}>Non</button>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-danger" onClick={() => delMeeting(props.meetingId)}>Oui
                    </button>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
}
