import {useEffect, useMemo} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import AddAssistantForm from "../../components/AddUser/AddAssistantForm"
import type {RootState} from "../../redux/Store"
import {setPage} from "../../redux/User/UserSlice"
import PageHeader from "../../components/PageHeader"

export default function AddAssistant() {

    const dispatch = useAppDispatch()
    const profil = useAppSelector((state: RootState) => state.addUser.profil)

    const profilTitle = useMemo(() => {
        switch (profil) {
            case "assistante":
                return "un.e assistant.e"
            case "membre-codir":
                return "un membre Codir"
            case "admin":
            case "webmaster":
                return "un webmaster"
        }
        return "un assistant"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPage("add-assistant"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageHeader title={"Ajout d'" + profilTitle} breadcrumb nav={[["Utilisateur", "/annuaire"]]}/>
            <div className="container">
                <div className="block">
                    <h3 className="text-primary">Informations générales</h3>
                    <AddAssistantForm editModeAssistant={false}/>
                </div>
            </div>
        </>
    )
}
