import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {useEffect} from "react"
import {fetchContents, TYPE_CONTENT_NEWS} from "../../api/ContentAPI"
import ItemActuality from "./ItemActuality"
import LoadingSpinner from "../LoadingSpinner"

export default function ZoneActualities(props: { context: string, nb?: number }) {
    const dispatch = useAppDispatch()
    const actualities = useAppSelector((state) => state.actuality.contents)
    const showLoadingHome = useAppSelector((state) => state.actuality.showLoadingHome)

    useEffect(() => {
            if (props.context === "home") {
                dispatch(fetchContents({
                    options: {
                        "type": TYPE_CONTENT_NEWS,
                        "published": true,
                        "page": 1,
                        "itemsPerPage": 3
                    }
                }))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.context]
    )

    let renderContent: JSX.Element[] = []
    if (showLoadingHome && props.context === "home") {
        renderContent.push(<LoadingSpinner key="loading"/>)
    } else {
        renderContent = actualities.map((actuality) => {
            return <div className="col-lg-6 col-xl-4" key={actuality.id}>
                <ItemActuality actuality={actuality} homepage={true}/>
            </div>
        })
    }

    return <div className="row row-h mb-8">{renderContent}</div>
}
