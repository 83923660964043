import React, {SyntheticEvent, useEffect} from "react"
import {Fragment} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setImageContentLoading,
    setDisplayPopinDeleteActualityConfirm,
    setActualitySelected,
    setShowModalAddActuality,
    setImageActuality,
    setTitleActuality,
    setPublishedActuality,
    setContentActuality,
    setVideoLinkActuality,
    setActualPage
} from "../../redux/Content/ContentSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {data} from "../../utils/Data"
import Editor from "../../components/Editor"
import {addActuality, fetchContents, TYPE_CONTENT_NEWS, updateActuality, uploadImage} from "../../api/ContentAPI"
import {ResponseInterface} from "../../utils/CommonInterfaces"
import {Spinner, Form, Modal} from "react-bootstrap"
import {Options} from "../../utils/Options"

export default function LightboxAddActuality(props: { editMode: boolean }) {

    const dispatch = useAppDispatch()
    const showModalAddActuality = useAppSelector((state: RootState) => state.actuality.showModalAddActuality)
    const titleActuality = useAppSelector((state: RootState) => state.actuality.titleActuality)
    const imageActuality = useAppSelector((state: RootState) => state.actuality.imageActuality)
    const publishedActuality = useAppSelector((state: RootState) => state.actuality.publishedActuality)
    const actualitySelected = useAppSelector((state: RootState) => state.actuality.actualitySelected)
    const contentActuality = useAppSelector((state: RootState) => state.actuality.contentActuality)
    const videoLinkActuality = useAppSelector((state: RootState) => state.actuality.videoLinkActuality)
    const imageContentLoading = useAppSelector((state: RootState) => state.actuality.imageContentLoading)
    const viewOnlyPublished = useAppSelector((state: RootState) => state.actuality.viewOnlyPublished)

    useEffect(() => {
        dispatch(setImageContentLoading(false))
        if (props.editMode && actualitySelected !== null) {
            dispatch(setImageActuality(actualitySelected.filePath))
            dispatch(setTitleActuality(actualitySelected.title))
            dispatch(setPublishedActuality(actualitySelected.published))
            dispatch(setVideoLinkActuality(actualitySelected.videoLink))
            dispatch(setContentActuality(actualitySelected.content))
        }

    }, [dispatch, props.editMode, actualitySelected])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (contentActuality === "") {
            dispatch(displayMessage({text: "Veuillez saisir un contenu", status: "error"}))
            return false
        }

        let elem = document.querySelector("#editor-instances")
        if (elem !== null) {
            let textContent = elem.children[0].textContent
            if (textContent !== null && textContent !== undefined && textContent.length > 400) {
                dispatch(displayMessage({
                    text: "Votre texte ne doit pas faire plus de 400 caractères",
                    status: "error"
                }))
                return false
            }
        }

        let response: ResponseInterface
        if (!imageContentLoading) {
            if (props.editMode && actualitySelected !== null) {
                dispatch(displayMessage({text: "Mise à jour en cours"}))
                response = await dispatch(updateActuality({
                    actuality: {
                        id: actualitySelected.id,
                        title: titleActuality,
                        content: contentActuality,
                        filePath: imageActuality,
                        videoLink: videoLinkActuality,
                        published: publishedActuality
                    }
                }))
            } else {
                dispatch(displayMessage({text: "Enregistrement en cours"}))
                response = await dispatch(addActuality({
                    actuality: {
                        title: titleActuality,
                        content: contentActuality,
                        videoLink: videoLinkActuality,
                        published: publishedActuality,
                        type: 1,
                        filePath: imageActuality
                    }
                }))
            }

            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({
                    text: props.editMode ? "Enregistrement effectué avec succès !" : "Mise à jour effectuée avec succès !",
                    status: "success"
                }))
                let options: Options = {type: TYPE_CONTENT_NEWS}
                if (viewOnlyPublished) {
                    options.published = viewOnlyPublished
                }
                dispatch(fetchContents({}))
                dispatch(setActualPage(1))
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({
                    text: props.editMode ? "Echec de la mise à jour de l'actualité" : "Echec de l'enregistrement",
                    status: "error"
                }))
            }
        }
    }

    const saveImage = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files !== null && e.target.files.length > 0) {
            dispatch(setImageContentLoading(true))

            let formData = new FormData()
            formData.append("file", e.target.files[0])
            uploadImage(formData).then((response) => {
                dispatch(setImageContentLoading(false))
                let currentMonth: string | number = new Date().getMonth() + 1
                if (currentMonth < 10) {
                    currentMonth = "0" + currentMonth
                }
                dispatch(setImageActuality("/img/content/" + new Date().getFullYear() + "/" + currentMonth + "/" + response.data.filename))
                dispatch(displayMessage({text: "Image chargé avec succès", status: "success"}))
            }).catch((result) => {
                dispatch(setImageContentLoading(false))
                if (result.response.status !== undefined && result.response.status === 400) {
                    dispatch(displayMessage({text: result.response.data.message, status: "error"}))
                } else {
                    dispatch(displayMessage({text: "Echec du chargement de l'image", status: "error"}))
                }
            })
        } else {
            dispatch(setImageActuality(""))
            dispatch(displayMessage({text: "Aucun fichier n'a été sélectionnée", status: "error"}))
        }

    }

    const closeLightbox = () => {
        dispatch(setShowModalAddActuality(false))
        dispatch(setActualitySelected(null))
        dispatch(setImageActuality(""))
        dispatch(setTitleActuality(""))
        dispatch(setPublishedActuality(true))
        dispatch(setVideoLinkActuality(""))
        dispatch(setContentActuality(""))
    }

    return (
        <Modal className="modal-form" show={showModalAddActuality} onHide={() => {
            closeLightbox()
        }}>
            <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                handleSubmit(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.editMode ? "Modification d'une actualité" : "Ajout d'une actualité"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-title">Titre *</Form.Label>
                        <Form.Control type="text" value={titleActuality} required id="input-title"
                                      placeholder="Votre titre ici" onChange={(e) => {
                            dispatch(setTitleActuality(e.target.value))
                        }}/>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-img-actuality">Visuel</Form.Label>
                        {
                            imageContentLoading && <Spinner animation="border" className="d-block" variant="primary"/>
                        }
                        {
                            !imageContentLoading &&
                            <Fragment>
                                {(imageActuality !== undefined && imageActuality !== null && imageActuality !== "") &&
                                    <Fragment>
                                        <img alt="" className="img-fluid d-block mb-1" width="440"
                                             src={`${data.host}${imageActuality}`}/>
                                        <button
                                            className="btn-less d-block text-danger text-hover-black fw-semibold fs-xs mb-1"
                                            onClick={() => {
                                                dispatch(setImageActuality(""))
                                            }}>
                                            <i className="mai fs-sm me-2">delete</i> Supprimer l'image
                                        </button>
                                    </Fragment>
                                }
                                <input type="file" id="input-img-actuality" className="form-control mb-1"
                                       onChange={(e) => {
                                           saveImage(e)
                                       }}/>
                                <Form.Text className="d-block mt-0 fs-xs">Fichier jpg, png uniquement. Poids max
                                    3mo.</Form.Text>
                            </Fragment>
                        }
                    </Form.Group>

                    <Form.Group className="mb-4">

                        <Form.Label htmlFor="input-video">Lien si vidéo</Form.Label>
                        <Form.Control type="text" value={videoLinkActuality} id="input-video"
                                      placeholder="Adresse http de la vidéo" onChange={(e) => {

                            dispatch(setVideoLinkActuality(e.target.value))
                        }}/>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Check type="switch" label="Publié" checked={publishedActuality} onChange={() => {
                            dispatch(setPublishedActuality(!publishedActuality))
                        }}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Contenu</Form.Label>
                        <Editor value={contentActuality} id="editor-add-actuality" onChange={(e) => {
                            dispatch(setContentActuality(e))
                            let elem = document.querySelector("#editor-instances")
                            if (elem !== null) {
                                let textContent = elem.children[0].textContent
                                if (textContent !== null && textContent !== undefined && textContent.length > 400) {
                                    dispatch(displayMessage({
                                        text: "Votre texte ne doit pas faire plus de 400 caractères",
                                        status: "error"
                                    }))
                                }
                            }
                        }}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {(() => {
                        if (props.editMode) {
                            return <div className="row row-sm justify-content-center">
                                <div className="col-auto">
                                    <button type="button" className="btn btn-light" onClick={() => {
                                        dispatch(setDisplayPopinDeleteActualityConfirm(true))
                                    }}>
                                        <i className="mai fs-md me-2">delete</i> Supprimer
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-light">
                                        <i className="mai fs-md me-2">check</i> Valider
                                    </button>
                                </div>
                            </div>
                        } else {
                            return <button type="submit" className="btn btn-light">
                                <i className="mai fs-md me-2">add</i> Ajouter
                            </button>
                        }
                    })()}
                </Modal.Footer>
            </form>
        </Modal>
    )
}
