const data = {
    host: process.env.REACT_APP_API_URL,
    context: process.env.REACT_APP_CONTEXT,
    STATUS: [
        "Titulaire",
        "Suppléant",
    ],
    CIVILITIES: {
        "FEMALE": 0,
        "MALE": 1
    },
    MANDATE_ROLES: [
        {
            key: 0,
            label: "Président"
        },
        {
            key: 1,
            label: "Vice-président"
        },
        {
            key: 2,
            label: "Trésorier"
        },
        {
            key: 3,
            label: "Trésorier adjoint"
        },
        {
            key: 4,
            label: "Secrétaire"
        },
        {
            key: 5,
            label: "Secrétaire adjoint"
        },
        {
            key: 6,
            label: "Commissaire-administrateur"
        },
        {
            key: 7,
            label: "Co-animateur"
        },
        {
            key: 8,
            label: "Chef de file"
        }
    ],
    ATTENDING_AVAILABLE: "present",
    ATTENDING_VISIO: "visio",
    ATTENDING_NO: "absent"
}

export const textPassword = "Doit contenir au moins 12 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial"

export {data}

export const API_URL = "/api"
export const COMMITTEE_API = API_URL + "/committees"
export const MANDATE_API = API_URL + "/mandates"
export const USERS_API = API_URL + "/users"
export const LOG_API = API_URL + "/logs"
export const LOGIN_API = API_URL + "/login_check"
export const USER_TOKEN_API = API_URL + "/get-user-by-token"
export const MEETING_API = API_URL + "/meetings"
export const MEETING_USER_API = API_URL + "/meeting_users"
export const DOCUMENT_API = API_URL + "/documents"
export const DOCUMENT_USER_API = API_URL + "/document_users"
export const CREATE_USER_API = API_URL + "/user/create"
export const UPDATE_USER_API = API_URL + "/user/"
export const UPDATE_PERIODS_USER_API = API_URL + "/periods/user/"
export const DELETE_USER_API = API_URL + "/users/"
export const GET_USER_API = API_URL + "/users/"
export const EXPECTED_DOCUMENT_API = API_URL + "/expected_documents"
export const EXPECTED_DOCUMENT_CAT_API = API_URL + "/expected_document_categories"
export const SEND_MAILS_API = API_URL + "/send-mails"
export const TOOLCATEGORY_API = API_URL + "/document_categories"
export const UPDATETOOLCATEGORIES_API = API_URL + "/update-all-toolcategories"
export const DOCUMENT_CATEGORIES_API = API_URL + "/document_categories"
export const CONTENTS_API = API_URL + "/contents"
export const IMAGE_CONTENT_API = API_URL + "/img/content/upload"
export const FILE_TOOL_UPLOAD_API = API_URL + "/documents/upload"
export const IMAGE_USER_API = API_URL + "/img/user/upload"
export const MEETING_ZIP_API = API_URL + "/meetings/zip"
export const USERS_BY_SELECT_FILTERS = API_URL + "/users-by-select-filter"
export const SEND_CONTACT_MESSAGE = API_URL + "/send-contact-message"
export const EXPORT_USERS = API_URL + "/generate/excel/users"
export const SMS_API = API_URL + "/sms"
export const SMS_USER_API = API_URL + "/sms_users"

export const FULL_COMMITTEE_API = data.host + COMMITTEE_API
export const FULL_DOCUMENT_USER_API = data.host + DOCUMENT_USER_API
export const FULL_DOCUMENT_USERS_ADD_API = data.host + DOCUMENT_USER_API + "/create"
export const FULL_EXPORT_USERS = data.host + EXPORT_USERS
export const FULL_LOG_API = data.host + LOG_API
export const FULL_LOGIN_API = data.host + LOGIN_API
export const FULL_MANDATE_API = data.host + MANDATE_API
export const FULL_MEETING_API = data.host + MEETING_API
export const FULL_MEETING_USER_API = data.host + MEETING_USER_API
export const FULL_USERS_API = data.host + USERS_API
export const FULL_USER_TOKEN_API = data.host + USER_TOKEN_API
export const FULL_DOCUMENT_API = data.host + DOCUMENT_API
export const FULL_CREATE_USER_API = data.host + CREATE_USER_API
export const FULL_UPDATE_USER_API = data.host + UPDATE_USER_API
export const FULL_UPDATE_PERIODS_USER_API = data.host + UPDATE_PERIODS_USER_API
export const FULL_GET_USER_API = data.host + GET_USER_API
export const FULL_EXPECTED_DOCUMENT_API = data.host + EXPECTED_DOCUMENT_API
export const FULL_EXPECTED_DOCUMENT_CAT_API = data.host + EXPECTED_DOCUMENT_CAT_API
export const FULL_SEND_MAILS_API = data.host + SEND_MAILS_API
export const FULL_DELETE_USER_API = data.host + DELETE_USER_API
export const FULL_TOOLCATEGORY_API = data.host + TOOLCATEGORY_API
export const FULL_UPDATETOOLCATEGORIES_API = data.host + UPDATETOOLCATEGORIES_API
export const FULL_DOCUMENT_CATEGORIES_API = data.host + DOCUMENT_CATEGORIES_API
export const FULL_CONTENTS_API = data.host + CONTENTS_API
export const FULL_IMAGE_CONTENT_API = data.host + IMAGE_CONTENT_API
export const FULL_FILE_TOOL_UPLOAD_API = data.host + FILE_TOOL_UPLOAD_API
export const FULL_IMAGE_USER_API = data.host + IMAGE_USER_API
export const FULL_MEETING_ZIP_API = data.host + MEETING_ZIP_API
export const FULL_MEETING_CSV_API = data.host + MEETING_API + "/csv"
export const FULL_USERS_BY_SELECT_FILTERS = data.host + USERS_BY_SELECT_FILTERS
export const FULL_SEND_CONTACT_MESSAGE = data.host + SEND_CONTACT_MESSAGE
export const FULL_SMS_API = data.host + SMS_API
export const FULL_SMS_USER_API = data.host + SMS_USER_API

export const TOKEN_INVALIDATE_API = "/api/token/invalidate"
export const FULL_TOKEN_INVALIDATE_API = data.host + TOKEN_INVALIDATE_API
