import {MeetingOccurrence} from "../../../api/MeetingAPI"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {ATTENDING_AVAILABLE} from "../../../api/MeetingUserAPI"
import {Form} from "react-bootstrap"
import {setLunch} from "../../../redux/Meeting/MeetingSlice"
import React from "react"
import {getFormattedLocalisedDate} from "../../../utils/Date"

export function RenderedLunch(props: {
    occurrence?: MeetingOccurrence,
    typeAttendance: string,
    lunchAvailable?: boolean
}) {
    const dispatch = useAppDispatch()
    const meetingTime = props.occurrence?.date && getFormattedLocalisedDate(new Date(props.occurrence.date), "hm")
    const lunch = useAppSelector((state) => state.meeting.lunch)

    if (!props.lunchAvailable || props.typeAttendance !== ATTENDING_AVAILABLE || props.occurrence === undefined || !meetingTime) {
        return <></>
    }

    const date = new Date()
    let limit = new Date()
    if (props.occurrence.date !== undefined) {
        limit = new Date(props.occurrence.date)
    }
    limit.setDate(limit.getDate() - 2)

    if (limit >= date) {
        return <>
            <h3 className="text-primary mb-3">Je souhaite le repas</h3>
            <Form.Group>
                <Form.Check
                    type={"radio"}
                    id="lunch-1"
                    className="mt-3"
                    checked={lunch !== undefined && lunch}
                    label="Oui"
                    onChange={() => {
                        dispatch(setLunch(true))
                    }}
                />
                <Form.Check
                    type={"radio"}
                    id="lunch-2"
                    className="mt-3"
                    checked={lunch !== undefined && !lunch}
                    onChange={() => {
                        dispatch(setLunch(false))
                    }}
                    label="Non"
                />
            </Form.Group>
        </>
    }

    return <p className="text-main-gray"><em>Les délais sont dépassés pour commander le repas</em></p>
}