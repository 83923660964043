import React, {useEffect, SyntheticEvent, useState} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {removeCommittee, addCommittee, setProperty} from "../../redux/User/AddUserSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Form, Modal} from "react-bootstrap"
import Select from "react-select"
import {Committee} from "../../api/CommitteeAPI"
import {formatDateInputDate} from "../../utils/Functions"
import {Mandate, Period} from "../../api/MandateAPI"
import {CommitteeSelectItem} from "../../utils/CommonInterfaces"
import ItemMandate from "./ItemMandate"
import DatePicker from "react-datepicker"
import {getFormattedLocalisedDate, getToday} from "../../utils/Date"

export default function LightBoxCopyPeriod(props: { showCopyPeriodLightbox: boolean }) {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.addUser.committees)
    const selectedCommittees = useAppSelector((state: RootState) => state.addUser.selectedCommittees)
    const dateStartPeriode = useAppSelector((state: RootState) => state.addUser.dateStartPeriod)
    const dateEndPeriode = useAppSelector((state: RootState) => state.addUser.dateEndPeriod)
    const mandates = useAppSelector((state: RootState) => state.addUser.mandates)
    const periods = useAppSelector((state: RootState) => state.addUser.periods)
    const periodToClose = useAppSelector((state: RootState) => state.addUser.periodToClose)
    const periodToEdit = useAppSelector((state: RootState) => state.addUser.periodToEdit)
    const editPeriodMode = useAppSelector((state: RootState) => state.addUser.editPeriodMode)

    const [periodDateStart, setPeriodDateStart] = useState<Date | null>(null)
    const [periodDateEnd, setPeriodDateEnd] = useState<Date | null>(null)
    const [mandatesToDisplay, setMandatesToDisplay] = useState<Mandate[]>([])

    useEffect(() => {
        dispatch(fetchCommittees({}))
        if (periodToClose !== null) {
            let newMandates: Mandate[] = []
            if (periodToClose?.mandates) {
                for (let mandate of periodToClose.mandates) {
                    let newMandate = {...mandate}
                    newMandate.id = undefined
                    newMandates.push(newMandate)
                }
            }
            dispatch(setProperty({property: "mandates", value: [...newMandates]}))
        }
        if (editPeriodMode && periodToEdit !== null) {
            dispatch(setProperty({property: "dateStartPeriod", value: periodToEdit.dateStart}))
            setPeriodDateStart(new Date(periodToEdit.dateStart))
        } else {
            dispatch(setProperty({property: "dateStartPeriod", value: formatDateInputDate()}))
            setPeriodDateStart(new Date())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let defaultValueSelectCommittee: CommitteeSelectItem[] = []
        let mandatesForSelectMultiple: Mandate[] = []
        if (editPeriodMode && periodToClose !== null) {
            mandatesForSelectMultiple = periodToClose.mandates
        } else {
            mandatesForSelectMultiple = mandates
        }

        if (mandatesForSelectMultiple !== undefined) {
            mandatesForSelectMultiple.forEach((mandate: Mandate, index: number) => {
                if (!defaultValueSelectCommittee.some(item => Number(item.value) === mandate.committee.id)) {
                    defaultValueSelectCommittee.push({
                        value: mandate.committee.id, label: mandate.committee.title
                    })
                }
            })
            dispatch(setProperty({property: "selectedCommittees", value: defaultValueSelectCommittee}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (editPeriodMode && periodToClose !== null) {
            setMandatesToDisplay(periodToClose.mandates)
        } else {
            setMandatesToDisplay(mandates)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mandates, periodToClose])

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (dateStartPeriode === "" || dateStartPeriode === null) {
            dispatch(displayMessage({text: "Veuillez saisir une date de début de période de mandats", status: "error"}))
            return false
        }

        if (mandates.length > 0) {
            for (let mandate of mandates) {

                if (mandate.dateStart === "" || mandate.dateStart === null) {
                    dispatch(displayMessage({
                        text: "Veuillez saisir une date de début pour tout les mandats",
                        status: "error"
                    }))
                    return false
                }

                if (mandate.status !== 0 && mandate.status !== 1) {
                    dispatch(displayMessage({text: "Veuillez saisir un statut pour chaque mandat", status: "error"}))
                    return false
                }

                if (mandate.roles.length === 0) {
                    dispatch(displayMessage({
                        text: "Vous devez choisir au moins une fonction pour chaque mandats",
                        status: "error"
                    }))
                    return false
                }

            }
        } else {
            dispatch(displayMessage({text: "Veuillez créer au moins un mandat pour cette période", status: "error"}))
            return false
        }
        dispatch(displayMessage({text: ""}))
        // Si la période et les mandats sont valides, on les ajoute

        let newPeriods: Period[] = []

        if (periodToClose !== null) {
            for (let period of periods) {
                let todayStr = getToday()

                if (period.id === periodToClose.id) {

                    let newMandates: Mandate[] = []

                    for (let mandate of period.mandates) {
                        let newMandate = {...mandate}
                        newMandate.dateEnd = todayStr
                        newMandates.push(newMandate)
                    }
                    newPeriods.push({
                        id: period.id,
                        dateStart: period.dateStart,
                        dateEnd: todayStr,
                        active: true,
                        mandates: newMandates
                    })
                } else {
                    newPeriods.push(period)
                }
            }
        }

        newPeriods.push({
            dateStart: dateStartPeriode,
            dateEnd: dateEndPeriode,
            active: true,
            mandates: mandates
        })

        dispatch(displayMessage({text: ""}))
        dispatch(displayMessage({text: "La période de mandats a été ajoutée avec succès !", status: "success"}))
        dispatch(setProperty({property: "periods", value: [...newPeriods]}))
        dispatch(setProperty({property: "showCopyPeriodLightbox", value: false}))
        dispatch(setProperty({property: "periodToClose", value: {}}))
    }

    const checkDateStart = (date: Date | null) => {
        dispatch(displayMessage({text: ""}))
        dispatch(setProperty({
            property: "dateStartPeriod",
            value: date !== null ? getFormattedLocalisedDate(new Date(date), "yyyy-MM-dd") : ""
        }))
        setPeriodDateStart(date)
    }

    const checkDateEnd = (date: Date | null) => {
        if (dateStartPeriode === "" || dateStartPeriode === null) {
            dispatch(displayMessage({text: "Veuillez saisir une date de début de période", status: "error"}))
            dispatch(setProperty({property: "dateEndPeriod", value: ""}))
            setPeriodDateEnd(null)
        } else {
            const dateS = new Date(dateStartPeriode)
            if (date !== null && date <= dateS) {
                dispatch(displayMessage({
                    text: "Veuillez saisir une date de fin supérieur à la date de début de période de mandats",
                    status: "error"
                }))
                dispatch(setProperty({property: "dateEndPeriod", value: ""}))
                setPeriodDateEnd(null)
            } else {
                dispatch(displayMessage({text: ""}))
                dispatch(setProperty({
                    property: "dateEndPeriod",
                    value: date !== null ? getFormattedLocalisedDate(new Date(date), "yyyy-MM-dd") : ""
                }))
                setPeriodDateEnd(date)
            }
        }
    }

    let optionsSelectCommittee: CommitteeSelectItem[] = []

    if (committees !== undefined) {
        committees.forEach((committee: Committee) => {
            optionsSelectCommittee.push({
                value: committee.id, label: committee.title === undefined ? "" : committee.title
            })
        })
    }

    const hideLightBoxCopyPeriod = () => {
        dispatch(setProperty({property: "mandates", value: []}))
        dispatch(setProperty({property: "dateStartPeriod", value: formatDateInputDate()}))
        dispatch(setProperty({property: "dateEndPeriod", value: ""}))

        dispatch(setProperty({property: "showCopyPeriodLightbox", value: false}))
        dispatch(setProperty({property: "periodToClose", value: {}}))
    }
    return <Modal className="modal-form" show={props.showCopyPeriodLightbox} scrollable onHide={() => {
        hideLightBoxCopyPeriod()
    }}>
        <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            handleSubmit(e)
        }}>
            <Modal.Header closeButton>
                <Modal.Title as="h3">Copie de période</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row row-sm align-items-center">
                    <div className="col-auto">
                        <Form.Label htmlFor="mandatDateStart">Période du *</Form.Label>
                        <DatePicker
                            locale="fr"
                            id="date-period-start"
                            selected={periodDateStart}
                            required={true}
                            onChange={(date) => {
                                checkDateStart(date)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"jj/mm/aaaa"}
                        />
                    </div>
                    <div className="col-auto">
                        <Form.Label htmlFor="mandatDateEnd">Au</Form.Label>
                        <DatePicker
                            locale="fr"
                            id="date-period-start"
                            selected={periodDateEnd}
                            onChange={(date) => {
                                checkDateEnd(date)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"jj/mm/aaaa"}
                            minDate={periodDateStart}
                        />
                    </div>
                </div>
                <h3 className="mt-6 mb-3 text-primary">Mandats</h3>
                <div className="mb-4">
                    <Select
                        value={selectedCommittees}
                        isMulti
                        name="committees"
                        options={optionsSelectCommittee}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={(e) => {
                            if (dateStartPeriode !== "" && dateStartPeriode !== null) {
                                let diff = selectedCommittees.filter(item => !e.includes(item))
                                if (diff.length > 0) {
                                    dispatch(removeCommittee({removedCommittees: diff}))
                                } else {
                                    dispatch(addCommittee({selectedCommittees: e}))
                                }
                            } else {
                                dispatch(displayMessage({
                                    text: "Veuillez saisir une date de début de période avant d'ajouter un mandat",
                                    status: "error"
                                }))
                            }
                        }}
                    />
                </div>

                {mandatesToDisplay.map((mandate, index) => {
                    return <ItemMandate key={index} mandate={mandate} index={index}/>
                })}
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary">
                    <i className="mai me-2">check</i> Valider
                </button>
            </Modal.Footer>
        </form>
    </Modal>
}
