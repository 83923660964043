import React, { Fragment, ReactElement } from 'react'
import ReactTooltip, { Place, Effect, Type } from 'react-tooltip'

const { v4: uuidv4 } = require('uuid')

type Props = {
    message: string;
    children: ReactElement | string;
    place?: Place;
    type?: Type;
    effect?: Effect;
    className?: string;
}

const Tooltip = ({ message, children, place, type, effect, className }: Props) => {
    const uuid = uuidv4()

    return <Fragment>
        <span data-tip="" data-for={uuid}>
            {children}
        </span>
        <ReactTooltip
            id={uuid}
            aria-label={uuid}
            html={true}
            type={type || undefined}
            place={place || 'top'}
            effect={effect || 'solid'}
            className={`${(className ? className + ' ' : '')}react-tooltip bs-custom-tooltip`}
        >
            {message}
        </ReactTooltip>
    </Fragment>
}

export default Tooltip
