import {useState} from "react";

export default function ToggleMeetings() {
    const [isActive, setActive] = useState<boolean>(false);

    const handleToggle = () => {
        setActive(!isActive);
        const accordionElements = document.getElementsByClassName('accordion-collapse')
        for (let i = 0; i < accordionElements.length; i++) {
            if (!isActive) {
                accordionElements[i].classList.add('show')
            } else {
                accordionElements[i].classList.remove('show')
            }
        }
    };

    return (
        <button id="buttonToggleAccordion" className={"btn btn-outline-primary border-0 " + (isActive ? "open" : "close")} onClick={handleToggle}>
            <span className="open">
                <i className="mai fs-md me-2">expand_more</i> Tout ouvrir
            </span>
            <span className="close">
                <i className="mai fs-md me-2">expand_less</i> Tout fermer
            </span>
        </button>
    );
}
