import React from "react"
import {Meeting} from "../../api/MeetingAPI"
import {Committee} from "../../api/CommitteeAPI"
import {useAppSelector} from "../../redux/Hooks"
import ItemDocumentsByYearBlock from "./ItemDocumentsByYearBlock"
import LoadingSpinner from "../LoadingSpinner"

export default function DocumentsByYear(props: { committee: Committee | null }) {
    const meetings = useAppSelector((state) => state.meeting.items)
    const loading = useAppSelector((state) => state.meeting.loading)

    if (null === props.committee || meetings.length === 0) {
        return <></>
    }

    // On récupère les années à afficher
    const years = getYears({meetings: meetings})

    let link = ""
    if (null !== props.committee) {
        link = `/documents/${props.committee.slug}/`
    }

    // On veut afficher x documents par année
    return <>
        {
            loading ?
                <LoadingSpinner/> :
                (
                    years.map((year) => (
                        <ItemDocumentsByYearBlock key={year} committee={props.committee} link={`${link}${year}`}
                                                  year={year}/>
                    ))
                )}</>
}

export function getYears(props: { meetings?: Meeting[] }) {
    let years: Array<number> = []

    if (props.meetings === undefined) {
        return years
    }

    props.meetings.forEach((meeting) => {
        let currentYear = (new Date(meeting.title)).getFullYear()
        const isFound = years.some(element => {
            return element === currentYear
        })
        if (!isFound) {
            years.push(currentYear)
        }
    })
    return years
}
