import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {setModalAdminShow, setModalAdminType} from "../../redux/Meeting/MeetingSlice"
import {
    setDocuments,
    setMeetingDate,
    setMeetingTime,
    setPlace,
    setSelectedMeeting,
    setVideoLink
} from "../../redux/Meeting/MeetingFormSlice"
import {setCommitteeSelected} from "../../redux/Committee/CommitteeSlice"
import {hasRole} from "../../utils/UserTools"
import {RootState} from "../../redux/Store"
import {ROLES_MANAGE_MEETING} from "../../api/UserAPI"
import {getFormattedLocalisedDate} from "../../utils/Date"

export function AddMeetingButton() {
    const dispatch = useAppDispatch()
    const committees = useAppSelector((state) => state.committee.items)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    function setSelected() {
        dispatch(setModalAdminShow(true))
        dispatch(setModalAdminType("creation"))
        dispatch(setMeetingDate(getFormattedLocalisedDate(new Date(), "MM/dd/yyyy")))
        dispatch(setMeetingTime("09:00"))
        dispatch(setCommitteeSelected(committees[0]))
        dispatch(setSelectedMeeting(undefined))
        dispatch(setPlace(""))
        dispatch(setVideoLink(""))
        dispatch(setDocuments([]))
    }

    if (hasRole(ROLES_MANAGE_MEETING, roles)) {
        return <button className="btn btn-lg btn-icon rounded-circle btn-tertiary" onClick={() => setSelected()}>
            <i className="mai fs-lg">add</i>
        </button>
    }
    return <></>
}
