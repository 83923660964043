import {setDisplayPopinDeleteCategoryConfirm, setShowModalAddToolCategory} from "../../redux/Toolkit/ToolkitSlice"
import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {deleteDocumentCategory} from "../../api/DocumentAPI"
import {Modal} from "react-bootstrap"

export default function PopinDeleteCategoryConfirm() {

    const dispatch = useAppDispatch()
    const displayPopinDeleteCategoryConfirm = useAppSelector((state: RootState) => state.toolkit.displayPopinDeleteCategoryConfirm)
    const toolCategorySelected = useAppSelector((state: RootState) => state.toolkit.toolCategorySelected)

    const deleteToolCate = async () => {
        if (toolCategorySelected !== null) {
            dispatch(displayMessage({text: "Suppression de la catégorie en cours"}))
            let response = await dispatch(deleteDocumentCategory({toolCategory: toolCategorySelected}))
            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({
                    text: "Suppression de la catégorie effectuée avec succès !",
                    status: "success"
                }))
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({text: "Echec de la suppression de la catégorie", status: "error"}))
            }
        }
    }

    return (
        <Modal className="modal-confirm" size="sm" show={displayPopinDeleteCategoryConfirm}
               backdropClassName="modal-backdrop-confirm">
            <Modal.Header>
                <Modal.Title as="h3"><span className="mai me-2">warning</span>Confirmation requise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes vous sûr de vouloir supprimer la catégorie {toolCategorySelected !== null ?
                <strong>{toolCategorySelected.title}</strong> : ""} ?
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="button" className="btn btn-main-gray-light" data-bs-dismiss="modal"
                                onClick={() => {
                                    dispatch(setDisplayPopinDeleteCategoryConfirm(false))
                                    dispatch(setShowModalAddToolCategory(true))
                                }}>Non
                        </button>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-danger" onClick={() => {
                            deleteToolCate()
                        }}>Oui
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
