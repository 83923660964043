import {Link} from 'react-router-dom';

export default function Breadcrumb(props: { current: string | number | undefined, nav?: Array<Array<string|undefined>> }) {
    let renderedNav: Array<JSX.Element> = []

    if (props.nav !== undefined) {
        props.nav.forEach((item, index) => {
            if (item[1] !== null) {
                renderedNav.push(
                    <li key={index + 1} className="breadcrumb-item">
                        <Link to={(item[1] !== null && item[1] !== undefined ? item[1] : '')}>
                            {item[0]}
                        </Link>
                    </li>
                )
            }
        })
    }

    return <nav aria-label="breadcrumb" className="mb-4">
        <ol className="breadcrumb">
            <li key={0} className="breadcrumb-item">
                <Link to={'/accueil'}>Accueil</Link>
            </li>
            {renderedNav}
            <li key={renderedNav.length + 1} className="breadcrumb-item active" aria-current="page">{props.current}</li>
        </ol>
    </nav>
}
