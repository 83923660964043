import React, {useState, useEffect, ChangeEvent, SyntheticEvent, Fragment} from "react"
import {Form, Button, Row, Col, Container} from "react-bootstrap"
import type {RootState} from "../redux/Store"
import {useAppSelector, useAppDispatch} from "../redux/Hooks"
import {connectUser, setForgottenPassword} from "../redux/User/UserSlice"
import {sendChangePasswordEmail, getUserByToken} from "../api/UserAPI"
import {useNavigate} from "react-router-dom"
import PageHeader from "./PageHeader"
import {displayMessage} from "../redux/Message/MessageSlice"
import {getCookie} from "../utils/cookies"
import {authLogin, authLogout} from "../services/auth"

export default function Login() {

    const dispatch = useAppDispatch()
    const forgottenPassword = useAppSelector((state: RootState) => state.app.forgottenPassword)

    const navigate = useNavigate()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [hidePassword, setHidePassword] = useState<boolean>(true)

    const errorMessage: string = "Identification erronée, veuillez saisir un identifiant et un mot de passe valide pour vous connecter."

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (forgottenPassword) {
            dispatch(displayMessage({text: "Envoi de l'email en cours", status: "info"}))
            const response = await dispatch(sendChangePasswordEmail({email: username}))
            if (response.meta.requestStatus === "fulfilled" && response.payload !== undefined && response.payload !== null) {
                let status: string = "success"
                if (response.payload.error) {
                    status = "error"
                }
                dispatch(displayMessage({text: response.payload.message, status: status}))
            } else {
                dispatch(displayMessage({
                    text: "Echec de l'envoi de l'email de modification de mot de passe",
                    status: "error"
                }))
            }
            return
        }

        dispatch(displayMessage({text: "Connexion en cours", status: "info"}))
        authLogin({username, password}).then((data) => {
            dispatch(connectUser({token: data.token, username: username}))
            data.token && dispatch(getUserByToken({token: data.token, context: "login"})).then(res => {
                if (res &&
                    res?.meta.requestStatus === "fulfilled" &&
                    res?.payload?.user !== undefined
                ) {
                    dispatch(displayMessage({text: "Connexion effectuée avec succès", status: "success"}))
                    return navigate("/accueil")
                } else {
                    const dataLogout = {
                        accessToken: getCookie("accessToken") || "",
                        refreshToken: getCookie("refreshToken") || ""
                    }
                    authLogout(dataLogout)
                    dispatch(displayMessage({
                        text: errorMessage,
                        status: "error"
                    }))
                    return navigate("/s-identifier")
                }
            })
        }).catch(err => {
            if (err.code === "ERR_BAD_REQUEST") {
                dispatch(displayMessage({
                    text: errorMessage,
                    status: "error"
                }))
            } else {
                dispatch(displayMessage({text: "Echec de la connexion", status: "error"}))
            }
        })
    }

    useEffect(() => {
        document.body.classList.add("page-login")

        return () => {
            document.body.classList.remove("page-login")
        }
    }, [])

    return (
        <Fragment>
            <PageHeader title="Bienvenue" subTitle="Connexion"/> :
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Form className="bg-white p-4 p-lg-8" onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                            handleSubmit(e).then()
                        }}>
                            {!forgottenPassword ?
                                <Fragment>
                                    <h3 className="text-primary mb-4">Veuillez vous identifier</h3>
                                    <Row className="row-h">
                                        <Col xl>
                                            <div className="form-label">Identifiant *</div>
                                            <div className="input-group">
                                                <Form.Control type="email" value={username} required
                                                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                  setUsername(e.target.value)
                                                              }}
                                                />
                                                <div className="input-group-text"><span className="mai">person</span>
                                                </div>
                                            </div>
                                            <div className="form-text mb-0 help-text">
                                                <i className="maio me-1">info</i>
                                                Votre identifiant est votre adresse email
                                            </div>
                                        </Col>
                                        <Col xl>
                                            <div className="form-label">Mot de passe *</div>
                                            <div className="input-group">
                                                <Form.Control type={hidePassword ? "password" : "text"} value={password}
                                                              required
                                                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                  setPassword(e.target.value)
                                                              }}
                                                />
                                                <button type="button" className="input-group-text" onClick={(e) => {
                                                    e.preventDefault()
                                                    setHidePassword(!hidePassword)
                                                }}>
                                                    <span className="mai">{hidePassword ? "visibility" : "lock"}</span>
                                                </button>
                                            </div>
                                            <div className="form-text mb-0 help-text">
                                                <i className="maio me-1">info</i>
                                                Saisissez le mot de passe correspondant à votre identifiant sur la
                                                plateforme
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-5 row row-sm row-sm-h align-items-end justify-content-between">
                                        <div className="col-auto">
                                            <Button variant="outline-border-gray text-dark" type="submit">
                                                <i className="mai me-2 fs-md text-primary">save</i>
                                                <span className="fw-semibold">Se connecter</span>
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn btn-link text-danger text-hover-dark fw-semibold pe-0"
                                                onClick={() => {
                                                    dispatch(setForgottenPassword(true))
                                                }}>
                                                <i className="mai me-2">lock</i> Mot de passe oublié
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <h3 className="text-primary mb-4">
                                        Réinitialisation de votre mot de passe. Entrez
                                        l'adresse e-mail associée à votre compte, et nous vous enverrons par e-mail un
                                        lien pour réinitialiser votre mot de passe.
                                    </h3>
                                    <div className="form-label">Identifiant</div>
                                    <div className="input-group">
                                        <Form.Control type="email" value={username}
                                                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                          setUsername(e.target.value)
                                                      }}
                                        />
                                        <div className="input-group-text"><span className="mai">person</span></div>
                                    </div>
                                    <div className="form-text mb-0 help-text">
                                        <i className="maio me-1">info</i>Votre identifiant est votre adresse email
                                    </div>
                                    <Row className="mt-5 row-sm row-sm-h align-items-end justify-content-between">
                                        <Col xs="auto">
                                            <Button variant="outline-border-gray" className="text-dark" type="submit">
                                                <i className="mai me-2 fs-md text-primary">lock</i>
                                                <span className="fw-semibold">Modifier mon mot de passe</span>
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <button className="btn btn-link fw-semibold pe-0" onClick={() => {
                                                dispatch(setForgottenPassword(false))
                                            }}>
                                                <i className="mai me-2">save</i> Revenir à la page de connexion
                                            </button>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
