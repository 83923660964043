import {createSlice} from "@reduxjs/toolkit"
import {Meeting} from "../../api/MeetingAPI"
import {
    deleteDocument,
    Document,
    fetchMeetingDocuments, TYPE_CAT_PV,
    uploadDocument
} from "../../api/DocumentAPI"
import {ExpectedDocument, fetchExpectedDocuments} from "../../api/ExpectedDocumentAPI"
import {getToday} from "../../utils/Date"

export interface MeetingFormState {
    loading: boolean,
    selectedMeeting?: Meeting,
    published?: boolean,
    special?: boolean,
    meetingDate?: string,
    meetingTime?: string,
    lunchAvailable?: boolean,
    oldMeetingDateTime?: string,
    place: string,
    videoLink: string,
    documentIndex: number,
    expectedDocuments: ExpectedDocument[],
    selectedDocument?: Document,
    nbExpectedDocuments: number,
    nbMoreDocuments: number,
    documents: Document[],
    documentFilePath: string,
    errors: string[],
    flagRefreshDocumentUsers: boolean
}

const initialState: MeetingFormState = {
    loading: false,
    selectedMeeting: undefined,
    published: true,
    lunchAvailable: false,
    special: false,
    place: "",
    videoLink: "",
    documentIndex: 0,
    expectedDocuments: [],
    selectedDocument: undefined,
    nbExpectedDocuments: 0,
    nbMoreDocuments: 0,
    documents: [],
    documentFilePath: "",
    errors: [],
    flagRefreshDocumentUsers: false
}

export const MeetingFormSlice = createSlice({
    name: "meetingForm",
    initialState,
    reducers: {
        initDocuments: (state, action) => {
            const newArray: Document[] = []
            for (let i = 0; i < action.payload.length; i++) {
                let data: Document
                if (state.documents[i] === undefined) {
                    data = {
                        id: 0,
                        title: ""
                    }
                } else {
                    data = state.documents[i]
                }
                newArray.push(data)
            }
            state.documents = newArray
        },
        resetData: (state) => {
            state.loading = false
            state.selectedMeeting = undefined
            state.published = true
            state.lunchAvailable = false
            state.special = false
            state.place = ""
            state.videoLink = ""
            state.documentIndex = 0
            state.expectedDocuments = []
            state.selectedDocument = undefined
            state.documents = []
            state.documentFilePath = ""
            state.errors = []
            state.documents = []
        },
        setLunchAvailable: (state, action) => {
            state.lunchAvailable = action.payload
        },
        setPublished: (state, action) => {
            state.published = action.payload
        },
        setSpecial: (state, action) => {
            state.special = action.payload
        },
        setSelectedMeeting: (state, action) => {
            if (action.payload !== undefined) {
                state.oldMeetingDateTime = action.payload.title
            }
            state.selectedMeeting = action.payload
        },
        setMeetingDate: (state, action) => {
            state.meetingDate = action.payload
        },
        setMeetingTime: (state, action) => {
            state.meetingTime = action.payload
        },
        setPlace: (state, action) => {
            state.place = action.payload
        },
        setVideoLink: (state, action) => {
            state.videoLink = action.payload
        },
        setSelectedDocument: (state, action) => {
            state.selectedDocument = action.payload
        },
        setDocumentIndex: (state, action) => {
            state.documentIndex = action.payload
        },
        setDocumentTitle: (state, action) => {

            if (!state.documents[state.documentIndex]) {
                state.documents[state.documentIndex] = {
                    id: 0,
                    title: "",
                    expectedDocument: action.payload.expectedDocument
                }
            }
            state.documents[state.documentIndex].title = action.payload.value
            state.documents[state.documentIndex].expectedDocument = action.payload.expectedDocument
        },
        setDocumentDate: (state, action) => {
            if (!state.documents[state.documentIndex]) {
                state.documents[state.documentIndex] = {
                    id: 0,
                    title: "",
                    expectedDocument: action.payload.expectedDocument
                }
            }
            state.documents[state.documentIndex].publishedDate = action.payload.value
            state.documents[state.documentIndex].expectedDocument = action.payload.expectedDocument
        },
        setDocumentFilePath: (state, action) => {
            state.documentFilePath = action.payload
        },
        setDocuments: (state, action) => {
            state.documents = action.payload
        },
        setExpectedDocuments: (state, action) => {
            state.expectedDocuments = action.payload
        },
        setNbExpectedDocuments: (state, action) => {
            state.nbExpectedDocuments = action.payload
        },
        setNbMoreDocuments: (state, action) => {
            state.nbMoreDocuments = action.payload
        },
        addDocument: (state, action) => {
            const newDocument: Document = {
                id: 0,
                title: "",
                publishedDate: getToday()
            }
            if (action.payload.type === TYPE_CAT_PV) {
                let expectedPvDocument: ExpectedDocument | undefined
                state.expectedDocuments.forEach((item) => {
                    if (item.category?.type === TYPE_CAT_PV) {
                        expectedPvDocument = item
                    }
                })
                newDocument.expectedDocument = expectedPvDocument
                state.documents.push(newDocument)

            } else {
                state.documentIndex = state.nbExpectedDocuments + state.nbMoreDocuments + 1
                state.documents[state.documentIndex] = newDocument
            }
        },
        setFlagRefreshDocumentUsers: (state, action) => {
            state.flagRefreshDocumentUsers = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMeetingDocuments.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchMeetingDocuments.fulfilled, (state, action) => {
                state.loading = false
                state.documents = action.payload.documents
            })
            .addCase(fetchMeetingDocuments.rejected, (state) => {
                state.loading = false
            })
            .addCase(fetchExpectedDocuments.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchExpectedDocuments.fulfilled, (state, action) => {
                state.loading = false
                state.expectedDocuments = action.payload
            })
            .addCase(fetchExpectedDocuments.rejected, (state) => {
                state.loading = false
            })
            .addCase(uploadDocument.pending, (state) => {
                state.loading = true
            })
            .addCase(uploadDocument.fulfilled, (state, action) => {
                state.loading = false
                state.documents[state.documentIndex] = action.payload
                delete state.errors[state.documentIndex]
                state.flagRefreshDocumentUsers = true
            })
            .addCase(uploadDocument.rejected, (state, action) => {
                state.loading = false
                let error: string | undefined
                if (action.payload) {
                    error = action.payload.error
                } else {
                    error = action.error.message
                }
                state.errors[state.documentIndex] = `Votre fichier n'a pas pu être uploadé. ${error || ""}`
            })
            .addCase(deleteDocument.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.loading = false
                state.documents = state.documents.filter((doc, index) => doc.id !== action.payload && index !== state.documentIndex)
                state.documentFilePath = ""
            })
            .addCase(deleteDocument.rejected, (state) => {
                state.loading = false
            })
    }
})

export const {
    initDocuments,
    resetData,
    setLunchAvailable,
    setPublished,
    setSpecial,
    setSelectedMeeting,
    setMeetingDate,
    setMeetingTime,
    setPlace,
    setVideoLink,
    setSelectedDocument,
    setDocumentTitle,
    setDocumentDate,
    setDocumentIndex,
    setDocuments,
    setExpectedDocuments,
    setDocumentFilePath,
    addDocument,
    setNbExpectedDocuments,
    setNbMoreDocuments,
    setFlagRefreshDocumentUsers
} = MeetingFormSlice.actions

export default MeetingFormSlice.reducer
