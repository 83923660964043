import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {useNavigate} from "react-router-dom"
import {Form} from "react-bootstrap"
import {setProperty} from "../../redux/User/AddUserSlice"

export default function PopinAddUserForm() {

    const dispatch = useAppDispatch()
    const profil = useAppSelector((state: RootState) => state.addUser.profil)
    const navigate = useNavigate()

    const redirectForm = () => {
        let elem = document.getElementById("btn-close-form-select-profil")
        if (elem !== null) {
            elem.click()
        }
        if (profil === "commissaire-administrateur") {
            return navigate(`/ajout-admin`)
        } else {
            return navigate(`/ajout-assistant`)
        }
    }

    return (
        <div className="modal modal-confirm fade" id="modal-add-user-form">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">CHOIX DU PROFIL</h3>
                        <button type="button" data-bs-dismiss="modal" id="btn-close-form-select-profil"
                                className="btn-close"></button>
                    </div>
                    <div className="modal-body">
                        <h3 className="text-primary">Quel profil à l'utilisateur que vous êtes sur le point de créer
                            ?</h3>

                        <div className="row row-xs row-sm-h justify-content-sm-between">
                            {[
                                {key: "commissaire-administrateur", label: "Commissaire-Administrateur"},
                                {key: "assistante", label: "Assistant.e"},
                                {key: "membre-codir", label: "Membres ATPRO"},
                                {key: "webmaster", label: "Webmaster"},
                            ].map((data, index: number) => (
                                <div className="col-auto" key={index}>
                                    <Form.Check
                                        key={index}
                                        type="radio"
                                        name="user-role"
                                        id={data.key}
                                        label={data.label}
                                        className="text-main-gray form-check-lg"
                                        onClick={() => {
                                            dispatch(setProperty({property: "profil", value: data.key}))
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" onClick={() => {
                            redirectForm()
                        }}>
                            <i className="mai fs-md me-2">check</i> Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
