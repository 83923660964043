import {Committee} from "../../api/CommitteeAPI";
import {useDispatch} from "react-redux";
import {setCommitteeSelected, setShow} from "../../redux/Committee/CommitteeSlice";
import {setColor, setContent, setDocumentForAll, setTitle} from "../../redux/Committee/CommitteeFormSlice";
import {hasRole} from "../../utils/UserTools";
import { ROLES_MANAGE_COMMITTEE } from "../../api/UserAPI";
import {useAppSelector} from "../../redux/Hooks";
import {RootState} from "../../redux/Store";

export function EditCommittee(props: {committee: Committee}) {
    const dispatch = useDispatch();
    const roles = useAppSelector((state: RootState) => state.app.roles)

    function setSelected(committee: Committee) {
        dispatch(setCommitteeSelected(committee))
        dispatch(setTitle(props.committee.title))
        dispatch(setContent(props.committee.content))
        dispatch(setColor(props.committee.color))
        dispatch(setDocumentForAll(props.committee.documentForAll))
        dispatch(setShow(true))
    }

    if (hasRole(ROLES_MANAGE_COMMITTEE, roles)) {
        return <button className="btn-less link text-danger text-hover-dark" onClick={() => setSelected(props.committee)}><span className="mai me-2">edit</span> Éditer</button>
    }
    return <></>
}
