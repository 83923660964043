import {useEffect, SyntheticEvent, useState} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {
    addCommittee,
    removeCommittee, setPeriodToEdit, setProperty,
} from "../../redux/User/AddUserSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Form, Modal} from "react-bootstrap"
import Select from "react-select"
import {Committee} from "../../api/CommitteeAPI"
import {Mandate} from "../../api/MandateAPI"
import {formatDateInputDate} from "../../utils/Functions"
import {Period} from "../../api/MandateAPI"
import {CommitteeSelectItem} from "../../utils/CommonInterfaces"
import {updatePeriodsAdmin} from "../../api/UserAPI"
import {useParams} from "react-router-dom"
import DatePicker from "react-datepicker"
import ItemMandate from "./ItemMandate"
import {getFormattedLocalisedDate} from "../../utils/Date"

export default function LightBoxAddMandat(props: { showModalAddPeriod: boolean, editMode: boolean }) {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const selectedCommittees = useAppSelector((state: RootState) => state.addUser.selectedCommittees)
    const dateStartPeriode = useAppSelector((state: RootState) => state.addUser.dateStartPeriod)
    const dateEndPeriode = useAppSelector((state: RootState) => state.addUser.dateEndPeriod)
    const mandates = useAppSelector((state: RootState) => state.addUser.mandates)
    const periods = useAppSelector((state: RootState) => state.addUser.periods)
    const periodToEdit = useAppSelector((state: RootState) => state.addUser.periodToEdit)
    const editPeriodMode = useAppSelector((state: RootState) => state.addUser.editPeriodMode)
    const params = useParams()

    const [periodDateStart, setPeriodDateStart] = useState<Date | null>(null)
    const [periodDateEnd, setPeriodDateEnd] = useState<Date | null>(null)
    const [mandatesToDisplay, setMandatesToDisplay] = useState<Mandate[]>([])

    useEffect(() => {
        let defaultValueSelectCommittee: CommitteeSelectItem[] = []
        let mandatesForSelectMultiple: Mandate[] = []
        if (editPeriodMode && periodToEdit !== null) {
            mandatesForSelectMultiple = periodToEdit.mandates
        } else {
            mandatesForSelectMultiple = mandates
        }

        if (mandatesForSelectMultiple !== undefined) {
            mandatesForSelectMultiple.forEach((mandate: Mandate, index: number) => {
                if (!defaultValueSelectCommittee.some(item => Number(item.value) === mandate.committee.id)) {
                    defaultValueSelectCommittee.push({
                        value: mandate.committee.id, label: mandate.committee.title
                    })
                }
            })
            dispatch(setProperty({property: "selectedCommittees", value: defaultValueSelectCommittee}))
        }

        dispatch(fetchCommittees({}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (editPeriodMode && periodToEdit !== null) {
            dispatch(setProperty({property: "dateStartPeriod", value: periodToEdit.dateStart}))
            setPeriodDateStart(new Date(periodToEdit.dateStart))
            dispatch(setProperty({property: "dateEndPeriod", value: periodToEdit.dateEnd}))
            periodToEdit.dateEnd && setPeriodDateEnd(new Date(periodToEdit.dateEnd))
        } else {
            dispatch(setProperty({property: "dateStartPeriod", value: formatDateInputDate()}))
            setPeriodDateStart(new Date())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPeriodMode, periodToEdit])

    useEffect(() => {
        setMandatesToDisplay((editPeriodMode && periodToEdit !== null) ? periodToEdit.mandates : mandates)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mandates, periodToEdit?.mandates])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        let dateStartToCheck: string | Date = dateStartPeriode
        if (periodToEdit !== null && editPeriodMode) {
            dateStartToCheck = periodToEdit.dateStart
        }
        if (dateStartToCheck === "" || dateStartToCheck === null) {
            dispatch(displayMessage({text: "Veuillez saisir une date de début de période de mandats", status: "error"}))
            return false
        }

        let mandatesToUse = mandates
        if (editPeriodMode && periodToEdit !== null) {
            mandatesToUse = periodToEdit.mandates
        }

        if (mandatesToUse.length > 0) {
            for (let mandate of mandatesToUse) {

                if (mandate.dateStart === "" || mandate.dateStart === null) {
                    dispatch(displayMessage({
                        text: "Veuillez saisir une date de début pour tout les mandats",
                        status: "error"
                    }))
                    return false
                }

                if (mandate.status !== 0 && mandate.status !== 1) {
                    dispatch(displayMessage({text: "Veuillez saisir un statut pour chaque mandat", status: "error"}))
                    return false
                }

                if (mandate.roles.length === 0) {
                    dispatch(displayMessage({
                        text: "Vous devez choisir au moins une fonction pour chaque mandats",
                        status: "error"
                    }))
                    return false
                }

            }
        } else {
            dispatch(displayMessage({text: "Veuillez créer au moins un mandat pour cette période", status: "error"}))
            return false
        }

        dispatch(displayMessage({text: ""}))

        let newPeriods: Period[] = []
        if (!editPeriodMode) {
            newPeriods = [...periods]
            newPeriods.push({
                dateStart: dateStartPeriode,
                dateEnd: dateEndPeriode === "" ? "" : dateEndPeriode,
                active: true,
                mandates: mandates
            })
            dispatch(displayMessage({text: "La période de mandats a été ajoutée avec succès !", status: "success"}))
        } else if (periodToEdit !== null) {
            newPeriods = []
            for (let period of periods) {
                if (period.id === periodToEdit.id) {
                    newPeriods.push(periodToEdit)
                } else {
                    newPeriods.push(period)
                }
            }

            if (props.editMode) {
                dispatch(displayMessage({text: "Modification en cours", status: "info"}))
                const result = await dispatch(updatePeriodsAdmin({
                    user: {
                        id: Number(params.id),
                        periods: newPeriods
                    }
                }))
                if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "fulfilled") {
                    dispatch(displayMessage({
                        text: "La période de mandats a été modifiée avec succès !",
                        status: "success"
                    }))
                } else if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "rejected") {
                    dispatch(displayMessage({
                        text: "Echec de la modification de la période de mandats",
                        status: "error"
                    }))
                    return false
                }
            } else {
                dispatch(displayMessage({
                    text: "La période de mandats a été modifiée avec succès !",
                    status: "success"
                }))
            }

        }
        dispatch(setProperty({property: "periods", value: newPeriods}))
        dispatch(setProperty({property: "showModalAddPeriod", value: false}))
    }

    let optionsSelectCommittee: CommitteeSelectItem[] = []

    if (committees !== undefined) {
        committees.forEach((committee: Committee) => {
            optionsSelectCommittee.push({
                value: committee.id, label: committee.title !== undefined ? committee.title : ""
            })
        })
    }

    function closeModal() {
        dispatch(setProperty({property: "showModalAddPeriod", value: false}))
        dispatch(setProperty({property: "editPeriodMode", value: false}))
        dispatch(setPeriodToEdit(null))
        dispatch(displayMessage({text: ""}))
    }

    return <Modal className="modal-form" show={props.showModalAddPeriod} scrollable onHide={() => {
        closeModal()
    }}>
        <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            handleSubmit(e)
        }}>
            <Modal.Header closeButton>
                <Modal.Title
                    as="h3">{editPeriodMode && props.editMode ? "Edition d'une période de mandat" : "Ajout d'une période de mandat"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row row-sm align-items-center">
                    <div className="col-auto">
                        <Form.Label htmlFor="date-period-start">Période du *</Form.Label>
                        <DatePicker
                            locale="fr"
                            id="date-period-start"
                            selected={periodDateStart}
                            required={true}
                            onChange={(date) => {
                                dispatch(displayMessage({text: ""}))
                                dispatch(setProperty({
                                    property: "dateStartPeriod",
                                    value: date !== null ? getFormattedLocalisedDate(new Date(date), "yyyy-MM-dd") : null
                                }))
                                setPeriodDateStart(date)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"jj/mm/aaaa"}
                        />
                    </div>
                    <div className="col-auto">
                        <Form.Label htmlFor="mandatDateEnd">Au</Form.Label>
                        <DatePicker
                            locale="fr"
                            id="date-period-end"
                            selected={periodDateEnd}
                            onChange={(date) => {
                                dispatch(displayMessage({text: ""}))
                                dispatch(setProperty({
                                    property: "dateEndPeriod",
                                    value: date !== null ? getFormattedLocalisedDate(new Date(date), "yyyy-MM-dd") : undefined
                                }))
                                setPeriodDateEnd(date)
                            }}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"jj/mm/aaaa"}
                            minDate={periodDateStart}
                        />
                    </div>
                </div>
                <h3 className="mt-6 mb-3 text-primary">Mandats</h3>
                <div className="mb-4">
                    <Select
                        id="instanceSelect"
                        value={selectedCommittees}
                        isMulti
                        name="committees"
                        options={optionsSelectCommittee}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={(e) => {
                            let dateStartPeriodeToCheck: string | Date = dateStartPeriode
                            if (editPeriodMode && periodToEdit !== null) {
                                dateStartPeriodeToCheck = periodToEdit.dateStart
                            }
                            if (dateStartPeriodeToCheck !== "" && dateStartPeriodeToCheck !== null) {
                                let diff = selectedCommittees.filter(item => !e.includes(item))
                                if (diff.length > 0) {
                                    dispatch(removeCommittee({removedCommittees: diff}))
                                } else {
                                    dispatch(addCommittee({selectedCommittees: e}))
                                }
                            } else {
                                dispatch(displayMessage({
                                    text: "Veuillez saisir une date de début de période avant d'ajouter un mandat",
                                    status: "error"
                                }))
                            }
                        }}
                    />
                </div>

                {mandatesToDisplay.map((mandate, index) => {
                    return <ItemMandate key={index} mandate={mandate} index={index}/>
                })}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>
                    <i className="mai me-2">close</i> Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                    <i className="mai me-2">check</i> Valider
                </button>
            </Modal.Footer>
        </form>
    </Modal>
}
