import {createAsyncThunk} from "@reduxjs/toolkit"
import {Options, toQuery} from "../utils/Options"
import {axiosInstance, axiosInstanceJustJson, axiosInstanceMultipart, axiosInstancePatch} from "../utils/http-common"
import type {AxiosError} from "axios"
import {
    FULL_DOCUMENT_API,
    FULL_DOCUMENT_CATEGORIES_API,
    FULL_FILE_TOOL_UPLOAD_API,
    FULL_MEETING_ZIP_API,
    FULL_TOOLCATEGORY_API,
    FULL_UPDATETOOLCATEGORIES_API
} from "../utils/Data"
import {ExpectedDocumentCategory} from "./ExpectedDocumentAPI"
import {ValidationErrors} from "../utils/ValidationErrors"
import {DocumentUser} from "./DocumentUserAPI"
import {Meeting} from "./MeetingAPI"

const AxiosInstance = axiosInstance()
const AxiosInstanceJson = axiosInstanceJustJson()
const AxiosInstanceMultipart = axiosInstanceMultipart()
const AxiosInstancePatch = axiosInstancePatch()

export const TYPE_CAT_CONVOCATION = 2
export const TYPE_CAT_PV = 1
export const TYPE_CAT_OTHER = 0
export const TYPE_TOOLBOX = "toolbox"
export const TYPE_MEETING = "meeting"

export interface DocumentCategory {
    id?: number;
    title?: string;
    orderView: number;
    type: string;
    documents?: Document[];
}

interface ExpectedDocument {
    id?: number;
    category?: ExpectedDocumentCategory;
}

export interface Document {
    id: number | string;
    oldId?: number;
    title?: string;
    filename?: string;
    filePath?: string | undefined;
    fileOriginalName?: string;
    type?: string;
    published?: boolean;
    documentCategory?: DocumentCategory | string | number;
    expectedDocument?: ExpectedDocument;
    documentUsers?: DocumentUser[]
    active?: boolean;
    fileExtension?: string;
    createdDate?: string;
    updatedDate?: string;
    publishedDate?: string;
    approuved?: boolean;
    meeting?: Meeting
}

export const fetchDocuments = createAsyncThunk<{ meetingId?: number, documents: Document[] }, {

    options?: Options
}>(
    "documents/fetchAll",
    async (documentData) => {
        const {data} = (await AxiosInstance.get(`${FULL_DOCUMENT_API}` + toQuery(documentData.options)))

        return {
            meetingId: documentData.options && documentData.options["meeting.id"] ? documentData.options["meeting.id"] : undefined,
            documents: data["hydra:member"]
        }
    }
)

export const fetchMeetingDocuments = createAsyncThunk<{ meetingId?: number, documents: Document[] }, {

    options?: Options
}>(
    "documents/fetchMeetingAll",
    async (documentData) => {
        const {data} = (await AxiosInstance.get(`${FULL_DOCUMENT_API}` + toQuery(documentData.options)))

        return {
            meetingId: documentData.options && documentData.options["meeting.id"] ? documentData.options["meeting.id"] : undefined,
            documents: data["hydra:member"]
        }
    }
)

export const uploadDocument = createAsyncThunk<Document, {
    file: File,
    documentId: number | string,
    type: string,
    title?: string,
    publishedDate?: string,
    expectedDocumentId: string
}, { rejectValue: ValidationErrors }>(
    "documents/upload",
    async (documentData, {rejectWithValue}) => {
        let formData = new FormData()
        formData.append("file", documentData.file)
        formData.append("id", documentData.documentId.toString())
        formData.append("type", documentData.type)
        formData.append("title", ((documentData.title !== undefined) ? documentData.title : ""))
        formData.append("publishedDate", (documentData.publishedDate !== undefined) ? documentData.publishedDate : "")
        formData.append("expectedDocumentId", documentData.expectedDocumentId)

        try {
            const {data} = (await AxiosInstanceMultipart.post(`${FULL_FILE_TOOL_UPLOAD_API}`, formData))
            return data
        } catch (err) {
            // @ts-ignore
            let error: AxiosError<ValidationErrors> = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const uploadToolDocument = async (documentData: {
    file: File,
    documentId: number | string,
    type: string
}) => {
    let formData = new FormData()
    formData.append("file", documentData.file)
    formData.append("id", documentData.documentId.toString())
    formData.append("type", documentData.type)

    const {data} = (await AxiosInstanceMultipart.post(`${FULL_FILE_TOOL_UPLOAD_API}`, formData))

    return data
}

export const updateDocument = createAsyncThunk<Document, {

    id?: number | string,
    fields: object
}>(
    "documents/patch",
    async (documentData) => {
        const {data} = (await AxiosInstancePatch.patch(`${FULL_DOCUMENT_API}/${documentData.id}`, JSON.stringify(documentData.fields)))
        return data
    }
)

export const deleteDocument = createAsyncThunk<number | string, { id: number | string }, {
    rejectValue: ValidationErrors
}>(
    "document/delete",
    async (documentData) => {

        (await AxiosInstance.delete(`${FULL_DOCUMENT_API}/${documentData.id}`))

        return documentData.id

    })

export function displayDocument(action: string, id: number | string): Promise<Blob> {
    return AxiosInstance.get(
        `${FULL_DOCUMENT_API}/dwn/${id}/${action}`,
        {
            responseType: "blob"
        }
    ).then(response => response.data)
}

export function downloadZip(id: number): Promise<BlobPart> {
    return AxiosInstance.get(
        `${FULL_MEETING_ZIP_API}/${id}`,
        {
            responseType: "blob"
        }
    ).then(response => response.data)
}

export const fetchDocumentCategories = createAsyncThunk<DocumentCategory[], {

    options?: Options
}, { rejectValue: ValidationErrors }>(
    "document_categories/fetchAll",
    async (documentData, {rejectWithValue}) => {
        const {data} = (await AxiosInstance.get(FULL_DOCUMENT_CATEGORIES_API + toQuery(documentData.options)))

        return data["hydra:member"]
    })

export const createDocumentCategory = createAsyncThunk<DocumentCategory, { fields: object }>(
    "document_categories/create",
    async (documentCatData) => {
        const {data} = (await AxiosInstance.post(`${FULL_DOCUMENT_CATEGORIES_API}`, JSON.stringify(documentCatData.fields)))
        return data
    }
)

export const updateDocumentCategory = createAsyncThunk<DocumentCategory, {
    id?: number,
    fields: object
}>(
    "document_categories/patch",
    async (documentCatData) => {
        const {data} = (await AxiosInstancePatch.patch(`${FULL_DOCUMENT_CATEGORIES_API}/${documentCatData.id}`, JSON.stringify(documentCatData.fields)))
        return data
    }
)

// ToolkitSlice

interface ResponseToolCategories {
    response: DocumentCategory
}

interface ResponseTool {
    response: Document
}

export const deleteDocumentCategory = createAsyncThunk<ResponseToolCategories, {
    toolCategory: DocumentCategory
}, { rejectValue: ValidationErrors }>("toolkit/deleteDocumentCategory", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.delete(FULL_TOOLCATEGORY_API + "/" + dt.toolCategory.id)).data

    return {
        response: response
    }

})

export const updateAllToolCategories = createAsyncThunk<ResponseToolCategories, {
    toolCategories: DocumentCategory[]
}, { rejectValue: ValidationErrors }>("toolkit/updateAllToolCategories", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.post(FULL_UPDATETOOLCATEGORIES_API, JSON.stringify(dt.toolCategories))).data

    return {
        response: response
    }

})


export const addTool = createAsyncThunk<ResponseToolCategories, { tool: Document }, {
    rejectValue: ValidationErrors
}>("toolkit/addTool", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstancePatch.patch(FULL_DOCUMENT_API + "/" + dt.tool.id, JSON.stringify(dt.tool))).data

    return {
        response: response
    }

})

export const updateTool = createAsyncThunk<ResponseToolCategories, { tool: Document }, {
    rejectValue: ValidationErrors
}>("toolkit/updateTool", async (dt, {rejectWithValue}) => {

    let toolToSend: Document = {...dt.tool}

    if (typeof toolToSend.documentCategory === "string" && toolToSend.documentCategory.indexOf("document") === -1) {
        toolToSend.id = "/api/documents/" + toolToSend.id
    }
    if (typeof toolToSend.documentCategory === "string" && toolToSend.documentCategory.indexOf("document_categories") === -1) {
        toolToSend.documentCategory = "/api/document_categories/" + toolToSend.documentCategory
    }

    const response = (await AxiosInstancePatch.patch(FULL_DOCUMENT_API + "/" + dt.tool.id, JSON.stringify(toolToSend))).data

    return {
        response: response
    }

})

export const deleteTool = createAsyncThunk<ResponseTool, { tool: Document }, {
    rejectValue: ValidationErrors
}>("toolkit/deleteTool", async (dt, {rejectWithValue}) => {

    await AxiosInstanceJson.delete(FULL_DOCUMENT_API + "/" + dt.tool.id)

    return {
        response: dt.tool
    }

})

