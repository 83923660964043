import {createSlice} from "@reduxjs/toolkit";
import {
    DocumentUser, fetchDocumentUsers,
    patchDocumentUser
} from "../../api/DocumentUserAPI"

export interface DocumentUserState {
    loading: boolean,
    item?: DocumentUser,
    items: DocumentUser[],
    pvUsers: number[],
    validate?: boolean,
    nb: number,
    showConfirm: boolean
}

const initialState: DocumentUserState = {
    loading: false,
    item: undefined,
    items: [],
    pvUsers: [],
    nb: 0,
    showConfirm: false
}

export const DocumentUserSlice = createSlice({
    name: 'document_users',
    initialState,
    reducers: {
        setShowConfirm: (state, action) => {
            state.showConfirm = action.payload
        },
        setItem: (state, action) => {
            state.item = action.payload
        },
        setValidate: (state, action) => {
            state.validate = action.payload
        },
        resetPvUsers: (state) => {
            state.pvUsers = []
        },
        setPvUsers: (state, action) => {
            state.pvUsers = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchDocumentUsers.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(fetchDocumentUsers.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload
                })
                .addCase(fetchDocumentUsers.rejected, (state, action) => {
                    state.loading = false
                    state.item = undefined
                })
                .addCase(patchDocumentUser.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(patchDocumentUser.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = state.items.filter((item) => item.id !== action.payload.id)
                    state.items.push(action.payload)
                    state.validate = true
                    state.showConfirm = false
                })
                .addCase(patchDocumentUser.rejected, (state, action) => {
                    state.loading = false
                    state.item = undefined
                })
        }
})

export const { setItem, setShowConfirm, setValidate, resetPvUsers, setPvUsers } = DocumentUserSlice.actions

export default DocumentUserSlice.reducer
