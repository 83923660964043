import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ExpectedDocument,
    ExpectedDocumentCategory,
    fetchExpectedDocumentCategories,
    fetchExpectedDocuments
} from "../../api/ExpectedDocumentAPI";
import {TYPE_CAT_CONVOCATION, TYPE_CAT_PV} from "../../api/DocumentAPI";
import {CAT_PV} from "../../api/CommitteeAPI";

export interface CommitteeFormState {
    loading: boolean,
    title: string,
    content: string,
    color: string,
    documentForAll: boolean,
    selectedDocument?: ExpectedDocument,
    expectedDocuments: ExpectedDocument[],
    categoryConvocation?: ExpectedDocumentCategory
    categoryPv?: ExpectedDocumentCategory
}

const initialState: CommitteeFormState = {
    loading: false,
    title: '',
    content: '',
    color: '',
    documentForAll: false,
    selectedDocument: undefined,
    expectedDocuments: []
}

const ExpectedDocumentCategoryState = {
    id: CAT_PV,
    title: "Documents",
    type: 0
}

export const CommitteeFormSlice = createSlice({
    name: 'committeeForm',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setContent: (state, action) => {
            state.content = action.payload
        },
        setColor: (state, action) => {
            state.color = action.payload
        },
        setDocumentForAll: (state, action) => {
            state.documentForAll = action.payload
        },
        setExpectedDocumentSelected: (state, action) => {
            state.selectedDocument = action.payload
        },
        setExpectedDocumentNotify: (state, action) => {
            state.expectedDocuments[action.payload.index].notify = action.payload.value
        },
        setExpectedDocumentTitle: (state, action) => {
            state.expectedDocuments[action.payload.index].title = action.payload.value
        },
        setExpectedDocumentDelay1: (state, action) => {
            state.expectedDocuments[action.payload.index].delay1 = parseInt(action.payload.value)
        },
        setExpectedDocumentDelay2: (state, action) => {
            let value = action.payload.value
            if (value !== "") {
                value = parseInt(action.payload.value)
            }
            state.expectedDocuments[action.payload.index].delay2 = value
        },
        setExpectedDocumentAvailable: (state, action) => {
            state.expectedDocuments[action.payload.index].available = action.payload.value
        },
        removeExpectedDocument: (state, action) => {
            state.expectedDocuments = state.expectedDocuments.filter((item, index) => index !== action.payload)
        },
        addExpectedDocument: (state, action: PayloadAction<ExpectedDocumentCategory|undefined>) => {
            state.expectedDocuments.push({
                title: "",
                delay1: 0,
                delay2: 0,
                category: (action.payload !== undefined)?action.payload:ExpectedDocumentCategoryState
            })
        },
        resetExpectedDocuments: (state) => {
            state.expectedDocuments = []
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchExpectedDocuments.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchExpectedDocuments.fulfilled, (state, action) => {
                    state.loading = false
                    state.expectedDocuments = action.payload
                })
                .addCase(fetchExpectedDocuments.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchExpectedDocumentCategories.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchExpectedDocumentCategories.fulfilled, (state, action) => {
                    state.loading = false
                    const category = action.payload[0]
                    if (category) {
                        switch (category.type) {
                            case TYPE_CAT_CONVOCATION:
                                state.categoryConvocation = category
                                break
                            case TYPE_CAT_PV:
                                state.categoryPv = category
                                break
                            default:
                                break
                        }
                    }
                })
                .addCase(fetchExpectedDocumentCategories.rejected, (state) => {
                    state.loading = false
                })
        }
})

export const {
    addExpectedDocument,
    removeExpectedDocument,
    setTitle,
    setContent,
    setColor,
    setDocumentForAll,
    setExpectedDocumentSelected,
    setExpectedDocumentNotify,
    setExpectedDocumentTitle,
    setExpectedDocumentDelay1,
    setExpectedDocumentDelay2,
    setExpectedDocumentAvailable,
    resetExpectedDocuments
} = CommitteeFormSlice.actions

export default CommitteeFormSlice.reducer
