import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {Document} from "../../../api/DocumentAPI"
import React, { Fragment, useEffect, useState } from "react"
import {ExpectedDocument} from "../../../api/ExpectedDocumentAPI"
import {ItemDocumentAddButton} from "./ItemDocumentAddButton"
import ItemDocument from "./ItemDocument";
import {setNbExpectedDocuments, setNbMoreDocuments} from "../../../redux/Meeting/MeetingFormSlice";
import GroupItemDocument from "./GroupItemDocument"

export function Documents() {
    const dispatch = useAppDispatch()
    const expectedDocuments = useAppSelector((state: RootState) => state.meetingForm.expectedDocuments)
    const documents = useAppSelector((state: RootState) => state.meetingForm.documents)
    const [expectedDocs, setExpectedDocs] = useState<ExpectedDocument[]>([])
    const [moreDocs, setMoreDocs] = useState<Document[]>([])

    useEffect(() => {
        // Documents supplémentaires
        let mores: Document[] = []
        documents.forEach((e: Document) => {
            if (e.expectedDocument === undefined) {
                mores.push(e)
            }
        })
        dispatch(setNbMoreDocuments(mores.length))
        setMoreDocs(mores)

        // Documents attendus
        const tempExpectedDocs = expectedDocuments.filter(e => (e.category !== undefined && e.available))
        setExpectedDocs(tempExpectedDocs)
        dispatch(setNbExpectedDocuments(tempExpectedDocs.length))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents, expectedDocuments])

    let docIndex: number = -1

    return <Fragment>
        {
            expectedDocs.map((expectedDoc, index) => {
                let currentDocuments: Document[] = documents.filter((item) => item && item.expectedDocument && item.expectedDocument.id === expectedDoc.id)
                if (currentDocuments.length > 0) {
                    docIndex += currentDocuments.length
                } else {
                    docIndex++
                }
                return <GroupItemDocument key={index} docIndex={docIndex} expectedDocument={expectedDoc} documents={currentDocuments} />
            })
        }
        {
            moreDocs.map((doc) => {
                docIndex++
                return <ItemDocument
                    key={docIndex}
                    docIndex={docIndex}
                    document={doc}
                />
            })
        }
        <ItemDocumentAddButton />
    </Fragment>
}
