import {Mandate, fetchMandates, fetchUserMandates, fetchMandatesWithOption} from "../../api/MandateAPI"
import {createSlice} from "@reduxjs/toolkit"
import {Committee} from "../../api/CommitteeAPI"

export interface MandateState {
    loading: boolean,
    committee: number,
    items: Mandate[],
    committees: Committee[]
}

const initialState: MandateState = {
    loading: false,
    committee: 0,
    items: [],
    committees: []
}

export const MandateSlice = createSlice({
    name: "mandates",
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchMandates.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(fetchMandates.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload
                })
                .addCase(fetchMandates.rejected, (state, action) => {
                    state.loading = false
                })
                .addCase(fetchMandatesWithOption.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchMandatesWithOption.fulfilled, (state, action) => {
                    state.loading = false
                    if (action.payload.context === "mandate") {
                        state.items = action.payload.data
                    }
                })
                .addCase(fetchMandatesWithOption.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchUserMandates.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(fetchUserMandates.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload
                })
                .addCase(fetchUserMandates.rejected, (state, action) => {
                    state.loading = false
                })
        }
})

export default MandateSlice.reducer
