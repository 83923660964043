import {createAsyncThunk} from "@reduxjs/toolkit"
import {axiosInstance, axiosInstancePatch} from "../utils/http-common"
import {FULL_DOCUMENT_USER_API, FULL_DOCUMENT_USERS_ADD_API} from "../utils/Data"
import {ValidationErrors} from "../utils/ValidationErrors"
import {User} from "./UserAPI"
import {Document} from "./DocumentAPI"
import {Options, toQuery} from "../utils/Options"

const AxiosInstance = axiosInstance()
const AxiosInstancePatch = axiosInstancePatch()

export interface DocumentUser {
    id: number | string;
    document?: Document;
    user: User;
    validate?: boolean;
    validatedAt?: string;
    createdDate?: string;
    updatedDate?: string;
}

export const fetchDocumentUsers = createAsyncThunk<DocumentUser[], { options: Options }>(
    "document_users/fetchAll",
    async (documentData) => {
        const {data} = (await AxiosInstance.get(`${FULL_DOCUMENT_USER_API}` + toQuery(documentData.options)))

        return data["hydra:member"]
    }
)

export const patchDocumentUser = createAsyncThunk<DocumentUser, {
    data: { validate: boolean },
    documentUserId: string
}, { rejectValue: ValidationErrors }>(
    "document_users/patch",
    async (documentData, {rejectWithValue}) => {
        const {data} = (await AxiosInstancePatch.patch(`${FULL_DOCUMENT_USER_API}/${documentData.documentUserId}`, JSON.stringify(documentData.data)))
        return data
    }
)

export const createDocumentUser = createAsyncThunk<DocumentUser[], { data: { document: number, user: number }[] }, {
    rejectValue: ValidationErrors
}>(
    "document_users/add",
    async (documentData) => {
        const {data} = (await AxiosInstance.post(`${FULL_DOCUMENT_USERS_ADD_API}`, JSON.stringify({documentUsers: documentData.data})))

        return data["hydra:member"]
    }
)

export const deleteDocumentUser = createAsyncThunk<number | string, { documentUserId: number | string }, {
    rejectValue: ValidationErrors
}>(
    "document_users/delete",
    async (documentData, {rejectWithValue}) => {
        (await AxiosInstance.delete(`${FULL_DOCUMENT_USER_API}/${documentData.documentUserId}`))

        return documentData.documentUserId
    }
)
