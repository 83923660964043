import {Period} from "../api/MandateAPI";
/** @todo ce fichier est voué à disparaître quand le tri sera géré côté apiplatform */

// Permet de trier les périodes de mandats du + récent au - récent
export function sortPeriods(periods: Period[]){
    const sortedPeriods = [...periods]
    const sortArray = (x: Period, y: Period) => {
        if (x.dateStart > y.dateStart && (!x.dateEnd || (y.dateEnd && x.dateEnd > y.dateEnd))) {
            return -1
        } else {
            return 1
        }
    }

    return sortedPeriods.sort(sortArray)
}

/**
 * Trie un tableau par date
 * @param array
 * @param attribut
 * @param state
 * @returns {*}
 */
export const sortArrayByDate = (array: any[], attribut: string, state: boolean) => {
    return array.sort(function (a, b) {
        if (!a[attribut]) {
            return 1
        }
        if (!b[attribut]) {
            return -1
        }
        if (a[attribut] === b[attribut]) {
            return 0
        }
        // @ts-ignore
        return state ? new Date(b[attribut]) - new Date(a[attribut]) : new Date(a[attribut]) - new Date(b[attribut])
    })
}

/**
 * Trie un tableau par ordre alphabétique
 * @param array
 * @param attributs
 * @param state
 * @returns {*}
 */
export const sortArrayAlphabeticallyFromObject = (array: any[], attributs: [string, string], state: boolean) => {
    return array.sort(function (a, b) {
        if (!a[attributs[0]] || !a[attributs[0]][attributs[1]]) {
            return 1
        }
        if (!b[attributs[0]] || !b[attributs[0]][attributs[1]]) {
            return -1
        }
        if (a[attributs[0]][attributs[1]] === b[attributs[0]][attributs[1]]) {
            return 0
        }
        if (state) {
            // @ts-ignore
            if (a[attributs[0]][attributs[1]] < b[attributs[0]][attributs[1]]) { return -1 }
            // @ts-ignore
            if (a[attributs[0]][attributs[1]] > b[attributs[0]][attributs[1]]) { return 1 }
            return 0
        } else {
            // @ts-ignore
            if (b[attributs[0]][attributs[1]] < a[attributs[0]][attributs[1]]) { return -1 }
            // @ts-ignore
            if (b[attributs[0]][attributs[1]] > a[attributs[0]][attributs[1]]) { return 1 }
            return 0
        }
    })
}

/**
 * Trie un tableau par ordre alphabétique
 * @param array
 * @param attributs
 * @param state
 * @returns {*}
 */
export const sortArrayAlphabeticallyFromObjectInObject = (array: any[], attributs: [string, string, string], state: boolean) => {
    return array.sort(function (a, b) {
        if (!a[attributs[0]] || !a[attributs[0]][attributs[1]] || !a[attributs[0]][attributs[1]][attributs[2]]) {
            return 1
        }
        if (!b[attributs[0]] || !b[attributs[0]][attributs[1]] || !b[attributs[0]][attributs[1]][attributs[2]]) {
            return -1
        }
        if (a[attributs[0]][attributs[1]][attributs[2]] === b[attributs[0]][attributs[1]][attributs[2]]) {
            return 0
        }
        if (state) {
            // @ts-ignore
            if (a[attributs[0]][attributs[1]][attributs[2]] < b[attributs[0]][attributs[1]][attributs[2]]) { return -1 }
            // @ts-ignore
            if (a[attributs[0]][attributs[1]][attributs[2]] > b[attributs[0]][attributs[1]][attributs[2]]) { return 1 }
            return 0
        } else {
            // @ts-ignore
            if (b[attributs[0]][attributs[1]][attributs[2]] < a[attributs[0]][attributs[1]][attributs[2]]) { return -1 }
            // @ts-ignore
            if (b[attributs[0]][attributs[1]][attributs[2]] > a[attributs[0]][attributs[1]][attributs[2]]) { return 1 }
            return 0
        }
    })
}