import React from "react"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {putMeeting} from "../../api/MeetingAPI"
import {
    resetModalMeetingObjectSendEmail, setModalAdminShow,
    setModalMeetingObjectSendEmail,
    setModalMeetingSendEmail,
    setModalUpdateShow
} from "../../redux/Meeting/MeetingSlice"
import {COMMITTEE_API, DOCUMENT_API} from "../../utils/Data"
import {RootState} from "../../redux/Store"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {TYPE_MEETING, updateDocument} from "../../api/DocumentAPI"
import {Modal, Form, Row, Col, Button} from "react-bootstrap"
import {resetData} from "../../redux/Meeting/MeetingFormSlice"
import {createDocumentUser, deleteDocumentUser} from "../../api/DocumentUserAPI"
import {resetPvUsers} from "../../redux/DocumentUser/DocumentUserSlice"
import {getFormattedLocalisedDate} from "../../utils/Date"

export default function ConfirmUpdateModal() {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.meeting.modalUpdateShow)
    const sendEmail = useAppSelector((state) => state.meeting.modalMeetingSendEmail)
    const subjects = useAppSelector((state) => state.meeting.modalMeetingObjectSendEmail)
    const documents = useAppSelector((state) => state.meetingForm.documents)
    const committee = useAppSelector((state) => state.committee.item)
    const meetingDate = useAppSelector((state) => state.meetingForm.meetingDate)
    const meetingTime = useAppSelector((state) => state.meetingForm.meetingTime)
    const special = useAppSelector((state: RootState) => state.meetingForm.special)
    const place = useAppSelector((state) => state.meetingForm.place)
    const videoLink = useAppSelector((state) => state.meetingForm.videoLink)
    const published = useAppSelector((state: RootState) => state.meetingForm.published)
    const lunchAvailable = useAppSelector((state: RootState) => state.meetingForm.lunchAvailable)
    const meeting = useAppSelector((state) => state.meetingForm.selectedMeeting)
    const oldMeetingDateTime = useAppSelector((state) => state.meetingForm.oldMeetingDateTime)
    const sendEmailPv = useAppSelector((state) => state.meeting.sendEmailPv)
    const pvUsers = useAppSelector((state) => state.documentUser.pvUsers)

    const checkboxes = [
        {
            index: "date",
            label: "Date"
        },
        {
            index: "time",
            label: "Heure de convocation"
        },
        {
            index: "place",
            label: "Lieu"
        },
        {
            index: "odj",
            label: "ODJ"
        },
        {
            index: "document",
            label: "Document"
        },
        {
            index: "pv",
            label: "Procès-verbal"
        },
        {
            index: "lunch",
            label: "Déjeuner"
        }
    ]

    const updMeeting = async () => {
        if (meeting !== undefined) {
            const iriDocuments: string[] = []

            // Liste des utilisateurs devant signer le(s) PV(s)
            const deleteDocumentUsers: number[] = []
            const addDocumentUsers: { document: number, user: number }[] = []
            documents.forEach((doc) => {
                pvUsers.forEach((pvUser) => {
                    if (!doc.documentUsers?.some((documentUser) => pvUser === documentUser.user.id)) {
                        addDocumentUsers.push({
                            document: Number(doc.id),
                            user: pvUser
                        })
                    }
                })
                doc.documentUsers?.forEach((documentUser) => {
                    if (!pvUsers.some((pvUser) => pvUser === documentUser.user.id)) {
                        deleteDocumentUsers.push(Number(documentUser.id))
                    }
                })
            })

            if (addDocumentUsers.length > 0) {
                dispatch(createDocumentUser({data: addDocumentUsers}))
            }
            if (deleteDocumentUsers.length > 0) {
                deleteDocumentUsers.forEach((du) => {
                    dispatch(deleteDocumentUser({documentUserId: du}))
                })
            }

            dispatch(resetPvUsers())

            documents.forEach((doc) => {
                if (doc.filePath !== undefined && doc.filePath !== "") {
                    let date = new Date()
                    if (doc.publishedDate !== undefined) {
                        date = new Date(doc.publishedDate)
                    }
                    date.setHours(9, 0, 0)

                    const fields = {
                        title: doc.title,
                        publishedDate: date,
                        type: TYPE_MEETING,
                        pv: sendEmailPv
                    }
                    dispatch(updateDocument({
                        id: doc.id,
                        fields: fields
                    }))

                    iriDocuments.push(DOCUMENT_API + "/" + doc.id)
                }
            })

            if (committee !== undefined) {
                const data = {
                    title: (meetingDate ? getFormattedLocalisedDate(new Date(meetingDate), "yyyy-MM-dd") : "") + " " + meetingTime,
                    published: published,
                    lunchAvailable: lunchAvailable,
                    special: special,
                    committee: `${COMMITTEE_API}/${committee.id}`,
                    place: place,
                    videoLink: videoLink,
                    documents: iriDocuments,
                    oldTitle: oldMeetingDateTime
                }

                // On met à jour la séance
                let text = "Problème dans la mise à jour de la séance."
                let messageStatus = "error"
                let response = await dispatch(putMeeting({
                    meetingId: meeting.id,
                    fields: data,
                    email: sendEmail,
                    subjects: subjects
                }))
                if (response.meta.requestStatus === "fulfilled") {
                    text = "Séance modifiée."
                    messageStatus = "success"
                }

                dispatch(displayMessage({text: text, status: messageStatus}))
            }
        }
        closeModal()
    }

    function closeModal() {
        dispatch(resetModalMeetingObjectSendEmail([]))
        dispatch(setModalMeetingSendEmail(false))
        dispatch(resetData())
        dispatch(setModalAdminShow(false))
        dispatch(setModalUpdateShow(false))
    }

    return <Modal size="sm" className="modal-confirm" show={show} onHide={() => closeModal()}
                  backdropClassName="modal-backdrop-confirm">
        <Modal.Header>
            <Modal.Title as="h3">
                <i className="mai me-2">warning</i> Confirmation requise
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Êtes vous sûr de vouloir modifier cet élément ?</p>
            <Form.Group className="mb-3">
                <Form.Check
                    label="Je souhaite avertir les commissaires-administrateurs de la modification de la réunion"
                    type="switch"
                    name="input-documentForAll"
                    checked={sendEmail}
                    onChange={() => {
                        dispatch(setModalMeetingSendEmail(!sendEmail))
                    }}
                />
            </Form.Group>
            <div className={sendEmail ? "d-block" : "d-none"}>
                <Form.Group>
                    <Form.Label>Sujet(s) de la modification</Form.Label>
                    {
                        checkboxes.map((item) => {
                            return <Form.Check
                                type="checkbox"
                                onChange={(e) => dispatch(setModalMeetingObjectSendEmail(e.target.value))}
                                value={item.index}
                                label={item.label}
                                key={item.index}
                                id={item.index}
                            />
                        })
                    }
                </Form.Group>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Row className="row-sm row-sm-h align-items-center">
                <Col xs="auto">
                    <Button variant="light" onClick={() => updMeeting()}>Non</Button>
                </Col>
                <Col xs="auto">
                    <Button variant="danger" onClick={() => updMeeting()}>Oui</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}
