import {createMeeting, deleteMeeting, Meeting, putMeeting} from "../../api/MeetingAPI"
import {createSlice} from "@reduxjs/toolkit"
import {fetchMeetings} from "../../api/MeetingAPI"
import {fetchMandatesWithOption, Mandate} from "../../api/MandateAPI"
import {
    createMeetingUser,
    fetchMeetingUsersWithOption,
    fetchUserMeetings,
    MeetingUser,
    putMeetingUser
} from "../../api/MeetingUserAPI"

export interface MeetingState {
    loading: boolean,
    loadingFilter: number,
    items: Meeting[],
    item?: Meeting,
    show: boolean,
    modalShow: boolean,
    modalAdminShow: boolean,
    modalAdminType: string,
    modalDeleteShow: boolean,
    modalUpdateShow: boolean,
    modalMeetingSendEmail: boolean,
    modalMeetingObjectSendEmail: string[],
    typeAttendance: string,
    meetingUsers: MeetingUser[],
    meetingUser?: MeetingUser,
    committeeMandates: Mandate[],
    lunch?: boolean,
    replacedBy: number | null,
    userMeetings: MeetingUser[],
    sendEmailPv: boolean,
}

const initialState: MeetingState = {
    loading: false,
    loadingFilter: 0,
    items: [],
    item: undefined,
    show: false,
    modalShow: false,
    modalAdminShow: false,
    modalAdminType: "creation",
    modalDeleteShow: false,
    modalUpdateShow: false,
    modalMeetingSendEmail: false,
    modalMeetingObjectSendEmail: [],
    typeAttendance: "",
    meetingUsers: [],
    committeeMandates: [],
    replacedBy: null,
    userMeetings: [],
    sendEmailPv: false
}

export const MeetingSlice = createSlice({
    name: "meetings",
    initialState,
    reducers: {
        resetItems: (state) => {
            state.items = []
        },
        setItems: (state, action) => {
            state.items = action.payload
        },
        resetModalMeetingObjectSendEmail: (state, action) => {
            state.modalMeetingObjectSendEmail = []
        },
        setLoadingFilter: (state, action) => {
            state.loadingFilter = action.payload
        },
        setMeetingSelected: (state, action) => {
            state.item = state.items.find((item) => item.id === parseInt(action.payload))
        },
        setShow: (state, action) => {
            state.show = action.payload
        },
        setModalShow: (state, action) => {
            state.modalShow = action.payload
        },
        setModalDeleteShow: (state, action) => {
            state.modalDeleteShow = action.payload
        },
        setModalUpdateShow: (state, action) => {
            state.modalUpdateShow = action.payload
        },
        setModalMeetingSendEmail: (state, action) => {
            state.modalMeetingSendEmail = action.payload
        },
        setModalAdminShow: (state, action) => {
            state.modalAdminShow = action.payload
        },
        setModalAdminType: (state, action) => {
            state.modalAdminType = action.payload
        },
        setTypeAttendance: (state, action) => {
            state.typeAttendance = action.payload
        },
        setLunch: (state, action) => {
            state.lunch = action.payload
        },
        setReplacedBy: (state, action) => {
            state.replacedBy = action.payload
        },
        setModalMeetingObjectSendEmail: (state, action) => {
            if (state.modalMeetingObjectSendEmail.find((item) => item === action.payload) === undefined) {
                state.modalMeetingObjectSendEmail.push(action.payload)
            } else {
                state.modalMeetingObjectSendEmail = state.modalMeetingObjectSendEmail.filter((item) => item !== action.payload)
            }
        },
        setSendEmailPv: (state, action) => {
            state.sendEmailPv = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchMeetings.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchMeetings.fulfilled, (state, action) => {
                    state.loading = false
                    /*let items = state.items
                    const newItems = action.payload
                    newItems.forEach(newItem => {
                        if (!items.some(item => item.id === newItem.id)) {
                            items = [...items, newItem]
                        }
                    })
                    state.items = items*/
                    state.items = action.payload
                    state.loadingFilter = 0
                })
                .addCase(fetchMeetings.rejected, (state) => {
                    state.loading = false
                    state.loadingFilter = 0
                })
                .addCase(fetchMeetingUsersWithOption.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchMeetingUsersWithOption.fulfilled, (state, action) => {
                    state.loading = false
                    state.meetingUsers = action.payload
                })
                .addCase(fetchMeetingUsersWithOption.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchUserMeetings.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchUserMeetings.fulfilled, (state, action) => {
                    state.loading = false
                    state.userMeetings = action.payload
                })
                .addCase(fetchUserMeetings.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchMandatesWithOption.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchMandatesWithOption.fulfilled, (state, action) => {
                    state.loading = false
                    if (action.payload.context === "meeting") {
                        state.committeeMandates = action.payload.data
                    }
                })
                .addCase(fetchMandatesWithOption.rejected, (state) => {
                    state.loading = false
                })
                .addCase(createMeetingUser.pending, (state) => {
                    state.loading = true
                })
                .addCase(createMeetingUser.fulfilled, (state, action) => {
                    state.loading = false
                    state.meetingUser = action.payload
                    state.meetingUsers.push(action.payload)
                })
                .addCase(createMeetingUser.rejected, (state) => {
                    state.loading = false
                })
                .addCase(putMeetingUser.pending, (state) => {
                    state.loading = true
                })
                .addCase(putMeetingUser.fulfilled, (state, action) => {
                    state.loading = false
                    state.meetingUser = action.payload
                    const meetingUsers = state.meetingUsers.filter((item) => item.id !== action.payload.id)
                    meetingUsers.push(action.payload)
                    state.meetingUsers = meetingUsers
                })
                .addCase(putMeetingUser.rejected, (state) => {
                    state.loading = false
                })
                .addCase(createMeeting.fulfilled, (state, action) => {
                    state.loading = false
                    state.item = action.payload
                    state.items.push(action.payload)
                    state.modalAdminShow = false
                })
                .addCase(createMeeting.rejected, (state) => {
                    state.loading = false
                })
                .addCase(putMeeting.pending, (state) => {
                    state.loading = true
                })
                .addCase(putMeeting.fulfilled, (state, action) => {
                    state.loading = false
                    const index = state.items.findIndex((item: { id: number; }) => item.id === action.payload.id)
                    const newArray = state.items
                    newArray[index] = action.payload
                    state.item = action.payload
                    state.items = newArray
                    state.modalAdminShow = false
                    state.show = false
                })
                .addCase(putMeeting.rejected, (state) => {
                    state.loading = false
                })
                .addCase(deleteMeeting.pending, (state) => {
                    state.loading = true
                })
                .addCase(deleteMeeting.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = state.items.filter((item: { id: number }) => item.id !== action.payload)
                    state.modalAdminShow = false
                    state.show = false
                })
                .addCase(deleteMeeting.rejected, (state) => {
                    state.loading = false
                    state.modalAdminShow = false
                    state.show = false
                })
        }
})

export const {
    resetItems,
    resetModalMeetingObjectSendEmail,
    setMeetingSelected,
    setShow,
    setModalShow,
    setTypeAttendance,
    setLunch,
    setReplacedBy,
    setModalAdminShow,
    setModalAdminType,
    setModalDeleteShow,
    setModalUpdateShow,
    setModalMeetingSendEmail,
    setModalMeetingObjectSendEmail,
    setSendEmailPv,
    setLoadingFilter,
    setItems
} = MeetingSlice.actions

export default MeetingSlice.reducer
