import {configureStore} from "@reduxjs/toolkit"
import sidebarReducer from "./Navigation/SidebarSlice"
import userReducer from "./User/UserSlice"
import committeeReducer from "./Committee/CommitteeSlice"
import mandateReducer from "./Mandate/MandateSlice"
import trombinoscopeReducer from "./User/TrombinoscopeSlice"
import meetingReducer from "./Meeting/MeetingSlice"
import meetingFormReducer from "./Meeting/MeetingFormSlice"
import documentReducer from "./Document/DocumentSlice"
import documentUserReducer from "./DocumentUser/DocumentUserSlice"
import addUserReducer from "./User/AddUserSlice"
import committeeFormReducer from "./Committee/CommitteeFormSlice"
import committeeAttendanceFormReducer from "./Committee/CommitteeAttendanceFormSlice"
import emailingReducer from "./Emailing/EmailingSlice"
import toolkitReducer from "./Toolkit/ToolkitSlice"
import ContentReducer from "./Content/ContentSlice"
import MessageReducer from "./Message/MessageSlice"
import smsReducer from "./Sms/SmsSlice"
import logReducer from "./Log/LogSlice"

export const store = configureStore({
    reducer: {
        app: userReducer,
        sidebar: sidebarReducer,
        message: MessageReducer,
        committee: committeeReducer,
        committeeForm: committeeFormReducer,
        committeeAttendanceForm: committeeAttendanceFormReducer,
        document: documentReducer,
        documentUser: documentUserReducer,
        mandate: mandateReducer,
        meeting: meetingReducer,
        meetingForm: meetingFormReducer,
        tromb: trombinoscopeReducer,
        emailing: emailingReducer,
        toolkit: toolkitReducer,
        actuality: ContentReducer,
        addUser: addUserReducer,
        sms: smsReducer,
        log: logReducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
