import {Fragment, useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import AddAssistantForm from "../../components/AddUser/AddAssistantForm"
import type {RootState} from "../../redux/Store"
import {setPage} from "../../redux/User/UserSlice"
import PageHeader from "../../components/PageHeader"
import {hasRole} from "../../utils/UserTools"
import {Link, useNavigate, useParams} from "react-router-dom"
import PopinDeleteUserConfirm from "./PopinDeleteUserConfirm"
import {setProperty} from "../../redux/User/AddUserSlice"
import {format} from "date-fns"
import {fr} from "date-fns/locale"

export default function EditAssistant() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const addUser = useAppSelector((state: RootState) => state.addUser)
    const profil = useAppSelector((state: RootState) => state.addUser.profil)
    const userId = useAppSelector((state) => state.app.userId)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    useEffect(() => {
        if (!hasRole(["ROLE_ADMIN"], roles)) {
            return navigate("/accueil")
        }
        dispatch(setPage("add-assistant"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let profilTitle = "Assistant.e"

    switch (profil) {
        case "assistante":
            profilTitle = "Assistant.e"
            break
        case "membre-codir":
            profilTitle = "Membre Codir"
            break
        case "admin":
        case "webmaster":
            profilTitle = "Webmaster"
            break
    }

    return (
        <>
            <PageHeader title={"Edition d'un.e " + profilTitle} breadcrumb nav={[["Utilisateur", "/annuaire"]]}>
                {hasRole(["ROLE_ADMIN"], roles) && String(userId) !== params.id &&
                    <a href="/" onClick={() => {
                        dispatch(setProperty({property: "idUserToDelete", value: params.id}))
                    }} className="btn btn-lg btn-tertiary rounded-circle btn-icon" data-bs-toggle="modal"
                       data-bs-target="#modal-delete-user-form">
                        <i className="mai fs-lg">delete_outline</i>
                    </a>
                }
            </PageHeader>
            <div className="container">
                <div className="block">
                    <h3 className="text-primary">Informations générales</h3>
                    <AddAssistantForm editModeAssistant={true}/>
                    <p className="text-main-gray fst-italic">{addUser.civilite === "Madame" ? "Elle" : "Il"} {addUser.dpoValidated ? "a" : "n'a pas encore"} déclaré
                        avoir pris connaissance et
                        accepter sans
                        réserves les
                        règles
                        de <Link target="_blank" to={"/conditions-generales-d-utilisation"}>confidentialité et
                            de protection des données
                            personnelles</Link> {addUser.dpoValidatedAt &&
                            <Fragment>le {addUser.dpoValidatedAt && format(new Date(addUser.dpoValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}</Fragment>}.
                    </p>
                    <p className="text-main-gray fst-italic">{addUser.civilite === "Madame" ? "Elle" : "Il"} {addUser.cguValidated ? "a" : "n'a pas encore"} déclaré
                        avoir pris connaissance et
                        accepter sans
                        réserves
                        les <Link target="_blank" to={"/conditions-generales-d-utilisation"}>conditions générales
                            d’utilisation</Link> {addUser.cguValidatedAt &&
                            <Fragment>le {addUser.cguValidatedAt && format(new Date(addUser.cguValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}</Fragment>}.
                    </p>
                </div>
            </div>
            <PopinDeleteUserConfirm/>
        </>
    )
}
