export default function LoadingSpinner() {
    return (
        <div className="row row-sm row-sm-h justify-content-center align-items-center h-75">
            <div className="col-auto">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Chargement en cours...</span>
                </div>
            </div>
            <div className="col-auto">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Chargement en cours...</span>
                </div>
            </div>
            <div className="col-auto">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Chargement en cours...</span>
                </div>
            </div>
        </div>
    )
}
