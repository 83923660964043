import MeetingSidebar from "./MeetingSidebar"
import Calendar from "./Calendar"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {Fragment, useEffect, useState} from "react"
import {MeetingAttendanceLightbox} from "./Attendance/MeetingAttendanceLightbox"
import {AddUpdateMeetingLightbox} from "./AddUpdateMeetingLightbox"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import ConfirmUpdateModal from "./ConfirmUpdateModal"
import {Col, Row} from "react-bootstrap"
import {Options} from "../../utils/Options"
import {hasRole} from "../../utils/UserTools"
import {ROLES_ASSIST_CODIR, ROLES_VIEW_MEETINGS} from "../../api/UserAPI"
import {fetchUserMandates} from "../../api/MandateAPI"
import {fetchUserMeetings} from "../../api/MeetingUserAPI"
import {Committee, fetchCommittees} from "../../api/CommitteeAPI"
import {isActiveInCommittee} from "../../utils/Functions"
import {setFilters} from "../../redux/Committee/CommitteeSlice"
import {RootState} from "../../redux/Store"
import {fetchMeetings} from "../../api/MeetingAPI"
import {USERS_API} from "../../utils/Data"

export default function ZoneCalendar() {
    const dispatch = useAppDispatch()
    const meeting = useAppSelector((state) => state.meetingForm.selectedMeeting)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const userId = useAppSelector((state: RootState) => state.app.userId)
    const committees = useAppSelector((state) => state.committee.items)
    const mandates = useAppSelector((state: RootState) => state.mandate.items)

    const [selectedCommittees, setSelectedCommittees] = useState<Committee[]>([])

    useEffect(() => {
        if (!hasRole(ROLES_VIEW_MEETINGS, roles)) {
            const tempCommittees: Committee[] = []
            committees.forEach((committee: Committee, index) => {
                if (committee && isActiveInCommittee(mandates, userId, committee)) {
                    tempCommittees.push(committee)
                }
            })
            setSelectedCommittees(tempCommittees)
            dispatch(setFilters(tempCommittees.map((item) => {
                return item.id
            })))
        } else {
            setSelectedCommittees(committees)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles, committees, mandates])

    useEffect(() => {
        if (roles.length === 0 || userId === 0 || userId === 0) return

        let optionUserMandates: Options = {}
        let optionCommittees: Options = {}
        let optionUserMeetings: Options = {}

        if (hasRole(ROLES_ASSIST_CODIR, roles)) {
            optionCommittees["users.id"] = userId
        }

        if (!hasRole(ROLES_VIEW_MEETINGS, roles)) {
            optionUserMandates = {"user.id": userId}
            optionUserMeetings = {"user.id": userId}
            optionUserMandates["order[meeting.title]"] = "asc"
            optionUserMeetings["order[meeting.title]"] = "asc"
            dispatch(fetchUserMandates({options: optionUserMandates}))
            dispatch(fetchUserMeetings({options: optionUserMeetings}))
        }
        dispatch(fetchCommittees({options: optionCommittees}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    return <Fragment>
        <div className="zone-calendar bg-white p-5 p-lg-8">
            <Row className="row-h">
                <Col xl={12} className="d-xl-none order-1"><h2 className="agenda-title mb-0">Prochains rendez-vous et
                    alertes</h2></Col>
                <Col xl={3} className="order-3 order-xl-1">
                    <h2 className="agenda-title d-none d-xl-block">Prochains rendez-vous et alertes</h2>
                    <MeetingSidebar selectedCommittees={selectedCommittees}/>
                </Col>
                <Col xl className="order-2 order-xl-2">
                    <Calendar selectedCommittees={selectedCommittees} mandates={mandates}/>
                </Col>
            </Row>
            <MeetingAttendanceLightbox/>
            <AddUpdateMeetingLightbox/>
            <ConfirmDeleteModal meetingId={meeting !== undefined ? meeting.id : 0}/>
            <ConfirmUpdateModal/>
        </div>
    </Fragment>
}
