import {resetToolFields, setDisplayPopinDeleteToolConfirm} from "../../redux/Toolkit/ToolkitSlice"
import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {deleteTool} from "../../api/DocumentAPI"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Modal} from "react-bootstrap"

export default function PopinDeleteToolConfirm() {

    const dispatch = useAppDispatch()
    const displayPopinDeleteToolConfirm = useAppSelector((state: RootState) => state.toolkit.displayPopinDeleteToolConfirm)
    const toolSelected = useAppSelector((state: RootState) => state.toolkit.toolSelected)

    const deleteToolFunc = async () => {
        if (toolSelected !== null) {
            dispatch(displayMessage({text: "Suppression de l'outil' en cours"}))
            let response = await dispatch(deleteTool({tool: toolSelected}))
            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Suppression de l'outil effectuée avec succès !", status: "success"}))
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({text: "Echec de la suppression de l'outil", status: "error"}))
            }

            dispatch(resetToolFields())
        }
    }
    return (
        <Modal className="modal-confirm" size="sm" show={displayPopinDeleteToolConfirm}
               backdropClassName="modal-backdrop-confirm">
            <Modal.Header>
                <Modal.Title as="h3">
                    <span className="mai me-2">warning</span>Confirmation requise
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes vous sûr de vouloir supprimer l'outil {toolSelected !== null ?
                <strong>{toolSelected.title}</strong> : ""} ?
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="button" className="btn btn-main-gray-light" onClick={() => {
                            dispatch(setDisplayPopinDeleteToolConfirm(false))
                        }}>Non
                        </button>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-danger" onClick={() => {
                            deleteToolFunc()
                        }}>Oui
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
