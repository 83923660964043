import {Link} from "react-router-dom"

interface ItemSideBarProps {
    url: string,
    icon: string,
    text: string,
    active: boolean
}

export default function ItemSideBar(props: ItemSideBarProps) {
    return (
        <Link to={props.url} className={(props.active ? "active " : "") + "nav-link level-0"}>
            <i className="nav-icon mai">{props.icon}</i>
            <div className="nav-content">{props.text}</div>
        </Link>
    )
}
