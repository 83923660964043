import React from "react"
import {ChangeEvent, Dispatch, SetStateAction, SyntheticEvent, useEffect, useState} from "react"
import {Form, Button, Modal} from "react-bootstrap"
import {ConfirmSms} from "./ConfirmSms"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {ChoiceItem} from "../../utils/ChoiceList"
import Select, {MultiValue} from "react-select"
import {fetchSmsMeetings, Meeting} from "../../api/MeetingAPI"
import {Committee, fetchCommittees} from "../../api/CommitteeAPI"
import {fetchMandatesWithOption} from "../../api/MandateAPI"
import {USERS_API} from "../../utils/Data"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {STATUS_SUPPLEANT, STATUS_TITULAIRE} from "../../constants/MandateData"
import {getFormattedLocalisedDate, getToday} from "../../utils/Date"

interface Props {
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>
    meeting?: Meeting
}

const maxLength = 110

export function AddSms({show, setShow, meeting}: Props) {
    const dispatch = useAppDispatch()
    const committees = useAppSelector((state) => state.committee.items)
    const meetings = useAppSelector((state) => state.sms.meetings)
    const mandates = useAppSelector((state) => state.mandate.items)

    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [committeeId, setCommitteeId] = useState<number | undefined>()
    const [meetingId, setMeetingId] = useState<number | undefined>()
    const [texte, setTexte] = useState<string>("")
    const [nbCaracters, setNbCaracters] = useState<number>(maxLength)
    const [usersMandates, setUsersMandates] = useState<{ value: string, label: string }[]>([])
    const [titulairesMandates, setTitulairesMandates] = useState<{ value: string, label: string }[]>([])
    const [suppleantsMandates, setSuppleantsMandates] = useState<{ value: string, label: string }[]>([])

    useEffect(() => {
        if (meeting) {
            setCommitteeId(meeting.committee.id)
            setMeetingId(meeting.id)
        } else {
            dispatch(fetchCommittees({}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting])

    useEffect(() => {
        dispatch(fetchSmsMeetings({
            options: {
                "committee.id": committeeId,
                "meetingOccurrences.date[after]": getToday()
            }, sorting: "asc"
        }))
        dispatch(fetchMandatesWithOption({
            context: "mandate",
            options: {
                "committee.id": committeeId,
                "dateStart[before]": getToday(),
                "dateEnd[after]": getToday()
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [committeeId])

    const handleTextLimit = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e === null) {
            setTexte("")
            setNbCaracters(maxLength)
        }
        if (e.target.value.length <= maxLength) {
            setTexte(e.target.value)
            setNbCaracters(maxLength - e.target.value.length)
        }
    }

    const handleClose = () => {
        setCommitteeId(undefined)
        setMeetingId(undefined)
        setUsersMandates([])
        setTitulairesMandates([])
        setSuppleantsMandates([])
        setTexte("")
        setNbCaracters(maxLength)
        setShow(false)
    }

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!committeeId || !meetingId || (titulairesMandates.length === 0 && suppleantsMandates.length === 0) || texte === "") {
            dispatch(displayMessage({text: "Merci de remplir tous les champs.", status: "error"}))
        } else {
            setUsersMandates(titulairesMandates.concat(suppleantsMandates))
            setShowConfirm(true)
        }
    }

    const committeesList: ChoiceItem[] = committees.map((item) => {
        return {key: item.id, label: (item.title ?? "")}
    })
    const committee: Committee | undefined = committees.find((item) => item.id === committeeId)

    const meetingsList: ChoiceItem[] = meetings.map((item) => {
        return {
            key: item.id,
            label: (item.title ? getFormattedLocalisedDate(new Date(item.title), "dd/MM/yyyy HH:mm") : "")
        }
    })
    const selectedMeeting: Meeting | undefined = meetings.find((item) => item.id === meetingId)

    let titulairesList: { value: string, label: string }[] = []
    let suppleantsList: { value: string, label: string }[] = []
    mandates.forEach((mandate) => {
        const memberValue = USERS_API + "/" + mandate.user.id
        if (mandate.status === STATUS_TITULAIRE && !titulairesList.some((member) => member.value === memberValue)) {
            titulairesList.push({
                value: memberValue,
                label: mandate.user.firstname + " " + mandate.user.lastname
            })
        }
        if (mandate.status === STATUS_SUPPLEANT && !suppleantsList.some((member) => member.value === memberValue)) {
            suppleantsList.push({
                value: memberValue,
                label: mandate.user.firstname + " " + mandate.user.lastname
            })
        }
    })
    if (titulairesList.length > 0) {
        titulairesList.reverse()
        titulairesList.push({
            value: "all",
            label: "Tous"
        })
        titulairesList.reverse()
    }
    if (suppleantsList.length > 0) {
        suppleantsList.reverse()
        suppleantsList.push({
            value: "all",
            label: "Tous"
        })
        suppleantsList.reverse()
    }

    const handleMandatesChange = (context: string, choices: MultiValue<{ value: string, label: string }>) => {
        let userMandates: { value: string, label: string }[] = []
        let all: boolean = false
        let usersList: { value: string, label: string }[] = []
        if (context === "titulaire") {
            usersList = titulairesList
        } else {
            usersList = suppleantsList
        }
        choices.forEach((choice) => {
            if (choice.value === "all") {
                userMandates = []
                all = true
                usersList.forEach((item) => {
                    userMandates.push(item)
                })
            } else {
                const mandate = usersList.find((item) => item.value === choice.value)
                if (mandate !== undefined) {
                    userMandates.push(mandate)
                }
            }
        })
        if (all) {
            userMandates = userMandates.filter((item) => item.value !== "all")
        }
        if (context === "titulaire") {
            setTitulairesMandates(userMandates)
        } else {
            setSuppleantsMandates(userMandates)
        }
    }

    return (
        <>
            <Modal show={show} scrollable onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Création de SMS</Modal.Title>
                </Modal.Header>
                <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                    handleSubmit(e)
                }}>
                    <Modal.Body className="text-primary">
                        {
                            meeting === undefined && <>
                                <Form.Group className="mb-4">
                                    <Form.Label htmlFor="select-committee">Choix de l'instance *</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        id="select-committee"
                                        options={committeesList}
                                        placeholder="Choisir une instance"
                                        getOptionLabel={(item: ChoiceItem) => item.label}
                                        getOptionValue={(item: ChoiceItem) => item.key.toString()}
                                        value={committeesList.find((item) => item.key === committeeId)}
                                        onChange={(e) => e !== null && setCommitteeId(e.key as number)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label htmlFor="select-committee">Choix de la séance *</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        id="select-meeting"
                                        placeholder="Choisir une séance"
                                        isDisabled={committeeId === undefined}
                                        options={meetingsList}
                                        getOptionLabel={(item: ChoiceItem) => item.label}
                                        getOptionValue={(item: ChoiceItem) => item.key.toString()}
                                        value={meetingsList.find((item) => item.key === meetingId)}
                                        onChange={(e) => e !== null && setMeetingId(e.key as number)}
                                    />
                                </Form.Group>
                            </>
                        }
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="select-committee">Sélection de participants *</Form.Label>
                            <Select
                                className="react-select-container mb-2"
                                classNamePrefix="react-select"
                                placeholder="Choisir les titulaires"
                                isMulti={true}
                                isClearable={true}
                                hideSelectedOptions={false}
                                isDisabled={committeeId === undefined || meetingId === undefined}
                                options={titulairesList}
                                value={titulairesMandates}
                                onChange={(e) => handleMandatesChange("titulaire", e)}
                            />
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Choisir les suppléants"
                                isMulti={true}
                                isClearable={true}
                                hideSelectedOptions={false}
                                isDisabled={committeeId === undefined || meetingId === undefined}
                                options={suppleantsList}
                                value={suppleantsMandates}
                                onChange={(e) => handleMandatesChange("suppleant", e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="select-committee">Texte du SMS *</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={texte}
                                onChange={(e) => handleTextLimit(e)}
                            />
                            <div className="form-text mb-0 help-text">Limité à {maxLength} caractères - Il vous
                                reste {nbCaracters} caractère{nbCaracters > 1 ? "s" : ""}</div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" type="button" onClick={() => handleClose()}>
                            <i className="mai text-primary me-2">close</i>Annuler
                        </Button>
                        <Button variant="light" type="submit">
                            <i className="mai text-primary me-2">save</i>Envoyer le SMS
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmSms
                show={showConfirm}
                setShow={setShowConfirm}
                setShowModal={handleClose}
                nb={usersMandates.length}
                date={selectedMeeting ? getFormattedLocalisedDate(new Date(selectedMeeting.title), "dd/MM/yyyy") : ""}
                committee={committee && committee.title ? committee.title : ""}
                committeeId={committeeId}
                meetingId={meetingId}
                users={usersMandates}
                texte={texte}
            />
        </>
    )
}
