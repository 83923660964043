export const COLLEGES = [
    {key: 0, label: 'Employeurs'},
    {key: 1, label: 'Salariés'}
]

export const SYNDICATES = [
    [
        {key: 0, label: 'MEDEF'},
        {key: 1, label: 'U2P'},
        {key: 2, label: 'CPME'}
    ],
    [
        {key: 0, label: 'FO'},
        {key: 1, label: 'CGT'},
        {key: 2, label: 'CFTC'},
        {key: 3, label: 'CFDT'},
        {key: 4, label: 'CFE-CGC'}
    ]
]

export const OPTIONS_ROLES = [
    { key: 'ROLE_ASSIST', label: 'Assistant.e'},
    { key: 'ROLE_CODIR', label: 'Membre ATPRO'},
    { key: 'ROLE_ADMIN', label: 'Webmaster'}
]
