import {Page, Text, View, Document, StyleSheet, Font} from '@react-pdf/renderer'
import {User} from '../../api/UserAPI'

Font.register({
    family: 'Roboto-Bold',
    src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#E4E4E4'
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: "1px solid lightgrey",
        width: '40%',
        padding: '20px 15px 20px 15px'
    },
    firstLine: {
        fontFamily: 'Roboto-Bold'
    }
});

export const PdfLabelUser = (props: { users: User[] }) => {

    return <Document>
        <Page size="A4" style={styles.page}>
            {
                props.users.map((user, index) => {
                    return <View key={index} style={{...styles.label}} wrap={false}>
                        <Text style={{...styles.firstLine, fontSize: '12px'}}>{user.civility === 0 || user.civility === false ? 'Madame' : 'Monsieur'} {user.firstname} {user.lastname}</Text>
                        <Text style={{fontSize: '11px'}}>{user.address1}</Text>
                        <Text style={{fontSize: '11px'}}>{user.zipcode} {user.city}</Text>
                    </View>
                })
            }
        </Page>
    </Document>
}
