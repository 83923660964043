import {ReactNode} from 'react'
import {formatDateToDisplay} from "../../utils/Functions";
import {Period} from "../../api/MandateAPI";

export default function ItemPeriodTitle(props: {period: Period}) {
    let dateString: ReactNode = <></>
    if (props.period.date_end !== undefined && props.period.date_end !== null && props.period.date_end !== '') {
        dateString = <span className="fw-medium fs-md">Du {formatDateToDisplay(props.period.date_start)} au {formatDateToDisplay(props.period.date_end)}</span>
    } else if (props.period.date_start !== undefined) {
        dateString = <span className="fw-medium fs-md">Depuis le {formatDateToDisplay(props.period.date_start)}</span>
    } else if (props.period.dateEnd !== undefined && props.period.dateEnd !== null && props.period.dateEnd !== '') {
        dateString = <span className="fw-medium fs-md">Du {formatDateToDisplay(props.period.dateStart)} au {formatDateToDisplay(props.period.dateEnd)}</span>
    } else if (props.period.dateStart !== undefined) {
        dateString = <span className="fw-medium fs-md">Depuis le {formatDateToDisplay(props.period.dateStart)}</span>
    }

    return <span className="fw-medium fs-md">{dateString}</span>
}
