import {Committee} from "../../api/CommitteeAPI"
import {Mandate, User} from "../../api/MandateAPI"
import {fetchUserById} from "../../api/UserAPI"
import {useDispatch} from "react-redux"
import {setShow, setUserSelected} from "../../redux/User/TrombinoscopeSlice"
import {store} from "../../redux/Store"
import {displayMessage} from "../../redux/Message/MessageSlice"

export default function ItemCommitteeMandates(props: { committee: Committee, mandates: Mandate[] }) {
    const dispatch = useDispatch()
    let renderedAllMandates: Array<JSX.Element> = []
    let renderedMandates: Array<JSX.Element> = []
    const nbToDisplay = 3
    let nbDisplayed = 0
    const mandates = props.mandates
    let total = 0

    if (mandates === undefined) {
        return <></>
    }

    const selectUser = async (user: User) => {
        if (user.id !== undefined) {
            let response = await store.dispatch(fetchUserById({id_user: Number(user.id)}))
            if (response.payload !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(setUserSelected(response.payload.user))
                dispatch(setShow(true))
            } else {
                dispatch(displayMessage({text: "Echec de l'affichage des informations du membre", status: "error"}))
            }

        }
    }

    mandates.forEach((mandate, index) => {
        const initiales = mandate.user.lastname[0].toUpperCase() + mandate.user.firstname[0].toUpperCase()
        if (nbDisplayed < nbToDisplay) {
            renderedMandates.push(
                <span key={`${mandate.user.id}-${index}`}
                      className="btn btn-sm btn-primary btn-icon rounded-circle me-n3">{initiales}</span>
            )
            nbDisplayed++
        }

        renderedAllMandates.push(
            <button
                key={`${props.committee.id}-${mandate.user.id}-${index}`}
                className="dropdown-item border-bottom border-2px border-main-gray-light"
                onClick={() => {
                    selectUser(mandate.user)
                }}
            >
                <span className="row row-xs align-items-center flex-nowrap">
                    <span className="col-auto">
                        <span className="btn btn-sm btn-primary btn-icon rounded-circle">{initiales}</span>
                    </span>
                    <span className="col">
                        <span className="font-weight-semibold"
                              data-bs-target={"#" + mandate.user.id}>{mandate.user.firstname + " " + mandate.user.lastname}</span>
                    </span>
                </span>
            </button>
        )
        total++
    })

    let nbMore = total - nbDisplayed

    if (total === 0) {
        return <></>
    }

    let renderedNbMore = <></>
    if (nbMore > 0) {
        renderedNbMore =
            <div className="badge badge-pill rounded-circle badge-tertiary bg-hover-secondary">+ {nbMore}</div>
    }

    return <div className="dropdown show">
        <button className="dropdown-toggle d-block btn-less" data-bs-toggle="dropdown" aria-expanded="true">
            <div className="concat-pictures d-flex align-items-center">
                <div className="d-flex pe-2">
                    {renderedMandates}
                </div>
                {renderedNbMore}
            </div>
        </button>
        <div className="dropdown-menu dropdown-menu-end fade" style={{maxHeight: "300px"}}>
            {renderedAllMandates}
        </div>
    </div>
}
