import React, {SyntheticEvent, useEffect, useMemo} from "react"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {
    setLunch,
    setModalShow
} from "../../../redux/Meeting/MeetingSlice"
import {MEETING_TYPE_MAIN, MeetingOccurrence} from "../../../api/MeetingAPI"
import {Form, Modal, Button} from "react-bootstrap"
import {
    ATTENDING_AVAILABLE,
    ATTENDING_NO,
    createMeetingUser,
    putMeetingUser
} from "../../../api/MeetingUserAPI"
import {MEETING_API, USERS_API} from "../../../utils/Data"
import MeetingDateTime from "../MeetingElements/MeetingDateTime"
import {RenderedLunch} from "./RenderedLunch"
import {RenderedReplacedBy} from "./RenderedReplacedBy"

export function MeetingAttendanceLightbox() {
    const dispatch = useAppDispatch()
    const userId = useAppSelector((state) => state.app.userId)
    const userCivility = useAppSelector((state) => state.app.userCivility)
    const show = useAppSelector((state: RootState) => state.meeting.modalShow)
    const item = useAppSelector((state: RootState) => state.meeting.item)
    const lunch = useAppSelector((state) => state.meeting.lunch)
    const meetingUsers = useAppSelector((state: RootState) => state.meeting.meetingUsers)
    const typeAttendance = useAppSelector((state: RootState) => state.meeting.typeAttendance)
    const replacedBy = useAppSelector((state) => state.meeting.replacedBy)

    const meetingUser = useMemo(() => {
        if (item && (meetingUsers.length > 0)) {
            return meetingUsers.find((meetingUser) => meetingUser.user.id === userId && meetingUser.meeting.id === item.id)
        }
        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, meetingUsers])

    useEffect(() => {
        meetingUser && meetingUser.lunch !== undefined && dispatch(setLunch(meetingUser.lunch))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingUser])

    if (item === undefined) {
        return <></>
    }

    let selectedOccurrence: MeetingOccurrence | undefined
    item.meetingOccurrences.forEach((occurrence) => {
        if (occurrence.type === MEETING_TYPE_MAIN) {
            selectedOccurrence = occurrence
        }
    })

    let title = "Confirmation de présence"
    let description = "Je confirme ma présence :"
    if (typeAttendance === "absent") {
        title = "Être excusé"
        description = "Je demande à être excusé"
        if (!userCivility) {
            title += "e"
            description += "e"
        }
    }

    const closeModal = () => {
        dispatch(setLunch(undefined))
        dispatch(setModalShow(false))
    }

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        }

        let uriReplacedBy = null
        if (replacedBy) {
            uriReplacedBy = `${USERS_API}/${replacedBy}`
        }

        const data = {
            meeting: `${MEETING_API}/${item.id}`,
            user: `${USERS_API}/${userId}`,
            attending: typeAttendance,
            replacedBy: typeAttendance === ATTENDING_NO ? uriReplacedBy : null,
            lunch: typeAttendance === ATTENDING_AVAILABLE ? lunch : false
        }

        // On crée ou met à jour la présence de l'utilisateur
        if (meetingUser === undefined) {
            dispatch(createMeetingUser({fields: data}))
        } else {
            dispatch(putMeetingUser({meetingUserId: meetingUser.id, fields: data}))
        }

        closeModal()
    }

    return <Modal className="modal-form" show={show} scrollable onHide={() => closeModal()}>
        <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            handleSubmit(e)
        }}>
            <Modal.Header closeButton>
                <Modal.Title as="h3">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="text-primary mb-3">{description}</h3>
                <h3 className="mb-2 d-flex align-items-center">
                    <span className="mai fs-sm me-2"
                          style={{color: item.committee.color}}>{item.special ? "star" : "circle"}</span>
                    {item.committee.title}
                </h3>
                <MeetingDateTime occurrence={selectedOccurrence}/>
                <RenderedLunch occurrence={selectedOccurrence} typeAttendance={typeAttendance}
                               lunchAvailable={item.lunchAvailable}/>
                <RenderedReplacedBy civility={typeof userCivility === "number" ? (userCivility !== 0) : userCivility}
                                    typeAttendance={typeAttendance} item={item} userId={userId}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" type="submit" className="btn btn-light">
                    <i className="mai fs-md me-2">check</i>
                    Confirmer
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
