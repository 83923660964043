import {useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {AddCommittee} from "./AddCommittee"
import {fetchMandatesWithOption} from "../../api/MandateAPI"
import LightBoxUser from "../../components/Trombinoscope/LightBoxUser"
import {AddUpdateCommitteeLightBox} from "./AddUpdateCommitteeLightbox"
import {setPage} from "../../redux/User/UserSlice"
import PageHeader from "../../components/PageHeader"
import CommitteesList from "../../components/Committee/CommitteesList"
import {setContext} from "../../redux/Document/DocumentSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {setDeletedCommittee} from "../../redux/Committee/CommitteeSlice"
import {getFormattedLocalisedDate} from "../../utils/Date"

/**
 * voir projet Instances Backend SF - Entité Committee
 */
export default function Committee() {
    const dispatch = useAppDispatch()
    const userSelected = useAppSelector((state: RootState) => state.tromb.userSelected)
    const deletedCommittee = useAppSelector((state) => state.committee.deletedCommittee)

    useEffect(() => {
        dispatch(setPage("committee"))
        dispatch(fetchCommittees({}))
        dispatch(setContext("instances"))
        dispatch(fetchMandatesWithOption({
            context: "mandate",
            options: {
                "dateStart[before]": getFormattedLocalisedDate(new Date(), "yyyy-MM-dd"),
                "dateEnd[after]": getFormattedLocalisedDate(new Date(), "yyyy-MM-dd"),
                "period.user.active": true
            }
        }))
    }, [dispatch])

    useEffect(() => {
        if (deletedCommittee && deletedCommittee !== "") {
            dispatch(displayMessage({"text": `L'instance ${deletedCommittee} a été supprimée.`, "status": "danger"}))
            dispatch(setDeletedCommittee(""))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletedCommittee])

    return (
        <>
            <PageHeader title="Les Instances" breadcrumb>
                <div className="row row-sm row-sm-h align-items-center">
                    <div className="col-auto"><AddCommittee/></div>
                </div>
            </PageHeader>
            <div className="container">
                <CommitteesList/>

                {(() => {
                    if (Object.keys(userSelected).length > 0) {
                        return <LightBoxUser/>
                    }
                })()}
                <AddUpdateCommitteeLightBox/>
            </div>
        </>
    )
}
