import React from "react"
import ReactQuill, {Quill} from "react-quill"

const Link = Quill.import("formats/link")

class MyLink extends Link {
    static create(value: any) {
        const node = Link.create(value)
        value = Link.sanitize(value)
        node.setAttribute("href", value)
        node.setAttribute("target", "_blank")
        return node
    }

    format(name: any, value: any) {
        super.format(name, value)
        this["domNode"].removeAttribute("target")
    }
}

Quill.register(MyLink)

type Props = {
    value: string;
    placeholder?: string;
    id?: string;
    onChange: (value: string) => void;
};

export default function Editor({value, id, onChange}: Props) {
    const modules = {
        toolbar: [
            [{"header": [1, 2, false]}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{"list": "ordered"}, {"list": "bullet"}, {"indent": "-1"}, {"indent": "+1"}],
            ["link"],
            ["clean"]
        ],
    }

    const formats = [
        "header",
        "bold", "italic", "underline", "strike", "blockquote",
        "list", "bullet", "indent",
        "link"
    ]
    return <div id={id} className="editor">
        <ReactQuill
            value={value}
            onChange={onChange}
            className="editor editor-instances border"
            modules={modules}
            formats={formats}
        />
    </div>
}
