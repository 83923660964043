import {createAsyncThunk} from "@reduxjs/toolkit"
import {axiosInstance} from "../utils/http-common"
import {FULL_SMS_API, FULL_SMS_USER_API} from "../utils/Data"
import {Options, toQuery} from "../utils/Options"

const AxiosInstance = axiosInstance()

interface Meeting {
    id: number,
    title?: string
}

interface Committee {
    id: number,
    title?: string
}

export interface Sms {
    id: number
    title?: string
    committee?: Committee
    meeting: Meeting
    smsUsers: SmsUser[],
    createdDate?: Date,
    total?: number,
    nbYes?: number,
    nbNo?: number,
    nbNsp?: number
}

export interface SmsUser {
    user: User,
    answer: boolean | null,
    status: string,
    syndicate: string
}

interface User {
    niceName: string
}

export const createSms = createAsyncThunk<Sms, { fields: object }>(
    "sms/create",
    async (smsData) => {
        const {data} = (await AxiosInstance.post(`${FULL_SMS_API}`, JSON.stringify(smsData.fields)))
        return data
    }
)

export const fetchSms = createAsyncThunk<{ items: Sms[], total: number }, { options?: Options }>(
    "sms/fetchAll",
    async (smsData) => {
        const {data} = (await AxiosInstance.get(`${FULL_SMS_API}` + toQuery(smsData.options)))

        return {
            items: data["hydra:member"],
            total: data["hydra:totalItems"]
        }
    }
)

export const createSmsUser = createAsyncThunk<SmsUser, { fields: object }>(
    "smsUser/create",
    async (smsuserData) => {
        const {data} = (await AxiosInstance.post(`${FULL_SMS_USER_API}`, JSON.stringify(smsuserData.fields)))
        return data
    }
)
