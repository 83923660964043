import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {useNavigate} from "react-router-dom"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {deleteAdmin} from "../../api/UserAPI"
import {setProperty} from "../../redux/User/AddUserSlice"

export default function PopinDeleteUserConfirm() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const idUserToDelete = useAppSelector((state: RootState) => state.addUser.idUserToDelete)
    const deleteAdminAction = async (idUserToDelete: number) => {

        if (idUserToDelete !== 0) {
            dispatch(displayMessage({text: "Suppression en cours", status: "info"}))
            let response = await dispatch(deleteAdmin({user_id: idUserToDelete}))
            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Utilisateur supprimé avec succès", status: "error"}))
                document.getElementById("btn-close-form-confirm-delete-user")?.click()
                return navigate("/annuaire")
            } else {
                dispatch(displayMessage({text: "Echec de la suppression", status: "error"}))
            }
        }
    }

    return (
        <div className="modal modal-confirm fade" id="modal-delete-user-form">
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">
                            <span className="mai me-2">warning</span>Suppression d'un membre
                        </h3>
                    </div>
                    <div className="modal-body">
                        <p>Etes-vous vous de vouloir supprimer ce membre ?</p>
                    </div>
                    <div className="modal-footer">
                        <div className="row row-sm row-sm-h">
                            <div className="col-auto">
                                <button type="button" className="btn btn-main-gray-light" data-bs-dismiss="modal"
                                        id="btn-close-form-confirm-delete-user" onClick={() => {
                                    dispatch(setProperty({property: "idUserToDelete", value: 0}))
                                }}>
                                    Annuler
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-main-gray-light" onClick={() => {
                                    deleteAdminAction(idUserToDelete)
                                }}>
                                    Supprimer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
