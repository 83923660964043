import {createAsyncThunk} from "@reduxjs/toolkit"
import {Committee} from "./CommitteeAPI"
import {SelectOption} from "../utils/CommonInterfaces"
import {ValidationErrors} from "../utils/ValidationErrors"
import {axiosInstanceJustJson} from "../utils/http-common"
import {FULL_SEND_MAILS_API} from "../utils/Data"

const AxiosInstanceJson = axiosInstanceJustJson()

interface EmailSend {
    result: boolean
}

export const sendEmails = createAsyncThunk<EmailSend, {
    committees: Committee[],
    roles: SelectOption[],
    statuses: SelectOption[],
    moreEmails: string[],
    subject: string,
    message: string
}, { rejectValue: ValidationErrors }>("emiling/send-mails", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.post(`${FULL_SEND_MAILS_API}`, JSON.stringify(dt))).data

    return {
        result: response.result
    }
})