import {downloadAttendanceFile, Meeting} from "../api/MeetingAPI"
import {downloadZip} from "../api/DocumentAPI"
import {getFormattedLocalisedDate} from "../utils/Date"

// On va chercher le fichier ZIP télécharger avec les documents de la séance.
// Si l'utilisateur a les droits, on lui renvoie le fichier.
export function getZipFile(meeting: Meeting) {
    const promiseGetFile = downloadZip(meeting.id)
    promiseGetFile.then((data) => {
        const file = new Blob(
            [data],
            {type: "application/zip"}
        )

        const filename = "pieces_" + meeting.committee.slug +
            "_" + getFormattedLocalisedDate(new Date(meeting.title), "yyyy-MM-dd-HH-mm")
        const fileURL = URL.createObjectURL(file)

        const link = document.createElement("a")
        link.href = fileURL
        link.download = filename
        link.click()
    })
}

// On va chercher le fichier CSV avec la participation de la séance.
// Si l'utilisateur a les droits, on lui renvoie le fichier.
export function getCsvFile(meeting: Meeting) {
    const promiseGetFile = downloadAttendanceFile(meeting.id)
    promiseGetFile.then((data) => {
        const file = new Blob(
            [data],
            {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
        )

        const filename = "liste_" + meeting.committee.slug +
            "_" + getFormattedLocalisedDate(new Date(meeting.title), "yyyy-MM-dd-HH-mm")
        const fileURL = URL.createObjectURL(file)

        const link = document.createElement("a")
        link.href = fileURL
        link.download = filename
        link.click()
    })
}
