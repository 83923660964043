import Breadcrumb from "./Breadcrumb";

export default function PageHeader(props: {children?: any, title?: string, subTitle?: string, noMargin?: boolean, breadcrumb?: boolean, nav?: Array<Array<string|undefined>>, h2Class?: string}) {
    const actions = props.children ? (props.children[0] ? props.children[0] : props.children) : null
    return <section className={'page-header' + (props.noMargin ? ' no-margin' : '')}>
        <div className="container">
            <div className="page-content">
                {props.breadcrumb &&
                    <Breadcrumb current={props.title} nav={props.nav} />
                }
                <div className="row">
                    <div className={props.h2Class ?? 'col-lg'}>
                        <h2>{props.subTitle}</h2>
                    </div>
                </div>
                <div className="row row-h align-items-center">
                    <div className="col-lg"><h1 className="mb-0">{props.title}</h1></div>
                    {actions &&
                        <div className="col-auto">{actions}</div>
                    }
                </div>
                {(props.children && props.children[1]) &&
                    props.children[1]
                }
            </div>
        </div>
    </section>
}
