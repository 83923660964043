import {useEffect} from "react"
import PageHeader from "./PageHeader"
import {useAppDispatch} from "../redux/Hooks"
import {setPage} from "../redux/User/UserSlice"

export default function ConditionsOfUse() {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setPage("conditionOfUse"))
    }, [dispatch])

    return <>
        <PageHeader
            title="Conditions générales d’utilisation de la plateforme Instances de Transitions Pro Île-de-France"
            breadcrumb/>
        <div className="container">
            <div className="block">
                <p>Nous vous remercions d'utiliser l’outil Instances Transitions Pro IDF.<br/>
                    L’accès à l’outil et son utilisation sont soumis aux présentes conditions générales, et à toutes
                    modifications qui pourront y être apportées ultérieurement (ci-après « les conditions »).<br/>
                    En accédant à l'outil et en l’utilisant, l’utilisateur accepte de façon inconditionnelle d’être lié
                    par
                    les conditions, à l’exclusion de toutes autres conditions.</p>
                <h2>ARTICLE 1 – SERVICE PROPOSÉ</h2>
                <ol>
                    <li>L’outil Instances Transitions Pro IDF (ci-après « l’outil ») est un outil qui est destiné aux
                        administrateurs et aux commissaires de Transitions Pro IDF et qui devra centraliser les éléments
                        et
                        outils nécessaires et utiles pour que les commissaires-administrateurs puissent assumer et
                        assurer au
                        mieux leurs mandats.
                    </li>
                    <li>L'outil est accessible depuis toute connexion Internet via l’interface web.</li>
                </ol>

                <h2>ARTICLE 2 – CONDITIONS D’ACCÈS DU SERVICE</h2>
                <ol>

                    <li>L'outil est accessible aux personnes physiques</li>
                    <li>Pour accéder à l'outil, l’utilisateur doit se connecter après que l’administrateur de l’outil
                        lui ait
                        créé un compte en communiquant prénom, courriel (qui servira d’identifiant), Civilité, Photo,
                        Adresse,
                        Téléphones, le Collège, l’Organisation syndicale<br/>
                        La Société se réserve le droit de soumettre l’accès à des conditions supplémentaires, sans
                        indication de
                        motif.
                    </li>
                    <li>Un seul compte est autorisé par utilisateur. Chaque utilisateur est entièrement et exclusivement
                        responsable de l’accès et de toute activité effectuée sur l'outil au moyen de ses identifiants.
                        L’utilisateur veille à ce qu’aucune autre personne n’ait accès à l'outil au moyen de ses
                        identifiants et
                        veille à se déconnecter de l’outil à la fin de chaque session. Dans l’hypothèse où l’utilisateur
                        aurait
                        connaissance d’une utilisation illicite de ses identifiants, il doit en informer Transitions Pro
                        IDF
                        sans délai en utilisant le formulaire de contact présent sur le site
                    </li>
                    <li>Après 1 an sans mandat, le compte créé est supprimé.</li>
                </ol>

                <h2>ARTICLE 3 – CONDITIONS D’UTILISATION DU SERVICE</h2>
                <ol>
                    <li>L’outil est mis à disposition des utilisateurs sans contrepartie financière</li>
                    <li>L’outil est mis à disposition uniquement pendant la durée du mandat des utilisateurs. Un
                        utilisateur
                        mettant fin à son mandat, ou dont le mandat ne serait pas renouvelé n’aura plus accès à l’outil
                    </li>
                    <li>Un utilisateur qui aurait une interruption de mandat pendant un an ou plus et qui serait à
                        nouveau
                        mandaté aura un nouveau compte et donc ne pourra pas voir l’historique de son ancien compte qui
                        aura été
                        supprimé entre temps.
                    </li>
                </ol>
                <h2>ARTICLE 4 – RÉSILIATION, SUSPENSION OU REFUS DE L’ACCÈS</h2>
                <ol>
                    <li>Transitions Pro IDF se réserve le droit de suspendre, limiter, supprimer ou refuser l’accès à
                        l'outil
                        à tout utilisateur qui ne respecterait pas les Conditions d’Utilisation.
                    </li>
                    <li>Transitions Pro IDF se réserve le droit d’interrompre à tout moment et sans avis préalable,
                        l’accès à
                        l'outil afin d’effectuer des opérations de maintenance ou d’apporter des modifications ou
                        améliorations
                        à l'outil.
                    </li>
                    <li>L’utilisateur est libre de demander, à tout moment, à ce que son compte soit supprimé, en en
                        faisant
                        la demande depuis le formulaire de contact.
                    </li>
                </ol>
                <h2>ARTICLE 5 – RESPONSABILITÉ ET INDEMNISATION</h2>
                <ol>
                    <li>La Société Rich ID, développeur et hébergeur de la solution, ainsi que Transitions Pro
                        Île-de-France,
                        ne peuvent garantir la disponibilité continue, sécurisée et exempte d’erreur de l'outil et de
                        toutes les
                        fonctions de l'outil, ni l'absence de virus ou de tout autre élément dommageable sur
                        l'outil.<br/>
                        L’utilisateur est seul responsable de son matériel informatique, de ses logiciels et de son
                        accès à
                        Internet.<br/>
                        La Société Rich ID et Transitions Pro IDF, et se réservent par ailleurs le droit d’interrompre à
                        tout
                        moment et sans avis préalable, l’accès à l'outil afin d’effectuer des opérations de maintenance
                        ou
                        d’apporter des modifications ou améliorations à l'outil.
                    </li>
                    <li>Aucune garantie n’est offerte par Transitions Pro IDF, en cas de perte ou de défaut de
                        conservation
                        de tout contenu sur l'outil.
                    </li>
                    <li>Transitions Pro IDF n’est responsable d’aucune perte ou dommage que l’utilisateur aurait subi à
                        la
                        suite de l’utilisation non autorisée de ses identifiants par autrui, et ce, même si
                        l’utilisateur en
                        ignorait l’utilisation.
                    </li>
                    <li>Transitions Pro IDF ne pourra en aucun cas être tenue responsable de tout dommage, direct ou
                        indirect
                        (incluant la perte de profits et la perte d’opportunité), subi par l’utilisateur, en lien avec
                        l’utilisation de l'outil, son Contenu ou en raison de l’impossibilité d’utiliser l'outil.
                    </li>
                </ol>

                <h2>ARTICLE 6 – PROTECTION DE LA VIE PRIVÉE</h2>
                <p>Transitions Pro Île-de-France agissant en qualité de responsable de traitement, est amené à traiter
                    les données personnelles saisie dans l’outil Espace Instances pour gérer les membres de son
                    administration et les membres des instances paritaires de sa structure et pour mettre à leur
                    disposition des documents, informations et fonctionnalités indispensables au bon déroulement de leur
                    mission au cours de leur(s) mandat(s).</p>

                <p>a. Les finalités de traitement sont :</p>
                <ul>
                    <li>La gestion des membres des Instances (CA, Bureau et Commissions) et de leurs mandat(s) et des
                        collaborateurs de Transitions Pro Île-de-France en charge des Instances.
                    </li>
                    <li>La mise à disposition aux membres des Instances et des collaborateurs de Transitions Pro
                        Île-de-France en charge des Instances à des documents en fonctions de leur(s) mandat(s)
                    </li>
                    <li>La mise à disposition aux membres des Instances de Transitions Pro Île-de-France les réunions
                        auxquelles ils peuvent avoir accès en fonction de leur(s) mandat(s) (calendrier)
                    </li>

                    <li>L’accès par les membres des Instances et les collaborateurs en charge des Instances
                        (Assistantes, Administrateurs de l’Espace Instances) à un annuaire des membres
                    </li>
                    <li>La gestion automatisée des présences, et des remplacements des membres aux Instances et la
                        gestion automatisée des réservations des repas
                    </li>
                    <li>La gestion administrative des Instances par les collaborateurs de Transitions Pro Île-de-France
                        en charge des Instances (gestion des dates de réunions, des repas, des présences, des documents
                        mis à disposition des membres de l’instance)
                    </li>
                    <li>La gestion des votes par SMS</li>
                    <li>L'envoi d’emailing d’informations aux membres de l’annuaire</li>
                </ul>

                <p>b. La nature des opérations réalisées sur les données est : la collecte, l’enregistrement, la
                    modification, l’accès et la mise à disposition, la consultation, la conservation dans la limite des
                    règles établies (article 2 et 3) l’extraction (emailing), l’hébergement.</p>
                <p>c. Les données à caractère personnel traitées sont : civilité, prénom, nom, courriel (qui servira
                    d’identifiant) photo, adresse, téléphone(s), collège et organisation syndicale.</p>
                <p>d. Les données d’identification (nom, prénom, etc.), les données professionnelles (courriel,
                    fonction,
                    etc.) et les données de connexion (logs de connexion, préférences de profil…).</p>
                <p>e. Les catégories de personnes concernées sont : Les personnes sous l’autorité De Transitions Pro
                    Île-de-France</p>
                <p>f. Destinataires / Sous-traitance : Aucune donnée n’est transférée à un autre sous-traitant que Rich
                    ID
                    développeur et hébergeur de la solution sur des serveurs en France.</p>

                <h2>ARTICLE 7 - MESURES DE SÉCURITÉ</h2>
                <p>Afin de protéger vos informations personnelles contre tout accès, modification, divulgation ou
                    destruction non autorisés, nous mettons en œuvre des mesures de sécurité appropriées. Rich-ID est
                    responsable de l'hébergement de la solution, qui est hébergée chez OVH sur une infrastructure située
                    en France et certifiée ISO/IEC 27001. Cette certification garantit que l'infrastructure répond à des
                    normes internationales strictes en matière de gestion de la sécurité de l'information.</p>

                <p>Nous mettons en place des mesures de sécurité physiques et logicielles pour protéger les données de
                    l'application Espaces Instances de Transitions Pro Île-de-France. Ces mesures incluent entre autre
                    :</p>

                <p><strong>Accès aux postes de travail</strong> : Les postes de travail des employés sont protégés par
                    des mesures de
                    sécurité telles que des mots de passe complexes et le verrouillage automatique après une période
                    d'inactivité. Les postes de travail sont également équipés de logiciels antivirus et anti-malware
                    régulièrement mis à jour pour prévenir toute tentative de compromission.</p>

                <p><strong>Accès des personnes autorisées</strong> : L'accès aux données et à l'application est
                    strictement réservé aux
                    personnes autorisées. Ces personnes disposent d'accès nominatifs et limités aux seules données
                    nécessaires à l'accomplissement de leurs tâches. Un contrôle d'accès régulier et rigoureux est en
                    place
                    pour s'assurer que seules les personnes habilitées peuvent accéder aux informations sensibles.</p>

                <p><strong>Pare-feu et protocoles de sécurité</strong> : Nos serveurs, qui évoluent sous Debian, sont
                    protégés par des
                    pare-feu configurés pour filtrer les flux de données. Toutes les communications vers et depuis nos
                    serveurs sont cryptées via des protocoles de sécurité tels que HTTPS pour le web et SSH pour les
                    accès
                    distants, l'accès SSH requiert un accès VPN. Ces mesures garantissent que les données transmises
                    sont
                    sécurisées et protégées contre les interceptions non autorisées.</p>

                <p><strong>Détection d'intrusion</strong> : Pour prévenir les tentatives d'intrusion, nous avons mis en
                    place des systèmes
                    de détection tels que Fail2ban et iptables. Ces outils permettent de surveiller les tentatives de
                    connexion et de bloquer automatiquement les adresses IP suspectes. De plus, un monitoring constant
                    de
                    l'infrastructure est effectué pour détecter toute activité anormale et réagir rapidement en cas
                    d'incident de sécurité.</p>

                <p><strong>Sauvegardes et reprise après sinistre</strong> : Nous disposons d'un plan de sauvegarde et de
                    reprise après
                    sinistre bien établi pour assurer la continuité de service. Des sauvegardes automatiques et
                    sécurisées
                    des données sont effectuées régulièrement pour garantir la restauration rapide en cas d'incident.
                    Des
                    tests de restauration des sauvegardes sont effectués périodiquement pour vérifier leur intégrité et
                    leur
                    disponibilité.</p>

                <p><strong>Conformité et sensibilisation</strong> : Nous nous assurons que toutes nos pratiques de
                    sécurité sont conformes
                    aux lois et réglementations en vigueur, telles que le RGPD (Règlement général sur la protection des
                    données). Notre personnel reçoit une formation régulière sur les meilleures pratiques de sécurité et
                    la
                    protection des données pour maintenir un haut niveau de vigilance.</p>

                <p>Ces mesures de sécurité sont régulièrement revues et mises à jour afin de répondre aux évolutions des
                    risques et des technologies, et pour garantir le plus haut niveau de protection de vos données
                    personnelles.</p>

                <h2>ARTICLE 8 – DROITS D’ACCES DE RECTIFICATIONS D’EFFACEMENT ET D’OPPOSITION</h2>

                <p>Conformément à la règlementation vous bénéficiez d’un droit d’accès, de rectification, d’effacement
                    de vos données et d’opposition. Pour toute demande d’exercice de droit et d’information relative à
                    la protection des données, vous pouvez écrire à : <a
                        href="mailto:dpo@transitionspro-idf.fr">dpo@transitionspro-idf.fr</a><br/>
                    L’Utilisateur est seul responsable des usages et seul garant de la confidentialité de ses
                    identifiants (adresse électronique et mot de passe), ainsi que de toute utilisation du Compte.</p>

                <p>L’Utilisateur s’engage à informer immédiatement Transitions Pro Île-de-France de toute utilisation
                    non autorisée du Compte, et de toute atteinte à la confidentialité et à la sécurité de ses moyens
                    d’identification via l’adresse électronique suivante : <a
                        href="mailto:dpo@transitionspro-idf.fr">dpo@transitionspro-idf.fr</a></p>
                <p>Si Transitions Pro IDF a des motifs sérieux et objectifs de penser que la sécurité de l’outil
                    Instances est violé, ou qu’il existe une utilisation non autorisée des moyens d’identification de
                    l’Utilisateur, alors Transitions Pro Île-de-France pourra, après suspension du Compte, exiger la
                    modification de ces moyens d’identification, afin notamment de préserver l’intégrité de l’outil
                    Instances.</p>
                <p>Le non-respect de ces conditions entrainera l’application des sanctions prévues sous l'article 4 «
                    Résiliation, suspension ou refus de l'accès » des présentes CGU.</p>


                <h2>ARTICLE 9 – MODIFICATION DES CONDITIONS ET DE L’OUTIL</h2>
                <ol>
                    <li>La Société Rich ID se réserve le droit de modifier et de mettre à jour à tout moment, et sans
                        préavis, les Conditions et/ou l'outil. En cas de modification substantielle des Conditions, une
                        notification sera publiée sur l'outil. En continuant à accéder à l'outil ou à l’utiliser après
                        la
                        notification, l’utilisateur marque son accord sur les Conditions ainsi modifiées.
                    </li>
                    <li>L’utilisateur est tenu de prendre connaissance des Conditions et de vérifier périodiquement
                        qu’elles
                        n’ont pas été modifiées. L’utilisateur peut consulter la plus récente version des Conditions à
                        tout
                        moment sur l'outil.
                    </li>
                    <li>La Société Rich ID se réserve le droit, à tout moment, de manière définitive ou temporaire,
                        d’arrêter
                        de fournir tout ou partie des services ou fonctionnalités de l'outil.
                    </li>
                </ol>
                <h2>ARTICLE 10 – DIVERS</h2>
                <ol>
                    <li>La nullité, la caducité ou le caractère non exécutoire de tout ou partie d'une des dispositions
                        des
                        Conditions n’affectera pas la validité ni l’efficacité des autres dispositions des Conditions.
                    </li>
                    <li>Le fait pour Transitions Pro IDF de ne pas se prévaloir, à un moment donné, d’une disposition
                        des
                        Conditions ou d’un droit, ne pourra être interprété comme une renonciation à faire valoir
                        ultérieurement
                        cette même disposition ou droit.
                    </li>
                </ol>

                <h2>ARTICLE 11 – LOI APPLICABLE ET COMPÉTENCE DE JURIDICTION</h2>
                <ol>
                    <li>Toute contestation qui résulterait de l'utilisation de l'outil sera soumise aux cours et
                        tribunaux de
                        Paris (France), sous réserve des cas où les tribunaux compétents sont désignés par des
                        dispositions
                        légales ou réglementaires.
                    </li>
                    <li>En cas de litige, l’utilisateur accepte que la production des données relatives à l’utilisation
                        de
                        l'outil, enregistrées sur les serveurs de l'outil, puisse faire foi entre les parties au litige.
                    </li>
                </ol>

                <h2>ARTICLE 12 – ENTREE EN VIGUEUR</h2>

                <p>Les présentes Conditions générales sont applicables à compter de la date de leur acceptation par
                    l’utilisateur lors de l'activation de son espace personnel et jusqu’à la date de suppression de son
                    accès.</p>


            </div>
        </div>
    </>
}
