import {sliceEvents, createPlugin, EventRenderRange, DateProfile, Duration, ViewProps} from "@fullcalendar/react"
import {MEETING_TYPE_ALERT, MEETING_TYPE_ALERT_USER, MEETING_TYPE_MAIN, MeetingOccurrence} from "../../api/MeetingAPI"
import {ConfirmAttendance} from "./Attendance/ConfirmAttendance"
import {EditMeetingButton} from "./EditMeetingButton"
import parse from "html-react-parser"
import {MeetingDocuments} from "./PopinElements/MeetingDocuments"
import {getFormattedLocalisedDate} from "../../utils/Date"

// Affichage en mode liste du calendrier
const CalendarCustomViewConfig = {
    classNames: ["custom-view"],
    na: "Liste",
    duration: {month: 1},
    content: function (props: ViewProps & { dateProfile: DateProfile, nextDayThreshold: Duration }) {
        let segs = sliceEvents(props, true) // allDay=true
        const html = segs.map((item: EventRenderRange, index: number) => {
            // On récupère le type d'événement
            let typeIcone = "circle"
            let typeLabel = "Séance"
            if (item.def.extendedProps.special) {
                typeIcone = "star"
                typeLabel = "Séance extraordinaire"
            }
            if (item.def.extendedProps.type === MEETING_TYPE_ALERT
                || item.def.extendedProps.type === MEETING_TYPE_ALERT_USER) {
                typeIcone = "notifications"
                typeLabel = "Alerte"
            }

            let videoLink = ""
            if (item.def.extendedProps.videoLink !== undefined && item.def.extendedProps.videoLink !== "") {
                videoLink = "<a href=\"" + item.def.extendedProps.videoLink + "\" target=\"_blank\">Lien visio</a>"
            }

            let selectedOccurrence: MeetingOccurrence | undefined
            item.def.extendedProps.meeting.meetingOccurrences.forEach((occurrence: MeetingOccurrence) => {
                if (occurrence.type === MEETING_TYPE_MAIN) {
                    selectedOccurrence = occurrence
                }
            })

            return <div key={index} className="p-4 bg-light mb-1"
                        style={{borderLeft: "4px solid " + item.ui.backgroundColor}}>
                <div className="row row-sm row-sm-h align-items-center">
                    <div className="col-lg-auto fs-md text-main-gray">
                        {getFormattedLocalisedDate(new Date(item.range.start), "EEE d MMM")}
                        <br/>à {item.def.extendedProps.time}
                    </div>
                    <div className="col-lg-2 fs-sm">
                        <strong>{item.def.title}</strong>
                        <br/>{item.def.extendedProps.committee}
                    </div>
                    <div className="col-lg-2 d-flex align-items-center">
                        <span className="mai fs-lg me-1" style={{color: item.ui.backgroundColor}}>{typeIcone}</span>
                        <span className="text-main-gray fs-sm">{typeLabel}</span>
                    </div>
                    <div className="col-lg fs-sm">
                        {item.def.extendedProps.place}
                        {(item.def.extendedProps.place && videoLink) && <br/>}
                        {parse(videoLink ? videoLink : "")}
                    </div>
                    <div className="col-lg-3 text-end">
                        {
                            (selectedOccurrence && selectedOccurrence.date && new Date(selectedOccurrence.date) > new Date()) &&
                            <ConfirmAttendance shortway meeting={item.def.extendedProps.meeting}/>
                        }
                    </div>
                    <div className="col-auto">
                        {
                            item.def.extendedProps.type === MEETING_TYPE_MAIN &&
                            <EditMeetingButton shortway meeting={item.def.extendedProps.meeting}
                                               documents={item.def.extendedProps.meeting.documents}/>
                        }
                    </div>
                    {
                        item.def.extendedProps.type === MEETING_TYPE_MAIN &&
                        <div className="col-12">
                            <MeetingDocuments shortway meeting={item.def.extendedProps.meeting}/>
                        </div>
                    }
                </div>
            </div>
        })

        return <>{html}</>
    }
}

export default createPlugin({
    views: {
        custom: CalendarCustomViewConfig,
    }
})
