import React, { Fragment } from 'react'
import {hasRole} from '../../utils/UserTools'
import {setCommitteeSelected, setShow} from "../../redux/Committee/CommitteeSlice";
import {
    addExpectedDocument, resetExpectedDocuments, setColor,
    setContent, setDocumentForAll,
    setTitle
} from "../../redux/Committee/CommitteeFormSlice";
import {useAppDispatch, useAppSelector} from "../../redux/Hooks";
import {TYPE_CAT_CONVOCATION, TYPE_CAT_PV} from "../../api/DocumentAPI";
import {RootState} from "../../redux/Store";
import {ROLES_MANAGE_COMMITTEE} from "../../api/UserAPI";

export function AddCommittee() {
    const dispatch = useAppDispatch()
    const categoryConvocation = useAppSelector((state: RootState) => state.committeeForm.categoryConvocation)
    const categoryPv = useAppSelector((state: RootState) => state.committeeForm.categoryPv)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    function setSelected() {
        dispatch(resetExpectedDocuments())
        dispatch(setCommitteeSelected(undefined));
        dispatch(setTitle(''))
        dispatch(setColor(''))
        dispatch(setContent(''))
        dispatch(setDocumentForAll(true))
        dispatch(setShow(true))
        dispatch(addExpectedDocument({
            id: 1,
            title: categoryConvocation ? categoryConvocation.title : '',
            type: TYPE_CAT_CONVOCATION
        }))
        dispatch(addExpectedDocument({
            id: 2,
            title: categoryPv ? categoryPv.title : '',
            type: TYPE_CAT_PV
        }))
    }

    if (hasRole(ROLES_MANAGE_COMMITTEE, roles)) {
        return <button className="btn btn-lg btn-icon rounded-circle btn-tertiary" onClick={() => setSelected()}>
            <i className="mai fs-lg">add</i>
        </button>
    }

    return <Fragment />
}
