import React from "react"
import {MEETING_TYPE_MAIN, MeetingOccurrence} from "../../api/MeetingAPI"
import {fetchMeetingDocuments, TYPE_MEETING} from "../../api/DocumentAPI"
import {fetchMeetingUsersWithOption} from "../../api/MeetingUserAPI"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {ConfirmAttendance} from "./Attendance/ConfirmAttendance"
import {EditMeetingButton} from "./EditMeetingButton"
import {useEffect,} from "react"
import {Modal} from "react-bootstrap"
import {setShow, setTypeAttendance} from "../../redux/Meeting/MeetingSlice"
import {MeetingAttendance} from "./Attendance/MeetingAttendance"
import {fetchMandatesForCommittee} from "../../api/MandateAPI"
import {setDocuments, setExpectedDocuments, setSelectedMeeting} from "../../redux/Meeting/MeetingFormSlice"
import {ROLES_MANAGE_MEETING, ROLES_VIEW_MEETINGS} from "../../api/UserAPI"
import {hasRole} from "../../utils/UserTools"
import {fetchSms} from "../../api/SmsAPI"
import {isActiveInMeetingCommittee, isReplacement} from "../../utils/Functions"
import {MeetingDocuments} from "./PopinElements/MeetingDocuments"
import MeetingTitle from "./MeetingElements/MeetingTitle"
import MeetingDateTime from "./MeetingElements/MeetingDateTime"
import MeetingPlace from "./MeetingElements/MeetingPlace"
import MeetingLink from "./MeetingElements/MeetingLink"
import {fetchDocumentUsers} from "../../api/DocumentUserAPI"
import {getToday} from "../../utils/Date"

export default function MeetingPopin() {
    const dispatch = useAppDispatch()
    const userId = useAppSelector((state) => state.app.userId)
    const show = useAppSelector((state: RootState) => state.meeting.show)
    const meeting = useAppSelector((state: RootState) => state.meetingForm.selectedMeeting)
    const userMeetings = useAppSelector((state: RootState) => state.meeting.userMeetings)
    const mandates = useAppSelector((state: RootState) => state.mandate.items)
    const roles = useAppSelector((state) => state.app.roles)

    useEffect(() => {
        if (meeting) {
            dispatch(fetchMandatesForCommittee({
                options: {
                    "committee.id": meeting.committee.id,
                    "dateStart[before]": meeting.title,
                    "dateEnd[after]": meeting.title,
                    "period.user.active": true
                }
            }))

            // On va chercher les documents à afficher
            if (hasRole(ROLES_VIEW_MEETINGS, roles) || isActiveInMeetingCommittee(mandates, meeting) || isReplacement(meeting.meetingUsers, userId)) {
                let options = {
                    type: TYPE_MEETING,
                    "meeting.id": meeting.id,
                    "publishedDate[before]": getToday(),
                    "title": "",
                    "order[expectedDocument.category.type]": "desc"
                }
                dispatch(fetchMeetingDocuments({options: options}))
            }

            dispatch(fetchMeetingUsersWithOption({
                options: {
                    "meeting.id": meeting.id,
                }
            }))

            dispatch(fetchDocumentUsers({
                options: {
                    "document.meeting.id": meeting.id,
                }
            }))

            dispatch(fetchSms({
                options: {
                    "meeting.id": meeting.id
                }
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting, userMeetings])

    if (meeting === undefined) {
        return <></>
    }

    let selectedOccurrence: MeetingOccurrence | undefined
    meeting.meetingOccurrences.forEach((occurrence) => {
        if (occurrence.type === MEETING_TYPE_MAIN) {
            selectedOccurrence = occurrence
        }
    })

    let canManage: boolean = (meeting.committee.documentForAll || hasRole(ROLES_MANAGE_MEETING, roles))

    function closeModal() {
        dispatch(setShow(false))
        dispatch(setSelectedMeeting(undefined))
        dispatch(setDocuments([]))
        dispatch(setExpectedDocuments([]))
        dispatch(setTypeAttendance(""))
    }

    return (
        <Modal className="modal-form" show={show} scrollable onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title as="h3"><MeetingTitle committee={meeting.committee}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    {
                        meeting.special &&
                        <h3 className="mb-3 fw-medium ps-3">
                            <i className="mai me-1 mb-1" style={{color: meeting.committee.color}}>star</i>
                            Séance extraordinaire
                        </h3>
                    }
                    <MeetingDateTime occurrence={selectedOccurrence}/>
                    {meeting.videoLink && <MeetingLink link={meeting.videoLink}/>}
                </div>

                {meeting.place && <MeetingPlace place={meeting.place}/>}
                <MeetingDocuments meeting={meeting}/>
                <MeetingAttendance meeting={meeting}/>
                {
                    (selectedOccurrence && selectedOccurrence.date && new Date(selectedOccurrence.date) > new Date()) &&
                    <ConfirmAttendance meeting={meeting} isReplacement={isReplacement(meeting.meetingUsers, userId)}/>
                }
                {/*
                    hasRole(ROLES_MANAGE_SMS, roles) && <MeetingSms/>
                */}
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        {canManage && <EditMeetingButton meeting={meeting}/>}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
