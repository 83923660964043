import {createSlice} from "@reduxjs/toolkit"
import {Committee} from "../../api/CommitteeAPI"
import {Mandate, Period} from "../../api/MandateAPI"
import {User} from "../../api/UserAPI"
import {CommitteeSelectItem} from "../../utils/CommonInterfaces"
import {formatDateInputDate} from "../../utils/Functions"
import {getToday} from "../../utils/Date"

export interface addUserState {
    profil: string,
    lastname: string,
    firstname: string,
    email: string,
    authDiffEmail: boolean,
    civilite: string,
    photo: string, // texte qui contient le nom et le chemin du fichier image qui vient d'être uploadé
    imageUserLoading: boolean,
    profilActif: boolean,
    committees: Committee[],
    roles: string[],
    editMode: boolean,
    editPeriodMode: boolean,
    idUserEdit: number | null,
    showModalAddPeriod: boolean,
    college?: number,
    organisationSyndicale?: number,
    adresse: string,
    authDiffAddress: boolean,
    codePostal: string | number,
    ville: string,
    tel1: string,
    authDifftel1: boolean,
    tel2: string,
    authDiffTel2: boolean,
    committeeSelectedTitle: string,
    dateStartPeriod: string,
    dateEndPeriod?: string,
    messageLightBoxAddMandate: string, // Message qui s'affiche dans la boite modale d'ajout et d'édition de période de mandats (LightBoxAddMandat)
    errorMessageLightBoxAddMandate: boolean // Indique si le message qui s'affiche dans la boite modale d'ajout et d'édition de période de mandats est rouge ou vert (LightBoxAddMandat)
    mandates: Mandate[],
    mandatesToSend: Mandate[],
    periods: Period[], // On stock la liste des périodes à afficher dans cette variable
    period: Period,
    viewCopyPeriodPopin: boolean, // Si == true, on affiche la popin de confirmation qui permet d'afficher la boite modale de duplication de période
    periodToClose: Period | null,
    showCopyPeriodLightbox: boolean,
    idUserToDelete: number,
    messageDeleteAdminPopin: string,
    errorMessageDeleteAdminPopin: boolean,
    periodToEdit: Period | null, // On stocke la période que l'on souhaite modifier dans cette variable
    usersByName: User[],
    viewMandateDuplicationWarning: boolean,
    selectedCommittees: CommitteeSelectItem[],
    mandateToDuplicate?: Mandate,
    dpoValidatedAt?: string,
    cguValidatedAt?: string,
    dpoValidated?: boolean
    cguValidated?: boolean
}

const initialState: addUserState = {
    profil: "admin",
    lastname: "",
    firstname: "",
    email: "",
    authDiffEmail: false,
    civilite: "",
    photo: "",
    imageUserLoading: false,
    profilActif: true,
    editMode: false,
    committees: [],
    roles: [],
    editPeriodMode: false,
    idUserEdit: null,
    showModalAddPeriod: false,
    adresse: "",
    authDiffAddress: false,
    codePostal: "",
    ville: "",
    tel1: "",
    authDifftel1: false,
    tel2: "",
    authDiffTel2: false,
    committeeSelectedTitle: "",
    dateStartPeriod: formatDateInputDate(),
    messageLightBoxAddMandate: "",
    errorMessageLightBoxAddMandate: false,
    mandates: [],
    mandatesToSend: [],
    periods: [],
    period: {
        dateStart: formatDateInputDate(),
        dateEnd: "",
        active: true,
        mandates: []
    },
    viewCopyPeriodPopin: false,
    periodToClose: {
        dateStart: "",
        dateEnd: "",
        active: true,
        mandates: []
    },
    showCopyPeriodLightbox: false,
    idUserToDelete: 0,
    messageDeleteAdminPopin: "",
    errorMessageDeleteAdminPopin: false,
    periodToEdit: null,
    usersByName: [],
    viewMandateDuplicationWarning: false,
    selectedCommittees: [],
}

export const addUserSlice = createSlice({
    name: "addUser",
    initialState,
    reducers: {
        setProperty: (state, action) => {
            if (action.payload.property === "dateStartPeriod" || action.payload.property === "dateEndPeriod") {
                if (!state.editPeriodMode) {
                    // @ts-ignore
                    state[action.payload.property] = action.payload.value
                } else if (state.periodToEdit !== null) {
                    // @ts-ignore
                    state.periodToEdit[action.payload.property === "dateEndPeriod" ? "dateEnd" : "dateStart"] = action.payload.value
                }
            } else {
                // @ts-ignore
                state[action.payload.property] = action.payload.value
            }
        },
        addMandate: (state, action) => {
            const mandatesToSort = [...(state.periodToEdit) ? [...state.periodToEdit.mandates] : [...state.mandates], action.payload]
            mandatesToSort.sort((a, b) => {
                let fa = a.committee.title.toLowerCase(),
                    fb = b.committee.title.toLowerCase()

                if (fa > fb) {
                    return 1
                }

                if (fa < fb) {
                    return -1
                }

                return 0
            })
            if (state.periodToEdit) {
                state.periodToEdit.mandates = mandatesToSort
            } else {
                state.mandates = mandatesToSort
            }
        },
        deleteMandate: (state, action) => {
            let currentMandates = []
            if (state.periodToEdit) {
                currentMandates = (state.periodToEdit ? [...state.periodToEdit.mandates] : [])
                    .sort((a, b) => {
                        let fa = a.committee.title.toLowerCase(),
                            fb = b.committee.title.toLowerCase()

                        if (fa > fb) {
                            return 1
                        }

                        if (fa < fb) {
                            return -1
                        }

                        return 0
                    })
                    .filter((item, index) => index !== action.payload.mandateIndex)
                state.periodToEdit.mandates = currentMandates
            } else {
                currentMandates = [...state.mandates]
                    .sort((a, b) => {
                        let fa = a.committee.title.toLowerCase(),
                            fb = b.committee.title.toLowerCase()

                        if (fa > fb) {
                            return 1
                        }

                        if (fa < fb) {
                            return -1
                        }

                        return 0
                    })
                    .filter((item, index) => index !== action.payload.mandateIndex)
                state.mandates = currentMandates
            }

            if (!currentMandates.some(item => item.committee.id === action.payload.committeeId)) {
                state.selectedCommittees = [...state.selectedCommittees].filter(item => Number(item.value) !== action.payload.committeeId)
            }
        },
        setDateStartMandate: (state, action) => {
            if (!state.editPeriodMode) {
                state.mandates[action.payload.indexMandate].dateStart = action.payload.dateStart
            } else if (state.periodToEdit !== null) {
                state.periodToEdit.mandates[action.payload.indexMandate].dateStart = action.payload.dateStart
            }
        },
        setDateEndMandate: (state, action) => {
            if (!state.editPeriodMode) {
                state.mandates[action.payload.indexMandate].dateEnd = action.payload.dateEnd
            } else if (state.periodToEdit !== null) {
                state.periodToEdit.mandates[action.payload.indexMandate].dateEnd = action.payload.dateEnd
            }
        },
        setStatusMandate: (state, action) => {
            if (!state.editPeriodMode) {
                state.mandates[action.payload.indexMandate].status = action.payload.status
            } else if (state.periodToEdit !== null) {
                state.periodToEdit.mandates[action.payload.indexMandate].status = action.payload.status
            }
        },
        addRole: (state, action) => {
            if (!state.editPeriodMode) {
                if (!state.mandates[action.payload.indexMandate].roles.includes(action.payload.role)) {
                    state.mandates[action.payload.indexMandate].roles.push(action.payload.role)
                } else {
                    let newRoles = []
                    for (let role of state.mandates[action.payload.indexMandate].roles) {
                        if (role !== action.payload.role) {
                            newRoles.push(role)
                        }
                    }
                    state.mandates[action.payload.indexMandate].roles = newRoles
                }
            } else {
                if (state.periodToEdit !== null && !state.periodToEdit.mandates[action.payload.indexMandate].roles.includes(action.payload.role)) {
                    state.periodToEdit.mandates[action.payload.indexMandate].roles.push(action.payload.role)
                } else if (state.periodToEdit !== null) {
                    let newRoles = []
                    for (let role of state.periodToEdit.mandates[action.payload.indexMandate].roles) {
                        if (role !== action.payload.role) {
                            newRoles.push(role)
                        }
                    }
                    state.periodToEdit.mandates[action.payload.indexMandate].roles = [...newRoles]
                }
            }
        },
        addCommittee: (state, action) => {
            let addedCommittee: CommitteeSelectItem | undefined
            for (let committee of action.payload.selectedCommittees) {
                if (!state.selectedCommittees.some(item => item.value === committee.value)) {
                    state.selectedCommittees = [...state.selectedCommittees, committee]
                    addedCommittee = committee
                }
            }

            // Si on ajoute une instance, on ajoute un premier mandat
            let datestr: string = getToday()
            if (state.dateStartPeriod !== "") {
                datestr = state.dateStartPeriod
            }

            if (addedCommittee) {
                let editMandates = state.periodToEdit ? [...state.periodToEdit.mandates] : []
                const newMandate = {
                    dateStart: datestr,
                    dateEnd: "",
                    status: 0,
                    roles: [],
                    user: {
                        id: 0,
                        lastname: "",
                        firstname: "",
                        picture: ""
                    },
                    committee: {
                        id: Number(addedCommittee.value),
                        title: addedCommittee.label,
                        slug: ""
                    }
                }
                if (state.editPeriodMode && state.periodToEdit) {
                    state.periodToEdit.mandates = [...editMandates, newMandate]
                } else {
                    state.mandates = action.payload?.mandates ? [...action.payload.mandates, newMandate] : [newMandate]
                }
            }
        },
        removeCommittee: (state, action) => {
            for (let committee of action.payload.removedCommittees) {
                state.selectedCommittees = state.selectedCommittees.filter(item => item.value !== committee.value)

                // Si on supprime une instance, on supprime les mandats associés
                let mandates = [...state.mandates]
                let editMandates = state.periodToEdit ? [...state.periodToEdit.mandates] : []
                state.mandates = mandates.filter(item => item.committee.id !== Number(committee.value))
                if (state.periodToEdit) {
                    state.periodToEdit.mandates = editMandates.filter(item => item.committee.id !== Number(committee.value))
                }
            }
        },
        setPeriodToEdit: (state, action) => {
            if (null === action.payload) return
            const periodMandates = [...action.payload.mandates]
            periodMandates.sort((a, b) => {
                let fa = a.committee.title.toLowerCase(),
                    fb = b.committee.title.toLowerCase()

                if (fa > fb) {
                    return 1
                }

                if (fa < fb) {
                    return -1
                }

                return 0
            })
            state.periodToEdit = {...action.payload, mandates: periodMandates}
        },
        deletePeriod: (state, action) => {
            // supprimer la période
            let newPeriods = []
            for (let period of state.periods) {
                if (period.id !== action.payload.id) {
                    newPeriods.push(period)
                }
            }

            state.periods = [...newPeriods]
        },
        resetAllFields: (state, action) => {
            state.showModalAddPeriod = false
            state.lastname = ""
            state.firstname = ""
            state.email = ""
            state.authDiffEmail = false
            state.civilite = ""
            state.photo = ""
            state.profilActif = true
            state.college = undefined
            state.organisationSyndicale = undefined
            state.adresse = ""
            state.authDiffAddress = false
            state.codePostal = ""
            state.ville = ""
            state.tel1 = ""
            state.authDifftel1 = false
            state.tel2 = ""
            state.authDiffTel2 = false
            state.committees = []
            state.committeeSelectedTitle = ""
            state.dateStartPeriod = ""
            state.dateEndPeriod = ""
            state.messageLightBoxAddMandate = ""
            state.errorMessageLightBoxAddMandate = false
            state.mandates = []
            state.mandatesToSend = []
            state.periods = []
            state.period = {
                dateStart: "",
                dateEnd: "",
                active: true,
                mandates: []
            }
            state.viewCopyPeriodPopin = false
            state.periodToClose = {
                dateStart: "",
                dateEnd: "",
                active: true,
                mandates: []
            }
            state.showCopyPeriodLightbox = false
        },
    }
})

export const {
    setProperty,
    addMandate,
    deleteMandate,
    setDateStartMandate,
    setDateEndMandate,
    setStatusMandate,
    addRole,
    addCommittee,
    removeCommittee,
    setPeriodToEdit,
    deletePeriod,
    resetAllFields
} = addUserSlice.actions

export default addUserSlice.reducer
