import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {Modal} from "react-bootstrap"
import {patchDocumentUser} from "../../api/DocumentUserAPI"
import {setShowConfirm} from "../../redux/DocumentUser/DocumentUserSlice"

interface Props {
    show: boolean
}

export default function ConfirmPvApprouval({show}: Props) {
    const dispatch = useAppDispatch()
    const documentUser = useAppSelector((state) => state.documentUser.item)

    function register() {
        documentUser && dispatch(patchDocumentUser({
            data: {validate: true},
            documentUserId: String(documentUser.id)
        }))
    }

    return <Modal size="sm" className="modal-confirm" show={show} onHide={() => dispatch(setShowConfirm(false))}
                  backdropClassName="modal-backdrop-confirm">
        <Modal.Header>
            <Modal.Title as="h3">
                <i className="mai me-2">warning</i> Confirmation requise
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Êtes vous sûr de vouloir approuver ce procès-verbal ?</p>
        </Modal.Body>
        <Modal.Footer>
            <div className="row row-sm row-sm-h align-items-center">
                <div className="col-auto">
                    <button type="button" className="btn btn-light"
                            onClick={() => dispatch(setShowConfirm(false))}>Annuler
                    </button>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-danger" onClick={() => register()}>Confirmer</button>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
}
