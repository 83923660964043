import React from "react"
import {Page, Text, View, Document, StyleSheet, Image, Font} from "@react-pdf/renderer"
import {Committee} from "../api/CommitteeAPI"
import {Member} from "../redux/Committee/CommitteeAttendanceFormSlice"
// @ts-ignore
import staticFont from "../_assets/fonts/Static.ttf"
// @ts-ignore
import barlowFont from "../_assets/fonts/Barlow-Regular.ttf"
// @ts-ignore
import materialIcons from "../_assets/fonts/MaterialIcons-Regular.ttf"
import {getFormattedLocalisedDate} from "../utils/Date"
import {data} from "../utils/Data"

Font.register({family: "Static", format: "truetype", src: staticFont})
Font.register({family: "Barlow", format: "truetype", src: barlowFont})
Font.register({family: "MaterialIcons", format: "truetype", src: materialIcons})

const styles = StyleSheet.create({
    header: {
        paddingTop: 60,
        paddingBottom: 20,
        paddingHorizontal: 20,
        alignContent: "center"
    },
    section: {
        paddingHorizontal: 20
    },
    image: {
        width: 100,
        maxWidth: "100%",
        height: "auto",
        alignSelf: "center",
        marginBottom: 20
    },
    h1: {
        width: "100%",
        fontSize: "17pt",
        marginBottom: "25pt",
        paddingBottom: "20pt",
        textAlign: "center",
        fontFamily: "Static",
        borderBottom: "1px solid #E5E5E5",
    },
    h2: {
        color: "#00A7A8",
        fontFamily: "Barlow",
        fontSize: "11pt",
        textAlign: "left",
        marginBottom: "16pt",
    },
    highlight: {
        padding: "20px",
        marginBottom: "10pt",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#FBFBFB"
    },
    sectionCommitteeTitle: {
        flexDirection: "row",
        alignItems: "center"
    },
    committeeTitle: {
        fontSize: "11pt",
        fontWeight: "medium"
    },
    committeeDate: {
        flexDirection: "row",
        alignItems: "center",
        fontSize: "9pt",
    },
    list: {
        margin: 10,
        padding: 10,
        flexDirection: "column"
    },
    listItem: {
        fontSize: "11pt",
        color: "#7A7A7A",
        marginBottom: "20px"
    },
    scheduleIcon: {
        // @ts-ignore
        display: "block",
        fontFamily: "MaterialIcons",
        marginRight: "5pt",
        color: "#7A7A7A",
    }
})

export default function Pdf(props: { members: Member[], date?: string, committee?: Committee }) {
    if (props.committee === undefined || props.date === undefined) {
        return <Document></Document>
    }

    const renderedMembers = props.members.map((member, index) => {
        return <Text style={styles.listItem} key={index}>{member.firstname} {member.lastname}</Text>
    })

    return <Document>
        <Page size="A4">
            <View style={styles.header}>
                <Image style={styles.image}
                       src={data.context === "dev" ? require("../_images/logo-bas.svg").default : require("../_images/logo.svg").default}/>
            </View>

            <View style={styles.section}>
                <Text style={styles.h1}>FEUILLE DE PRÉSENCE</Text>
                <Text style={styles.h2}>Liste des présents à la séance de/du</Text>
            </View>

            <View style={styles.highlight}>
                <View style={styles.sectionCommitteeTitle}>
                    <div style={{
                        display: "inline-block",
                        borderRadius: "50%",
                        backgroundColor: props.committee.color,
                        width: "8px",
                        height: "8px",
                        marginRight: "15px"
                    }}/>
                    <Text style={styles.committeeTitle}>{props.committee.title}</Text>
                </View>
                <View style={styles.committeeDate}>
                    <Text style={styles.scheduleIcon}>schedule</Text>
                    <Text>{getFormattedLocalisedDate(new Date(props.date), "EEEE d MMMM yyyy à H:mm")}</Text>
                </View>
            </View>

            <View style={styles.list}>
                {renderedMembers}
            </View>
        </Page>
    </Document>
}
