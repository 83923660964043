import React from "react"
import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {Modal} from "react-bootstrap"
import {setProperty} from "../../redux/User/AddUserSlice"

export default function PopinCopyMandateWarning() {

    const dispatch = useAppDispatch()
    const viewMandateDuplicationWarning = useAppSelector((state: RootState) => state.addUser.viewMandateDuplicationWarning)

    return (
        <Modal show={viewMandateDuplicationWarning} className="modal-confirm" size="sm"
               backdropClassName="modal-backdrop-confirm">
            <Modal.Header>
                <Modal.Title as="h3">Duplication de mandat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Pour pouvoir dupliquer un mandat sur la période, vous devez renseigner une date de fin de mandat sur
                    la précédente instance du même type.</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            dispatch(setProperty({property: "viewMandateDuplicationWarning", value: false}))
                        }}>
                            J'ai compris
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
