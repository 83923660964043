export interface Options {
    active?: boolean,
    page?: number,
    itemsPerPage?: number,
    "document.id"?: number | string,
    "d.published"?: boolean,
    college?: number,
    committee?: string,
    "committee.slug"?: string,
    "committee.id"?: number,
    "committee.id[]"?: number[],
    "committee.canViewDocs"?: boolean,
    "dateEnd"?: string,
    "dateEnd[before]"?: string,
    "dateEnd[strictly_before]"?: string,
    "dateEnd[after]"?: string,
    "dateEnd[strictly_after]"?: string,
    "dateStart[before]"?: string,
    "dateStart[strictly_before]"?: string,
    "dateStart[after]"?: string,
    "dateStart[strictly_after]"?: string,
    "documents.title"?: string,
    "document.meeting.id"?: number,
    enabled?: boolean,
    entityType?: string,
    niceName?: string,
    "meeting.committee.slug"?: string,
    "meeting.id"?: number,
    "meeting.title"?: string,
    "meetingOccurrences.date[before]"?: string,
    "meetingOccurrences.date[after]"?: string,
    "meetingUsers.replacedBy"?: string,
    "order[lastname]"?: string,
    "order[firstname]"?: string,
    "order[meeting.title]"?: string,
    "order[expectedDocument.category.type]"?: string,
    "order[createdDate]"?: string,
    "pagination"?: boolean,
    "periods.mandates.committee.id"?: number,
    "periods.mandates.dateEnd[after]"?: string,
    "periods.mandates.dateStart[before]"?: string,
    "periods.mandates.roles"?: number,
    "periods.mandates.status"?: number,
    "period.user.active"?: boolean,
    published?: boolean,
    "publishedDate[before]"?: string,
    roles?: string,
    "slug"?: string,
    status?: number,
    syndicate?: number,
    "user.college"?: number,
    "user.syndicate"?: number,
    "title"?: string,
    type?: number | string,
    order?: string,
    sort?: string,
    isUserActive?: number,
    "user.id"?: number,
    "users.id"?: number,
    "withReplacedBy"?: boolean,
}

export function toQuery(options?: Options): string {
    let query: string = ""

    if (options === undefined) {
        return ""
    }

    query = query + Object.keys(options).map((key: string, index) => {
        const value: boolean | number | string | number[] | undefined = options[key as keyof typeof options]
        if (key === "committee.id[]" && value !== undefined && Array.isArray(value)) {
            return value.map((o: boolean | number | string | number[], index2) => {
                return `${(index === 0 && index2 === 0) ? "?" : "&"}${key}=${o}`
            }).join("")
        }

        return `${index === 0 ? "?" : "&"}${key}=${value}`
    }).join("")

    return query
}
