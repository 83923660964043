import {Committee, createCommittee, deleteCommittee, fetchCommittees, putCommittee} from "../../api/CommitteeAPI";
import {createSlice} from "@reduxjs/toolkit";
import {fetchMandatesForCommittee, Mandate} from "../../api/MandateAPI";

export interface CommitteeState {
    loading: boolean,
    item?: Committee,
    items: Committee[],
    show: boolean,
    filters: number[],
    modalDeleteCommitteeShow: boolean,
    modalCanNotDeleteCommitteeShow: boolean,
    deletedCommittee?: string,
    mandates: Mandate[]
}

const initialState: CommitteeState = {
    loading: false,
    item: undefined,
    items: [],
    show: false,
    filters: [],
    modalDeleteCommitteeShow: false,
    modalCanNotDeleteCommitteeShow: false,
    mandates: []
}

export const CommitteeSlice = createSlice({
    name: 'committees',
    initialState,
    reducers: {
        setCommitteeSelected: (state, action) => {
            state.item = action.payload
        },
        setShow: (state, action) => {
            state.show = action.payload
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setModalDeleteCommitteeShow: (state, action) => {
            state.modalDeleteCommitteeShow = action.payload
        },
        setModalCanNotDeleteCommitteeShow: (state, action) => {
            state.modalCanNotDeleteCommitteeShow = action.payload
        },
        setDeletedCommittee: (state, action) => {
            state.deletedCommittee = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchMandatesForCommittee.fulfilled, (state, action) => {
                    state.mandates = action.payload
                })
                .addCase(fetchCommittees.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchCommittees.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload
                    let tmpFilters: number[] = []
                    action.payload.forEach((item) => {
                        if (!tmpFilters.find((fitem) => fitem === item.id)) {
                            tmpFilters.push(item.id)
                        }
                    })
                    state.filters = tmpFilters
                })
                .addCase(fetchCommittees.rejected, (state) => {
                    state.loading = false
                })
                .addCase(createCommittee.pending, (state) => {
                    state.loading = true
                })
                .addCase(createCommittee.fulfilled, (state, action) => {
                    state.loading = false
                    state.items.push(action.payload)
                    state.show = false
                })
                .addCase(createCommittee.rejected, (state) => {
                    state.loading = false
                })
                .addCase(putCommittee.pending, (state) => {
                    state.loading = true
                })
                .addCase(putCommittee.fulfilled, (state, action) => {
                    state.loading = false
                    const index = state.items.findIndex((item: { id: number; }) => item.id === action.payload.id);
                    const newArray = state.items;
                    newArray[index] = action.payload;
                    state.items = newArray
                    state.show = false
                })
                .addCase(putCommittee.rejected, (state) => {
                    state.loading = false
                })
                .addCase(deleteCommittee.pending, (state) => {
                    state.loading = true
                })
                .addCase(deleteCommittee.fulfilled, (state, action) => {
                    state.loading = false
                    state.deletedCommittee = action.payload.title
                    const newArray = state.items;
                    state.items = newArray.filter((item) => item.id !== action.payload.id)
                })
                .addCase(deleteCommittee.rejected, (state) => {
                    state.loading = false
                })
    }
})

export const { setShow, setCommitteeSelected, setFilters, setModalCanNotDeleteCommitteeShow, setModalDeleteCommitteeShow, setDeletedCommittee } = CommitteeSlice.actions

export default CommitteeSlice.reducer
