import {SyntheticEvent} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setCommitteesSelected,
    setStatusSelected,
    setRolesSelected,
    setShowModal, setMoreEmails
} from "../../redux/Emailing/EmailingSlice"
import Select from "react-select"
import {Committee} from "../../api/CommitteeAPI"
import {data} from "../../utils/Data"
import {CommitteeSelectItem, SelectOption} from "../../utils/CommonInterfaces"
import {Form, Modal} from "react-bootstrap"
import {OPTIONS_ROLES} from "../../constants/UserData"
import {hasRole} from "../../utils/UserTools"
import {ROLES_ADMIN} from "../../api/UserAPI"
import Tooltip from "../../utils/Tooltip"

export default function LightboxChoiceRecipients(props: { showModal: boolean }) {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.emailing.committees)
    const committeesSelected = useAppSelector((state: RootState) => state.emailing.committeesSelected)
    const rolesSelected = useAppSelector((state: RootState) => state.emailing.rolesSelected)
    const statusSelected = useAppSelector((state: RootState) => state.emailing.statusSelected)
    const moreEmails = useAppSelector((state: RootState) => state.emailing.moreEmails)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    const validRecipients = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(setShowModal(false))
    }

    // Données du select multiple d'instances
    let optionsSelectCommittee: CommitteeSelectItem[] = []

    if (committees !== undefined) {
        committees.forEach((committee: Committee) => {
            optionsSelectCommittee.push({
                value: committee.id, label: committee.title ?? "", color: committee.color
            })
        })
    }

    let defaultValueSelectCommittee: CommitteeSelectItem[] = []

    if (committeesSelected.length > 0) {
        committeesSelected.forEach((committee) => {
            defaultValueSelectCommittee.push({
                value: committee.id, label: committee.title ?? "", color: committee.color
            })
        })
    }

    // Données du select multiple de fonctions
    // On y ajoute les fonctions Webmaster, Membre ATPRO et Assistante (ce sont des rôles)
    let optionsSelectRoles: SelectOption[] = []

    if (data.MANDATE_ROLES !== undefined) {
        data.MANDATE_ROLES.forEach((role) => {
            optionsSelectRoles.push({
                value: role.key, label: role.label
            })
        })
    }
    if (hasRole(ROLES_ADMIN, roles) && OPTIONS_ROLES !== undefined) {
        OPTIONS_ROLES.forEach((item) => {
            optionsSelectRoles.push({
                value: item.key, label: item.label
            })
        })
    }

    let defaultValueSelectRoles: SelectOption[] = []

    if (rolesSelected.length > 0) {
        rolesSelected.forEach((role) => {
            defaultValueSelectRoles.push({
                value: role.value, label: role.label
            })
        })
    }
    optionsSelectRoles.sort((a, b) => a.label.localeCompare(b.label))

    // Données du select multiple de statuts
    let optionsSelectStatus: SelectOption[] = []

    if (data.STATUS !== undefined) {
        data.STATUS.forEach((status, index) => {
            optionsSelectStatus.push({
                value: index, label: status
            })
        })
    }

    let defaultValueSelectStatus: SelectOption[] = []

    if (statusSelected.length > 0) {
        statusSelected.forEach((status) => {
            defaultValueSelectStatus.push({
                value: status.value, label: status.label
            })
        })
    }

    const updateSelectCommittee = (comsSelected: CommitteeSelectItem[]) => {

        let modif = ""

        if (comsSelected.length > committeesSelected.length) {
            modif = "add"
        } else if (comsSelected.length < committeesSelected.length) {
            modif = "delete"
        }
        let newCommittees: Committee[] = []

        if (modif === "add") { // Si on a ajouté une instance dans le select
            for (let committeeSelected of comsSelected) {
                for (let committee of committees) {
                    if (committee.id === committeeSelected.value) {
                        newCommittees.push(committee)
                    }
                }
            }
            dispatch(setCommitteesSelected([...newCommittees]))
        }

        if (modif === "delete") { // Si on a supprimé une instance dans le select
            for (let committee of comsSelected) {
                // let exist = false
                for (let com of committeesSelected) {
                    if (com.id === committee.value) {
                        newCommittees.push(com)
                    }
                }
            }
            dispatch(setCommitteesSelected([...newCommittees]))
        }
    }

    return (
        <Modal show={props.showModal} scrollable className="modal-form" onHide={() => {
            dispatch(setShowModal(false))
        }}>
            <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                validRecipients(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Choix des destinataires</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label>Instances</Form.Label>
                        <Select
                            value={[...defaultValueSelectCommittee]}
                            isMulti
                            name="committees[]"
                            options={optionsSelectCommittee}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Toutes les instances"
                            onChange={(e) => {
                                updateSelectCommittee([...e])
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Fonctions</Form.Label>
                        <Select
                            value={[...defaultValueSelectRoles]}
                            isMulti
                            name="roles[]"
                            options={optionsSelectRoles}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Toutes les fonctions"
                            onChange={(e) => {
                                dispatch(setRolesSelected(e))
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Statuts</Form.Label>
                        <Select
                            value={[...defaultValueSelectStatus]}
                            isMulti
                            name="status[]"
                            options={optionsSelectStatus}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Tous les statuts"
                            onChange={(e) => {
                                dispatch(setStatusSelected(e))
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Destinataires supplémentaires <Tooltip message="Merci de saisir un email par ligne"><span
                            className="mai">error_outline</span></Tooltip></Form.Label>
                        <Form.Control
                            as="textarea"
                            value={moreEmails}
                            onChange={(e) => dispatch(setMoreEmails(e.target.value))}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary">Valider</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
