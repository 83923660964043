import React, { Fragment, ReactNode } from "react"
import {User} from "../../api/UserAPI"
import {useAppSelector} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {checkValidMandate} from "../../utils/Functions"
import {avatar, hasRole, isAdmin} from "../../utils/UserTools"
import {Mandate} from "../../api/MandateAPI"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"
import { Col } from "react-bootstrap";

export default function UserCard(props: {
    user: User,
    redirectEditUserPage: (user: User) => void,
    connectAdminAsUser: (user: User) => void,
    selectUser: (user: User) => void
}) {

    const roles = useAppSelector((state: RootState) => state.app.roles)
    const userConnected = useAppSelector((state: RootState) => state.app.user)
    const college: { key: number, label: string } | undefined = COLLEGES.find((item) => item.key === props.user.college)
    const syndicat: {
        key: number,
        label: string
    } | undefined = college && SYNDICATES[college.key].find((item) => item.key === props.user.syndicate)

    return <Col sm={6} md={4} lg={3} xl={2}>
        <div
            className={"bg-white p-3 h-100 position-relative cursor-pointer text-center trombinoscope-user" + (Number(props.user.active) === 0 ? " disabled" : "")}
            onClick={() => {
                props.selectUser(props.user)
            }}>
            <div className="row row-0 row-sm-h flex-column h-100">
                <Col>
                    <div className="position-absolute t-2 r-2">
                        {hasRole(["ROLE_ADMIN"], roles) || (isAdmin(roles) && userConnected.id === props.user.id) ?
                            <button className="btn-less text-primary" onClick={(e) => {
                                e.stopPropagation()
                                props.redirectEditUserPage(props.user)
                            }}>
                                <i className="mai fs-md d-block p-2">edit</i>
                            </button>
                            :
                            <i className="mai fs-md d-block text-primary p-2">arrow_forward</i>
                        }
                    </div>
                    <div className="position-absolute">
                        {hasRole(["ROLE_ADMIN"], roles) ?
                            <button className="btn-less text-primary" onClick={(e) => {
                                e.stopPropagation()
                                props.connectAdminAsUser(props.user)
                            }}>
                                <span className="mai">swap_horiz</span>
                            </button>
                            : <Fragment />
                        }
                    </div>
                    <img src={avatar(props.user.filePath, Number(props.user.civility))} className="avatar img-cover" alt={props.user.firstname + " " + props.user.lastname} width="80" height="80"/>
                    <div className="fs-md fw-medium mt-3">{props.user.firstname} {props.user.lastname}</div>
                    <div className="fs-sm fw-normal mb-2">{syndicat ? syndicat.label : ""}</div>
                    <div className="row row-xs row-sm-h justify-content-center">
                        <div className="col-auto">
                            <i className={"mai fs-md " + (props.user.email !== undefined && props.user.email !== "" && props.user.authDiffEmail ? "text-primary" : "text-disabled-gray")}>mail</i>
                        </div>
                        <div className="col-auto">
                            <i className={"mai fs-md " + (props.user.phone1 !== undefined && props.user.phone1 !== "" && props.user.authDifftel1 ? "text-primary" : "text-disabled-gray")}>phone_android</i>
                        </div>
                        <div className="col-auto">
                            <i className={"mai fs-md " + (props.user.phone2 !== undefined && props.user.phone2 !== "" && props.user.authDiffTel2 ? "text-primary" : "text-disabled-gray")}>call</i>
                        </div>
                    </div>
                </Col>
                {props.user.mandates &&
                    <Col xs="auto">
                        <div
                            className="row row-sm row-xs-h align-items-center justify-content-center justify-content-sm-start">
                            {(() => {
                                let nodeMandates: ReactNode[] = []
                                props.user.mandates.forEach((mandate: Mandate, index: number) => {
                                    if (checkValidMandate(mandate)) {
                                        nodeMandates.push(<div key={index} className="col-auto text-start">
                                            <div className="row row-xxs align-items-center">
                                                <div className="col-auto d-flex align-items-center">
                                                    <span className="d-inline-block rounded-circle" style={{
                                                        width: "6px",
                                                        minWidth: "6px",
                                                        height: "6px",
                                                        backgroundColor: mandate["committee"]["color"]
                                                    }}></span>
                                                </div>
                                                <div className="col fs-xs text-main-gray">
                                                    <div className="text-truncate"
                                                         style={{maxWidth: "80px"}}>{mandate["committee"]["title"]}</div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                })
                                return nodeMandates
                            })()}
                        </div>
                    </Col>
                }
            </div>
        </div>
    </Col>

}
