import {createAsyncThunk} from "@reduxjs/toolkit"
import http from "../utils/http-common"
import {removeCookie, setCookie} from "../utils/cookies"
import {FULL_LOGIN_API, FULL_TOKEN_INVALIDATE_API} from "../utils/Data"

export interface AuthState {
    accessToken: string,
    refreshToken?: string,
    errors?: string[]
}

export interface AuthSubmitState {
    accessToken: string,
    refreshToken: string,
}

export interface AuthLogin {
    username: string,
    password: string
}

export const authLogout = createAsyncThunk<AuthState, AuthSubmitState>(
    "logout",
    async (data, thunkAPI) => {
        return http.post(FULL_TOKEN_INVALIDATE_API, data).then(res => {
            // on supprime les cookies de connexion
            removeCookie("accessToken")
            removeCookie("refreshToken")

            return res.data
        })
    }
)

export const authLogin = async (data: AuthLogin) => {
    const res = await http.post(FULL_LOGIN_API, data)

    // on met à jour les cookies de connexion
    setCookie("accessToken", res.data.token)
    setCookie("refreshToken", res.data.refreshToken)

    return res.data
}