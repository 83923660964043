import { createSlice } from '@reduxjs/toolkit'

export interface SidebarState {
    mainMenu: string,
    menuAndClick: string
}

const initialState: SidebarState = {
    mainMenu: '',
    menuAndClick: ''
}

export const sidebarSlice = createSlice({
    name: 'toogle_sidebar',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.mainMenu = state.mainMenu === '' ? 'hover ' : '';
            state.menuAndClick = state.menuAndClick === '' ? 'hover ' : '';
        },
        enterMenu: (state) => {
            state.mainMenu = 'hover ';
            state.menuAndClick = 'hover ';
        },
        leaveMenu: (state) => {
            state.mainMenu = '';
            state.menuAndClick = '';
        }
    },
})

export const { toggleMenu, enterMenu, leaveMenu } = sidebarSlice.actions

export default sidebarSlice.reducer