import React from "react"
import {render} from "react-dom"
import App from "./App"
import {BrowserRouter} from "react-router-dom"
import {store} from "./redux/Store"
import {Provider} from "react-redux"

const root = document.getElementById("root")
render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>,
    root
)
