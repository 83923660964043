import ItemSideBar from "./ItemSideBar"
import type {RootState} from "../../redux/Store"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {useLocation} from "react-router-dom"
import {hasRole} from "../../utils/UserTools"
import {enterMenu, leaveMenu} from "../../redux/Navigation/SidebarSlice"
import {ROLES_MANAGE_SMS} from "../../api/UserAPI"

export default function SideBar() {

    const dispatch = useAppDispatch()
    const location = useLocation()
    const mainMenu = useAppSelector((state: RootState) => state.sidebar.mainMenu)
    const connected = useAppSelector((state) => state.app.connected)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    const itemsMenu = [
        {url: "/instances", icon: "groups", text: "Les instances", is_granted: true},
        {url: "/agenda", icon: "today", text: "Agenda", is_granted: true},
        {url: "/annuaire", icon: "badge", text: "Annuaire", is_granted: true},
        {url: "/documents", icon: "folder_open", text: "Documents", is_granted: true},
        {url: "/boite-a-outils", icon: "home_repair_service", text: "Boite à outils", is_granted: true},
        {
            url: "/actualites-infos",
            icon: "newspaper",
            text: "Actualités/Informations",
            is_granted: hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)
        },
        /*{url: "/sms", icon: "check", text: "Vote par SMS", is_granted: hasRole(ROLES_MANAGE_SMS, roles)},*/
    ]

    return <>
        {
            connected && <div className={mainMenu + "main-menu"} onMouseOver={() => {
                dispatch(enterMenu())
            }} onMouseLeave={() => {
                dispatch(leaveMenu())
            }}>
                <ul className="nav-menu level--1">
                    {itemsMenu.map((itemMenu, index) => {
                        if (itemMenu.is_granted) {
                            return <li key={"sidebar-" + index}
                                       className={(location.pathname === itemMenu.url ? "current " : "") + "nav-item"}>
                                <ItemSideBar key={"sidebar-" + index} url={itemMenu.url} text={itemMenu.text}
                                             icon={itemMenu.icon} active={location.pathname === itemMenu.url}/>
                            </li>
                        }

                        return <span key={index}/>
                    })}
                </ul>
            </div>
        }
    </>
}
