import {Fragment, useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import AddAdminForm from "../../components/AddUser/AddAdminForm"
import LightBoxAddMandat from "../../components/AddUser/LightBoxAddMandat"
import type {RootState} from "../../redux/Store"
import PopinCopyPeriodConfirm from "../../components/AddUser/PopinCopyPeriodConfirm"
import LightBoxCopyPeriod from "../../components/AddUser/LightBoxCopyPeriod"
import {setPage} from "../../redux/User/UserSlice"
import {Link, useNavigate, useParams} from "react-router-dom"
import {hasRole} from "../../utils/UserTools"
import PopinDeleteUserConfirm from "../../components/AddUser/PopinDeleteUserConfirm"
import PageHeader from "../../components/PageHeader"
import PopinCopyMandateWarning from "./PopinCopyMandateWarning"
import {setProperty} from "../../redux/User/AddUserSlice"
import {format} from "date-fns"
import {fr} from "date-fns/locale"

export default function EditAdmin() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const addUser = useAppSelector((state: RootState) => state.addUser)
    const showCopyPeriodLightbox = useAppSelector((state: RootState) => state.addUser.showCopyPeriodLightbox)
    const showModalAddPeriod = useAppSelector((state: RootState) => state.addUser.showModalAddPeriod)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    useEffect(() => {
        if (!hasRole(["ROLE_ADMIN"], roles)) {
            return navigate("/accueil")
        }
        dispatch(setPage("edit-admin"))
        dispatch(setProperty({property: "profil", value: "commissaire-administrateur"}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div onClick={() => {
            dispatch(setProperty({property: "usersByName", value: []}))
        }}>
            <PageHeader title="Edition d'un.e commissaire-administrateur.trice" breadcrumb
                        nav={[["Utilisateur", "/annuaire"]]}>
                {hasRole(["ROLE_ADMIN"], roles) &&
                    <a href="/" onClick={() => {
                        dispatch(setProperty({property: "idUserToDelete", value: params.id}))
                    }} className="btn btn-lg btn-tertiary rounded-circle btn-icon" data-bs-toggle="modal"
                       data-bs-target="#modal-delete-user-form">
                        <i className="mai fs-lg">delete_outline</i>
                    </a>
                }
            </PageHeader>
            <div className="container">
                <div className="block">
                    <h3 className="text-primary">Informations générales</h3>
                    <AddAdminForm editMode={true}/>
                    {showModalAddPeriod &&
                        <LightBoxAddMandat showModalAddPeriod={showModalAddPeriod} editMode={true}/>
                    }
                    <PopinCopyPeriodConfirm/>
                    <PopinCopyMandateWarning/>
                    {showCopyPeriodLightbox &&
                        <LightBoxCopyPeriod showCopyPeriodLightbox={showCopyPeriodLightbox}/>
                    }
                    <p className="text-main-gray fst-italic">{addUser.civilite === "Madame" ? "Elle" : "Il"} {addUser.dpoValidated ? "a" : "n'a pas encore"} déclaré
                        avoir pris connaissance et
                        accepter sans
                        réserves les
                        règles
                        de <Link target="_blank" to={"/conditions-generales-d-utilisation"}>confidentialité et
                            de protection des données
                            personnelles</Link> {addUser.dpoValidatedAt &&
                            <Fragment>le {addUser.dpoValidatedAt && format(new Date(addUser.dpoValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}</Fragment>}.
                    </p>
                    <p className="text-main-gray fst-italic">{addUser.civilite === "Madame" ? "Elle" : "Il"} {addUser.cguValidated ? "a" : "n'a pas encore"} déclaré
                        avoir pris connaissance et
                        accepter sans
                        réserves
                        les <Link target="_blank" to={"/conditions-generales-d-utilisation"}>conditions générales
                            d’utilisation</Link> {addUser.cguValidatedAt &&
                            <Fragment>le {addUser.cguValidatedAt && format(new Date(addUser.cguValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}</Fragment>}.
                    </p>
                    <PopinDeleteUserConfirm/>
                </div>
            </div>
        </div>
    )
}
