import {SyntheticEvent, useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setDisplayPopinDeleteCategoryConfirm,
    setEditMode,
    setToolSelected,
    setShowModalAddToolCategory,
    setTitle,
    setToolCategorySelected
} from "../../redux/Toolkit/ToolkitSlice"
import {Modal, Form} from "react-bootstrap"
import {createDocumentCategory, TYPE_TOOLBOX, updateDocumentCategory} from "../../api/DocumentAPI"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {ResponseInterface} from "../../utils/CommonInterfaces"

export default function LightBoxAddToolCategory(props: { editMode: boolean }) {

    const dispatch = useAppDispatch()
    const title = useAppSelector((state: RootState) => state.toolkit.title)
    const showModalAddToolCategory = useAppSelector((state: RootState) => state.toolkit.showModalAddToolCategory)
    const toolCategories = useAppSelector((state: RootState) => state.toolkit.toolCategories)
    const toolCategorySelected = useAppSelector((state: RootState) => state.toolkit.toolCategorySelected)

    useEffect(() => {
        if (props.editMode && toolCategorySelected !== null) {
            dispatch(setTitle(toolCategorySelected.title))
        } else {
            dispatch(setToolCategorySelected(null))
            dispatch(setToolSelected(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        let response: ResponseInterface = {
            meta: {
                arg: {},
                requestId: "",
                requestStatus: ""
            }
        }

        if (props.editMode) {
            if (toolCategorySelected !== null) {
                let toolCategoryToSend = {...toolCategorySelected}
                toolCategoryToSend.title = title
                dispatch(displayMessage({text: "Mise à jour en cours"}))
                response = await dispatch(updateDocumentCategory({
                    id: toolCategorySelected.id,
                    fields: toolCategoryToSend
                }))
            }
        } else {
            dispatch(displayMessage({text: "Enregistrement en cours"}))
            response = await dispatch(createDocumentCategory({
                fields: {
                    title: title,
                    orderView: toolCategories.length,
                    type: TYPE_TOOLBOX
                }
            }))
        }
        if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
            dispatch(displayMessage({
                text: props.editMode ? "Catégorie modifiée avec succès !" : "Catégorie ajoutée avec succès !",
                status: "success"
            }))
        } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
            dispatch(displayMessage({
                text: props.editMode ? "Echec de la mise à jour de la catégorie" : "Echec de l'ajout de la catégorie",
                status: "error"
            }))
        }
    }

    const closeLightBox = () => {
        dispatch(setShowModalAddToolCategory(false))
        dispatch(setTitle(""))
        dispatch(setToolCategorySelected(null))
        dispatch(setToolSelected(null))
        dispatch(setEditMode(false))
        dispatch(setDisplayPopinDeleteCategoryConfirm(false))
    }

    return (
        <Modal className="modal-form" show={showModalAddToolCategory} scrollable onHide={() => {
            closeLightBox()
        }}>
            <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                handleSubmit(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title
                        as="h3">{props.editMode ? "Modification d'une catégorie" : "Ajout d'une catégorie"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-title">Titre *</Form.Label>
                        <Form.Control type="text" value={title} id="input-title" required placeholder="Votre titre ici"
                                      onChange={(e) => {
                                          dispatch(setTitle(e.target.value))
                                      }}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {(() => {
                        if (props.editMode) {
                            return <div className="row row-sm row-sm-h">
                                <div className="col-auto">
                                    <button type="button" className="btn btn-light" onClick={() => {
                                        closeLightBox()
                                    }}>
                                        <i className="mai fs-lg me-2 text-danger">clear</i> Annuler
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="button" className="btn btn-light" onClick={(e) => {
                                        dispatch(setDisplayPopinDeleteCategoryConfirm(true))
                                    }}>
                                        <i className="mai fs-lg me-2">delete_outline</i> Supprimer
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-light">
                                        <i className="mai fs-lg me-2">check</i> Valider
                                    </button>
                                </div>
                            </div>
                        } else {
                            return <button type="submit" className="btn btn-light">
                                <i className="mai fs-lg me-2">add</i> Ajouter
                            </button>
                        }
                    })()}
                </Modal.Footer>
            </form>
        </Modal>
    )
}
