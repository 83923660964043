import React, {Fragment, useEffect} from "react"
import {Meeting, MEETING_TYPE_MAIN, MeetingOccurrence} from "../../../api/MeetingAPI"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {
    ATTENDING_AVAILABLE,
    ATTENDING_NO,
    ATTENDING_VISIO, MeetingUser,
} from "../../../api/MeetingUserAPI"
import {ValidAttendanceButton} from "./ValidAttendanceButton"
import {Dropdown} from "react-bootstrap"
import {RenderedAttendanceButton} from "./RenderedAttendanceButton"
import {hasRole} from "../../../utils/UserTools"
import {ROLES_VIEW_MEETINGS} from "../../../api/UserAPI"
import {setLunch} from "../../../redux/Meeting/MeetingSlice"
import {isReplacement} from "../../../utils/Functions"

export function ConfirmAttendance(props: { meeting: Meeting, shortway?: boolean, isReplacement?: boolean }) {
    const dispatch = useAppDispatch()
    const meeting = useAppSelector((state: RootState) => state.meeting.item)
    const meetingUsers = useAppSelector((state: RootState) => state.meeting.meetingUsers)
    const userId = useAppSelector((state) => state.app.userId)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const attendance = useAppSelector((state) => state.meeting.typeAttendance)

    const currentMeetingUser: MeetingUser | undefined = meeting ?
        meetingUsers.find((item) => item.user.id === userId) :
        props.meeting.meetingUsers.find((item) => item.user.id === userId)
    const currentTypeAttendanceShortway = currentMeetingUser ? currentMeetingUser.attending : undefined
    const currentTypeAttendance = attendance !== "" ? attendance : currentTypeAttendanceShortway

    let selectedOccurrence: MeetingOccurrence | undefined
    props.meeting.meetingOccurrences.forEach((occurrence) => {
        if (occurrence.type === MEETING_TYPE_MAIN) {
            selectedOccurrence = occurrence
        }
    })

    useEffect(() => {
        if (currentMeetingUser) {
            dispatch(setLunch(currentMeetingUser.lunch))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMeetingUser])

    if (!hasRole(ROLES_VIEW_MEETINGS, roles) && selectedOccurrence !== undefined && selectedOccurrence.date !== undefined) {
        if (props.shortway) {
            return <Dropdown>
                <Dropdown.Toggle variant="light" className="ws-n" id="dropdown-confirm-attendance">
                    {(currentMeetingUser && currentMeetingUser.attending) ?
                        <RenderedAttendanceButton
                            type={currentMeetingUser.attending}
                            typeCurrentAttendance={currentTypeAttendanceShortway}
                        />
                        : "..."
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                    <ValidAttendanceButton
                        shortway={props.shortway}
                        meeting={props.meeting}
                        type={ATTENDING_AVAILABLE}
                        typeCurrentAttendance={currentTypeAttendanceShortway}
                    />
                    <ValidAttendanceButton
                        shortway={props.shortway}
                        meeting={props.meeting}
                        type={ATTENDING_VISIO}
                        typeCurrentAttendance={currentTypeAttendanceShortway}
                    />
                    <ValidAttendanceButton
                        shortway={props.shortway}
                        meeting={props.meeting}
                        type={ATTENDING_NO}
                        typeCurrentAttendance={currentTypeAttendanceShortway}
                        disabled={props.isReplacement}
                    />
                </Dropdown.Menu>
            </Dropdown>
        } else {
            return <div className="btn-group flex-column flex-md-row w-100 mt-3">
                <ValidAttendanceButton
                    shortway={props.shortway}
                    meeting={props.meeting}
                    type={ATTENDING_AVAILABLE}
                    typeCurrentAttendance={currentTypeAttendance}
                />
                <ValidAttendanceButton
                    shortway={props.shortway}
                    meeting={props.meeting}
                    type={ATTENDING_VISIO}
                    typeCurrentAttendance={currentTypeAttendance}
                />
                <ValidAttendanceButton
                    shortway={props.shortway}
                    meeting={props.meeting}
                    type={ATTENDING_NO}
                    typeCurrentAttendance={currentTypeAttendance}
                    disabled={props.isReplacement}
                />
            </div>
        }
    }

    return <Fragment/>
}
