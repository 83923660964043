import {useAppDispatch, useAppSelector} from "../../redux/Hooks";
import {Modal} from "react-bootstrap"
import {Committee} from "../../api/CommitteeAPI";
import {setModalCanNotDeleteCommitteeShow} from "../../redux/Committee/CommitteeSlice";

export default function CanNotDeleteModal(props: { committee: Committee }) {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.committee.modalCanNotDeleteCommitteeShow)

    function closeModal() {
        dispatch(setModalCanNotDeleteCommitteeShow(false))
    }

    return <Modal size="sm" className="modal-confirm" show={show} onHide={() => closeModal()} backdropClassName="modal-backdrop-confirm">
        <Modal.Header>
            <Modal.Title as="h3">
                <i className="mai me-2">warning</i> Action requise avant suppression
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Des séances sont liées à cette instance. Merci de les supprimer afin que les commissaires-administrateurs soient avertis.</p>
        </Modal.Body>
        <Modal.Footer>
            <div className="row row-sm row-sm-h align-items-center">
                <div className="col-auto"><button type="button" className="btn btn-danger" onClick={() => closeModal()}>OK</button></div>
            </div>
        </Modal.Footer>
    </Modal>
}
