import {useAppSelector} from "../../redux/Hooks";
import {RootState} from "../../redux/Store";
import {TYPE_CAT_CONVOCATION, TYPE_CAT_OTHER, TYPE_CAT_PV} from "../../api/DocumentAPI";
import ItemExpectedDocument, {ItemExpectedDocumentAddButton} from "./ItemExpectedDocument";

export function ExpectedDocuments() {
    const documents = useAppSelector((state:RootState) => state.committeeForm.expectedDocuments);
    const convocation = documents.find(e => (e.category !== undefined && e.category.type === TYPE_CAT_CONVOCATION))
    const pv = documents.find(e => (e.category !== undefined && e.category.type === TYPE_CAT_PV))
    const otherDocs = documents.filter(e => ((e.category !== undefined && e.category.type === TYPE_CAT_OTHER)))

    let docIndex = 1;
    const docs = otherDocs.map((doc) => {
        docIndex++;
        return <ItemExpectedDocument key={docIndex} docIndex={docIndex} doc={doc} type={TYPE_CAT_OTHER} />
    })

    return <>
        <div id="expected-documents">
            <ItemExpectedDocument key={0} docIndex={0} doc={convocation} type={TYPE_CAT_CONVOCATION} />
            <ItemExpectedDocument key={1} docIndex={1} doc={pv} type={TYPE_CAT_PV} />
            {docs}
        </div>
        <ItemExpectedDocumentAddButton />
    </>
}
