import {createAsyncThunk} from "@reduxjs/toolkit"
import {Options, toQuery} from "../utils/Options"
import {axiosInstance} from "../utils/http-common"
import {FULL_MEETING_USER_API} from "../utils/Data"

const AxiosInstance = axiosInstance()

export const ATTENDING_AVAILABLE = "present"
export const ATTENDING_VISIO = "visio"
export const ATTENDING_NO = "absent"

interface User {
    id: number
    firstname: string
    lastname: string
    enabled?: boolean
    niceName?: string
}

interface Meeting {
    id: number;
}

export interface MeetingUser {
    id: number;
    attending?: string;
    lunch?: boolean;
    replacedBy?: User;
    user: User;
    meeting: Meeting;
}

export const fetchMeetingUsersWithOption = createAsyncThunk<MeetingUser[], { options?: Options }>(
    "meetingUsers/fetchWithOption",
    async (meetingUserData) => {
        const {data} = (await AxiosInstance.get(`${FULL_MEETING_USER_API}` + toQuery(meetingUserData.options)))
        return data["hydra:member"]
    }
)

export const fetchUserMeetings = createAsyncThunk<MeetingUser[], { options?: Options }>(
    "userMeetings/fetchWithOption",
    async (meetingUserData) => {
        const {data} = (await AxiosInstance.get(`${FULL_MEETING_USER_API}` + toQuery(meetingUserData.options)))
        return data["hydra:member"]
    }
)

export const createMeetingUser = createAsyncThunk<MeetingUser, { fields: object }>(
    "meetingUsers/create",
    async (meetingUserData) => {
        const {data} = (await AxiosInstance.post(`${FULL_MEETING_USER_API}`, JSON.stringify(meetingUserData.fields)))
        return data
    }
)

export const putMeetingUser = createAsyncThunk<MeetingUser, { meetingUserId: number, fields: object }>(
    "meetingUsers/put",
    async (meetingUserData) => {
        const {data} = (await AxiosInstance.put(`${FULL_MEETING_USER_API}/${meetingUserData.meetingUserId}`, JSON.stringify(meetingUserData.fields)))
        return data
    }
)
