import React from "react";

type Props = {
    place:string
}

export default function MeetingPlace({ place }:Props) {
    return <div className="bg-main-gray-light p-3 mb-2">
        <div className="row row-sm row-sm-h align-items-center">
            <div className="col-auto">
                <span className="mai fs-lg text-main-gray d-block">location_on</span>
            </div>
            <div className="col">
                {place}
            </div>
        </div>
    </div>
}
