import axios, {AxiosInstance} from "axios"
import {getCookie} from "./cookies"

export default axios.create({
    headers: {
        "Content-type": "application/ld+json"
    }
})

export const axiosInstanceNoToken = () => {

    return axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "application/json"
        }
    }) as AxiosInstance
}

export const axiosInstanceNoTokenPatch = () => {

    return axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    }) as AxiosInstance
}

export const axiosInstance = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "application/ld+json"
        }
    }) as AxiosInstance

    axiosInstance.interceptors.request.use(async function (config) {
        let token
        try {
            token = getCookie("accessToken")
        } catch (error) {
            console.log("CANNOT GET HTTP ACCESS TOKEN")
        }

        if (config?.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    })

    return axiosInstance
}

export const axiosInstanceJustJson = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "application/json"
        }
    }) as AxiosInstance

    axiosInstance.interceptors.request.use(async function (config) {
        let token
        try {
            token = getCookie("accessToken")
        } catch (error) {
            console.log("CANNOT GET HTTP ACCESS TOKEN")
        }

        if (config?.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    })

    return axiosInstance
}

export const axiosInstancePatch = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    }) as AxiosInstance

    axiosInstance.interceptors.request.use(async function (config) {
        let token
        try {
            token = getCookie("accessToken")
        } catch (error) {
            console.log("CANNOT GET HTTP ACCESS TOKEN")
        }

        if (config?.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    })

    return axiosInstance
}

export const axiosInstanceMultipart = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.API_URL,
        timeout: 3000,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }) as AxiosInstance

    axiosInstance.interceptors.request.use(async function (config) {
        let token
        try {
            token = getCookie("accessToken")
        } catch (error) {
            console.log("CANNOT GET HTTP ACCESS TOKEN")
        }

        if (config?.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    })

    return axiosInstance
}