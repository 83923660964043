import React, {useEffect, useState} from "react"
import {Document} from "../../api/DocumentAPI"
import {useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {isActiveInMeetingCommittee} from "../../utils/Functions"
import {Meeting} from "../../api/MeetingAPI"
import {hasRole} from "../../utils/UserTools"
import {ROLES_VIEW_ALL_DOCUMENTS} from "../../api/UserAPI"
import DocumentsByMeetingContainer from "./DocumentsByMeetingContainer"
import {getFormattedLocalisedDate} from "../../utils/Date"

export function DocumentsByMeeting(props: { meeting: Meeting }) {
    const mandates = useAppSelector((state: RootState) => state.mandate.items)
    const documents = useAppSelector((state) => state.document.items)
    const roles = useAppSelector((state) => state.app.roles)
    const [currentMeetingDocuments, setCurrentMeetingDocuments] = useState<Document[]>([])

    useEffect(() => {
        if (props.meeting &&
            ((props.meeting.committee && props.meeting.committee.canViewDocs) ||
                hasRole(ROLES_VIEW_ALL_DOCUMENTS, roles) ||
                isActiveInMeetingCommittee(mandates, props.meeting)
            )
        ) {
            setCurrentMeetingDocuments(documents.filter((item) => item?.meeting?.id === props.meeting.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents])

    let labelNbDocs = "document" + (currentMeetingDocuments.length > 1 ? "s" : "")
    
    return <div className="bg-white p-4">
        <button className="btn-less row row-sm row-sm-h align-items-center w-100 fw-medium collapsed" type="button"
                data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${props.meeting.id}`}
                aria-expanded="true" aria-controls={`panelsStayOpen-collapse${props.meeting.id}`}
        >
            <span className="col-auto fs-lg text-tertiary">
                <i className="mai mai-expand-less">expand_less</i>
            </span>
            <span className="col fs-lg">{getFormattedLocalisedDate(new Date(props.meeting.title), "dd/MM/yyyy")}</span>
            <span className="col-auto">
                <span className="badge badge-sm border border-border-gray text-dark fw-medium">
                    {currentMeetingDocuments.length} {labelNbDocs}
                </span>
            </span>
        </button>
        <div id={`panelsStayOpen-collapse${props.meeting.id}`} className="accordion-collapse collapse"
             data-bs-parent="#accordionMeetings">
            <div className="accordion-body">
                <DocumentsByMeetingContainer context="" meeting={props.meeting} documents={currentMeetingDocuments}/>
            </div>
        </div>
    </div>
}
