import {ReactNode} from 'react'

function linkPagination($i: number, actualPage: number, modifyActualPage: () => void) {
    return $i === actualPage ?
        <li className="page-item active" key={$i}>
            <span className="page-link">
                {$i}
            </span>
        </li>
        : <li className="page-item" key={$i}>
            <button className="page-link" onClick={() => modifyActualPage()}>
                {$i}
            </button>
        </li>
}

export default function GenerateLinksPagination(props: {
    nbPages: number, // Nombre total de pages dans la pagination
    actualPage: number, // Numéro de la page actuelle
    setActualPage:(page:number)=>void, // Fonction permettant de modifier le numéro de page actuelle et passer à la page dont on a cliqué sur le numéro
    getData:(page: number)=>void // Fonction de récupération de données de la nouvelle page, elle prend en paramètre le nouveau numéro de page sur lequel on a cliqué
}) {

    let linksPagiation:ReactNode[] = []
    let dottedLine = true
    if (props.nbPages <= 10) { // Si on a moins de 10 pages dans la pagination
        if(props.nbPages === 1) return <></> // Si on a qu'ne seule page, on n'affiche aucun lien de pagination

        for (let $i = 1; $i <= props.nbPages; $i++) {
            linksPagiation.push(linkPagination($i, props.actualPage, () => {
                props.setActualPage($i)
                props.getData($i)}
            ))
        }
    } else {
        if (props.actualPage > 2) {
            if (props.actualPage < props.nbPages - 4) {

                if(props.actualPage % 2 === 0){ // Si on est sur une page paire
                    for (let $i = props.actualPage - 1; $i <= props.actualPage + 1; $i++) {
                        linksPagiation.push(linkPagination($i, props.actualPage, () => {
                            props.setActualPage($i)
                            props.getData($i)}
                        ))
                    }
                }else{ // Si on est sur une page impaire
                    for (let $i = props.actualPage; $i <= props.actualPage + 2; $i++) {
                        linksPagiation.push(linkPagination($i, props.actualPage, () => {
                            props.setActualPage($i)
                            props.getData($i)}
                        ))
                    }
                }
            } else if (props.actualPage === props.nbPages - 4){
                dottedLine = false
                for (let $i = props.actualPage-2; $i <= props.actualPage; $i++) {
                    linksPagiation.push(linkPagination($i, props.actualPage, () => {
                        props.setActualPage($i)
                        props.getData($i)}
                    ))
                }
                linksPagiation.push(<li className="page-item disabled"><span className="page-link">...</span></li>)
                for (let $i = props.nbPages-2; $i <= props.nbPages; $i++) {
                    linksPagiation.push(linkPagination($i, props.actualPage, () => {
                        props.setActualPage($i)
                        props.getData($i)}
                    ))
                }
            }else{
                dottedLine = false
                for (let $i = props.nbPages - 4; $i <= props.nbPages; $i++) {
                    linksPagiation.push(linkPagination($i, props.actualPage, () => {
                        props.setActualPage($i)
                        props.getData($i)}
                    ))
                }
            }

        } else {
            for (let $i = 1; $i <= 3; $i++) {
                linksPagiation.push(linkPagination($i, props.actualPage, () => {
                    props.setActualPage($i)
                    props.getData($i)}
                ))
            }
        }

        if (dottedLine) {
            linksPagiation.push(<li key={0} className="page-item disabled"><span className="page-link">...</span></li>)
            for (let $i = props.nbPages - 1; $i <= props.nbPages; $i++) {
                linksPagiation.push(linkPagination($i, props.actualPage, () => {
                    props.setActualPage($i)
                    props.getData($i)}
                ))

            }
        }
    }
  return (<>


  <div className="row row-sm row-sm-h align-items-center justify-content-between mt-5">
                        <div className="col-auto">
                            <button className="btn btn-light" disabled={props.actualPage === 1} onClick={() => {
                                    props.setActualPage(1)
                                    props.getData(1)
                                }}>
                                <i className="mai fs-md me-2">arrow_back</i>
                                Début
                            </button>
                        </div>
                        <div className="col-auto">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    {linksPagiation}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-light" disabled={props.nbPages === props.actualPage} onClick={() => {
                                props.setActualPage(props.nbPages)
                                props.getData(props.nbPages)
                            }}>
                                Fin
                                <i className="mai fs-md ms-2">arrow_forward</i>
                            </button>
                        </div>
                    </div>






  </>)
}
