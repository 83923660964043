import {createSlice} from "@reduxjs/toolkit"
import {fetchCommittees, Committee} from "../../api/CommitteeAPI"
import {Mandate, Period} from "../../api/MandateAPI"
import {fetchUsersForLabel, getUsers, User, userEmpty} from "../../api/UserAPI"
import {SelectOption} from "../../utils/CommonInterfaces"
import {CommitteeSelectItem} from "../../utils/CommonInterfaces"
import {ChoiceItem} from "../../utils/ChoiceList"

export interface trombinoscopeState {
    users: User[],
    userSelected: User,
    show: boolean,
    mandates: Mandate[],
    stateListUser: string,
    errorLoading: boolean,
    reverse: boolean,
    nbPerPage: number | string,
    page: number,
    nbUsersFilteredTotal: number | string, // Nombre total d'utilisateurs correspondant aux filtres sélectionnées
    actualPage: number,
    loadingMandates: boolean,
    onlyActive: boolean,
    onlyActivePopinLabels: boolean,
    periods: Period[],
    committees: Committee[],
    committeesSelected: Committee[],
    rolesSelected: ChoiceItem[],
    statusSelected: SelectOption[],
    filterName: string,
    filterInstance: string,
    filterCollege: string,
    filterSyndicate: string,
    filterStatus: string,
    filterRole: string,
    filterProfil: string,
    optionsFonctions: CommitteeSelectItem[],
    optionsStatus: CommitteeSelectItem[],
    usersLabel: User[],
    displayPdfDownloadLink: boolean,
    loadingUserLabels: boolean
}

const initialState: trombinoscopeState = {
    users: [],
    userSelected: userEmpty,
    show: false,
    mandates: [],
    stateListUser: "loading",
    errorLoading: false,
    reverse: false,
    nbPerPage: 24,
    page: 1,
    nbUsersFilteredTotal: 0,
    actualPage: 1,
    loadingMandates: true,
    onlyActive: true,
    onlyActivePopinLabels: true,
    periods: [],
    committees: [],
    committeesSelected: [],
    rolesSelected: [],
    statusSelected: [],
    filterName: "",
    filterInstance: "",
    filterCollege: "",
    filterSyndicate: "",
    filterStatus: "",
    filterRole: "",
    filterProfil: "",
    optionsFonctions: [
        {value: "0", label: "Président", color: ""},
        {value: "1", label: "Vice-président", color: ""},
        {value: "2", label: "Trésorier", color: ""},
        {value: "3", label: "Trésorier adjoint", color: ""},
        {value: "4", label: "Secrétaire", color: ""},
        {value: "5", label: "Secrétaire adjoint", color: ""},
        {value: "6", label: "Conseiller", color: ""},
        {value: "7", label: "Co-animateur", color: ""},
        {value: "8", label: "Chef de file", color: ""}
    ],
    optionsStatus: [
        {value: "0", label: "Titulaire", color: ""},
        {value: "1", label: "Suppléant", color: ""}
    ],
    usersLabel: [],
    displayPdfDownloadLink: false,
    loadingUserLabels: false
}

export const trombinoscopeSlice = createSlice({
    name: "trombinoscope",
    initialState,
    reducers: {
        setShow: (state, action) => {
            state.show = action.payload
        },
        setUserSelected: (state, action) => {
            state.userSelected = action.payload
        },
        setUsers(state, action) {
            state.users = action.payload
        },
        setReverse(state, action) {
            state.reverse = action.payload
        },
        setNbPerPage: (state, action) => {
            state.nbPerPage = action.payload
            if (action.payload === "Tout") {
                state.actualPage = 1
            }
        },
        setActualPage: (state, action) => {
            state.actualPage = action.payload
        },
        setOnlyActive: (state, action) => {
            state.onlyActive = action.payload
        },
        setOnlyActivePopinLabels: (state, action) => {
            state.onlyActivePopinLabels = action.payload
        },
        setCommitteesSelected: (state, action) => {
            state.committeesSelected = action.payload
        },
        setRolesSelected: (state, action) => {
            state.rolesSelected = action.payload
        },
        setStatusSelected: (state, action) => {
            state.statusSelected = action.payload
        },
        setFilterName: (state, action) => {
            state.filterName = action.payload
        },
        setFilterInstance: (state, action) => {
            state.filterInstance = action.payload
        },
        setFilterCollege: (state, action) => {
            state.filterCollege = action.payload
        },
        setFilterSyndicate: (state, action) => {
            state.filterSyndicate = action.payload
        },
        setFilterStatus: (state, action) => {
            state.filterStatus = action.payload
        },
        setFilterRole: (state, action) => {
            state.filterRole = action.payload
        },
        setFilterProfil: (state, action) => {
            state.filterProfil = action.payload
        },
        setUsersLabel: (state, action) => {
            state.usersLabel = action.payload
        },
        setDisplayPdfDownloadLink: (state, action) => {
            state.displayPdfDownloadLink = action.payload
        },
        setNbUsersFilteredTotal: (state, action) => {
            state.nbUsersFilteredTotal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.stateListUser = "loading"
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.stateListUser = ""
                state.users = action.payload.users
                state.nbUsersFilteredTotal = Number(action.payload.nbTotalUsers)
            })
            .addCase(getUsers.rejected, (state) => {
                state.stateListUser = ""
                state.errorLoading = true
            })
            .addCase(fetchCommittees.pending, (state) => {
                state.loadingMandates = true
            })
            .addCase(fetchCommittees.fulfilled, (state, action) => {
                state.committees = action.payload
            })
            .addCase(fetchUsersForLabel.pending, (state) => {
                state.loadingUserLabels = true
            })
            .addCase(fetchUsersForLabel.fulfilled, (state, action) => {
                state.usersLabel = action.payload.users
                state.displayPdfDownloadLink = true
                state.loadingUserLabels = false
            })
            .addCase(fetchUsersForLabel.rejected, (state) => {
                state.loadingUserLabels = false
            })
    }
})

export const {
    setUserSelected,
    setShow,
    setUsers,
    setReverse,
    setNbPerPage,
    setActualPage,
    setOnlyActive,
    setOnlyActivePopinLabels,
    setCommitteesSelected,
    setRolesSelected,
    setStatusSelected,
    setFilterName,
    setFilterInstance,
    setFilterCollege,
    setFilterSyndicate,
    setFilterStatus,
    setFilterRole,
    setFilterProfil,
    setUsersLabel,
    setDisplayPdfDownloadLink,
    setNbUsersFilteredTotal
} = trombinoscopeSlice.actions

export default trombinoscopeSlice.reducer
