import React, { Fragment } from "react"
import {ExpectedDocument} from "../../../api/ExpectedDocumentAPI"
import {Document, TYPE_CAT_PV} from "../../../api/DocumentAPI"
import ItemDocumentForm from "./ItemDocumentForm"

export default function ItemDocument(props: {expectedDocument?: ExpectedDocument, document?: Document, type?: number, docIndex: number}) {
    if (props.type === TYPE_CAT_PV && !props.expectedDocument?.available) {
        return <Fragment />
    }

    return <ItemDocumentForm key={props.docIndex} index={props.docIndex} document={props.document} documentType={props.type} expectedDocument={props.expectedDocument} />
}
