import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {deleteActuality, fetchContents, TYPE_CONTENT_NEWS} from "../../api/ContentAPI"
import {setDisplayPopinDeleteActualityConfirm, setActualPage} from "../../redux/Content/ContentSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Modal} from "react-bootstrap"

export default function PopinDeleteActualityConfirm() {

    const dispatch = useAppDispatch()
    const displayPopinDeleteActualityConfirm = useAppSelector((state: RootState) => state.actuality.displayPopinDeleteActualityConfirm)
    const actualitySelected = useAppSelector((state: RootState) => state.actuality.actualitySelected)
    const contentActuality = useAppSelector((state: RootState) => state.actuality.contentActuality)
    const titleActuality = useAppSelector((state: RootState) => state.actuality.titleActuality)
    const imageActuality = useAppSelector((state: RootState) => state.actuality.imageActuality)
    const publishedActuality = useAppSelector((state: RootState) => state.actuality.publishedActuality)
    const videoLinkActuality = useAppSelector((state: RootState) => state.actuality.videoLinkActuality)

    const deleteActu = async () => {
        dispatch(displayMessage({text: "Suppression en cours"}))
        if (actualitySelected !== null) {
            let response = await dispatch(deleteActuality({
                actuality: {
                    id: actualitySelected.id,
                    title: titleActuality,
                    content: contentActuality,
                    filePath: imageActuality,
                    videoLink: videoLinkActuality,
                    published: publishedActuality
                }
            }))

            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Suppression effectué avec succès !", status: "success"}))
                dispatch(fetchContents({options: {type: TYPE_CONTENT_NEWS}}))
                dispatch(setActualPage(1))
            } else {
                dispatch(displayMessage({text: "Echec de la suppression de l'actualité", status: "error"}))
            }

        }
    }

    return (
        <Modal show={displayPopinDeleteActualityConfirm} className="modal-confirm" size="sm"
               backdropClassName="modal-backdrop-confirm">
            <Modal.Header>
                <Modal.Title as="h3"><span className="mai me-2">warning</span>Confirmation requise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes vous sûr de vouloir supprimer cette actualité ?
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="button" className="btn btn-main-gray-light" data-bs-dismiss="modal"
                                onClick={() => {
                                    dispatch(setDisplayPopinDeleteActualityConfirm(false))
                                }}>Non
                        </button>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-danger" onClick={() => {
                            deleteActu()
                        }}>
                            Oui
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
