import {createSlice} from "@reduxjs/toolkit";
import {createSms, createSmsUser, fetchSms, Sms} from "../../api/SmsAPI";
import {fetchSmsMeetings, Meeting} from "../../api/MeetingAPI";

export interface SmsState {
    loading: boolean,
    status?: string,
    message?: string,
    meetings: Meeting[],
    items: Sms[],
    item?: Sms,
    total: number
}

const initialState: SmsState = {
    loading: false,
    meetings: [],
    items: [],
    total: 0
}

export const SmsSlice = createSlice({
    name: 'sms',
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = undefined
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(createSms.pending, (state) => {
                    state.loading = true
                })
                .addCase(createSms.rejected, (state) => {
                    state.loading = false
                    state.status = "error"
                    state.message = "Une erreur a eu lieu."
                })
                .addCase(createSms.fulfilled, (state, action) => {
                    state.loading = false
                    state.status = "success"
                    state.message = "Votre SMS a bien été envoyé."
                    state.item = action.payload
                    state.items.reverse()
                    state.items.push(action.payload)
                    state.items.reverse()
                })
                .addCase(createSmsUser.rejected, (state) => {
                    state.loading = false
                    state.status = "error"
                    state.message = "Une erreur a eu lieu."
                })
                .addCase(fetchSms.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchSms.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchSms.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload.items
                    state.total = action.payload.total
                })
                .addCase(fetchSmsMeetings.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchSmsMeetings.rejected, (state) => {
                    state.loading = false
                })
                .addCase(fetchSmsMeetings.fulfilled, (state, action) => {
                    state.loading = false
                    state.meetings = action.payload
                })
        }
})

export const { resetStatus } = SmsSlice.actions

export default SmsSlice.reducer
