import {MeetingOccurrence} from "../../../api/MeetingAPI"
import React from "react"
import {getFormattedLocalisedDate} from "../../../utils/Date"

export default function MeetingDateTime(props: { occurrence?: MeetingOccurrence }) {
    if (props.occurrence?.date === undefined) {
        return <></>
    }

    const day = getFormattedLocalisedDate(new Date(props.occurrence.date), "EEEE")
    const date = getFormattedLocalisedDate(new Date(props.occurrence.date), "d MMMM yyyy")
    const time = getFormattedLocalisedDate(new Date(props.occurrence.date), "HH:mm")

    return <div className="p-3 mb-2">
        <div className="row row-sm row-sm-h align-items-center">
            <div className="col-auto"><span className="mai fs-lg text-main-gray">schedule</span></div>
            <div className="col">
                <span className="text-capitalize">{day}</span> {date} - {time}
            </div>
        </div>
    </div>
}
