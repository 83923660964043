import {createAsyncThunk} from "@reduxjs/toolkit"
import {Options, toQuery} from "../utils/Options"
import {axiosInstance} from "../utils/http-common"
import {FULL_EXPECTED_DOCUMENT_API, FULL_EXPECTED_DOCUMENT_CAT_API} from "../utils/Data"

const AxiosInstance = axiosInstance()

export interface ExpectedDocument {
    id?: number;
    title?: string;
    mandatory?: boolean;
    delay1?: number;
    delay2?: number;
    category?: ExpectedDocumentCategory;
    notify?: boolean;
    available?: boolean;
}

export interface ExpectedDocumentCategory {
    id?: number;
    title: string;
    type: number;
}

export const fetchExpectedDocuments = createAsyncThunk<ExpectedDocument[], { options?: Options }>(
    "expected_documents/fetchAll",
    async (documentData) => {
        const {data} = (await AxiosInstance.get(
            `${FULL_EXPECTED_DOCUMENT_API}` + toQuery(documentData.options)
        ))
        return data["hydra:member"]
    }
)

export const fetchExpectedDocumentCategories = createAsyncThunk<ExpectedDocumentCategory[], {
    options?: Options
}>(
    "expected_document_categories/fetchOne",
    async (documentData) => {
        const {data} = (await AxiosInstance.get(
            `${FULL_EXPECTED_DOCUMENT_CAT_API}` + toQuery(documentData.options)
        ))
        return data["hydra:member"]
    }
)
