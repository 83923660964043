import {createAsyncThunk} from "@reduxjs/toolkit"
import {axiosInstance} from "../utils/http-common"
import {FULL_COMMITTEE_API} from "../utils/Data"
import {Options, toQuery} from "../utils/Options"

const AxiosInstance = axiosInstance()

export const DOC_TO_SIGN = 1
export const CAT_PV = 3

export interface Committee {
    id: number;
    title?: string;
    content?: string;
    color?: string;
    documentForAll?: boolean;
    canViewDocs?: boolean;
    onlyReplacedByCommitteeMembers?: boolean;
    slug: string;
    createdDate?: string;
    updatedDate?: string;
}

export const fetchCommittees = createAsyncThunk<Committee[], { options?: Options }>(
    "committees/fetchAll",
    async (committeeData) => {
        const {data} = (await AxiosInstance.get(
            `${FULL_COMMITTEE_API}` + toQuery(committeeData.options)
        ))

        return data["hydra:member"]
    }
)

export const createCommittee = createAsyncThunk<Committee, { fields: object }>(
    "committees/create",
    async (committeeData) => {
        const {data} = (await AxiosInstance.post(
            `${FULL_COMMITTEE_API}`,
            JSON.stringify(committeeData.fields)
        ))
        return data
    }
)

export const putCommittee = createAsyncThunk<Committee, { committeeId: number, fields: object }>(
    "committees/put",
    async (committeeData) => {
        const {data} = (await AxiosInstance.put(
            `${FULL_COMMITTEE_API}/${committeeData.committeeId}`,
            JSON.stringify(committeeData.fields)
        ))
        return data
    }
)

export const deleteCommittee = createAsyncThunk<{ id: number, title: string }, {
    committeeTitle: string,
    committeeId: number
}>(
    "committees/delete",
    async (committeeData) => {
        (await AxiosInstance.delete(
            `${FULL_COMMITTEE_API}/${committeeData.committeeId}`
        ))

        return {
            id: committeeData.committeeId,
            title: committeeData.committeeTitle
        }
    }
)
