import React, {useEffect, ReactNode, Fragment} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setViewUnpublishedTools,
    setShowModalAddToolCategory,
    setToolCategories,
    setEditMode,
    setToolCategorySelected,
    setShowModalAddTool,
    setEditModeTool,
    setToolSelected
} from "../../redux/Toolkit/ToolkitSlice"
import {setPage} from "../../redux/User/UserSlice"
import {hasRole} from "../../utils/UserTools"
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc"
import {arrayMoveImmutable} from "array-move"
import LightBoxAddToolCategory from "../../components/Toolkit/LightBoxAddToolCategory"
import LightBoxAddTool from "../../components/Toolkit/LightBoxAddTool"
import {
    displayDocument,
    DocumentCategory,
    Document,
    fetchDocumentCategories,
    TYPE_TOOLBOX,
    updateAllToolCategories
} from "../../api/DocumentAPI"
import PopinDeleteCategoryConfirm from "../../components/Toolkit/PopinDeleteCategoryConfirm"
import PopinDeleteToolConfirm from "../../components/Toolkit/PopinDeleteToolConfirm"
import {displayMessage} from "../../redux/Message/MessageSlice"
import PageHeader from "../../components/PageHeader"
import {Col, Container, Form, Row} from "react-bootstrap"
import LoadingSpinner from "../../components/LoadingSpinner"
import {createLog, fetchLogs} from "../../api/LogAPI"
import LightBoxLogs from "../Log/LightBoxLogs"
import {setLightBoxShow, setSelectedEntityId} from "../../redux/Log/LogSlice"

export default function Toolkit() {
    const dispatch = useAppDispatch()

    const app = useAppSelector((state: RootState) => state.app)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const toolCategories = useAppSelector((state: RootState) => state.toolkit.toolCategories)
    const editMode = useAppSelector((state: RootState) => state.toolkit.editMode)
    const editModeTool = useAppSelector((state: RootState) => state.toolkit.editModeTool)
    const showModalAddToolCategory = useAppSelector((state: RootState) => state.toolkit.showModalAddToolCategory)
    const showModalAddTool = useAppSelector((state: RootState) => state.toolkit.showModalAddTool)
    const viewUnpublishedTools = useAppSelector((state: RootState) => state.toolkit.viewUnpublishedTools)
    const stateCategoryList = useAppSelector((state: RootState) => state.toolkit.stateCategoryList)
    const logs = useAppSelector((state: RootState) => state.log.items)
    const showLightBox = useAppSelector((state: RootState) => state.log.showLightBox)

    interface ISortableItem {
        key: string,
        index: number,
        item: DocumentCategory
    }

    useEffect(() => {
        dispatch(setPage("toolkit"))
        dispatch(fetchDocumentCategories({options: {type: TYPE_TOOLBOX}}))
        dispatch(fetchLogs({options: {entityType: "Document"}}))
    }, [dispatch])

    const DragHandle = SortableHandle(() => <i className="mai fs-lg text-main-gray cursor-move">drag_indicator</i>)

    const onSortEnd = ({oldIndex, newIndex}: { oldIndex: number; newIndex: number }): void => {
        let ntc = arrayMoveImmutable(toolCategories, oldIndex, newIndex)
        const newToolCategories = ntc.map((toolCategory: DocumentCategory, index: number) => {
            let tc = {...toolCategory}
            tc.orderView = index
            return tc
        })
        dispatch(setToolCategories([...newToolCategories]))
        dispatch(updateAllToolCategories({toolCategories: [...newToolCategories]}))
    }

    const downloadFileTool = (id_tool: number | string, title_tool: string, fileExtension: string) => {

        displayDocument("dwn", id_tool)
            .then((response) => {
                let blob = response
                let link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = title_tool + "." + fileExtension
                document.body.appendChild(link)
                link.click()

                // On enregistre la consultation dans la table de logs
                dispatch(createLog({
                    fields: {
                        userDetails: {
                            id: app.user.id,
                            firstname: app.firstname,
                            lastname: app.lastname
                        },
                        entityId: id_tool,
                        entityType: "Document"
                    }
                }))
            })
            .catch(() => {
                dispatch(displayMessage({text: "Echec du téléchargement du fichier", status: "error"}))
            })
    }

    const SortableItem = SortableElement<ISortableItem>(({item}: { item: DocumentCategory }) => {
        return <div key={item.id} className="block mb-1">
            <Row className="row-sm align-items-center">
                {
                    hasRole(["ROLE_ADMIN"], roles) &&
                    <Col xs="auto">
                        <DragHandle/>
                    </Col>
                }
                <Col xs="auto" className="fs-lg fw-bold">{item.title}</Col>
                {(() => {
                    if (hasRole(["ROLE_ADMIN"], roles)) {
                        return <Col xs="auto">
                            <button className="btn-less text-primary" onClick={() => {
                                dispatch(setEditMode(true))
                                dispatch(setShowModalAddToolCategory(true))

                                let toolCategory: DocumentCategory = {
                                    type: "",
                                    orderView: 0
                                }

                                toolCategory.id = item.id
                                toolCategory.orderView = item.orderView
                                toolCategory.title = item.title
                                toolCategory.type = item.type
                                dispatch(setToolCategorySelected(toolCategory))
                            }}>
                                <i className="mai fs-lg">edit</i>
                            </button>
                        </Col>
                    }
                })()}
            </Row>
            {item.documents && item.documents.length > 0 &&
                <div key={item.id} style={{zIndex: 1}} className="mt-5">
                    {item.documents?.map((outil: Document, index: number) => {
                        if (outil.published || viewUnpublishedTools) {
                            const nbLogs = logs.filter(item => item.entityId === outil.id).length
                            return <div key={index} className="row mb-1 justify-content-between">
                                <Col xs={10} md="auto">
                                    <div className="d-inline-block px-4 py-2 bg-light rounded-pill">
                                        <Row className="row-sm row-sm-h align-items-center">
                                            {outil.fileExtension === "pdf" ?
                                                <Col xs="auto">
                                                    <i className="mai fs-lg text-primary">picture_as_pdf</i>
                                                </Col>
                                                : <Col xs="auto">
                                                    {
                                                        outil.fileExtension === "zip"
                                                            ? <i className="mai fs-lg text-primary">folder_zip</i>
                                                            : <i className="mai fs-lg text-primary">table_view</i>
                                                    }
                                                </Col>
                                            }
                                            <Col xs="auto">
                                                <button className="text-dark btn-less" onClick={(e) => {
                                                    e.preventDefault()
                                                    downloadFileTool(outil.id, outil.filePath !== undefined ? outil.filePath.split("/")[5] : "", outil.fileExtension !== undefined ? outil.fileExtension : "")
                                                }}>
                                                    {outil.title}
                                                    {
                                                        !outil.published &&
                                                        <span> - dépublié</span>
                                                    }
                                                </button>
                                            </Col>
                                            {
                                                hasRole(["ROLE_ADMIN"], roles) &&
                                                <Col xs="auto">
                                                    <button className="btn-less text-primary" onClick={() => {
                                                        dispatch(setEditModeTool(true))
                                                        dispatch(setShowModalAddTool(true))
                                                        let newTool = {...outil}
                                                        newTool.filePath = newTool.filePath !== undefined ? newTool.filePath.split("/")[5] : ""
                                                        dispatch(setToolSelected(newTool))
                                                    }}>
                                                        <i className="mai d-block">edit</i>
                                                    </button>
                                                </Col>
                                            }

                                        </Row>
                                    </div>
                                </Col>
                                {hasRole(["ROLE_ADMIN"], roles) && <Col xs={2} md="auto">
                                    <button
                                        className="btn btn-sm btn-primary rounded-circle fw-bold btn-icon"
                                        onClick={() => {
                                            if (nbLogs === 0) {
                                                return
                                            }
                                            dispatch(setSelectedEntityId(outil.id))
                                            dispatch(setLightBoxShow(true))
                                        }}
                                    >{nbLogs}</button>
                                </Col>}
                            </div>
                        }
                        return <Fragment key={index}/>
                    })}
                </div>
            }
        </div>
    })

    const SContainer = SortableContainer(({children}: { children: ReactNode }) => {
        return <div>{children}</div>
    })

    let toolCategoryList: ReactNode = <></>
    if (stateCategoryList !== "loading") {
        /* @ts-ignore */
        toolCategoryList = <SContainer onSortEnd={onSortEnd} useDragHandle>
            {toolCategories.map((item: DocumentCategory, index: number) => (

                <SortableItem key={`item-${index}`} index={index} item={item}/>
            ))}
        </SContainer>
    } else {
        toolCategoryList = <LoadingSpinner/>
    }

    return (
        <>
            <PageHeader title="Boite à outils" breadcrumb>
                {hasRole(["ROLE_ADMIN"], roles) &&
                    <div className="row row-sm row-sm-h align-items-center">
                        <div className="col-auto">
                            <Form.Check type="switch" name="input-documentForAll" className="text-white"
                                        label="Voir aussi les outils dépubliés" checked={viewUnpublishedTools}
                                        onChange={(e) => {
                                            dispatch(setViewUnpublishedTools(!viewUnpublishedTools))
                                        }}/>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-lg btn-tertiary btn-icon rounded-circle" onClick={() => {
                                dispatch(setEditMode(false))
                                dispatch(setShowModalAddToolCategory(true))
                            }}>
                                <i className="mai fs-lg">create_new_folder</i>
                            </button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-lg btn-tertiary btn-icon rounded-circle" onClick={() => {
                                dispatch(setShowModalAddTool(true))
                            }}>
                                <i className="mai fs-lg">add</i>
                            </button>
                        </div>
                    </div>
                }
            </PageHeader>
            <Container>{toolCategoryList}</Container>
            {showModalAddToolCategory &&
                <LightBoxAddToolCategory editMode={editMode}/>
            }
            {showModalAddTool &&
                <LightBoxAddTool editModeTool={editModeTool}/>
            }
            {showLightBox && <LightBoxLogs/>}
            <PopinDeleteCategoryConfirm/>
            <PopinDeleteToolConfirm/>
        </>

    )
}
