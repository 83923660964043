import {Document, fetchDocuments, updateDocument} from "../../api/DocumentAPI"
import {createSlice} from "@reduxjs/toolkit"

export interface DocumentState {
    loading: boolean,
    context?: string,
    item?: Document,
    items: Document[],
    searchWords?: string,
    blob?: Blob
}

const initialState: DocumentState = {
    loading: false,
    item: undefined,
    items: [],
    blob: undefined,
}

export const DocumentSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        setContext: (state, action) => {
            state.context = action.payload
        },
        resetItems: (state) => {
            state.items = []
        },
        setSearchWords: (state, action) => {
            state.searchWords = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchDocuments.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(fetchDocuments.fulfilled, (state, action) => {
                    state.loading = false
                    state.items = action.payload.documents
                })
                .addCase(fetchDocuments.rejected, (state, action) => {
                    state.loading = false
                })
                .addCase(updateDocument.pending, (state, action) => {
                    state.loading = true
                })
                .addCase(updateDocument.fulfilled, (state, action) => {
                    state.loading = false
                    state.item = action.payload
                })
                .addCase(updateDocument.rejected, (state, action) => {
                    state.loading = false
                    state.item = undefined
                })
        }
})

export const {setContext, setSearchWords, resetItems} = DocumentSlice.actions

export default DocumentSlice.reducer
