import Message from "./Message"
import Header from "./Header"
import {Col, Form, Modal, Row} from "react-bootstrap"
import SideBar from "./Navigation/SideBar"
import Footer from "./Footer"
import React, {Fragment, SyntheticEvent, useEffect, useRef, useState} from "react"
import {useAppDispatch, useAppSelector} from "../redux/Hooks"
import {getCookie} from "../utils/cookies"
import {getUserByToken, updateUser} from "../api/UserAPI"
import {Link, useNavigate} from "react-router-dom"
import {displayMessage} from "../redux/Message/MessageSlice"
import {hasRole} from "../utils/UserTools"

export default function Layout({...props}) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const showMessage = useAppSelector((state) => state.message.showMessage)
    const token = getCookie("accessToken")
    const mounted = useRef()
    const user = useAppSelector((state) => state.app.user)
    const [validationIsRequested, setValidationIsRequested] = useState<boolean>(false)

    useEffect(() => {
        !mounted.current && componentDidMount()
    })

    const componentDidMount = () => {
        token && dispatch(getUserByToken({token: token})).then(() => {
            // @ts-ignore
            mounted.current = true
        })
    }

    useEffect(() => {
        const noAuthPages = ["/conditions-generales-d-utilisation", "/s-identifier", "/nouveau-compte/set-password", "/set-password"]
        if ((!token || (null === user.id && mounted.current)) && !noAuthPages.includes(window.location.pathname)) {
            return navigate("/s-identifier")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id, mounted.current])

    useEffect(() => {
        if (!user || null === token || getCookie("adminToken") !== null || !mounted.current || user.token !== getCookie("accessToken")) return
        const noCGUValidatedPages = ["/conditions-generales-d-utilisation", "/s-identifier", "/nouveau-compte/set-password", "/set-password"]
        setValidationIsRequested(
            !noCGUValidatedPages.includes(window.location.pathname) &&
            !window.location.pathname.includes("switch-user") &&
            (!user?.cguValidated || !user?.dpoValidated))
    }, [window.location.pathname, user, mounted.current])

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        const form = e.currentTarget

        if (!form.checkValidity() || form.dpo.checked === false || form.cgu.checked === false) {
            dispatch(displayMessage({
                text: "Merci de valider les conditions citées et les conditions générales d'utilisation",
                type: "danger"
            }))
            return
        }

        user?.id && dispatch(updateUser({
            id: user.id,
            data: {
                dpoValidated: true,
                cguValidated: true
            }
        }))
    }

    return (
        <Fragment>
            {
                showMessage &&
                <Message/>
            }
            <Header/>
            <div className="wrap">
                <Row className="row-0 h-100">
                    {user.id &&
                        <Col xs="auto">
                            <SideBar/>
                        </Col>
                    }
                    <Col>
                        <div className="main">
                            <Row className="row-0 h-100 flex-column">
                                <Col>{props.children}</Col>
                                <Col xs="auto" className="mt-8">
                                    <Footer/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="modal-form"
                   show={validationIsRequested}
                   scrollable>
                <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                    handleSubmit(e)
                }}>
                    <Modal.Header>
                        <Modal.Title as="h3">Confidentialité et protection des données personnelles : engagements des
                            commissaires-administrateurs et des membres de Transitions Pro IDF.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Transitions Pro IDF</strong>, à travers sa plateforme de gestion des Instances, place
                            la protection des
                            données personnelles au premier rang de ses priorités.<br/>
                            Dans le cadre de la gestion et de la participation aux Instances organisées par Transitions
                            Pro
                            IDF, en tant qu’assistant.e(s), commissaire(s)-administrateur.rice(s) ou membre(s) ATPRO,
                            vous
                            vous engagez à respecter la réglementation en vigueur applicable au traitement des données à
                            caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et
                            du
                            Conseil du 27 avril 2016 applicable à compter du 25 mai 2018.</p>

                        <p>Vous vous engagez à :</p>

                        <p>1/ Traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font l’objet
                            de
                            la gestion des Instances.</p>

                        <p>2/ Veiller à ce que les personnes autorisées à traiter les données à caractère personnel
                            s’engagent à en respecter la confidentialité.</p>

                        <p>3/ Informer les utilisateurs (assistant.es, membre(s) ATPRO,
                            commissaire(s)-administrateur(s))
                            de l’utilisation des données personnelles dans le cadre de la gestion des Instances par
                            Transitions Pro IDF ; les inviter à prendre connaissance des règles en matière
                            de <Link target="_blank"
                                     to={"/conditions-generales-d-utilisation"}>conditions
                                générales d'utilisation et de protection
                                des données personnelles</Link>.</p>

                        <p>4/ Informer les utilisateurs qu’ils peuvent exercer leurs droits d’accès, rectification,
                            limitation, opposition, effacement et adresser toute demande concernant le traitement de ses
                            données personnelles auprès du délégué à la protection des données de Transitions Pro IDF
                            :&nbsp;
                            <a href="mailto:dpo@transitionspro-idf.fr">dpo@transitionspro-idf.fr</a></p>

                        <p>5/ Notifier au responsable de traitement de Transitions Pro IDF toute violation de données à
                            caractère personnelle dans les meilleurs délais et si possible, 24 heures après en avoir
                            pris
                            connaissance et par l’envoi d’un mail à l’adresse suivante : <a
                                href="mailto:dpo@transitionspro-idf.fr">dpo@transitionspro-idf.fr</a>.
                            Cette
                            notification est accompagnée de toute documentation utile afin de permettre au responsable
                            de
                            traitement, si nécessaire, de notifier cette violation à l’autorité de contrôle
                            compétente.</p>
                        <Form.Check
                            type="checkbox"
                            id="dpo"
                            label="En cochant cette case, je déclare avoir pris connaissance et accepter sans réserves les conditions citées ci-dessus."
                            required/>
                        <Form.Check
                            type="checkbox"
                            id="cgu"
                            label={<>En cochant cette case, je déclare avoir pris connaissance et accepter sans réserves
                                les <Link target="_blank"
                                          to={"/conditions-generales-d-utilisation"}>conditions
                                    générales d'utilisation</Link>.</>}
                            required/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary">
                            Accéder à mon espace
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}
