/**
 * @title getCookie
 * @description Retourne la valeur d'un cookie
 */
export const getCookie = (name: string) => {
    function escape(s: string) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

/**
 * @title setCookie
 * @description Fonction permettant de créer un cookie
 */
export const setCookie = (cname: string, cvalue: string, ) => {
    document.cookie = `${cname} = ${cvalue}; Path=/`;
}

/**
 * @title removeCookie
 * @description Fonction permettant de supprimer un cookie
*/
export const removeCookie = (cname: string) => {
    let expires = "Expires=" + cookieExpirationDate(-1);
    document.cookie = `${cname} =; expires=${expires} ; Path=/`;
}

/**
 * @title cookieExpirationDate
 * @description Fonction permettant de générer une date d'expiration en nombre d'heure pour un cookie
 */
export const cookieExpirationDate = (exhours: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    return d.toUTCString();
}