import {
    setModalCanNotDeleteCommitteeShow,
    setModalDeleteCommitteeShow,
    setShow
} from "../../redux/Committee/CommitteeSlice"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {SyntheticEvent, useEffect} from "react"
import {
    resetExpectedDocuments,
    setColor,
    setContent,
    setDocumentForAll,
    setTitle
} from "../../redux/Committee/CommitteeFormSlice"
import {Button, Form, Modal} from "react-bootstrap"
import {createCommittee, putCommittee} from "../../api/CommitteeAPI"
import {TYPE_CAT_CONVOCATION, TYPE_CAT_PV} from "../../api/DocumentAPI"
import {
    fetchExpectedDocumentCategories,
    fetchExpectedDocuments
} from "../../api/ExpectedDocumentAPI"
import {ExpectedDocuments} from "./ExpectedDocuments"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {fetchMeetings} from "../../api/MeetingAPI"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import CanNotDeleteModal from "./CanNotDeleteModal"

export function AddUpdateCommitteeLightBox() {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state: RootState) => state.committee.show)
    const title = useAppSelector((state: RootState) => state.committeeForm.title)
    const content = useAppSelector((state: RootState) => state.committeeForm.content)
    const color = useAppSelector((state: RootState) => state.committeeForm.color)
    const documentForAll = useAppSelector((state: RootState) => state.committeeForm.documentForAll)
    const committee = useAppSelector((state: RootState) => state.committee.item)
    const expectedDocuments = useAppSelector((state: RootState) => state.committeeForm.expectedDocuments)
    const meetings = useAppSelector((state: RootState) => state.meeting.items)

    useEffect(() => {
        if (committee) {
            dispatch(fetchExpectedDocuments({options: {"committee.id": committee.id}}))
            committee && dispatch(fetchMeetings({options: {"committee.slug": committee.slug,}}))
        }
        dispatch(fetchExpectedDocumentCategories({options: {"type": TYPE_CAT_CONVOCATION}}))
        dispatch(fetchExpectedDocumentCategories({options: {"type": TYPE_CAT_PV}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [committee])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        }

        const data = {
            title: title,
            content: content,
            color: color,
            documentForAll: documentForAll,
            expectedDocuments: expectedDocuments
        }

        // On met à jour l'instance et les documents attendus
        let messageText = "Problème dans la mise à jour de l'instance."
        let messageStatus = "error"
        if (committee === undefined) {
            dispatch(displayMessage({text: "Instance en cours de création"}))
            messageText = "Problème dans la création de l'instance."
            let response = await dispatch(createCommittee({fields: data}))
            if (response.meta.requestStatus === "fulfilled") {
                messageText = "Instance créée."
                messageStatus = "success"
            }
        } else {
            dispatch(displayMessage({text: "Instance en cours de modification"}))
            let response = await dispatch(putCommittee({committeeId: committee.id, fields: data}))
            if (response.meta.requestStatus === "fulfilled") {
                messageText = "Instance modifiée."
                messageStatus = "success"
            }
        }
        dispatch(displayMessage({text: messageText, status: messageStatus}))
        dispatch(resetExpectedDocuments())
    }

    function closeModal() {
        dispatch(setShow(false))
        dispatch(resetExpectedDocuments())
    }

    return (
        <Modal show={show} scrollable onHide={() => closeModal()}>
            <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                handleSubmit(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Création d'instances</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-primary">Informations générales</h3>
                    <div className="mb-4">
                        <Form.Label htmlFor="input-intitule-instance">Intitulé *</Form.Label>
                        <Form.Control type="text" defaultValue={title} name="input-title" id="input-intitule-instance"
                                      required onChange={(e) => {
                            dispatch(setTitle(e.target.value))
                        }}/>
                    </div>
                    <div className="mb-4">
                        <Form.Label htmlFor="input-descriptif">Descriptif *</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={content} name="input-content"
                                      id="input-descriptif" required onChange={(e) => {
                            dispatch(setContent(e.target.value))
                        }}/>
                    </div>
                    <div className="mb-4">
                        <Form.Label htmlFor="input-color">Couleur *</Form.Label>
                        <Form.Control type="color" defaultValue={color} name="input-color" id="input-color" required
                                      onChange={(e) => {
                                          dispatch(setColor(e.target.value))
                                      }}/>
                    </div>
                    <h3 className="text-primary mt-6 mb-4">Documents attendus</h3>
                    <div className="mb-4">
                        <Form.Check type="switch" name="input-documentForAll" id="input-documentForAll"
                                    label="Visible par tous" checked={documentForAll} onChange={() => {
                            dispatch(setDocumentForAll(!documentForAll))
                        }}/>
                    </div>
                    <ExpectedDocuments/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" type="button" onClick={() => {
                        if (meetings.length > 0) {
                            dispatch(setModalCanNotDeleteCommitteeShow(true))
                        } else {
                            dispatch(setModalDeleteCommitteeShow(true))
                        }
                    }}>
                        <i className="mai text-primary me-2">delete</i>Supprimer
                    </Button>
                    <Button variant="light" type="submit">
                        <i className="mai text-primary me-2">save</i>Valider
                    </Button>
                </Modal.Footer>
                {
                    committee && <ConfirmDeleteModal committee={committee}/>
                }
                {
                    committee && meetings.length > 0 && <CanNotDeleteModal committee={committee}/>
                }
            </Form>
        </Modal>
    )
}
