import {
    FULL_UPDATE_PERIODS_USER_API,
    FULL_USER_TOKEN_API,
    FULL_GET_USER_API,
    data,
    FULL_CREATE_USER_API,
    FULL_UPDATE_USER_API,
    FULL_DELETE_USER_API,
    FULL_USERS_API,
    FULL_USERS_BY_SELECT_FILTERS,
    FULL_EXPORT_USERS,
    FULL_IMAGE_USER_API,
} from "../utils/Data"
import {createAsyncThunk} from "@reduxjs/toolkit"
import {
    axiosInstance,
    axiosInstanceJustJson,
    axiosInstanceMultipart, axiosInstanceNoToken, axiosInstanceNoTokenPatch,
    axiosInstancePatch
} from "../utils/http-common"
import {Mandate, Period} from "./MandateAPI"
import {Committee} from "./CommitteeAPI"
import {SelectOption} from "../utils/CommonInterfaces"
import {Options, toQuery} from "../utils/Options"
import {ChoiceItem} from "../utils/ChoiceList"

const AxiosInstance = axiosInstance()
const AxiosInstanceMultipart = axiosInstanceMultipart()
const AxiosInstancePatch = axiosInstancePatch()
const AxiosInstanceJson = axiosInstanceJustJson()
const AxiosInstanceNoToken = axiosInstanceNoToken()
const AxiosInstanceNoTokenPatch = axiosInstanceNoTokenPatch()

export const STATUS_SUB = 1
export const STATUS_MAIN = 0

export interface User {
    "@context"?: string;
    "@id"?: string;
    "@type"?: string;
    "id"?: number | null;
    "lastname": string;
    "firstname": string;
    "username": string;
    "file_path"?: string;
    "filePath"?: string;
    "civility": boolean | number;
    "city"?: string;
    "address1"?: string;
    "address2"?: string;
    "zipcode"?: string;
    "phone1"?: string;
    "phone2"?: string;
    "periods"?: Period[];
    "mandates"?: Mandate[];
    "roles": string[];
    "college"?: number;
    "syndicate"?: number;
    "email"?: string;
    "active"?: boolean | string | number;
    "passwordSet"?: boolean;
    "lastLogin": string | null;
    "authDiffEmail"?: boolean | string;
    "auth_diff_email"?: boolean | string;
    "authDiffAddress"?: boolean | string;
    "auth_diff_address"?: boolean | string;
    "authDifftel1"?: boolean | string;
    "auth_difftel1"?: boolean | string;
    "authDiffTel2"?: boolean | string;
    "auth_diff_tel2"?: boolean | string;
    "password"?: string;
    "committees": Committee[],
    dpoValidated?: boolean,
    cguValidated?: boolean,
    cguValidatedAt?: string,
    dpoValidatedAt?: string,
}

export interface UserPostData {
    "id"?: number | null;
    "lastname": string;
    "firstname": string;
    "file_path"?: string;
    "filePath"?: string;
    "civility": boolean | number;
    "city"?: string;
    "address1"?: string;
    "address2"?: string;
    "zipcode"?: string;
    "phone1"?: string;
    "phone2"?: string;
    "periods"?: Period[];
    "mandates"?: Mandate[];
    "roles"?: string[];
    "college"?: number;
    "syndicate"?: number;
    "email"?: string;
    "active"?: boolean | string | number;
    "passwordSet"?: boolean;
    "authDiffEmail"?: boolean | string;
    "auth_diff_email"?: boolean | string;
    "authDiffAddress"?: boolean | string;
    "auth_diff_address"?: boolean | string;
    "authDifftel1"?: boolean | string;
    "auth_difftel1"?: boolean | string;
    "authDiffTel2"?: boolean | string;
    "auth_diff_tel2"?: boolean | string;
    "password"?: string;
    "committees": Committee[],
}

export const userEmpty = {
    lastname: "",
    firstname: "",
    username: "",
    civility: 0,
    roles: [],
    lastLogin: null,
    committees: []
}

export const ROLES_ADMIN = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN"
]

export const ROLES_MANAGE_COMMITTEE = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN"
]

export const ROLES_MANAGE_MEETING = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN",
    "ROLE_ASSIST"
]

export const ROLES_MANAGE_SMS = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN"
]

export const ROLES_VIEW_MEETINGS = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN",
    "ROLE_ASSIST",
    "ROLE_CODIR"
]

export const ROLES_ASSIST_CODIR = [
    "ROLE_ASSIST",
    "ROLE_CODIR"
]

export const ROLES_VIEW_ALL_DOCUMENTS = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN"
]

export const ROLES_VIEW_ALERT_CONTRIB = [
    "ROLE_SUPER_ADMIN",
    "ROLE_ADMIN",
    "ROLE_ASSIST"
]

export const ROLES_VIEW_ALERT_NOTIF = [
    "ROLE_USER"
]

interface ValidationErrorsUser {
    errorMessage: string
    field_errors: Record<string, string>
    user?: User,
    response?: User[],
    message?: string,
    error?: boolean
}

interface DataUserLogin {
    token: string,
    user: User
}

export const getUserByToken = createAsyncThunk<DataUserLogin, { token: string, context?: string }, {
    rejectValue: ValidationErrorsUser
}>("users/getUserByToken", async (dt, {rejectWithValue}) => {
    const response = (await AxiosInstanceJson.get(`${FULL_USER_TOKEN_API}${dt.context ? `?context=${dt.context}` : ""}`)).data

    return {
        logged: true,
        token: dt.token,
        user: response["hydra:member"][0]
    }
})

interface SetPassword {
    message: string,
    username: string,
    error: boolean
}

interface ValidationErrorsUserName {
    errorMessage: string
    field_errors: Record<string, string>
    username: string,
    user?: User,
    response?: User[],
    message?: string,
    error?: boolean
}

export const SetNewPassword = createAsyncThunk<SetPassword, { password: string, confirmationToken: string | null },
    { rejectValue: ValidationErrorsUserName }>("users/SetNewPassword",
    async (userData, {rejectWithValue}) => {

        const response = (await AxiosInstanceNoTokenPatch.patch(`${FULL_GET_USER_API}set-password?token=${userData.confirmationToken}`, JSON.stringify({
            "plainPassword": userData.password,
            token: userData.confirmationToken
        }))).data

        return {
            message: response.message,
            username: response.username,
            error: response.error
        }
    })


export const updateUser = createAsyncThunk<User, {
    id: number,
    data: { dpoValidated?: boolean, cguValidated?: boolean }
}, {
    rejectValue: ValidationErrorsUserAdmin
}>("user/patchUser", async (userData, {rejectWithValue}) => {
    return (await AxiosInstancePatch.patch(`${FULL_USERS_API}/${userData.id}`, JSON.stringify(userData.data))).data
})

interface FetchUser {
    user: User
}

export const fetchUserById = createAsyncThunk<FetchUser, { id_user: number }, {
    rejectValue: ValidationErrorsUser
}>("users/fetchUserById", async (dt, {rejectWithValue}) => {
    const response = (await AxiosInstanceJson.get(FULL_GET_USER_API + dt.id_user)).data

    return {
        user: response
    }
})

interface ForgottenPassword {
    message: string,
    error: boolean
}

export const sendChangePasswordEmail = createAsyncThunk<ForgottenPassword, { email: string },
    { rejectValue: ValidationErrorsUser }>("users/sendChangePasswordEmail",
    async (userData) => {

        const response = (await AxiosInstanceNoToken.post(`${data.host}/api/user/forgotten-password`, JSON.stringify(userData))).data

        return {
            message: response.message,
            error: response.error
        }
    })

interface UsersTrombinoscope {
    users: User[],
    nbTotalUsers: number | string
}

export const getUsers = createAsyncThunk<UsersTrombinoscope, { options: Options }, {
    rejectValue: ValidationErrorsUser
}>(
    "trombinoscope/getUsers",
    async (userData, {rejectWithValue}) => {
        const response = (await AxiosInstance.get(FULL_USERS_API + toQuery(userData.options))).data

        return {
            users: response["hydra:member"],
            nbTotalUsers: response["hydra:totalItems"],
        }

    })

// Commissaires-administrateurs

interface ValidationErrorsUserAdmin {
    errorMessage: string
    field_errors: Record<string, string>,
    result?: object
}

interface Admin {
    result: User
}

interface adminData {
    user: {
        id?: string | number,
        civility: boolean,
        lastname: string,
        firstname: string,
        filePath: string,
        picture?: string,
        address1: string,
        zipcode: string | number,
        city: string,
        phone1: string,
        phone2: string,
        college?: number,
        syndicate?: number,
        passwordSet: boolean,
        authDiffAddress: boolean,
        authDifftel1: boolean,
        authDiffTel2: boolean,
        authDiffEmail: boolean,
        active: boolean,
        email: string,
        roles: string[],
        periods?: Period[],
        password?: string
    }
}

export const addAdmin = createAsyncThunk<Admin, adminData, {
    rejectValue: ValidationErrorsUserAdmin
}>("addAdmin/addAdmin", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.post(
        FULL_CREATE_USER_API, JSON.stringify(dataUser.user))).data

    return {
        result: response
    }

})

export const updateAdmin = createAsyncThunk<Admin, adminData, {
    rejectValue: ValidationErrorsUserAdmin
}>("addAdmin/updateAdmin", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstancePatch.patch(FULL_UPDATE_USER_API + "full/" + dataUser.user.id, JSON.stringify(dataUser.user))).data

    return {
        result: response
    }

})

interface periodsAdminData {
    user: {
        id?: string | number,
        periods?: Period[]
    }
}

export const updatePeriodsAdmin = createAsyncThunk<Admin, periodsAdminData, {
    rejectValue: ValidationErrorsUserAdmin
}>("addAdmin/updateAdmin", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstancePatch.patch(FULL_UPDATE_PERIODS_USER_API + dataUser.user.id, JSON.stringify(dataUser.user))).data

    return {
        result: response
    }

})

export const deleteAdmin = createAsyncThunk<Admin, { user_id: number }, {
    rejectValue: ValidationErrorsUserAdmin
}>("addAdmin/updateAdmin", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.delete(FULL_DELETE_USER_API + dataUser.user_id)).data
    return {
        result: response
    }

})

// Assistants

interface ValidationErrorsUserAssistant {
    errorMessage: string
    field_errors: Record<string, string>,
    result?: object
}

interface Assistant {
    result?: object
}

export interface AssistantData {
    user: UserPostData,
    id_user?: number | string
}

export const addAssistant = createAsyncThunk<Assistant, AssistantData, {
    rejectValue: ValidationErrorsUserAssistant
}>("addAssistant/addAssistant", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.post(FULL_CREATE_USER_API, JSON.stringify(dataUser.user))).data

    return {
        result: response
    }

})

export const editAssistant = createAsyncThunk<Assistant, AssistantData, {
    rejectValue: ValidationErrorsUserAssistant
}>("editAssistant/editAssistant", async (dataUser, {rejectWithValue}) => {

    const response = (await AxiosInstancePatch.patch(FULL_UPDATE_USER_API + "full/" + dataUser.user.id, JSON.stringify(dataUser.user))).data

    return {
        result: response
    }

})

// Récupération des Users pour les étiquettes PDF
interface GetLabelsFilTersParams {
    committeesSelected: Committee[],
    statusSelected: SelectOption[],
    rolesSelected: SelectOption[],
    onlyActivePopinLabels?: boolean,
    college?: { key: number, label: string },
    syndicates: { key: number, label: string }[],
    active?: boolean
}

interface UsersPrintLabel {
    users: User[]
}

export const fetchUsersForLabel = createAsyncThunk<UsersPrintLabel, GetLabelsFilTersParams, {
    rejectValue: ValidationErrorsUser
}>("trombinoscope/fetchUsersForLabel", async (dt, {rejectWithValue}) => {
    let data: {
        committees: Committee[],
        roles: SelectOption[],
        statuses: SelectOption[],
        active?: boolean,
        college?: { key: number, label: string },
        syndicates: { key: number, label: string }[]
    } = {
        "committees": dt.committeesSelected,
        "statuses": dt.statusSelected,
        "roles": dt.rolesSelected,
        "college": dt.college,
        "syndicates": dt.syndicates
    }
    if (dt.onlyActivePopinLabels) {
        data["active"] = true
    }
    const response = (await AxiosInstanceJson.post(FULL_USERS_BY_SELECT_FILTERS, data)).data

    return {users: response.users}
})


export const countUsersForEmailing = async (dt: GetLabelsFilTersParams) => {
    let data: {
        committees: Committee[],
        roles: SelectOption[],
        statuses: SelectOption[],
        active?: boolean,
        college?: { key: number, label: string },
        syndicates: { key: number, label: string }[]
    } = {
        "committees": dt.committeesSelected,
        "statuses": dt.statusSelected,
        "roles": dt.rolesSelected,
        "college": dt.college,
        "syndicates": dt.syndicates,
        "active": dt.active
    }
    const response = (await AxiosInstanceJson.post(FULL_USERS_BY_SELECT_FILTERS, data)).data

    return {nb: response.users.length}
}


export function downloadExportUser(data: {
    committees: Committee[],
    roles: ChoiceItem[],
    statuses: SelectOption[],
    format: string
}): Promise<BlobPart> {
    return AxiosInstance.post(
        `${FULL_EXPORT_USERS}`,
        data,
        {
            responseType: "blob"
        }
    ).then(response => response.data)
}

export function switchUser(data: { id: number }) {
    return AxiosInstancePatch.patch(`${FULL_USERS_API}/switch-user/${data.id}`, JSON.stringify(data))
        .then(response => response.data)
}

export function getUserByLastName(lastname: string) {
    return AxiosInstanceJson.get(data.host + "/api/get-users-by-lastname?lastname=" + lastname)
}

export const uploadUserImage = async (data: FormData) => {
    return await AxiosInstanceMultipart.post(FULL_IMAGE_USER_API, data)
}
