import React, {Fragment, useEffect, useState} from "react"
import PageHeader from "../PageHeader"
import {hasRole} from "../../utils/UserTools"
import {ROLES_MANAGE_MEETING, ROLES_MANAGE_SMS} from "../../api/UserAPI"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {AddSms} from "./AddSms"
import {fetchSms, SmsUser} from "../../api/SmsAPI"
import ReactPaginate from "react-paginate"
import {Col, Row} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {getFormattedLocalisedDate} from "../../utils/Date"

export default function SmsList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const items = useAppSelector((state) => state.sms.items)
    const total = useAppSelector((state) => state.sms.total)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const itemsPerPage = 10
    const [nbPages, setNbPages] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        if (!hasRole(ROLES_MANAGE_SMS, roles)) {
            return navigate("/")
        }
        dispatch(fetchSms({options: {itemsPerPage: itemsPerPage}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, roles])

    useEffect(() => {
        setNbPages(Math.ceil(total / itemsPerPage))
    }, [total])

    useEffect(() => {
        dispatch(fetchSms({options: {itemsPerPage: itemsPerPage, page: currentPage}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const handlePageClick = (event?: { selected: number }, page?: number) => {
        let selectedPage: number = 1
        if (page) {
            selectedPage = page
        }
        if (event) {
            selectedPage = event.selected + 1
        }
        setCurrentPage(selectedPage)
    }

    const displayAnswers = (item: SmsUser) => {
        let answer: string = item.user.niceName
        if (item.status) {
            answer += ", " + item.status
        }
        if (item.syndicate) {
            answer += ", " + item.syndicate
        }

        let answerStatus: string = "a répondu Non"
        if (item.answer) {
            answerStatus = "a répondu Oui"
        } else if (item.answer === undefined) {
            answerStatus = "s'abstient"
        }

        answer += " " + answerStatus

        return answer
    }

    return (
        <Fragment>
            <PageHeader title="Vote par SMS" noMargin breadcrumb>
                {hasRole(ROLES_MANAGE_MEETING, roles) &&
                    <button className="btn btn-lg btn-icon rounded-circle btn-tertiary" onClick={() => setShow(true)}>
                        <i className="mai fs-lg">add</i></button>}
            </PageHeader>
            <div className="container my-7">
                <div className="bg-white px-3 px-lg-5 py-2">
                    <div className="list-group list-group-flush">
                        <div className="list-group-item py-3 fw-semibold d-none d-lg-block">
                            <Row className="row-sm">
                                <Col>Date</Col>
                                <Col>Instance</Col>
                                <Col>Séance</Col>
                                <Col xs lg={4} xxl={4}>Objet</Col>
                                <Col lg={2} xxl={1} className="text-center">Nb de votants</Col>
                                <Col xs={1} className="text-center">Oui</Col>
                                <Col xs={1} className="text-center">Non</Col>
                                <Col className="text-center">Abstention</Col>
                            </Row>
                        </div>
                        {items.map((item, index) => {
                            return <div key={index} className="list-group-item py-3">
                                <div className="row row-sm row-sm-h">
                                    <Col xs={12} className="d-lg-none">{item.title}</Col>
                                    <Col xs={12} md={4} lg>
                                        <div className="label fs-sm d-lg-none">Date</div>
                                        {item.createdDate && getFormattedLocalisedDate(new Date(item.createdDate), "dd/MM/yyyy")}
                                    </Col>
                                    <Col xs={12} md={4} lg>
                                        <div className="label fs-sm d-lg-none">Instance</div>
                                        {item.committee && item.committee.title}
                                    </Col>
                                    <Col xs={12} md={4} lg>
                                        <div className="label fs-sm d-lg-none">Séance</div>
                                        {item.meeting && item.meeting.title && getFormattedLocalisedDate(new Date(item.meeting.title), "dd/MM/yyyy")}
                                    </Col>
                                    <Col xs={12} md={6} lg={4} xxl={4} className="d-none d-lg-block">
                                        <div className="label fs-sm d-lg-none">Objet</div>
                                        {item.title}
                                        {
                                            item.smsUsers.length > 0 &&
                                            <div className="mt-3">
                                                <div className="fw-semibold d-block mb-1">Réponses</div>
                                                {item.smsUsers.map((item) => {
                                                    return <p className="mb-0">{displayAnswers(item)}</p>
                                                })}
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={3} lg={2} xxl={1} className="text-lg-center">
                                        <span className="label fs-sm d-lg-none">Nb de votants : </span>
                                        {item.total}
                                    </Col>
                                    <Col xs={12} md={3} lg={1} className="text-lg-center">
                                        <span className="label fs-sm d-lg-none">Oui : </span>
                                        {item.nbYes}
                                    </Col>
                                    <Col xs={12} md={3} lg={1} className="text-lg-center">
                                        <span className="label fs-sm d-lg-none">Non : </span>
                                        {item.nbNo}
                                    </Col>
                                    <Col xs={12} md={3} lg className="text-lg-center">
                                        <span className="label fs-sm d-lg-none">Abstention : </span>
                                        {item.nbNsp}
                                    </Col>
                                    <Col xs={12} className="d-lg-none">
                                        {
                                            item.smsUsers.length > 0 &&
                                            <Fragment>
                                                <div className="label fs-sm d-block mb-1">Réponses</div>
                                                {item.smsUsers.map((item) => {
                                                    return <p className="mb-0">{displayAnswers(item)}</p>
                                                })}
                                            </Fragment>
                                        }
                                    </Col>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="row row-sm row-sm-h align-items-center justify-content-between mt-5">
                    <div className="col-auto">
                        <button className="btn btn-light" disabled={currentPage === 1} onClick={() => {
                            handlePageClick()
                        }}>
                            <i className="mai fs-md me-2">arrow_back</i>
                            Début
                        </button>
                    </div>
                    <div className="col-auto">
                        <ReactPaginate
                            breakLabel="..."
                            previousLabel=""
                            nextLabel=""
                            pageCount={nbPages}
                            forcePage={currentPage - 1}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-light" disabled={currentPage === nbPages} onClick={() => {
                            handlePageClick(undefined, nbPages)
                        }}>
                            Fin
                            <i className="mai fs-md ms-2">arrow_forward</i>
                        </button>
                    </div>
                </div>
            </div>

            <AddSms show={show} setShow={setShow}/>
        </Fragment>
    )
}
