import {useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {data} from "../../../utils/Data"
import {Meeting} from "../../../api/MeetingAPI"
import {Row, Col, Accordion, Table, Container} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {hasRole} from "../../../utils/UserTools"
import {ROLES_MANAGE_MEETING} from "../../../api/UserAPI"
import {getCsvFile} from "../../../services/export"
import Tooltip from "../../../utils/Tooltip"

type MeetingAttendanceProps = {
    type?: string,
    name: string,
    lunch?: boolean,
    replaced?: string,
    replacedAttendance?: string
}

export function MeetingAttendance(props: { meeting: Meeting }) {
    const meetingUsers = useAppSelector((state: RootState) => state.meeting.meetingUsers)
    const mandates = useAppSelector((state: RootState) => state.committee.mandates)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    const [nbMore, setNbMore] = useState<number>(0)
    const [nbNo, setNbNo] = useState<number>(0)
    const [nbYes, setNbYes] = useState<number>(0)
    const [meetingAttendances, setMeetingAttendances] = useState<MeetingAttendanceProps[]>([])

    useEffect(() => {
        const tempMeetingAttendances: MeetingAttendanceProps[] = []
        let tempNbNo = 0
        let tempNbYes = 0

        mandates.forEach((mandate) => {
            let userAttendance: MeetingAttendanceProps = {
                type: undefined,
                name: mandate.user.firstname + " " + mandate.user.lastname,
                lunch: undefined,
                replaced: undefined,
                replacedAttendance: undefined
            }
            meetingUsers.forEach((meetingUser) => {
                if (mandate.user.id === meetingUser.user.id) {
                    if (meetingUser.attending === data.ATTENDING_NO) {
                        tempNbNo++
                        userAttendance["type"] = "no"
                    }
                    if (meetingUser.attending === data.ATTENDING_AVAILABLE) {
                        tempNbYes++
                        userAttendance["type"] = "yes"
                    }
                    if (meetingUser.attending === data.ATTENDING_VISIO) {
                        tempNbYes++
                        userAttendance["type"] = "online"
                    }
                    if (meetingUser.lunch !== undefined) {
                        userAttendance["lunch"] = props.meeting.lunchAvailable ? meetingUser.lunch : undefined
                    }
                    if (meetingUser.replacedBy !== undefined) {
                        userAttendance["replaced"] = meetingUser.replacedBy.niceName
                        userAttendance["replacedAttendance"] = meetingUsers.find(item => item.user.id === meetingUser.replacedBy?.id)?.attending
                    }
                }
            })
            tempMeetingAttendances.push(userAttendance)
        })
        setMeetingAttendances(tempMeetingAttendances)
        setNbNo(tempNbNo)
        setNbYes(tempNbYes)
        setNbMore(mandates.length - tempNbNo - tempNbYes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mandates, meetingUsers])

    return <div className="meeting-attendance bg-main-gray-light p-3 mb-2">
        <Accordion className="accordion">
            <Accordion.Item eventKey="0" className="border-0">
                <Accordion.Header>
                    <Row className="row-sm row-sm-h align-items-center">
                        <Col xs="auto"><span className="mai fs-lg text-main-gray mb-1">people</span></Col>
                        <Col md="auto">
                            <Row className="row-xs align-items-center">
                                <Col xs="auto"><i className="mai d-block text-success fs-md">check_circle</i></Col>
                                <Col>{nbYes} confirmé{nbYes > 1 ? "s" : ""}</Col>
                            </Row>
                        </Col>
                        <Col md="auto">
                            <Row className="row-xs align-items-center">
                                <Col xs="auto"><i className="mai d-block text-danger fs-md">cancel</i></Col>
                                <Col>{nbNo} excusé{nbNo > 1 ? "s" : ""}</Col>
                            </Row>
                        </Col>
                        <Col md="auto">
                            <Row className="row-xs align-items-center">
                                <Col xs="auto"><i className="mai d-block text-warning fs-md">hourglass_bottom</i></Col>
                                <Col>{nbMore >= 0 ? nbMore : 0} en attente</Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Header>
                <Accordion.Body className="ps-0 pe-0">
                    <Table striped size="sm">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Membre</th>
                            <th>Remplacé par</th>
                            <th>Déjeuner</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            meetingAttendances.map((meetingAttendance, index) => {
                                let icon = <Tooltip message="N'a pas répondu"><i
                                    className="mai d-block text-warning fs-md">hourglass_bottom</i></Tooltip>
                                if (meetingAttendance.type === "no") {
                                    icon = <Tooltip message="Non"><i
                                        className="mai d-block text-danger fs-md">cancel</i></Tooltip>
                                } else if (meetingAttendance.type === "yes") {
                                    icon = <Tooltip message="Oui"><i
                                        className="mai d-block text-success fs-md">check_circle</i></Tooltip>
                                } else if (meetingAttendance.type === "online") {
                                    icon = <Tooltip message="En visio"><i
                                        className="mai d-block text-success fs-md">rss_feed</i></Tooltip>
                                }
                                let iconReplaced = <></>
                                if (meetingAttendance.replaced) {
                                    iconReplaced = <Tooltip message="N'a pas répondu"><i
                                        className="mai d-inline-block text-warning fs-md me-1">hourglass_bottom</i></Tooltip>
                                    if (meetingAttendance.replacedAttendance === "present") {
                                        iconReplaced = <Tooltip message="Oui"><i
                                            className="mai d-inline-block text-success fs-md me-1">check_circle</i></Tooltip>
                                    } else if (meetingAttendance.replacedAttendance === "visio") {
                                        iconReplaced = <Tooltip message="En visio"><i
                                            className="mai d-inline-block text-success fs-md me-1">rss_feed</i></Tooltip>
                                    }
                                }
                                return <tr key={index}>
                                    <td className="text-center">{icon}</td>
                                    <td>{meetingAttendance.name}</td>
                                    <td>{iconReplaced} {meetingAttendance.replaced ?? "-"}</td>
                                    <td>{meetingAttendance.lunch !== undefined ? (meetingAttendance.lunch ? "Oui" : "Non") : "-"}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>

                    <Container>
                        {
                            hasRole(ROLES_MANAGE_MEETING, roles) && (
                                <Row className="text-center">
                                    <Col md={12}>
                                        <button className="btn btn-primary p-2"
                                                onClick={() => getCsvFile(props.meeting)}>
                                            <span className="mai fs-md me-2">download</span>
                                            Télécharger la liste
                                        </button>
                                    </Col>
                                </Row>
                            )
                        }
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    </div>
}
