import React, {ChangeEvent, useEffect, useState, Fragment} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {useParams} from "react-router-dom"
import type {RootState} from "../../redux/Store"
import {fetchMeetings, Meeting} from "../../api/MeetingAPI"
import ToggleMeetings from "../../components/Document/ToggleMeetings"
import {Col, Container, Form, Row} from "react-bootstrap"
import {getCommitteeBySlug} from "../../utils/Functions"
import {DocumentsByMeeting} from "./DocumentsByMeeting"
import PageHeader from "../../components/PageHeader"
import {setSearchWords} from "../../redux/Document/DocumentSlice"
import ConfirmPvApprouval from "./ConfirmPvApprouval"
import {fetchDocuments, TYPE_MEETING} from "../../api/DocumentAPI"
import {Options} from "../../utils/Options"
import {setValidate} from "../../redux/DocumentUser/DocumentUserSlice"
import LoadingSpinner from "../LoadingSpinner"
import {getToday} from "../../utils/Date"

export default function DocumentByMeeting() {
    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const meetings = useAppSelector((state: RootState) => state.meeting.items)
    const [currentMeetings, setCurrentMeetings] = useState<{ index: string, meetings: Meeting[] }[]>([])
    const loading = useAppSelector((state: RootState) => state.meeting.loading)
    const context = useAppSelector((state) => state.document.context)
    const show = useAppSelector((state) => state.documentUser.showConfirm)
    const searchWords = useAppSelector((state) => state.document.searchWords)
    const validate = useAppSelector((state) => state.documentUser.validate)
    const {slug, year} = useParams()

    const [docOptions, setDocOptions] = useState<Options>({})

    let committee = getCommitteeBySlug({slug, committees})
    let committeeName: string | undefined = ""
    let committeeSlug: string = ""

    useEffect(() => {
        if (slug !== undefined) {
            // On récupère la liste des séances et des documents associés pour l'année en cours
            dispatch(fetchMeetings({
                options: {
                    "committee.canViewDocs": true,
                    "committee.slug": slug,
                    "title": year
                }
            }))
        }
        let options: Options = {
            type: TYPE_MEETING,
            "meeting.title": year,
            "pagination": false,
            "publishedDate[before]": getToday(),
            "order[expectedDocument.category.type]": "desc",
        }
        dispatch(fetchDocuments({options: options}))
        setDocOptions(options)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    useEffect(() => {
        if (!meetings) {
            return
        }

        // avec l'aide de Nat' :D
        const tempCol1CurrentMeetings: Meeting[] = []
        const tempCol2CurrentMeetings: Meeting[] = []
        const tempCol3CurrentMeetings: Meeting[] = []
        let index: number = 0
        meetings.forEach((meeting) => {
            if (meeting?.documents && meeting.documents.length > 0) {
                tempCol3CurrentMeetings.push(meeting)
                if (index % 2 === 0) {
                    tempCol1CurrentMeetings.push(meeting)
                } else {
                    tempCol2CurrentMeetings.push(meeting)
                }
                index++
            }
        })
        const colCurrentMeetings: { index: string, meetings: Meeting[] }[] = [
            {
                index: "col1",
                meetings: tempCol1CurrentMeetings
            },
            {
                index: "col2",
                meetings: tempCol2CurrentMeetings
            },
            {
                index: "colResponsive",
                meetings: tempCol3CurrentMeetings
            }
        ]
        setCurrentMeetings(colCurrentMeetings)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetings])

    useEffect(() => {
        let options: Options = docOptions
        if (searchWords && searchWords !== "") {
            options["title"] = searchWords
        }

        dispatch(fetchDocuments({options: options}))
        dispatch(setValidate(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWords, validate])

    // On filtre les documents par le champ de recherche
    const filterDocuments = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchWords(e.target.value))
    }

    if (committee !== null) {
        committeeName = committee.title
        committeeSlug = committee.slug
    }

    let firstBc = ["Les instances", "/instances"]
    if (context && context === "documents") {
        firstBc = ["Les documents", "/documents"]
    }

    return (
        <Fragment>
            <PageHeader title={year} breadcrumb noMargin
                        nav={[firstBc, [committeeName, `/documents/${committeeSlug}`]]}>
                {
                    !loading &&
                    <Row className="row-sm row-sm-h align-items-center">
                        <Col>
                            <Form className="row row-sm row-sm-h align-items-center">
                                <Col lg="auto">
                                    <Form.Label htmlFor="search-document" className="fs-lg text-primary mb-0">Rechercher
                                        un document</Form.Label>
                                </Col>
                                <Col><Form.Control name="name" id="search-document" type="text"
                                                   placeholder="Ex : Budget, CA, ordre du jour ..."
                                                   onChange={filterDocuments}/></Col>
                            </Form>
                        </Col>
                        <Col lg="auto" className="text-end">
                            <ToggleMeetings/>
                        </Col>
                    </Row>
                }
            </PageHeader>
            <div className="page-documents">
                <Container className="mt-6">
                    {
                        loading ? <LoadingSpinner/>
                            : <Fragment>
                                <Row className="row-h">
                                    {
                                        currentMeetings.map((col) => {
                                            if (col.index !== "colResponsive") {
                                                return <Col lg={6} key={col.index} className="d-none d-lg-block">
                                                    <Row className="row-h">
                                                        {
                                                            col.meetings.map((meeting) => {
                                                                return <Col key={meeting.id} lg={12}>
                                                                    <DocumentsByMeeting meeting={meeting}/>
                                                                </Col>
                                                            })
                                                        }
                                                    </Row>
                                                </Col>
                                            } else {
                                                return <Col xs={12} key={col.index} className="d-lg-none">
                                                    <Row className="row-h">
                                                        {
                                                            col.meetings.map((meeting) => {
                                                                return <Col xs={12} key={meeting.id}>
                                                                    <DocumentsByMeeting meeting={meeting}/>
                                                                </Col>
                                                            })
                                                        }
                                                    </Row>
                                                </Col>
                                            }
                                        })
                                    }
                                </Row>
                                <ConfirmPvApprouval show={show}/>
                            </Fragment>
                    }
                </Container>
            </div>
        </Fragment>
    )
}
