import React, {SyntheticEvent, useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setFileToolLoading,
    resetToolFields,
    setDisplayPopinDeleteToolConfirm,
    setEditMode,
    setEditModeTool,
    setInEditForm,
    setOldIdTool,
    setFilePath,
    setIdTool,
    setToolCategorySelected,
    setShowModalAddTool,
    setToolSelected,
    setTitleTool,
    setPublishedTool,
    setFileName,
    setExtensionFileTool,
    setToolCategorySelectId
} from "../../redux/Toolkit/ToolkitSlice"
import {
    addTool,
    deleteTool,
    fetchDocumentCategories,
    TYPE_TOOLBOX,
    updateTool,
    uploadToolDocument
} from "../../api/DocumentAPI"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Spinner, Form, Modal} from "react-bootstrap"

export default function LightBoxAddTool(props: { editModeTool: boolean }) {

    const dispatch = useAppDispatch()
    const titleTool = useAppSelector((state: RootState) => state.toolkit.titleTool)
    const showModalAddTool = useAppSelector((state: RootState) => state.toolkit.showModalAddTool)
    const toolSelected = useAppSelector((state: RootState) => state.toolkit.toolSelected)
    const publishedTool = useAppSelector((state: RootState) => state.toolkit.publishedTool)
    const filename = useAppSelector((state: RootState) => state.toolkit.filename)
    const filePath = useAppSelector((state: RootState) => state.toolkit.filePath)
    const toolCategories = useAppSelector((state: RootState) => state.toolkit.toolCategories)
    const toolCategorySelectId = useAppSelector((state: RootState) => state.toolkit.toolCategorySelectId)
    const extensionFileTool = useAppSelector((state: RootState) => state.toolkit.extensionFileTool)
    const idTool = useAppSelector((state: RootState) => state.toolkit.idTool)
    const oldIdTool = useAppSelector((state: RootState) => state.toolkit.oldIdTool)
    const fileToolLoading = useAppSelector((state: RootState) => state.toolkit.fileToolLoading)

    useEffect(() => {
        dispatch(setFileToolLoading(false))
        if (props.editModeTool) {
            if (toolSelected !== null) {
                dispatch(setIdTool(toolSelected.id))
                dispatch(setOldIdTool(0))
                dispatch(setTitleTool(toolSelected.title))
                dispatch(setPublishedTool(toolSelected.published))
                dispatch(setFileName(toolSelected.filename))
                dispatch(setFilePath(toolSelected.filePath))
                dispatch(setExtensionFileTool(toolSelected.fileExtension))
                dispatch(setToolCategorySelectId(toolSelected.documentCategory !== undefined ? toolSelected.documentCategory.toString().split("/")[3] : toolSelected.documentCategory))
                dispatch(setInEditForm(true))
            }

        } else {
            dispatch(setToolCategorySelected(null))
            dispatch(setToolSelected(null))
            dispatch(displayMessage({text: ""}))
            dispatch(setInEditForm(false))
        }
    }, [props.editModeTool, dispatch, toolSelected])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (props.editModeTool && toolSelected !== null) {
            dispatch(displayMessage({text: "Mise à jour en cours"}))
            let response = await dispatch(updateTool({
                tool: {
                    id: idTool,
                    published: publishedTool,
                    title: titleTool,
                    documentCategory: toolCategorySelectId,
                    filename: filename,
                    filePath: filePath,
                    fileExtension: extensionFileTool,
                    type: "toolbox",
                    active: true
                }
            }))

            if (response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Outil modifié avec succès !", status: "success"}))
                dispatch(setInEditForm(false))
                await dispatch(deleteTool({tool: {id: oldIdTool}}))
                dispatch(fetchDocumentCategories({options: {type: TYPE_TOOLBOX}}))
                dispatch(setEditMode(false))
                dispatch(setEditModeTool(false))
                dispatch(resetToolFields())
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({text: "Echec de la mise à jour de l'outil", status: "error"}))
            }
        } else {
            dispatch(displayMessage({text: "Enregistrement en cours"}))
            let response = await dispatch(addTool({
                tool: {
                    id: idTool,
                    published: publishedTool,
                    title: titleTool,
                    documentCategory: "/api/document_categories/" + toolCategorySelectId,
                    filename: filename,
                    filePath: filename,
                    fileExtension: extensionFileTool,
                    type: "toolbox",
                    active: true
                }
            }))

            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Outil ajoutée avec succès !", stauts: "success"}))
                dispatch(fetchDocumentCategories({options: {type: TYPE_TOOLBOX}}))
                dispatch(setEditMode(false))
                dispatch(setEditModeTool(false))
                dispatch(resetToolFields())
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({text: "Echec de l'ajout de l'outil", status: "error"}))
            }
        }

    }

    const saveFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length > 0) {
            dispatch(setFileToolLoading(true))
            let formData = new FormData()
            formData.append("file", e.target.files[0])
            formData.append("id_document", toolSelected !== null && props.editModeTool ? toolSelected.id.toString() : "null")
            formData.append("edit", props.editModeTool ? "true" : "false")

            if (!fileToolLoading) {
                uploadToolDocument({
                    file: e.target.files[0],
                    documentId: toolSelected !== null && props.editModeTool ? toolSelected.id.toString() : "null",
                    type: TYPE_TOOLBOX
                }).then((data) => {
                    dispatch(setFileToolLoading(false))
                    dispatch(setFileName(data.filePath.split("/")[5]))
                    dispatch(setFilePath(data.filePath.split("/")[5]))
                    dispatch(setExtensionFileTool(data.fileExtension))
                    dispatch(setIdTool(data.id))
                    if (props.editModeTool && toolSelected !== null) {
                        dispatch(setOldIdTool(toolSelected.id))
                    }
                    dispatch(displayMessage({text: "Fichier chargé avec succès", status: "success"}))
                }).catch((result) => {
                    dispatch(setFileToolLoading(false))
                    let message = result.response.data.detail !== undefined ? result.response.data.detail : "Erreur lors du chargement du fichier"
                    dispatch(displayMessage({text: message, status: "error"}))
                })
            }
        } else {
            dispatch(setFileName(""))
            dispatch(displayMessage({text: "Aucun fichier n'a été sélectionnée", status: "error"}))
        }
    }

    const closeLightbox = () => {
        dispatch(setShowModalAddTool(false))
        dispatch(setTitleTool(""))
        dispatch(setPublishedTool(true))
        dispatch(setFileName(""))
        dispatch(setFilePath(""))
        dispatch(setExtensionFileTool(""))
        dispatch(setToolCategorySelectId(""))
        dispatch(setToolSelected(null))
        dispatch(displayMessage({text: ""}))
        dispatch(setInEditForm(false))
        dispatch(setEditModeTool(false))
    }

    return (
        <Modal className="modal-form" show={showModalAddTool} scrollable onHide={() => {
            closeLightbox()
        }}>
            <form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                handleSubmit(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title
                        as="h3">{props.editModeTool ? "Modification d'un outil" : "Ajout d'un outil"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Check type="switch" name="input-documentForAll" label="Publié" checked={publishedTool}
                                    onChange={(e) => {
                                        dispatch(setPublishedTool(!publishedTool))
                                    }}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-title">Titre *</Form.Label>
                        <Form.Control value={titleTool} id="input-title" required placeholder="Votre titre ici"
                                      onChange={(e) => {
                                          dispatch(setTitleTool(e.target.value))
                                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-category">Catégorie *</Form.Label>
                        <Form.Select id="input-category" value={toolCategorySelectId} required onChange={(e) => {
                            dispatch(setToolCategorySelectId(e.target.value))
                        }}>
                            <option key={0} defaultValue="---">Sélectionner une catégorie</option>
                            {toolCategories.map((toolCategory, index: number) => {
                                return <option key={index} value={toolCategory.id}>{toolCategory.title}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-file-tool">Fichier *</Form.Label>
                        {(() => {
                            if (fileToolLoading) {
                                return <Spinner animation="border" className="d-block" variant="primary"/>
                            } else {
                                return <>
                                    {(filePath !== undefined && filePath !== null && filePath !== "") &&
                                        <div className="row row-sm row-sm-h align-items-center mb-2">
                                            <span className="col d-flex align-items-center">
                                                {extensionFileTool === "pdf" ?
                                                    <i className="mai fs-lg text-primary me-2">picture_as_pdf</i>
                                                    : (extensionFileTool === "zip" ?
                                                            <i className="mai fs-lg text-primary me-2">folder_zip</i>
                                                            : <i className="mai fs-lg text-primary me-2">table_view</i>
                                                    )
                                                } {filePath}
                                            </span>
                                            <div className="col-auto">
                                                <button
                                                    className="btn-less d-flex align-items-center text-danger fw-semibold fs-xs"
                                                    onClick={() => {
                                                        dispatch(setFilePath(""))
                                                    }}>
                                                    <i className="mai fs-sm me-2">delete</i> Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (filePath === undefined || filePath === null || filePath === "") && <>
                                            <input type="file" id="input-file-tool" className="form-control"
                                                   onChange={(e) => {
                                                       saveFile(e)
                                                   }}/>
                                            <Form.Text className="mt-2 d-block">Fichier .pdf, .xls, .xlsx ou .zip
                                                uniquement. Poids max 5mo.</Form.Text>
                                        </>
                                    }

                                </>
                            }
                        })()}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {(() => {
                        if (props.editModeTool) {
                            return <div className="row row-sm row-sm-h">
                                <div className="col-auto">
                                    <button type="button" className="btn btn-light" onClick={() => {
                                        closeLightbox()
                                    }}>
                                        <i className="mai fs-lg me-2 text-danger">clear</i> Annuler
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="button" className="btn btn-light" onClick={(e) => {
                                        dispatch(setDisplayPopinDeleteToolConfirm(true))
                                    }}>
                                        <i className="mai fs-lg me-2">delete_outline</i> Supprimer
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-light">
                                        <i className="mai fs-lg me-2">check</i> Valider
                                    </button>
                                </div>
                            </div>
                        } else {
                            return <button type="submit" className="btn btn-light">
                                <i className="mai fs-lg me-2">add</i> Ajouter
                            </button>
                        }
                    })()}
                </Modal.Footer>
            </form>
        </Modal>
    )
}
