import {format, getDate, getMonth, getYear, setDefaultOptions} from "date-fns"
import {fr} from "date-fns/locale"

setDefaultOptions({locale: fr})

export function getToday(endOfDay: boolean = true) {
    if (endOfDay) {
        return format(new Date(getYear(new Date()), getMonth(new Date()), getDate(new Date()), 23, 59), "yyyy-MM-dd HH:mm")
    }
    
    return format(new Date(), "yyyy-MM-dd")
}

export function getFormattedLocalisedDate(date: Date, formatDate: string) {
    return format(date, formatDate)
}