import {Link} from "react-router-dom"
import {Col, Container, Row} from "react-bootstrap"

export default function Footer() {
    return (
        <footer className="footer py-8 h-100">
            <Container>
                <Row className="row-h justify-content-between align-items-center">
                    <Col xs={12} md="auto">
                        <a href="https://www.transitionspro-idf.fr/" target="_blank" rel="noreferrer"
                           className="row row-sm row-sm-h align-items-center justify-content-center justify-content-md-start">
                            <Col xs="auto">
                                <span className="fs-lg">Visitez le site</span>
                            </Col>
                            <Col xs="auto">
                                <img src={require("../_images/logo-white.svg").default} alt="transitions-pro"
                                     width="195" height="81" className="img-fluid"/>
                            </Col>
                        </a>
                    </Col>
                    <Col xs="12" md="auto">
                        <Row className="align-items-center justify-content-center justify-content-md-end">
                            <Col xs="auto" className="border-white"><Link target="_blank"
                                                                          to={"/conditions-generales-d-utilisation"}>CGU
                                et protection des données personnelles</Link></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
