import React from "react";
import {Committee} from "../../../api/CommitteeAPI";

export default function MeetingTitle(props: { committee?: Committee }) {
    if (props.committee === undefined || props.committee.title === undefined) {
        return <></>
    }

    return <>{props.committee.title}</>
}
