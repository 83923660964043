import React from 'react'
import {
    setActualitySelected,
    setContentActuality,
    setEditMode,
    setShowModalAddActuality
} from "../../redux/Content/ContentSlice"
import {hasRole, isAdmin} from "../../utils/UserTools"
import {data} from "../../utils/Data"
import {formatDateToDisplay} from "../../utils/Functions"
import {Content, setPublished} from "../../api/ContentAPI"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {Form, Row, Col, Ratio} from "react-bootstrap"

export default function ItemActuality(props: { actuality: Content, homepage: boolean }) {
    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)

    return <div className="position-relative h-100 bg-white">
        <Row className="row-0 h-100 flex-column">
            {((isAdmin(roles) || hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)) && !props.homepage) &&
                <div className="position-absolute p-3 z-10 t-0 r-0 l-0">
                    <div className="bg-overlay-dark py-2 px-4">
                        <Row className="row-sm row-sm-h justify-content-between">
                            <Col xs="auto">
                                <Form.Check type="switch" className="text-white" id={"home-switch" + props.actuality.id}
                                            label="Publié en home" checked={props.actuality.published} onChange={() => {
                                    dispatch(setPublished({
                                        actuality: props.actuality,
                                        published: !props.actuality.published
                                    }))
                                }}/>
                            </Col>
                            <Col xs="auto">
                                <button className="btn-less link text-tertiary text-hover-light fs-sm" onClick={(e) => {
                                    e.preventDefault()
                                    dispatch(setContentActuality(props.actuality.content))
                                    dispatch(setActualitySelected({
                                        id: props.actuality.id,
                                        title: props.actuality.title,
                                        content: props.actuality.content,
                                        filePath: props.actuality.filePath,
                                        videoLink: props.actuality.videoLink,
                                        published: props.actuality.published
                                    }))
                                    dispatch(setEditMode(true))
                                    dispatch(setShowModalAddActuality(true))
                                }}>
                                    <i className="mai fs-md me-2">edit</i> Editer
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            <Col xs={12} className={(!props.actuality.published ? "o-50" : "")}>
                <Ratio aspectRatio="7x3">
                    {
                        (props.actuality.filePath !== undefined && props.actuality.filePath !== "" && props.actuality.filePath !== null) ?
                            <img src={`${data.host}${props.actuality.filePath}`} alt={props.actuality.title}
                                 className="img-cover"/>
                            :
                            <div
                                className="bg-disabled-gray w-100 h-100 d-flex align-items-center justify-content-center">
                                <i className="mai text-size-64px text-white">photo_camera</i>
                            </div>
                    }
                </Ratio>
            </Col>

            <Col className={(!props.actuality.published ? "o-50" : "")}>
                <div className="bg-white p-5 h-100">
                    <Row className="row-0 flex-column h-100">
                        <Col>
                            <h3 className="mb-3 fw-medium">{props.actuality.title}</h3>
                            <div className="mb-3" dangerouslySetInnerHTML={{__html: props.actuality.content}}/>
                        </Col>
                        <Col xs="auto">
                            <Row className="row-sm row-sm-h align-items-center">
                                <Col>
                                    <div className="fs-xs text-main-gray-dark">Publié
                                        le {formatDateToDisplay(props.actuality.createdDate)}</div>
                                </Col>
                                {(props.homepage && props.actuality.videoLink !== undefined && props.actuality.videoLink !== null && props.actuality.videoLink !== "") &&
                                    <Col className="d-flex justify-content-end">
                                        <a href={props.actuality.videoLink} rel="noreferrer" className="btn btn-primary"
                                           target="_blank">Voir la vidéo</a>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </div>
}
