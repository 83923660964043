import {useState, ChangeEvent, SyntheticEvent, useEffect} from "react"
import {useAppDispatch} from "../redux/Hooks"
import {useNavigate, useLocation} from "react-router-dom"
import {SetNewPassword} from "../api/UserAPI"
import {connectUser} from "../redux/User/UserSlice"
import PageHeader from "./PageHeader"
import {Button, Form} from "react-bootstrap"
import {checkpassword} from "../utils/UserTools"
import {Link} from "react-router-dom"
import {textPassword} from "../utils/Data"
import {displayMessage} from "../redux/Message/MessageSlice"
import {authLogin} from "../services/auth"

type Props = {
    forgottenPassword?: boolean,
}

export default function SetPassword({forgottenPassword = false}: Props) {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [classInputPassword1, setClassInputPassword1] = useState("")
    const [classInputPassword2, setClassInputPassword2] = useState("")
    const [password1Valid, setPassword1Valid] = useState(false)
    const [password2Valid, setPassword2Valid] = useState(false)
    const [hidePassword, setHidePassword] = useState<boolean>(true)
    const [cguOk, setCguOk] = useState(false)

    useEffect(() => {
        if (!/confirmation_token=[a-zA-Z0-9]+/.test(window.location.href)) {
            return navigate("/s-identifier")
        }
    })

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (password !== password2) {
            dispatch(displayMessage({text: "Les 2 mots de passe ne sont pas identiques", status: "error"}))
            return false
        }

        if (!password1Valid || !password2Valid) {
            dispatch(displayMessage({text: "Le mot de passe n'est pas pas valide", status: "error"}))
            return false
        }

        if (window.location.href.indexOf("nouveau-compte") !== -1 && !cguOk) {
            dispatch(displayMessage({
                text: "Vous devez accepter les conditions générales d'utilisation",
                status: "error"
            }))
            return false
        }
        dispatch(displayMessage({text: "Enregistrement en cours", status: "info"}))
        let response = await dispatch(SetNewPassword({
            password: password,
            confirmationToken: location.search.replace("?confirmation_token=", "")
        }))

        if (response.payload !== undefined && response.payload !== null) {
            if (!response.payload.error) {
                dispatch(displayMessage({text: response.payload.message, status: "success"}))
                const username = response.payload.username

                // ici, on peut faire confiance à la data "username" car elle nous est renvoyé de l'api
                // et le password vient d'être redéfini, il n'y est pas censé avoir d'erreur à ce moment
                authLogin({username, password}).then((data) => {
                    dispatch(connectUser({token: data.token, username: username}))
                    return navigate("/accueil")
                })
            } else {
                dispatch(displayMessage({text: response.payload.message, status: "error"}))
            }
        }

    }

    useEffect(() => {
        document.body.classList.add("page-login")

        return () => {
            document.body.classList.remove("page-login")
        }
    }, [])

    return <>
        <PageHeader
            title="Bienvenue"
            h2Class="col-lg-8 mx-auto"
            subTitle={forgottenPassword ? "Vous avez demandé à réinitialiser votre mot de passe pour accéder à la plateforme dédiée aux Instances de Transitions Pro IDF"
                : "Votre compte utilisateur vient d’être créé sur la plateforme dédiée aux Instances de Transitions Pro IDF"}
        />
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <Form className="bg-white p-4 p-lg-8" onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                        handleSubmit(e)
                    }}>
                        <h3 className="text-primary mb-4">Vous devez saisir un mot de passe</h3>
                        <div className="row">
                            <div className="col-lg">
                                <div className="form-label">Choisissez votre mot de passe *</div>
                                <div className="input-group">
                                    <Form.Control type={hidePassword ? "password" : "text"}
                                                  className={classInputPassword1}
                                                  value={password}
                                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                      checkpassword(
                                                          {
                                                              value: e.target.value,
                                                              input: "password1",
                                                              password1: password,
                                                              password2: password2,
                                                              setPassword: setPassword,
                                                              setPassword2: setPassword2,
                                                              setPassword1Valid: setPassword1Valid,
                                                              setPassword2Valid: setPassword2Valid,
                                                              setClassInputPassword1: setClassInputPassword1,
                                                              setClassInputPassword2: setClassInputPassword2
                                                          }
                                                      )
                                                  }}
                                                  required
                                    />
                                    <button className="input-group-text" onClick={(e) => {
                                        e.preventDefault()
                                        setHidePassword(!hidePassword)
                                    }}>
                                        <span className="mai">{hidePassword ? "visibility" : "lock"}</span>
                                    </button>
                                </div>
                                <div className="form-text mb-0 help-text">{textPassword}</div>
                            </div>
                            <div className="col-lg">
                                <div className="form-label">Confirmez votre mot de passe *</div>
                                <div className="input-group">
                                    <Form.Control type={hidePassword ? "password" : "text"}
                                                  className={classInputPassword2}
                                                  value={password2}
                                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                      checkpassword(
                                                          {
                                                              value: e.target.value,
                                                              input: "password2",
                                                              password1: password,
                                                              password2: password2,
                                                              setPassword: setPassword,
                                                              setPassword2: setPassword2,
                                                              setPassword1Valid: setPassword1Valid,
                                                              setPassword2Valid: setPassword2Valid,
                                                              setClassInputPassword1: setClassInputPassword1,
                                                              setClassInputPassword2: setClassInputPassword2
                                                          }
                                                      )
                                                  }} required
                                    />
                                    <button className="input-group-text" onClick={(e) => {
                                        e.preventDefault()
                                        setHidePassword(!hidePassword)
                                    }}>
                                        <span className="mai">{hidePassword ? "visibility" : "lock"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {window.location.href.indexOf("nouveau-compte") !== -1 &&
                            <div className="my-4" key="default-cgu">
                                <Form.Check id="default-cgu">
                                    <Form.Check.Input type="checkbox" checked={cguOk} required onChange={(e) => {
                                        setCguOk(!cguOk)
                                    }}/>
                                    <Form.Check.Label>
                                        Je valide
                                        <Link target="_blank" to={"/conditions-generales-d-utilisation"}>les CGU</Link>
                                        *
                                    </Form.Check.Label>
                                </Form.Check>
                            </div>
                        }
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto">
                                <Button variant="outline-border-gray text-dark" type="submit">
                                    <i className="mai me-2 fs-md text-primary">save</i>
                                    <span className="fw-semibold">Enregistrer et se connecter</span>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </>
}
