import {createSlice} from "@reduxjs/toolkit"
import {createLog, fetchLogs, Log} from "../../api/LogAPI"

export interface LogState {
    items: Log[],
    showLightBox: boolean,
    selectedEntityId?: number
}

const initialState: LogState = {
    items: [],
    showLightBox: false
}

export const LogSlice = createSlice({
    name: "emailing",
    initialState,
    reducers: {
        setLightBoxShow: (state, action) => {
            state.showLightBox = action.payload
        },
        setSelectedEntityId: (state, action) => {
            state.selectedEntityId = action.payload
        },
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchLogs.fulfilled, (state, action) => {
                    state.items = action.payload
                })
                .addCase(createLog.fulfilled, (state, action) => {
                    const items = state.items
                    state.items = [...items, action.payload]
                })
        }
})

export const {
    setLightBoxShow,
    setSelectedEntityId
} = LogSlice.actions

export default LogSlice.reducer
