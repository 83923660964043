import React from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {Modal, Table} from "react-bootstrap"
import {setLightBoxShow} from "../../redux/Log/LogSlice"
import {getFormattedLocalisedDate} from "../../utils/Date"
import {Log} from "../../api/LogAPI"

export default function LightBoxLogs() {
    const dispatch = useAppDispatch()

    const logs = useAppSelector((state: RootState) => state.log.items)
    const showLightBox = useAppSelector((state: RootState) => state.log.showLightBox)
    const selectedEntityId = useAppSelector((state: RootState) => state.log.selectedEntityId)

    const closeLightbox = () => {
        dispatch(setLightBoxShow(false))
    }

    return (
        <Modal className="modal-form" show={showLightBox} scrollable onHide={() => {
            closeLightbox()
        }}>
            <Modal.Header closeButton>
                <Modal.Title
                    as="h3">Détail des consultations du document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped size="md">
                    <tbody>
                    {
                        logs.filter(item => item.entityId === selectedEntityId).map((item: Log, index) => {
                            return <tr key={index}>
                                <td>{item.userDetails.firstname} {item.userDetails.lastname}</td>
                                <td>le {item.createdDate && getFormattedLocalisedDate(new Date(item.createdDate), "dd/MM/yyyy")} à {item.createdDate && getFormattedLocalisedDate(new Date(item.createdDate), "HH:mm")}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => closeLightbox()}
                >Fermer
                </button>
            </Modal.Footer>
        </Modal>
    )
}
