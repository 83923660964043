import ZoneCalendar from "./Meeting/ZoneCalendar"
import ZoneActualities from "./Actuality/ZoneActualities"
import PageHeader from "./PageHeader"
import {getFormattedLocalisedDate} from "../utils/Date"

export default function Home() {
    const currentDate = getFormattedLocalisedDate(new Date(), "EEEE d MMMM yyyy")

    document.body.classList.add("page-agenda")
    document.body.classList.add("page-home")

    return (
        <>
            <PageHeader title={"Nous sommes le " + (currentDate)} subTitle="Bienvenue dans votre Espace instances"/>
            <div className="container">
                <ZoneActualities context="home"/>
                <ZoneCalendar/>
            </div>
        </>
    )
}
