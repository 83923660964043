import {useEffect, ReactNode, useState, Fragment} from "react"
import LightBoxUser from "../../components/Trombinoscope/LightBoxUser"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setNbUsersFilteredTotal,
    setUsers,
    setFilterName,
    setFilterInstance,
    setFilterStatus,
    setFilterRole,
    setFilterProfil,
    setUserSelected,
    setShow,
    setReverse,
    setNbPerPage,
    setActualPage,
    setOnlyActive, setFilterCollege, setFilterSyndicate
} from "../../redux/User/TrombinoscopeSlice"
import {hasRole} from "../../utils/UserTools"
import FormFilterUser from "../../components/Trombinoscope/FormFilterUser"
import PopinAddUserForm from "../../components/Trombinoscope/PopinAddUserForm"
import PopinPrintLabelsForm from "../../components/Trombinoscope/PopinPrintLabelsForm"
import PopinExportUsersForm from "../../components/Trombinoscope/PopinExportUsersForm"
import {useNavigate} from "react-router-dom"
import {setPage, setUserId} from "../../redux/User/UserSlice"
import PageHeader from "../../components/PageHeader"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Col, Container, Form, Row} from "react-bootstrap"
import LoadingSpinner from "../../components/LoadingSpinner"
import UserCard from "../../components/Trombinoscope/UserCard"
import {getUsers, ROLES_ADMIN, switchUser, User} from "../../api/UserAPI"
import {IDataFetchUsers} from "../../utils/CommonInterfaces"
import GenerateLinksPagination from "../../components/GenerateLinksPagination"
import {Link} from "react-router-dom"
import {Options} from "../../utils/Options"
import PopinHistoric from "./PopinHistoric"
import {setCookie} from "../../utils/cookies"
import {getToday} from "../../utils/Date"
import {resetAllFields, setProperty} from "../../redux/User/AddUserSlice"
import {setCommittees} from "../../redux/User/AddAssistantSlice"
import {setFilters} from "../../redux/Committee/CommitteeSlice"
import {resetItems} from "../../redux/Meeting/MeetingSlice"

export default function Trombinoscope() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const users = useAppSelector((state: RootState) => state.tromb.users)
    const currentUser = useAppSelector((state) => state.app.user)
    const stateListUser = useAppSelector((state: RootState) => state.tromb.stateListUser)
    const nbPerPage = useAppSelector((state: RootState) => state.tromb.nbPerPage)
    const actualPage = useAppSelector((state: RootState) => state.tromb.actualPage)
    const show = useAppSelector((state: RootState) => state.tromb.show)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const onlyActive = useAppSelector((state: RootState) => state.tromb.onlyActive)
    const nbUsersFilteredTotal = useAppSelector((state: RootState) => state.tromb.nbUsersFilteredTotal)
    const errorLoading = useAppSelector((state) => state.tromb.errorLoading)
    const reverse = useAppSelector((state) => state.tromb.reverse)

    const filterName = useAppSelector((state: RootState) => state.tromb.filterName)
    const filterRole = useAppSelector((state: RootState) => state.tromb.filterRole)
    const filterProfil = useAppSelector((state: RootState) => state.tromb.filterProfil)
    const filterStatus = useAppSelector((state: RootState) => state.tromb.filterStatus)
    const filterInstance = useAppSelector((state: RootState) => state.tromb.filterInstance)
    const filterCollege = useAppSelector((state: RootState) => state.tromb.filterCollege)
    const filterSyndicate = useAppSelector((state: RootState) => state.tromb.filterSyndicate)

    const [showExport, setShowExport] = useState<boolean>(false)
    const [showHistoric, setShowHistoric] = useState<boolean>(false)

    useEffect(() => {
        resetFields()
        dispatch(setPage("annuaire"))
        setActualPageLink(1)
        dispatch(resetAllFields({}))
        return () => {
            dispatch(setUsers([]))
            resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchUsersPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles])

    useEffect(() => {
        if (errorLoading) {
            dispatch(setActualPage(1))
            displayMessage({texte: "Echec du chargement des utilisateurs", error: true})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorLoading])

    const setActualPageLink = (page: number) => {
        dispatch(setActualPage(page))
    }

    const fetchUsersPage = (page: number) => {
        fetchUsers({newPage: page})
    }

    const fetchUsers = (data: IDataFetchUsers) => {
        let currentReverse: boolean = reverse
        if (data.reverse !== undefined) {
            currentReverse = data.reverse
        }

        let options: Options = {
            "order[lastname]": !currentReverse ? "ASC" : "DESC",
            "order[firstname]": !currentReverse ? "ASC" : "DESC"
        }

        if (data.nbPerPage || nbPerPage) {
            const perPage = data.nbPerPage ? data.nbPerPage : nbPerPage
            if (perPage === "Tout") {
                options["pagination"] = false
            } else {
                options["itemsPerPage"] = perPage as number
                options["page"] = data.newPage ?? actualPage
            }
        }

        if (data.filterName || filterName !== "") {
            options["niceName"] = data.filterName ?? filterName
        }

        // On sélectionne en fonction du filtre profil
        // sauf si on est un commissaire-administrateur : on ne sélectionne alors
        // que les commissaires-administrateurs
        if (data.filterProfil || filterProfil !== "") {
            options["roles"] = data.filterProfil ?? filterProfil
        }
        if (!hasRole(ROLES_ADMIN, roles)) {
            options["roles"] = "ROLE_USER"
        }


        const role = data.filterRole !== undefined ? data.filterRole : filterRole
        if (role !== "") {
            options["periods.mandates.roles"] = parseInt(role)
        }

        const status = data.filterStatus !== undefined ? data.filterStatus : filterStatus
        if (status !== "") {
            options["periods.mandates.status"] = parseInt(status)
        }

        const committeeValue = data.filterInstance !== undefined ? data.filterInstance : filterInstance
        if (committeeValue !== "") {
            options["periods.mandates.committee.id"] = parseInt(committeeValue)
        }

        const collegeValue = data.filterCollege ?? filterCollege
        if (collegeValue !== "") {
            options["college"] = parseInt(collegeValue)
        }

        const syndicateValue = data.filterSyndicate ?? filterSyndicate
        if (syndicateValue !== "") {
            options["syndicate"] = parseInt(syndicateValue)
        }

        if (role !== "" || status !== "" || committeeValue !== "") {
            options["periods.mandates.dateEnd[after]"] = getToday()
            options["periods.mandates.dateStart[before]"] = getToday(true)
        }

        if ((data.active && data.active === 1) || (data.active === undefined && onlyActive)) {
            options["active"] = true
        }

        dispatch(getUsers({
            options: options
        }))
    }

    const resetFields = () => {
        dispatch(setFilterName(""))
        dispatch(setFilterInstance(""))
        dispatch(setFilterStatus(""))
        dispatch(setFilterRole(""))
        dispatch(setFilterProfil(""))
        dispatch(setFilterCollege(""))
        dispatch(setFilterSyndicate(""))
        dispatch(setNbUsersFilteredTotal(0))
    }

    const selectUser = (user: User) => {
        dispatch(setUserSelected(user))
        dispatch(setShow(true))
    }

    const redirectEditUserPage = (user: User) => {
        dispatch(setProperty({property: "idUserEdit", value: user.id}))

        if (hasRole(["ROLE_ASSIST", "ROLE_CODIR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"], user.roles)) {
            if (user.roles.indexOf("ROLE_ADMIN") !== -1 || user.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
                dispatch(setProperty({property: "profil", value: "webmaster"}))
                dispatch(setProperty({property: "editMode", value: true}))
            } else if (user.roles.indexOf("ROLE_CODIR") !== -1) {
                dispatch(setProperty({property: "profil", value: "membre-codir"}))
                dispatch(setProperty({property: "editMode", value: true}))
            } else if (user.roles.indexOf("ROLE_ASSIST") !== -1) {
                dispatch(setProperty({property: "profil", value: "assistante"}))
                dispatch(setProperty({property: "editMode", value: true}))
            }
            return navigate("/editer-assistant/" + user.id)
        }
        dispatch(setProperty({property: "editMode", value: true}))
        if (user.id === currentUser.id) {
            return navigate("/mon-profil")
        }
        return navigate("/editer-admin/" + user.id)
    }

    const connectAdminAsUser = (user: User) => {
        if (!hasRole(["ROLE_ADMIN"], roles) || !user.id) {
            return
        }

        switchUser({id: user.id}).then((data) => {
            setCookie("adminToken", currentUser.token)
            setCookie("adminUsername", currentUser.username)
            dispatch(setCommittees([]))
            dispatch(setFilters([]))
            dispatch(resetItems())
            dispatch(setUserId(0))
            return navigate(`/switch-user/${data.token}/${data.username}`)
        })
    }

    let nbPages = 1
    let nbrUsersTotal = nbUsersFilteredTotal
    let usersHtml: ReactNode[] = []

    if (users.length > 0) {
        users.forEach((user: User, idx: number) => {
            usersHtml.push(<UserCard user={user} redirectEditUserPage={redirectEditUserPage}
                                     connectAdminAsUser={connectAdminAsUser} selectUser={selectUser} key={idx}/>)
        })
        nbPages = typeof nbPerPage === "number" && typeof nbUsersFilteredTotal === "number" ? Math.ceil(nbUsersFilteredTotal / nbPerPage) : 1
    }

    let contentTable: ReactNode = <div className="row row-h">{usersHtml}</div>
    if (stateListUser === "loading") {
        contentTable = <LoadingSpinner/>
    }

    return (
        <Fragment>
            <PageHeader title="Annuaire" noMargin breadcrumb>
                {(() => {
                    if (hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)) {
                        return <a href="/" className="btn btn-lg btn-tertiary btn-icon rounded-circle"
                                  data-bs-toggle="modal" data-bs-target="#modal-add-user-form"><i
                            className="mai fs-lg">person_add</i></a>
                    }
                    return <Fragment/>
                })()}
                <FormFilterUser resetFields={resetFields} fetchUsers={fetchUsers}/>
            </PageHeader>
            <Container className="my-7">
                <Row className="row-sm row-sm-h align-items-center justify-content-between mb-5">
                    <Col xs="auto">
                        <strong className="fs-lg">
                            <span
                                className="text-primary">{nbrUsersTotal} membre{`${nbrUsersTotal > 1 ? "s" : ""}`}</span> selon
                            ces critères
                        </strong>
                    </Col>
                    <Col xs="auto">
                        <Row className="row-sm align-items-center">
                            {
                                hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles) &&
                                <Col xs="auto">
                                    <Form.Check type="switch" label="Uniquement les actifs" checked={onlyActive}
                                                onChange={() => {
                                                    dispatch(setOnlyActive(!onlyActive))
                                                    fetchUsers({
                                                        newPage: 1,
                                                        active: onlyActive ? 0 : 1
                                                    })
                                                }}/>
                                </Col>
                            }
                            <div className="col-auto">
                                <div className="row row-xs">
                                    <div className="col-auto">
                                        <Form.Label className="col-form-label">Trier par</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            defaultValue={"normal"}
                                            onChange={(e) => {
                                                dispatch(setReverse(e.target.value === "reverse"))
                                                fetchUsers({
                                                    reverse: (e.target.value === "reverse")
                                                })
                                            }}>
                                            <option value="normal">{"A > Z"}</option>
                                            <option value="reverse">{"Z > A"}</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="row row-xs">
                                    <div className="col-auto">
                                        <Form.Label className="col-form-label">Nb par page</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Select defaultValue={Number(nbPerPage) === 24 ? 24 : ""}
                                                     onChange={(e) => {
                                                         dispatch(setNbPerPage(e.target.value === "Tout" ? e.target.value : Number(e.target.value)))
                                                         fetchUsers({
                                                             newPage: 1,
                                                             nbPerPage: e.target.value
                                                         })
                                                     }}>
                                            <option value={24}>24</option>
                                            <option value={48}>48</option>
                                            <option value={96}>96</option>
                                            <option value="Tout">Tout</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                            {hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ASSIST"], roles) &&
                                <>
                                    <div className="col-auto">
                                        <Link to="/emailing" className="btn btn-light">
                                            <i className="mai me-2 fs-md">email</i>Envoi d'email
                                        </Link>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-light" onClick={() => setShowExport(true)}>
                                            <i className="mai me-2 fs-md">download</i>Exporter
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <a href="/" className="btn btn-light" data-bs-toggle="modal"
                                           data-bs-target="#modal-print-labels-form">
                                            <i className="mai me-2 fs-md">print</i>Imprimer les étiquettes
                                        </a>
                                    </div>
                                    {
                                        hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles) &&
                                        <div className="col-auto">
                                            <button className="btn btn-light" onClick={() => setShowHistoric(true)}>
                                                Historique
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                        </Row>
                    </Col>
                </Row>
                {contentTable}
                {nbPerPage !== "Tout" &&
                    <GenerateLinksPagination nbPages={nbPages} actualPage={actualPage} setActualPage={setActualPageLink}
                                             getData={fetchUsersPage}/>}
                {show && <LightBoxUser/>}
                <PopinAddUserForm/>
                <PopinPrintLabelsForm/>
                <PopinExportUsersForm show={showExport} setShow={setShowExport}/>
                <PopinHistoric show={showHistoric} setShow={setShowHistoric}/>
            </Container>
        </Fragment>

    )
}
