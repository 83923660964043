import {createSlice} from "@reduxjs/toolkit";
import {addActuality, Content, deleteActuality, setPublished, updateActuality} from '../../api/ContentAPI'
import {fetchContents} from "../../api/ContentAPI";

export interface ActualityState {
    showModalAddActuality: boolean,
    editMode: boolean,
    contents: Content[],
    stateListActuality: string,
    nbPerPage: number,
    actualPage: number,
    titleActuality: string,
    imageActuality: string,
    publishedActuality: boolean,
    contentActuality: string,
    videoLinkActuality: string,
    actualitySelected: Content | null,
    errorMessageActuality: boolean,
    displayPopinDeleteActualityConfirm: boolean,
    nbTotalActualities: number,
    showLoadingHome: boolean,
    imageContentLoading: boolean,
    viewOnlyPublished: boolean
}

const initialState: ActualityState = {
    showModalAddActuality: false,
    editMode: false,
    contents: [],
    stateListActuality: '',
    nbPerPage: 6,
    actualPage: 1,
    titleActuality: '',
    imageActuality: '',
    publishedActuality: false,
    contentActuality: '',
    videoLinkActuality: '',
    actualitySelected: null,
    errorMessageActuality: false,
    displayPopinDeleteActualityConfirm: false,
    nbTotalActualities: 0,
    showLoadingHome: true,
    imageContentLoading: false,
    viewOnlyPublished: true
}

export const ContentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setViewOnlyPublished: (state, action) => {
            state.viewOnlyPublished = action.payload
        },
        setShowModalAddActuality: (state, action) => {
            state.showModalAddActuality = action.payload
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload
        },
        setActualities: (state, action) => {
            state.contents = [...action.payload]
        },
        setActualPage: (state, action) => {
            state.actualPage = action.payload
        },
        setImageActuality: (state, action) => {
            state.imageActuality = action.payload
        },
        setTitleActuality: (state, action) => {
            state.titleActuality = action.payload
        },
        setPublishedActuality: (state, action) => {
            state.publishedActuality = action.payload
        },
        setContentActuality: (state, action) => {
            state.contentActuality = action.payload
        },
        setVideoLinkActuality: (state, action) => {
            state.videoLinkActuality = action.payload
        },
        setErrorMessageActuality: (state, action) => {
            state.errorMessageActuality = action.payload
        },
        setActualitySelected: (state, action) => {
            state.actualitySelected = action.payload
        },
        setStateListActuality: (state, action) => {
            state.stateListActuality = action.payload
        },
        setDisplayPopinDeleteActualityConfirm: (state, action) => {
            state.displayPopinDeleteActualityConfirm = action.payload
        },
        setImageContentLoading: (state, action) => {
            state.imageContentLoading = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
            .addCase(fetchContents.fulfilled, (state, action) => {
                state.stateListActuality = ''
                state.contents = action.payload['hydra:member']
                state.nbTotalActualities = action.payload['hydra:totalItems'] !== undefined ? action.payload['hydra:totalItems'] : 0
                state.showLoadingHome = false
            })
            .addCase(fetchContents.pending, (state) => {
                state.stateListActuality = 'loading'
            })
            .addCase(fetchContents.rejected, (state) => {
                state.showLoadingHome = false
            })
            .addCase(setPublished.fulfilled, (state, action) => {
                let newActualities:Content[] = []
                state.contents.forEach((actuality:Content)=>{
                    if(Number(actuality.id) === Number(action.payload.response.id)){
                        let newActuality = {...actuality}
                        newActuality.published = action.payload.response.published
                        newActualities.push(newActuality)
                    }else{
                        newActualities.push(actuality)
                    }
                })
                state.contents = [...newActualities]
            })
            .addCase(addActuality.rejected, (state) => {
                state.showModalAddActuality = false
            })
            .addCase(addActuality.fulfilled, (state, action) => {
                state.showModalAddActuality = false
                state.titleActuality = ''
                state.imageActuality = ''
                state.publishedActuality = true
                state.contentActuality = ''
                state.videoLinkActuality = ''
                state.actualitySelected = null
            })
            .addCase(updateActuality.rejected, (state, action) => {
                state.showModalAddActuality = false
            })
            .addCase(updateActuality.fulfilled, (state, action) => {
                state.showModalAddActuality = false
                state.titleActuality = ''
                state.imageActuality = ''
                state.publishedActuality = true
                state.contentActuality = ''
                state.videoLinkActuality = ''
                state.actualitySelected = null
            })
            .addCase(deleteActuality.fulfilled, (state) => {
                document.getElementById('btn-show-confirm-modal-delete-actuality')?.click()
                state.displayPopinDeleteActualityConfirm = false
                state.showModalAddActuality = false
                state.titleActuality = ''
                state.imageActuality = ''
                state.publishedActuality = true
                state.contentActuality = ''
                state.videoLinkActuality = ''
                state.actualitySelected = null
            })
            .addCase(deleteActuality.rejected, (state, action) => {
                state.showModalAddActuality = false
            })
    }
})

export const {
    setViewOnlyPublished,
    setImageContentLoading,
    setShowModalAddActuality,
    setEditMode,
    setActualPage,
    setImageActuality,
    setTitleActuality,
    setPublishedActuality,
    setContentActuality,
    setVideoLinkActuality,
    setActualitySelected,
    setStateListActuality,
    setActualities,
    setDisplayPopinDeleteActualityConfirm
} = ContentSlice.actions

export default ContentSlice.reducer
