import React, {ChangeEvent, Fragment, useEffect} from "react"
import {Col, Form, Row} from "react-bootstrap"
import {
    Document,
    deleteDocument,
    TYPE_CAT_PV,
    TYPE_MEETING,
    uploadDocument, TYPE_CAT_CONVOCATION
} from "../../../api/DocumentAPI"
import {ExpectedDocument} from "../../../api/ExpectedDocumentAPI"
import DatePicker from "react-datepicker"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {
    setDocumentDate,
    setDocumentIndex,
    setDocumentTitle
} from "../../../redux/Meeting/MeetingFormSlice"
import {setSendEmailPv} from "../../../redux/Meeting/MeetingSlice"
import {displayMessage} from "../../../redux/Message/MessageSlice"
import ZonePv from "./ZonePv"
import {getFormattedLocalisedDate} from "../../../utils/Date"

export default function ItemDocumentForm(props: {
    index: number,
    document?: Document,
    documentType?: number,
    expectedDocument?: ExpectedDocument
}) {
    const dispatch = useAppDispatch()
    const errors = useAppSelector((state) => state.meetingForm.errors)
    const documents = useAppSelector((state) => state.meetingForm.documents)

    useEffect(() => {
        if (errors.length > 0 && errors[props.index] !== undefined) {
            dispatch(displayMessage({text: errors[props.index], status: "error"}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    let document: Document | undefined
    let index = props.index
    documents.forEach((item, key) => {
        if (item && props.document && item.id === props.document.id) {
            document = item
            index = key
        }
    })
    if (!document) {
        index = documents.length
    }

    function saveDocumentFile(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files !== null && e.target.files.length > 0) {
            if (props.expectedDocument !== undefined && props.expectedDocument.category !== undefined) {
                if (props.expectedDocument.category.type === TYPE_CAT_PV) {
                    updateTitle("Procès-verbal")
                }
                if (props.expectedDocument.category.type === TYPE_CAT_CONVOCATION) {
                    updateTitle("Convocation")
                }
            }

            let publishedDate = new Date()
            if (props.document && props.document.publishedDate) {
                publishedDate = new Date(props.document.publishedDate)
            }

            dispatch(setDocumentIndex(index))
            dispatch(uploadDocument({
                file: e.target.files[0],
                documentId: props.document && props.document.id ? props.document.id : 0,
                type: TYPE_MEETING,
                title: props.document && props.document.title,
                publishedDate: getFormattedLocalisedDate(new Date(publishedDate), "yyyy-MM-dd"),
                expectedDocumentId: ((props.expectedDocument !== undefined && props.expectedDocument.id !== undefined) ? props.expectedDocument.id.toString() : "")
            }))

            if (props.expectedDocument !== undefined && props.expectedDocument.category !== undefined && props.expectedDocument.category.type === TYPE_CAT_PV) {
                dispatch(setSendEmailPv(true))
            }
        }
    }

    function updateTitle(value: string) {
        dispatch(setDocumentIndex(index))
        dispatch(setDocumentTitle({value: value, expectedDocument: props.expectedDocument}))
    }

    function updateDate(date: Date | null) {
        if (date) {
            dispatch(setDocumentIndex(index))
            dispatch(setDocumentDate({
                value: getFormattedLocalisedDate(date, "yyyy-MM-dd"),
                expectedDocument: props.expectedDocument
            }))
        }
    }

    function deleteFile(documentId: number | string) {
        dispatch(setDocumentIndex(index))
        dispatch(deleteDocument({id: documentId}))
        if (props.expectedDocument !== undefined && props.expectedDocument.category !== undefined && props.expectedDocument.category.type === TYPE_CAT_PV) {
            dispatch(setSendEmailPv(false))
        }
    }

    return <div className="mb-4 expected-document">
        <Form.Group className="mb-4">
            <Form.Label htmlFor={`input-file-tool-${props.index}`}>Fichier</Form.Label>
            <Row className="row-sm row-sm-h align-items-center">
                {document && document.filePath && document.filePath !== "" &&
                    <Fragment>
                        <Col>
                            {
                                document.fileExtension === "pdf"
                                    ? <i className="mai fs-lg text-primary me-2">picture_as_pdf</i>
                                    : document.fileExtension === "zip"
                                        ? <i className="mai fs-lg text-primary me-2">folder_zip</i>
                                        : <i className="mai fs-lg text-primary me-2">table_view</i>
                            }
                            {
                                document &&
                                document.fileOriginalName
                            }
                        </Col>
                        <Col xs="auto">
                            {
                                document &&
                                <button type="button" className="btn-less link text-danger fs-xs text-hover-black"
                                        onClick={() => {
                                            document && deleteFile(document.id)
                                        }}>
                                    <i className="mai fs-sm me-2">delete</i> Supprimer
                                </button>
                            }
                        </Col>
                    </Fragment>
                }
                {
                    (!document || !document.filePath) &&
                    <Col xs={12}>
                        <input
                            type="file"
                            id={`input-file-tool-${props.index}`}
                            className="form-control"
                            onChange={(e) => saveDocumentFile(e)}
                            required={(props.expectedDocument !== undefined && props.expectedDocument.mandatory)}
                        />
                        <Form.Text>Fichier .pdf, .xls, .xlsx ou .zip uniquement. Poids max 5mo.</Form.Text>
                    </Col>
                }
            </Row>
        </Form.Group>

        {
            props.index > 1 &&
            <Form.Group className="mb-4">
                <Form.Label htmlFor={`Titre-${props.index}`}>Titre</Form.Label>
                <Form.Control
                    type="text"
                    name="titre"
                    id={`Titre-${props.index}`}
                    value={document ? document.title : ""}
                    onChange={(e) => updateTitle(e.target.value)}
                />
            </Form.Group>
        }

        <Form.Group>
            <Form.Label htmlFor={`input-date-${props.index}`}>Date de publication</Form.Label>
            <DatePicker
                locale="fr"
                id={`input-date-${props.index}`}
                dateFormat="dd-MM-yyyy"
                selected={document?.publishedDate ? new Date(document.publishedDate) : new Date()}
                name={`input-date-${props.index}`}
                className="form-control"
                required={true}
                onChange={(date) => updateDate(date)}
            />
        </Form.Group>

        {
            documents.length > 0 && props.documentType === TYPE_CAT_PV &&
            <ZonePv expectedDocument={props.expectedDocument}/>
        }
    </div>
}
