import { createSlice } from '@reduxjs/toolkit'
import {Committee} from "../../api/CommitteeAPI";

export interface addAssistantState {
    profil: string,
    nom: string,
    prenom: string,
    email: string,
    authDiffEmail: boolean,
    civilite: string,
    photo: string, // texte qui contient le nom et le chemin du fichier image qui vient d'être uploadé
    profilActif: boolean,
    editModeAssistant: boolean,
    committees: Committee[],
    roles: string[]
}

const initialState: addAssistantState = {
    profil: 'assistant',
    nom: '',
    prenom: '',
    email: '',
    authDiffEmail: false,
    civilite: '',
    photo: '',
    profilActif: true,
    editModeAssistant: false,
    committees: [],
    roles: []
}

export const addAssistantSlice = createSlice({
    name: 'addAssistant',
    initialState,
    reducers: {
        setProfilAssist: (state, action) => {
            state.profil = action.payload.profil
        },
        setNom: (state, action) => {
            state.nom = action.payload
        },
        setPrenom: (state, action) => {
            state.prenom = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setProfilActif: (state, action) => {
            state.profilActif = action.payload
        },
        setCivilite: (state, action) => {
            state.civilite = action.payload
        },
        setPhoto: (state, action) => {
            state.photo = action.payload
        },
        setCommittees: (state, action) => {
            state.committees = action.payload
        },
        resetAllFields: (state) => {
            state.profil = initialState.profil
            state.nom = initialState.nom
            state.prenom = initialState.prenom
            state.email = initialState.email
            state.profilActif = initialState.profilActif
            state.civilite = initialState.civilite
            state.photo = initialState.photo
            state.committees = initialState.committees
            state.roles = initialState.roles
        },
        setEditModeAssistant: (state, action) => {
            state.editModeAssistant = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        }
    }
})

export const {
    setNom,
    setPrenom,
    setEmail,
    setCivilite,
    setPhoto,
    setProfilActif,
    setProfilAssist,
    resetAllFields,
    setEditModeAssistant,
    setCommittees,
    setRoles
    } = addAssistantSlice.actions

export default addAssistantSlice.reducer
