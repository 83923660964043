import React, {ChangeEvent, Fragment, useEffect, useState} from "react"
import {Document} from "../../api/DocumentAPI"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {DocumentUser} from "../../api/DocumentUserAPI"
import {ROLES_ADMIN, ROLES_MANAGE_MEETING} from "../../api/UserAPI"
import {Col, Form} from "react-bootstrap"
import {hasRole} from "../../utils/UserTools"
import {Meeting} from "../../api/MeetingAPI"
import {setItem, setShowConfirm} from "../../redux/DocumentUser/DocumentUserSlice"
import {getFormattedLocalisedDate} from "../../utils/Date"
import type {RootState} from "../../redux/Store"

// Dans le cas d'un procès-verbal, si l'utilisateur doit le signer, on lui affiche une case à cocher.
// Si l'utilisateur l'a signé, on lui affiche la date de sa signature
// Sinon on n'affiche rien

// Pour le PV,
// Si l'utilisateur peut gérer la séance (webmaster, assistante), on lui affiche le statut des signatures du PV
// Un PV est approuvé lorsque les utilisateurs devant le signer l'ont approuvé
// Sinon, le statut est "En cours d'approbation"

export default function ItemDocumentUser(props: { doc: Document, meeting: Meeting }) {
    const dispatch = useAppDispatch()
    const roles = useAppSelector((state) => state.app.roles)
    const userId = useAppSelector((state: RootState) => state.app.userId)
    const documentUsers = useAppSelector((state) => state.documentUser.items)
    const [documentUser, setDocumentUser] = useState<DocumentUser | undefined>()

    useEffect(() => {
        setDocumentUser(documentUsers.find((item) => userId !== null && item.user?.id === userId && item.document?.id === props.doc.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUsers])

    if (hasRole(ROLES_ADMIN, roles) || hasRole(ROLES_MANAGE_MEETING, roles)) {
        // Voir qui a signé le PV
        if (props.doc.documentUsers) {
            const approuved: string[] = []
            const notYetApprouved: string[] = []
            props.doc.documentUsers.forEach((item) => {
                if (item.validate) {
                    approuved.push(`${item.user.firstname} ${item.user.lastname}`)
                } else {
                    notYetApprouved.push(`${item.user.firstname} ${item.user.lastname}`)
                }
            })

            return <Col xl={6}>
                {
                    approuved.length > 0 &&
                    <p className="mb-0">{approuved.length > 1 ? "Ont" : "A"} approuvé : {approuved.join(", ")}</p>
                }
                {
                    notYetApprouved.length > 0 &&
                    <p className="mb-0">N'{notYetApprouved.length > 1 ? "ont" : "a"} pas encore approuvé
                        : {notYetApprouved.join(", ")}</p>
                }
            </Col>
        }
        return <Fragment/>
    }

    // Si l'utilisateur valide le document, on envoie l'information au serveur
    const validateDoc = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        dispatch(setItem(documentUser))
        dispatch(setShowConfirm(true))
    }

    if (documentUser) {
        if (documentUser.validate) {
            return <Col xl={6}>
                <span
                    className="text-success">Approuvé le {documentUser.validatedAt ? getFormattedLocalisedDate(new Date(documentUser.validatedAt), "dd/MM/yyyy") : ""}</span>
            </Col>
        }

        return <Col xl={6}>
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                    type="checkbox"
                    label="J'approuve"
                    id={`documentUser-${documentUser.id}`}
                    value={documentUser.id}
                    onChange={validateDoc}
                />
            </Form.Group>
        </Col>
    }

    return <Fragment/>
}


