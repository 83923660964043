import {createSlice} from "@reduxjs/toolkit"
import {getUserByToken, updateUser} from "../../api/UserAPI"
import {fetchUserMandates, Mandate} from "../../api/MandateAPI"
import {Committee} from "../../api/CommitteeAPI"
import {authLogout} from "../../services/auth"

export interface userState {
    user: {
        token: string,
        username: string,
        id?: number | null,
        dpoValidated?: boolean,
        cguValidated?: boolean,
        cguValidatedAt?: string,
        dpoValidatedAt?: string,
    },
    userId: number,
    userCivility: boolean | number,
    firstname: string,
    lastname: string,
    passwordSet: boolean,
    connected: boolean,
    lastLogin: string | null,
    state: string,
    initiales: string,
    roles: Array<string>,
    mandates: Mandate[],
    committees: Committee[],
    page: string,
    checkUserPending: boolean,
    forgottenPassword: boolean,
    loading: boolean,
    adminToken?: string,
    adminUsername?: string
}

const initialState: userState = {
    user: {
        token: "",
        username: "",
        id: null,
        dpoValidated: false,
        cguValidated: false
    },
    userId: 0,
    userCivility: true,
    firstname: "",
    lastname: "",
    passwordSet: false,
    connected: false,
    lastLogin: null,
    state: "loading",
    initiales: "",
    roles: ["ROLE_USER"],
    mandates: [],
    committees: [],
    page: "",
    checkUserPending: false,
    forgottenPassword: false,
    loading: true
}

export const userSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        connectUser: (state, action) => {
            state.user = action.payload
            state.connected = true
            state.state = "loading"
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUsername: (state, action) => {
            sessionStorage.setItem("username_user_instance", action.payload)
        },
        setAppState: (state, action) => {
            state.state = action.payload.state
        },
        setStateToLogin: (state) => {
            sessionStorage.removeItem("username_user_instance")
            sessionStorage.removeItem("token_user_instance")
            sessionStorage.removeItem("id_user")
            state.state = "login"
        },
        resetInitiales: (state) => {
            state.initiales = ""
        },
        setPasswordSet: (state, action) => {
            state.passwordSet = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setForgottenPassword: (state, action) => {
            state.forgottenPassword = action.payload
        },
        setUserId: (state, action) => {
            state.userId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authLogout.fulfilled, (state, action) => {
                state.state = "login"
                state.connected = false
                state.user.token = ""
            })
            .addCase(getUserByToken.fulfilled, (state, action) => {
                state.checkUserPending = false
                const user = action.payload.user
                if (user) {
                    state.roles = user.roles
                    state.user.token = action.payload.token
                    state.userId = Number(user.id)
                    state.userCivility = user.civility
                    state.firstname = user.firstname
                    state.lastname = user.lastname
                    state.state = "app"
                    state.lastLogin = user.lastLogin
                    if (typeof user.passwordSet === "boolean") {
                        state.passwordSet = user.passwordSet
                    }
                    state.connected = true
                    if (user.lastname !== null && user.firstname !== null) {
                        state.initiales = user.lastname[0].toUpperCase() + user.firstname[0].toUpperCase()
                    }
                    state.mandates = []
                    state.committees = user.committees
                    state.user.id = action.payload.user.id
                    state.user.dpoValidated = user.dpoValidated
                    state.user.cguValidated = user.cguValidated
                    state.user.dpoValidatedAt = user.dpoValidatedAt
                    state.user.cguValidatedAt = user.cguValidatedAt
                }
            })
            .addCase(getUserByToken.pending, (state) => {
                state.checkUserPending = true
            })
            .addCase(getUserByToken.rejected, (state) => {
                state.checkUserPending = false
                if (window.location.pathname.indexOf("/set-password") === -1) { // Si on ne se trouve pas dans la page de réinitialisation du password
                    state.state = "connexion"
                }

                sessionStorage.removeItem("token_user_instance")
                sessionStorage.removeItem("username_user_instance")
                sessionStorage.removeItem("id_user")
                state.connected = false
            })
            .addCase(fetchUserMandates.fulfilled, (state, action) => {
                state.mandates = action.payload
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.user.dpoValidated = action.payload.dpoValidated
                state.user.cguValidated = action.payload.cguValidated
                state.user.dpoValidatedAt = action.payload.dpoValidatedAt
                state.user.cguValidatedAt = action.payload.cguValidatedAt
            })
    },
})

export const {
    connectUser,
    setUsername,
    setForgottenPassword,
    setAppState,
    setStateToLogin,
    resetInitiales,
    setPasswordSet,
    setPage,
    setUserId
} = userSlice.actions

export default userSlice.reducer
