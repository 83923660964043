import {ExpectedDocument} from "../../api/ExpectedDocumentAPI";
import {TYPE_CAT_CONVOCATION, TYPE_CAT_PV} from "../../api/DocumentAPI";
import {useAppDispatch} from "../../redux/Hooks";
import {
    addExpectedDocument
} from "../../redux/Committee/CommitteeFormSlice";
import ItemExpectedDocumentForm from "./ItemExpectedDocumentForm";

export default function ItemExpectedDocument(props: {doc?: ExpectedDocument, type: number, docIndex: number}) {

    switch (props.type) {
        case TYPE_CAT_CONVOCATION:
            return <div className="mt-4">
                <ItemExpectedDocumentTitle doc={props.doc} />
                <ItemExpectedDocumentForm key={0} index={0} doc={props.doc} />
            </div>
        case TYPE_CAT_PV:
            return <div className="mt-4">
                <ItemExpectedDocumentTitle doc={props.doc} />
                <ItemExpectedDocumentForm key={1} index={1} doc={props.doc} />
            </div>
        default:
            if (props.docIndex <= 2) {
                return <div className="mt-4">
                    <ItemExpectedDocumentTitle doc={props.doc} index={props.docIndex} />
                    <div className="expected-document">
                        <ItemExpectedDocumentForm index={props.docIndex} doc={props.doc} />
                    </div>
                </div>
            }
            return <div className="mt-4 expected-document">
                <ItemExpectedDocumentForm index={props.docIndex} doc={props.doc} />
            </div>
    }
}

export function ItemExpectedDocumentTitle(props: {doc?: ExpectedDocument, type?: string, index?: number}) {
    let title = ""
    let from = 'doc'

    if (props.doc) {
        if (props.index && props.index >= 2 && props.doc.category && props.doc.category.title) {
            from = 'category'
        }
        if ((props.index === undefined || props.index < 2) && (props.doc.title === undefined || props.doc.title === "")) {
            from = 'category'
        }
    }

    if (from === 'doc' && props.doc && props.doc.title) {
        title = props.doc.title
    } else if (from === 'category' && props.doc && props.doc.category && props.doc.category.title) {
        title = props.doc.category.title
    }

    return <h4 className="mb-3 fw-medium fs-md text-main-gray">{title}</h4>
}

export function ItemExpectedDocumentAddButton() {
    const dispatch = useAppDispatch();

    function addElement() {
        dispatch(addExpectedDocument())
    }

    return <div className="mt-4">
        <button type="button" className="btn-less link text-dark text-hover-primary" onClick={() => addElement()}>
            <i className="mai me-2 fs-md text-primary">add</i>Ajouter un document
        </button>
    </div>
}
