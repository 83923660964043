import {Link} from "react-router-dom";
import {Committee} from "../../api/CommitteeAPI";

export default function ItemDocumentsByCommitteeBlock(props: { committee: Committee, link: string }) {
    return <div className="col-12">
        <div className="block mb-0">
            <div className="row row-sm row-sm-h justify-content-between align-items-center">
                <div className="col-lg-auto">
                    <div className="row row-sm-row-sm-h">
                        <div className="col-auto"><i className="mai text-tertiary fs-lg">folder_open</i></div>
                        <div className="col">
                            <h2 className="mb-0 fs-lg fw-medium">
                                <Link to={props.link}>
                                    {props.committee.title}
                                </Link>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="row row-sm row-sm-h align-items-center">
                        <div className="col-auto">
                            <div className="row row-sm row-sm-h align-items-center" key={`com-${props.committee.id}`}>
                                <Link to={props.link} className="btn btn-sm btn-light">Accéder aux documents <i className="mai ms-2">arrow_forward</i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
