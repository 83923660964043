import {createAsyncThunk} from "@reduxjs/toolkit"
import {Options, toQuery} from "../utils/Options"
import {axiosInstance} from "../utils/http-common"
import {FULL_LOG_API} from "../utils/Data"

const AxiosInstance = axiosInstance()

export interface Log {
    id?: number;
    entityId: number;
    entityType: string;
    userDetails: any;
    createdDate?: Date;
}

export const fetchLogs = createAsyncThunk<Log[], { options?: Options }>(
    "logs/fetchAll",
    async (contentData) => {
        const queryOptions = contentData.options
        const {data} = (await AxiosInstance.get(
            `${FULL_LOG_API}` + toQuery(queryOptions)
        ))

        return data["hydra:member"]
    }
)

export const createLog = createAsyncThunk<Log, { fields: object }>(
    "logs/create",
    async (logData) => {
        const {data} = (await AxiosInstance.post(
            `${FULL_LOG_API}`,
            JSON.stringify(logData.fields),
        ))
        return data
    }
)