import {createAsyncThunk} from "@reduxjs/toolkit"
import {FULL_MEETING_API, FULL_MEETING_CSV_API} from "../utils/Data"
import {axiosInstance} from "../utils/http-common"
import {Options, toQuery} from "../utils/Options"
import {ValidationErrors} from "../utils/ValidationErrors"
import {MeetingUser} from "./MeetingUserAPI"
import {Document} from "./DocumentAPI"

const AxiosInstance = axiosInstance()

export const MEETING_TYPE_MAIN = "main"
export const MEETING_TYPE_ALERT = "alert"
export const MEETING_TYPE_ALERT_USER = "alert-user"
export const MEETING_TYPE_ALERT_LABEL = "Limite de contribution"
export const MEETING_TYPE_ALERT_USER_LABEL = "Limite d'envoi de la confirmation"

export interface MeetingOccurrence {
    id: number,
    date?: Date,
    type: string
}

interface Committee {
    id: number
    title?: string
    color?: string
    documentForAll: boolean
    canViewDocs: boolean
    slug: string
}

export interface Meeting {
    id: number
    place: string
    videoLink: string
    title: string
    published: boolean
    special?: boolean
    committee: Committee
    meetingOccurrences: MeetingOccurrence[]
    meetingUsers: MeetingUser[]
    documents?: Document[]
    lunchAvailable?: boolean
    createdDate?: string
    updatedDate?: string
}

export const fetchMeetings = createAsyncThunk<Meeting[], { options?: Options }>(
    "meetings/fetchAll",
    async (meetingData) => {
        const {data} = (await AxiosInstance.get(
            `${FULL_MEETING_API}` + toQuery(meetingData.options)
        ))

        return data["hydra:member"]
    }
)

export const fetchSmsMeetings = createAsyncThunk<Meeting[], { options?: Options, sorting?: string }>(
    "meetings/smsFetchAll",
    async (meetingData) => {
        let sorting = ""
        if (meetingData.sorting) {
            sorting += meetingData.options ? "&" : "?"
            sorting += "order[title]=" + meetingData.sorting
        }
        const {data} = (await AxiosInstance.get(
            `${FULL_MEETING_API}` + toQuery(meetingData.options) + sorting,
        ))

        return data["hydra:member"]
    }
)

export const createMeeting = createAsyncThunk<Meeting, { fields: object }>(
    "meetings/create",
    async (meetingData) => {
        const {data} = (await AxiosInstance.post(
            `${FULL_MEETING_API}`,
            JSON.stringify(meetingData.fields),
        ))
        return data
    }
)

export const putMeeting = createAsyncThunk<Meeting, {
    meetingId: number,
    fields: object,
    email: boolean,
    subjects: string[]
}>(
    "meetings/put",
    async (meetingData) => {
        const {data} = (await AxiosInstance.put(
            `${FULL_MEETING_API}/${meetingData.meetingId}?email=${meetingData.email}&subjects=${meetingData.subjects}`,
            JSON.stringify(meetingData.fields)
        ))
        return data
    }
)

export const deleteMeeting = createAsyncThunk<{}, { id?: number, email: boolean }, {
    rejectValue: ValidationErrors
}>(
    "meeting/delete",
    async (meetingData) => {

        (await AxiosInstance.delete(`${FULL_MEETING_API}/${meetingData.id}?email=${meetingData.email}`))

        return meetingData.id

    }
)

export function downloadAttendanceFile(id: number): Promise<BlobPart> {
    return AxiosInstance.get(
        `${FULL_MEETING_CSV_API}/${id}`,
        {
            responseType: "blob"
        }
    ).then(response => response.data)
}
