import {Meeting} from "../../../api/MeetingAPI"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import React, {useEffect, useState} from "react"
import {ChoiceItem} from "../../../utils/ChoiceList"
import {fetchMandatesWithOption, fetchUserMandates} from "../../../api/MandateAPI"
import {SYNDICATES} from "../../../constants/UserData"
import {Options} from "../../../utils/Options"
import {setReplacedBy} from "../../../redux/Meeting/MeetingSlice"
import {ATTENDING_NO} from "../../../api/MeetingUserAPI"
import {Col, Row} from "react-bootstrap"
import Select from "react-select"
import {STATUSES} from "../../../constants/MandateData"
import {getToday} from "../../../utils/Date"

export function RenderedReplacedBy(props: {
    civility: boolean,
    typeAttendance: string,
    item: Meeting,
    userId: number
}) {
    const dispatch = useAppDispatch()
    const userMandates = useAppSelector((state) => state.app.mandates)
    const mandates = useAppSelector((state) => state.meeting.committeeMandates)
    const meetingUsers = useAppSelector((state: RootState) => state.meeting.meetingUsers)
    const mandate = userMandates.find((ma) => ma.committee.id === props.item.committee.id)
    const replacedBy = useAppSelector((state) => state.meeting.replacedBy)

    const [options, setOptions] = useState<ChoiceItem[]>([])
    const [currentSyndicateLabel, setCurrentSyndicateLabel] = useState<string | undefined>()

    useEffect(() => {
        dispatch(fetchUserMandates({options: {"user.id": props.userId}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (mandate?.user.college !== undefined) {
            const syndicate = SYNDICATES[mandate.user.college].find((item) => item.key === mandate?.user?.syndicate)
            syndicate && setCurrentSyndicateLabel(syndicate.label)
        }

        if (props.item !== undefined && mandate) {
            const optionMandates: Options = {
                "dateStart[before]": getToday(),
                "dateEnd[after]": getToday(),
                "period.user.active": true
            }
            if (mandate.committee?.onlyReplacedByCommitteeMembers) {
                optionMandates["committee.id"] = mandate.committee.id
            } else {
                optionMandates["user.college"] = mandate.user?.college
                optionMandates["user.syndicate"] = mandate.user?.syndicate
            }

            dispatch(fetchMandatesWithOption({context: "meeting", options: optionMandates}))
        }
    }, [mandate])

    useEffect(() => {
        const meetingUser = meetingUsers.find((item) => item.user.id === props.userId)
        dispatch(setReplacedBy(meetingUser?.replacedBy?.id))
        let tempOptions: ChoiceItem[] = []
        mandates.forEach((m) => {
            const mUser = meetingUsers.find((mu) => mu.user.id === m.user.id)
            if (m.user.enabled && (!mUser || mUser.attending !== ATTENDING_NO) && m.user.id !== mandate?.user.id) {
                let syndicate: string | undefined = currentSyndicateLabel
                if (mandate?.committee?.onlyReplacedByCommitteeMembers && m.user.college) {
                    syndicate = SYNDICATES[m.user.college].find((item) => item.key === m?.user?.syndicate)?.label
                }
                const status = m.committee.id === mandate?.committee.id ? STATUSES.find((item) => item.key === m.status) : undefined
                tempOptions.push({
                    key: m.user.id,
                    label: m.user.firstname + " " + m.user.lastname + " (" + syndicate + ")" + (status ? " - " + status.label : "")
                })
                if (m.committee.id !== mandate?.committee.id) {
                    tempOptions = tempOptions.filter((item) => item.key !== m.user.id)
                }
                if (!tempOptions.some((item) => item.key === m.user.id)) {
                    const status = m.committee.id === mandate?.committee.id ? STATUSES.find((item) => item.key === m.status) : undefined
                    tempOptions.push({
                        key: m.user.id,
                        label: m.user.firstname + " " + m.user.lastname + " (" + syndicate + ")" + (status ? " - " + status.label : "")
                    })
                }
            }
        })
        setOptions(tempOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingUsers, mandates])

    if (props.typeAttendance !== ATTENDING_NO || mandate === undefined) {
        return <></>
    }

    let e = ""
    if (!props.civility) {
        e = "e"
    }

    return <Row>
        <Col>
            <h3 className="mt-5 mb-3">Je souhaite être représenté{e} par</h3>
        </Col>
        <Col sm={12}>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Choisir ..."
                options={options}
                value={options.find((item) => item.key === replacedBy)}
                getOptionLabel={(item: ChoiceItem) => item.label}
                getOptionValue={(item: ChoiceItem) => item.key.toString()}
                onChange={(e) => e && dispatch(setReplacedBy(e.key))}
            />
        </Col>
    </Row>
}
