import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import React, {Fragment, SyntheticEvent, useEffect} from "react"
import {Modal, Form, Button, Row, Col} from "react-bootstrap"
import {setModalAdminShow, setModalDeleteShow, setModalUpdateShow} from "../../redux/Meeting/MeetingSlice"
import {
    resetData,
    setDocuments, setLunchAvailable,
    setMeetingDate,
    setMeetingTime,
    setPlace,
    setPublished, setSpecial,
    setVideoLink
} from "../../redux/Meeting/MeetingFormSlice"
import {createMeeting, Meeting} from "../../api/MeetingAPI"
import DatePicker from "react-datepicker"
import {Documents} from "./Document/Documents"
import {setCommitteeSelected} from "../../redux/Committee/CommitteeSlice"
import {fetchExpectedDocuments} from "../../api/ExpectedDocumentAPI"
import {COMMITTEE_API, DOCUMENT_API} from "../../utils/Data"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {fetchMeetingDocuments, TYPE_MEETING, updateDocument} from "../../api/DocumentAPI"
import {resetPvUsers} from "../../redux/DocumentUser/DocumentUserSlice"
import {hasRole} from "../../utils/UserTools"
import {ROLES_ASSIST_CODIR} from "../../api/UserAPI"
import {Options} from "../../utils/Options"
import {getFormattedLocalisedDate} from "../../utils/Date"

export function AddUpdateMeetingLightbox() {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state: RootState) => state.meeting.modalAdminShow)
    const type = useAppSelector((state: RootState) => state.meeting.modalAdminType)
    const published = useAppSelector((state: RootState) => state.meetingForm.published)
    const special = useAppSelector((state: RootState) => state.meetingForm.special)
    const meeting = useAppSelector((state) => state.meetingForm.selectedMeeting)
    const committees = useAppSelector((state) => state.committee.items)
    const committee = useAppSelector((state) => state.committee.item)
    const meetingDate = useAppSelector((state) => state.meetingForm.meetingDate)
    const meetingTime = useAppSelector((state) => state.meetingForm.meetingTime)
    const place = useAppSelector((state) => state.meetingForm.place)
    const videoLink = useAppSelector((state) => state.meetingForm.videoLink)
    const expectedDocuments = useAppSelector((state: RootState) => state.meetingForm.expectedDocuments)
    const documents = useAppSelector((state) => state.meetingForm.documents)
    const lunchAvailable = useAppSelector((state: RootState) => state.meetingForm.lunchAvailable)
    const sendEmailPv = useAppSelector((state) => state.meeting.sendEmailPv)
    const userId = useAppSelector((state) => state.app.userId)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    useEffect(() => {
        if (!show) return

        if (meeting !== undefined) {
            dispatch(setCommitteeSelected(meeting.committee))
            dispatch(setMeetingDate(getFormattedLocalisedDate(new Date(meeting.title), "yyyy-MM-dd")))
            dispatch(setMeetingTime(getFormattedLocalisedDate(new Date(meeting.title), "HH:mm")))

            let options: Options = {
                type: TYPE_MEETING,
                "meeting.id": meeting.id,
                "pagination": false
            }

            dispatch(fetchMeetingDocuments({options: options}))
        } else {
            dispatch(setCommitteeSelected(
                committees.find((item, index) => index === 0)
            ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, meeting])

    useEffect(() => {
        let optionCommittees: Options = {}
        if (hasRole(ROLES_ASSIST_CODIR, roles)) {
            optionCommittees["users.id"] = userId
        }
        if (committee !== undefined) {
            dispatch(fetchExpectedDocuments({options: {"committee.id": committee.id}}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [committee])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(displayMessage({text: "Modification de la seance en cours"}))
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        }

        if (meeting !== undefined) {
            dispatch(setModalUpdateShow(true))
        } else if (committee !== undefined) {
            const iriDocuments: string[] = []

            // Liste des documents à associer au meeting
            documents.forEach((doc, index) => {
                if (doc.filePath !== undefined && doc.filePath !== "") {
                    let title = doc.title
                    if (title === "" && expectedDocuments[index]) {
                        title = expectedDocuments[index].title
                    }
                    let date = new Date()
                    if (doc.publishedDate) {
                        date = new Date(doc.publishedDate)
                    }
                    date.setHours(9, 0, 0)
                    const fields = {
                        title: title,
                        publishedDate: date,
                        type: TYPE_MEETING,
                        pv: sendEmailPv
                    }
                    dispatch(updateDocument({
                        id: doc.id,
                        fields: fields
                    }))
                    iriDocuments.push(DOCUMENT_API + "/" + doc.id)
                }
            })

            const data = {
                title: (meetingDate ? getFormattedLocalisedDate(new Date(meetingDate), "yyyy-MM-dd") : "") + " " + meetingTime,
                published: published,
                lunchAvailable: lunchAvailable,
                special: special,
                committee: `${COMMITTEE_API}/${committee.id}`,
                place: place,
                videoLink: videoLink,
                documents: iriDocuments
            }

            // On met à jour la séance
            dispatch(displayMessage({text: "Modification de la seance en cours"}))

            let status = "error"
            let text = "Problème dans la création de l'instance."
            let response = await dispatch(createMeeting({fields: data}))
            if (response.meta.requestStatus === "fulfilled") {
                text = "Séance créée."
                status = "success"
            }
            dispatch(displayMessage({text: text, status: status}))
            dispatch(setDocuments([]))
        }
    }

    function closeModal() {
        dispatch(setModalAdminShow(false))
        dispatch(resetPvUsers())
        dispatch(resetData())
        dispatch(setCommitteeSelected(undefined))
        dispatch(setDocuments([]))
    }

    function chooseCommittee(value: string) {
        dispatch(setCommitteeSelected(
            committees.find((item) => item.id === parseInt(value))
        ))
    }

    function deleteMeeting(meeting?: Meeting) {
        if (meeting !== undefined) {
            dispatch(setModalDeleteShow(true))
        }
    }

    // On sélectionne l'instance par défaut
    let defaultCommitteeValue: string = ""
    if (committee !== undefined) {
        defaultCommitteeValue = committee.id.toString()
    }
    let committeeSelect: JSX.Element[] = []
    committees.forEach((committee) => {
        if (defaultCommitteeValue === "") {
            defaultCommitteeValue = committee.id.toString()
        }
        committeeSelect.push(
            <option key={committee.id} value={committee.id}>{committee.title}</option>
        )
    })

    // On initialise l'heure
    let selectedTime = new Date()
    selectedTime.setHours(9, 0)
    if (meetingTime) {
        const explodedTimes = meetingTime.split(":")
        selectedTime.setHours(parseInt(explodedTimes[0]), parseInt(explodedTimes[1]))
    }

    // On ajoute le bouton supprimer si on est en mode édition
    let btnDelete = <Fragment/>
    if (type === "edition") {
        btnDelete = <Button variant="light" type="button" onClick={() => deleteMeeting(meeting)}>
            <i className="mai fs-md me-2">delete</i> Supprimer
        </Button>
    }

    return (
        <Modal className="modal-form" show={show} scrollable onHide={() => closeModal()}>
            <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                handleSubmit(e)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h3">
                        {(type === "edition") ? "Modification d'une séance" : "Création d'une séance"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <Form.Group className="mb-4">
                            <Form.Check
                                type="switch"
                                name="input-documentForAll"
                                id="input-documentForAll"
                                label="Publiée"
                                checked={published}
                                onChange={() => {
                                    dispatch(setPublished(!published))
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Check
                                type="switch"
                                name="input-special"
                                id="input-special"
                                label="Séance extraordinaire"
                                checked={special}
                                onChange={() => {
                                    dispatch(setSpecial(!special))
                                }}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="select-committee">Instance *</Form.Label>
                        <Form.Select
                            id="select-committee"
                            defaultValue={defaultCommitteeValue}
                            onChange={(e) => chooseCommittee(e.target.value)}
                            required={true}
                        >
                            {committeeSelect}
                        </Form.Select>
                    </Form.Group>
                    <Row className="mb-4 align-items-center">
                        <Col lg={3}>
                            <Form.Label htmlFor="input-date">Date *</Form.Label>
                            <DatePicker
                                locale="fr"
                                id="input-date"
                                dateFormat="dd-MM-yyyy"
                                selected={meetingDate ? new Date(meetingDate) : new Date()}
                                required={true}
                                name="input-date"
                                className="form-control"
                                onChange={(date) => dispatch(setMeetingDate(date ? getFormattedLocalisedDate(date, "yyyy-MM-dd") : null))}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Label htmlFor="input-time">Heure de convocation</Form.Label>
                            <DatePicker
                                locale="fr"
                                id="input-time"
                                selected={selectedTime}
                                onChange={(date) => dispatch(setMeetingTime(date ? getFormattedLocalisedDate(date, "HH:mm") : null))}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                className="form-control"
                                timeCaption="Heure de convocation"
                                dateFormat="HH:mm"
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Label>&nbsp;</Form.Label>
                            <Form.Check
                                type="switch"
                                name="input-lunch"
                                id="input-lunch"
                                label="Proposer le repas"
                                checked={lunchAvailable}
                                onChange={() => {
                                    dispatch(setLunchAvailable(!lunchAvailable))
                                }}
                            />
                        </Col>
                    </Row>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-place">Lieu (sur le site)</Form.Label>
                        <Form.Control
                            as="textarea"
                            defaultValue={place}
                            name="input-place"
                            id="input-place"
                            onChange={(e) => dispatch(setPlace(e.target.value))}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-visio">Lien (visio)</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={videoLink}
                            name="input-visio"
                            id="input-visio"
                            onChange={(e) => dispatch(setVideoLink(e.target.value))}
                        />
                    </Form.Group>
                    <div className="mt-5">
                        <h3 className="mb-3 text-primary">Documents attendus</h3>
                        <Documents/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {btnDelete}
                    <Button variant="light" type="submit">
                        <i className="mai fs-md me-2">save</i>
                        {(type === "edition") ? "Modifier" : "Valider"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
