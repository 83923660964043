import {useEffect, ReactNode} from "react"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {setPage} from "../../redux/User/UserSlice"
import {hasRole} from "../../utils/UserTools"
import LightboxAddActuality from "../../components/Actuality/LightboxAddActuality"
import type {RootState} from "../../redux/Store"
import {
    setActualities,
    setShowModalAddActuality,
    setEditMode,
    setActualPage,
    setViewOnlyPublished
} from "../../redux/Content/ContentSlice"
import {fetchContents, TYPE_CONTENT_NEWS} from "../../api/ContentAPI"
import PopinDeleteActualityConfirm from "../../components/Actuality/PopinDeleteActualityConfirm"
import ItemActuality from "../../components/Actuality/ItemActuality"
import PageHeader from "../../components/PageHeader"
import LoadingSpinner from "../../components/LoadingSpinner"
import {Content} from "../../api/ContentAPI"
import GenerateLinksPagination from "../../components/GenerateLinksPagination"
import {Form} from "react-bootstrap"
import {Options} from "../../utils/Options"

export default function Actuality() {

    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const editMode = useAppSelector((state: RootState) => state.actuality.editMode)
    const actualities = useAppSelector((state: RootState) => state.actuality.contents)
    const stateListActuality = useAppSelector((state: RootState) => state.actuality.stateListActuality)
    const nbPerPage = useAppSelector((state: RootState) => state.actuality.nbPerPage)
    const actualPage = useAppSelector((state: RootState) => state.actuality.actualPage)
    const nbTotalActualities = useAppSelector((state: RootState) => state.actuality.nbTotalActualities)
    const viewOnlyPublished = useAppSelector((state: RootState) => state.actuality.viewOnlyPublished)

    useEffect(() => {
        dispatch(setPage("actuality"))

        dispatch(setActualities([]))
        setActualPageLink(1)
        fetchContentPage(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewOnlyPublished])

    const setActualPageLink = (page: number) => {
        dispatch(setActualPage(page))
    }
    const fetchContentPage = (page: number) => {
        let options: Options = {type: TYPE_CONTENT_NEWS, page: page}
        if (viewOnlyPublished) {
            options.published = viewOnlyPublished
        }
        dispatch(fetchContents({options: options}))
    }
    let nbPages = 1
    let actualitiesHtml: ReactNode = <></>

    if (actualities.length > 0) {
        let a = [...actualities]
        a.sort(function (a: Content, b: Content) {
            if (a.createdDate !== undefined && b.createdDate !== undefined) {
                return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            }
            return 0
        })
        actualitiesHtml = a.map((actuality: Content, index: number) => {
            return <div key={"actuality-" + index} className="col-lg-6 col-xl-4">
                <ItemActuality actuality={actuality} homepage={false}/>
            </div>
        })

        nbPages = Math.ceil(nbTotalActualities / nbPerPage)
    }

    let contentTable: ReactNode = <></>
    if (stateListActuality === "loading") {
        contentTable = <LoadingSpinner/>
    } else {
        contentTable = <div className="row row-h">{actualitiesHtml}</div>
    }

    return (
        <>
            <PageHeader title="Actualités" breadcrumb>
                {(() => {
                    if (hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)) {
                        return <div className="row row-sm row-sm-h align-items-center">
                            <div className="col-auto">
                                <Form.Check type="switch" name="input-documentForAll" className="text-white"
                                            label="Uniquement les publiées" checked={viewOnlyPublished}
                                            onChange={(e) => {
                                                dispatch(setViewOnlyPublished(!viewOnlyPublished))
                                            }}/>
                            </div>
                            <div className="col-auto">
                                <a href="/" className="btn btn-lg btn-tertiary btn-icon rounded-circle"
                                   onClick={(e) => {
                                       e.preventDefault()
                                       dispatch(setShowModalAddActuality(true))
                                       dispatch(setEditMode(false))
                                   }}><i className="mai fs-lg">add</i>
                                </a>
                            </div>
                        </div>
                    }
                })()}
            </PageHeader>
            <div className="container">
                {contentTable}
                {(actualities.length > 0) &&
                    <GenerateLinksPagination nbPages={nbPages} actualPage={actualPage} setActualPage={setActualPageLink}
                                             getData={fetchContentPage}/>
                }
            </div>

            <LightboxAddActuality editMode={editMode}/>
            <PopinDeleteActualityConfirm/>
        </>

    )
}
