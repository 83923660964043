import {useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {fetchCommittees, Committee} from "../../api/CommitteeAPI"
import type {RootState} from "../../redux/Store"
import {hasRole} from "../../utils/UserTools"
import {
    setFilterName,
    setFilterInstance,
    setFilterStatus,
    setFilterRole,
    setFilterProfil, setFilterCollege, setFilterSyndicate
} from "../../redux/User/TrombinoscopeSlice"
import {Form} from "react-bootstrap"
import {IDataFetchUsers} from "../../utils/CommonInterfaces"
import {data} from "../../utils/Data"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"

interface FormFilterUserProps {
    resetFields: () => void,
    fetchUsers: (data: IDataFetchUsers) => void
}

export default function FormFilterUser(props: FormFilterUserProps) {

    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const committees = useAppSelector((state: RootState) => state.tromb.committees)

    const filterName = useAppSelector((state: RootState) => state.tromb.filterName)
    const filterInstance = useAppSelector((state: RootState) => state.tromb.filterInstance)
    const filterCollege = useAppSelector((state: RootState) => state.tromb.filterCollege)
    const filterSyndicate = useAppSelector((state: RootState) => state.tromb.filterSyndicate)
    const filterStatus = useAppSelector((state: RootState) => state.tromb.filterStatus)
    const filterRole = useAppSelector((state: RootState) => state.tromb.filterRole)
    const filterProfil = useAppSelector((state: RootState) => state.tromb.filterProfil)

    const valueReset = filterName + filterInstance + filterStatus + filterRole + filterProfil + filterCollege + filterSyndicate
    useEffect(() => {
        dispatch(fetchCommittees({}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="row row-sm align-items-end mt-5 mb-n4">
            <div className="col-lg">
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Rechercher un membre</Form.Label>
                    <div className="input-group">
                        <Form.Control type="text" placeholder="Nom, Prénom..." aria-label="Search" value={filterName}
                                      onChange={(e) => {
                                          dispatch(setFilterName(e.target.value))
                                          props.fetchUsers({
                                              newPage: 1,
                                              filterName: e.target.value
                                          })
                                      }}/>
                        <div className="input-group-text">
                            <i className="mai">search</i>
                        </div>
                    </div>
                </Form.Group>
            </div>
            <div className="col-lg">
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Instance</Form.Label>
                    <Form.Select value={filterInstance} id="select-committee" onChange={(e) => {
                        let value = e.target.value === "Toutes" ? "" : e.target.value
                        dispatch(setFilterInstance(value))
                        props.fetchUsers({
                            newPage: 1,
                            filterInstance: value
                        })
                    }}>
                        <option key="0" defaultValue="---">Toutes</option>
                        {committees.map((commitee: Committee, index: number) => {
                            return <option key={index} value={commitee.id}>{commitee.title}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="col-lg">
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Collège</Form.Label>
                    <Form.Select value={filterCollege} id="select-college" onChange={(e) => {
                        let value = e.target.value === "Tous" ? "" : e.target.value
                        dispatch(setFilterCollege(value))
                        dispatch(setFilterSyndicate(""))
                        props.fetchUsers({
                            newPage: 1,
                            filterCollege: value,
                            filterSyndicate: ""
                        })
                    }}>
                        <option key="0" defaultValue="">Tous</option>
                        {COLLEGES.map((college: { key: number, label: string }, index: number) => {
                            return <option key={index} value={college.key}>{college.label}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="col-lg">
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Organisation syndicale</Form.Label>
                    <Form.Select value={`${filterCollege}-${filterSyndicate}`} id="select-syndicate"
                                 onChange={(e) => {
                                     const values = e.target.value.split("-")
                                     let value = values[1] ?? ""
                                     dispatch(setFilterSyndicate(value))
                                     !filterCollege && dispatch(setFilterCollege(values[0]))
                                     props.fetchUsers({
                                         newPage: 1,
                                         filterCollege: values[0],
                                         filterSyndicate: value
                                     })
                                 }}>
                        {!filterCollege && <option key="0-0" defaultValue="---">Toutes</option>}
                        {!filterCollege && SYNDICATES.map((group, index) => {
                            const college = COLLEGES.find((item) => item.key === index)
                            return college && <optgroup key={college.key} label={`Collège ${college.label}`}>
                                {
                                    group.map((syndicate: {
                                        key: number,
                                        label: string
                                    }, index2: number) => {
                                        return <option key={`${index}-${index2}`}
                                                       value={`${college.key}-${syndicate.key}`}>{syndicate.label}</option>
                                    })
                                }
                            </optgroup>
                        })}
                        {filterCollege && <option key="0-0" value={filterCollege}>Toutes</option>}
                        {filterCollege && SYNDICATES[parseInt(filterCollege)].map((syndicate: {
                            key: number,
                            label: string
                        }, index: number) => {
                            return <option key={index}
                                           value={`${filterCollege}-${syndicate.key}`}>{syndicate.label}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="col-lg">
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Statut</Form.Label>
                    <Form.Select value={filterStatus} id="select-status" onChange={(e) => {
                        dispatch(setFilterStatus(e.target.value))
                        props.fetchUsers({
                            newPage: 1,
                            filterStatus: e.target.value
                        })
                    }}>
                        <option value="">Tous</option>
                        <option value="0">Titulaire</option>
                        <option value="1">Supléant</option>
                    </Form.Select>
                </Form.Group>
            </div>
            <div className={hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ASSIST"], roles) ? "col-lg" : "col"}>
                <Form.Group className="mb-4">
                    <Form.Label className="text-white fw-medium">Fonction</Form.Label>
                    <Form.Select value={filterRole} id="select-role" onChange={(e) => {
                        dispatch(setFilterRole(e.target.value))
                        props.fetchUsers({
                            newPage: 1,
                            filterRole: e.target.value
                        })
                    }}>
                        <option value="" key="all">Toutes</option>
                        {data.MANDATE_ROLES.map((item) => (
                            <option value={item.key} key={item.key}>{item.label}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>
            {hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles) &&
                <div className="col">
                    <Form.Group className="mb-4">
                        <Form.Label className="text-white fw-medium">Profil</Form.Label>
                        <Form.Select value={filterProfil} id="select-profile" onChange={(e) => {
                            dispatch(setFilterProfil(e.target.value))
                            props.fetchUsers({
                                newPage: 1,
                                filterProfil: e.target.value
                            })
                        }}>
                            <option value="">Tous</option>
                            <option value="ROLE_USER">Commissaire-administrateur.trice</option>
                            <option value="ROLE_ASSIST">Assistant.e</option>
                            <option value="ROLE_CODIR">Membre ATPRO</option>
                            <option value="ROLE_ADMIN">Webmaster</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            }

            {valueReset &&
                <div className="col-auto">
                    <div className="mb-4">
                        <div className="btn btn-primary btn-icon" onClick={() => {
                            props.resetFields()
                            props.fetchUsers({
                                newPage: 1,
                                filterName: "",
                                filterInstance: "",
                                filterStatus: "",
                                filterRole: "",
                                filterProfil: "",
                                filterCollege: "",
                                filterSyndicate: ""
                            })
                        }}>
                            <span className="mai fs-md">refresh</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
