import {useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import AddAdminForm from "../../components/AddUser/AddAdminForm"
import LightBoxAddMandat from "../../components/AddUser/LightBoxAddMandat"
import type {RootState} from "../../redux/Store"
import {setPage} from "../../redux/User/UserSlice"
import PageHeader from "../../components/PageHeader"
import {setProperty} from "../../redux/User/AddUserSlice"

export default function AddAdmin() {

    const dispatch = useAppDispatch()
    const showModalAddPeriod = useAppSelector((state: RootState) => state.addUser.showModalAddPeriod)

    useEffect(() => {
        dispatch(setPage("add-admin"))
        dispatch(setProperty({property: "profil", value: "commissaire-administrateur"}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div onClick={() => {
            dispatch(setProperty({property: "usersByName", value: []}))
        }}>
            <PageHeader title="Ajout d'un.e commissaire-administrateur.trice" breadcrumb
                        nav={[["Utilisateur", "/annuaire"]]}/>
            <div className="container">
                <div className="block">
                    <h3 className="text-primary">Informations générales</h3>
                    <AddAdminForm editMode={false}/>
                    {showModalAddPeriod &&
                        <LightBoxAddMandat showModalAddPeriod={showModalAddPeriod} editMode={false}/>
                    }
                </div>
            </div>
        </div>
    )
}
