import {useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {useParams} from "react-router-dom"
import type {RootState} from "../../redux/Store"
import {fetchMeetings} from "../../api/MeetingAPI"
import DocumentsByYear from "../../components/Document/DocumentsByYear"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {getCommitteeBySlug} from "../../utils/Functions"
import {hasRole} from "../../utils/UserTools"
import {ROLES_VIEW_MEETINGS} from "../../api/UserAPI"
import {fetchMandatesWithOption} from "../../api/MandateAPI"
import PageHeader from "../../components/PageHeader"
import DocumentsByCommittee from "../../components/Document/DocumentsByCommittee"
import {resetItems, setContext} from "../../redux/Document/DocumentSlice"
import {fetchDocumentUsers} from "../../api/DocumentUserAPI"

export default function Document() {
    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const userId = useAppSelector((state) => state.app.userId)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const context = useAppSelector((state) => state.document.context)
    const {slug} = useParams()

    useEffect(() => {
        if (userId !== 0) {
            dispatch(fetchCommittees({}))

            let options = {}
            if (!hasRole(ROLES_VIEW_MEETINGS, roles)) {
                options = {"user.id": userId}
                dispatch(fetchMandatesWithOption({context: "mandate", options: options}))
            }

            dispatch(setContext("documents"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    useEffect(() => {
        if (slug !== undefined) {
            // On récupère la liste des séances et des documents associés
            dispatch(fetchMeetings({
                options: {
                    "committee.slug": slug
                }
            }))
            dispatch(fetchDocumentUsers({options: {}}))
        }
        dispatch(resetItems())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    // On gère l'affichage du titre et du contenu en fonction de la sélection ou non d'une instance
    let pageName: string = "Les documents"
    let committee = getCommitteeBySlug({slug, committees})
    let bc: Array<Array<string>> = []

    if (committee !== undefined && committee !== null) {
        if (committee.title !== undefined) {
            pageName = committee.title
        }
        if (context && context === "documents") {
            bc = [["Les documents", "/documents"]]
        } else {
            bc = [["Les instances", "/instances"]]
        }
    }

    return <>
        <PageHeader title={pageName} breadcrumb nav={bc}/>
        <div className="container">
            {
                committee && <div className="row row-xs-h">
                    <DocumentsByYear committee={committee}/>
                </div>
            }
            {
                !committee && <DocumentsByCommittee/>
            }
        </div>
    </>

}
