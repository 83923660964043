import React from "react";
import {Col, Row} from "react-bootstrap";
import {displayMessage} from "../../../redux/Message/MessageSlice";
import {useAppDispatch} from "../../../redux/Hooks";

type Props = {
    link:string
}

export default function MeetingLink({ link }:Props) {
    const dispatch = useAppDispatch()

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(link).then(
            ()=> {
                dispatch(displayMessage({text: 'Lien copié !', status: "success"}))
            },
            ()=> {
                const myTemporaryInputElement = document.createElement("input");
                myTemporaryInputElement.type = "text";
                myTemporaryInputElement.value = link;

                document.body.appendChild(myTemporaryInputElement);

                myTemporaryInputElement.select();
                document.execCommand("Copy");

                document.body.removeChild(myTemporaryInputElement);
                dispatch(displayMessage({text: 'Lien copié !', status: "success"}))
            },
        );

    }

    return <Row className="row-sm">
        <Col lg="auto">
            <a href={link} target="_blank" rel="noreferrer" className="btn btn-lg px-3 btn-primary mb-2">
                <span className="row row-sm row-sm-h align-items-center">
                    <span className="col-auto">
                        <span className="mai fs-lg d-block">videocam</span>
                    </span>
                    <span className="col fw-medium fs-sm">
                        Participer à la visio conférence
                    </span>
                </span>
            </a>
        </Col>
        <Col lg>
            <button onClick={copyLinkToClipboard} className="btn btn-lg px-3 btn-white bg-hover-main-gray-light mb-2">
                <span className="row row-sm row-sm-h align-items-center">
                    <span className="col-auto fw-medium fs-sm text-dark">
                        Copier le lien Teams
                    </span>
                    <span className="col">
                        <span className="mai fs-lg text-primary d-block">content_copy</span>
                    </span>
                </span>
            </button>
        </Col>
    </Row>
}
