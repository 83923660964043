import {SyntheticEvent, useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {
    setShowModal,
    setSubject,
    setContentEmail,
    setNbuserRecipients
} from "../../redux/Emailing/EmailingSlice"
import Editor from "../../components/Editor"
import LightboxChoiceRecipients from "../../components/Emailing/LightboxChoiceRecipients"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {setPage} from "../../redux/User/UserSlice"
import PopinConfirmSendMail from "../../components/Emailing/PopinConfirmSendMail"
import {displayMessage} from "../../redux/Message/MessageSlice"
import PageHeader from "../../components/PageHeader"
import {Col, Row, Form} from "react-bootstrap"
import {countUsersForEmailing} from "../../api/UserAPI"

export default function Emailing() {

    const dispatch = useAppDispatch()
    const showModal = useAppSelector((state: RootState) => state.emailing.showModal)
    const subject = useAppSelector((state: RootState) => state.emailing.subject)
    const contentEmail = useAppSelector((state: RootState) => state.emailing.contentEmail)
    const committeesSelected = useAppSelector((state: RootState) => state.emailing.committeesSelected)
    const rolesSelected = useAppSelector((state: RootState) => state.emailing.rolesSelected)
    const statusSelected = useAppSelector((state: RootState) => state.emailing.statusSelected)
    const moreEmails = useAppSelector((state: RootState) => state.emailing.moreEmails)

    useEffect(() => {
        dispatch(setPage("emailing"))
        dispatch(fetchCommittees({}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkEmail = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        let temp = document.createElement("div")
        temp.innerHTML = contentEmail

        if (temp.innerText === "") {
            dispatch(displayMessage({text: "Veuillez saisir un contenu de mail", status: "error"}))
            return false
        }
        document.getElementById("link-open-popin-confirm-email")?.click()
        fetchNrbUsersSelectEmail()
    }

    const fetchNrbUsersSelectEmail = () => {
        countUsersForEmailing({
            committeesSelected: committeesSelected,
            rolesSelected: rolesSelected,
            statusSelected: statusSelected,
            syndicates: [],
            active: true
        }).then((data) => {
            const nbMoreEmails = moreEmails ? moreEmails.split(new RegExp("[\r\n]", "gm")).length : 0
            dispatch(setNbuserRecipients(data.nb + nbMoreEmails))
        })
    }

    const tabCommittees: string[] = []
    committeesSelected.forEach((item, index) => {
        item.title && tabCommittees.push(item.title)
    })

    const tabFunctions: string[] = []
    rolesSelected.forEach((item, index) => {
        item.label && tabFunctions.push(item.label)
    })

    const tabStatuses: string[] = []
    statusSelected.forEach((item, index) => {
        item.label && tabStatuses.push(item.label)
    })

    return (
        <>
            <PageHeader title="Emailing" breadcrumb/>
            <div className="container">
                <div className="bg-white p-5 p-lg-8">
                    <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                        checkEmail(e)
                    }}>
                        <Row className="row-sm row-sm-h align-items-center mb-3">
                            <Col xs={12} xxl="auto">
                                <Row className="row-sm row-sm-h align-items-center">
                                    <Col xs="auto">Destinataires *</Col>
                                    <Col xs={12} sm>
                                        <a className="btn btn-primary d-inline-flex align-items-center" href="/"
                                           onClick={(e) => {
                                               e.preventDefault()
                                               dispatch(setShowModal(true))
                                           }}>
                                            <i className="mai me-2">mail</i>
                                            Choisir les destinataires
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} xxl={{offset: 1, span: true}}>
                                <Row className="row-sm row-sm-h justify-content-between">
                                    <Col lg="auto">
                                        Instances sélectionnées : <strong
                                        className="fw-medium">{tabCommittees.length > 0 ? tabCommittees.join(", ") : "aucune sélection"}</strong>
                                    </Col>
                                    <Col lg="auto">
                                        Fonctions sélectionnées : <strong
                                        className="fw-medium">{tabFunctions.length > 0 ? tabFunctions.join(", ") : "aucune sélection"}</strong>
                                    </Col>
                                    <Col lg="auto">
                                        Statuts sélectionnés : <strong
                                        className="fw-medium">{tabStatuses.length > 0 ? tabStatuses.join(", ") : "aucune sélection"}</strong>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="input-sujet">Sujet *</Form.Label>
                            <Form.Control type="text" value={subject} id="input-sujet" required onChange={(e) => {
                                dispatch(setSubject(e.target.value))
                            }}/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label className="d-block text-start" htmlFor="input-message-emailing">Message
                                *</Form.Label>
                            <Editor
                                value={contentEmail}
                                id="input-message-emailing"
                                onChange={(e) => {
                                    dispatch(setContentEmail(e))
                                }}
                            />
                        </Form.Group>

                        <div className="text-center">
                            <button type="submit" className="btn btn-light">Envoyer <i className="mai ms-2">send</i>
                            </button>
                            <a href="/" data-bs-toggle="modal" id="link-open-popin-confirm-email"
                               data-bs-target="#modal-confirm-email-form">&nbsp;</a>
                        </div>
                    </Form>

                    {showModal &&
                        <LightboxChoiceRecipients showModal={showModal}/>
                    }

                    <PopinConfirmSendMail/>
                </div>
            </div>
        </>

    )
}
