import React from "react"
import {ExpectedDocument} from "../../../api/ExpectedDocumentAPI"
import {Document, TYPE_CAT_PV} from "../../../api/DocumentAPI"
import ItemDocument from "./ItemDocument"
import {ItemExpectedDocumentTitle} from "../../Committee/ItemExpectedDocument";
import {ItemDocumentAddButton} from "./ItemDocumentAddButton";

export default function GroupItemDocument(props: {docIndex: number, expectedDocument?: ExpectedDocument, documents: Document[]}) {
    return <div className="mt-4">
        <ItemExpectedDocumentTitle doc={props.expectedDocument} />
        <div>
            {
                props.documents.length > 0 && props.documents.map((doc, index) => {
                    return <ItemDocument
                        key={index}
                        docIndex={props.docIndex}
                        expectedDocument={props.expectedDocument}
                        document={doc}
                        type={props.expectedDocument?.category?.type}
                    />
                })
            }
            {
                props.documents.length === 0 &&
                    <ItemDocument
                        key={0}
                        docIndex={props.docIndex}
                        expectedDocument={props.expectedDocument}
                        type={props.expectedDocument?.category?.type}
                    />
            }
            {
                props.expectedDocument?.category?.type === TYPE_CAT_PV &&
                    <ItemDocumentAddButton type={TYPE_CAT_PV} label="Ajouter un procès-verbal" />
            }
        </div>
    </div>
}
