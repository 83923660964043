import React, {SyntheticEvent, ChangeEvent, useEffect, useState} from 'react'
import {useAppDispatch} from '../../redux/Hooks'
import {Form} from 'react-bootstrap'
import Editor from '../../components/Editor'
import {setPage} from '../../redux/User/UserSlice'
import {FULL_SEND_CONTACT_MESSAGE} from '../../utils/Data'
import axios from 'axios'
import {displayMessage} from '../../redux/Message/MessageSlice'
import PageHeader from "../../components/PageHeader"
import {CONTACT_EMAILS, CONTACT_OBJECTS} from "../../constants/ContactData";
import Select from "react-select";
import {ChoiceItem} from "../../utils/ChoiceList";
import {useNavigate} from "react-router-dom";

export default function Contact() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [selectSubject, setSelectSubject] = useState<string>("")
    const [email, setEmail] = useState('')
    const [contentEmail, setContentEmail] = useState('')

    useEffect(() => {
        dispatch(setPage('contact'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (contentEmail === '' || contentEmail === '<p></p>') {
            dispatch(displayMessage({text: "Veuillez saisir un contenu de message", status: "error"}))
            return false
        }

        sendContactMessage(selectSubject, contentEmail)
    }

    const sendContactMessage = (selectSubject: string, contentEmail: string) => {

        const contact = CONTACT_EMAILS.find((item) => item.key === selectSubject)
        const subject = CONTACT_OBJECTS.find((item) => item.key === selectSubject)

        dispatch(displayMessage({text: "Votre email est en cours d'envoi", status: "info"}))
        axios({
            method: 'POST',
            url: FULL_SEND_CONTACT_MESSAGE,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                subject: subject ? subject.label : "",
                content: contentEmail,
                to: contact ? contact.emails : [],
                from: email
            }
        }).then((response) => {
            setSelectSubject("")
            setContentEmail('')
            let elem = document.querySelector('#editor-instances')
            if (elem !== null) {
                let element = elem.children[0]
                let textContent = elem.children[0].textContent
                if (textContent !== null && textContent !== undefined) {
                    element.innerHTML = ''
                }
            }
            dispatch(displayMessage({text: response.data.message, status: "success"}))
            return navigate('/')
        }).catch((error) => {
            dispatch(displayMessage({text: "Echec de l'envoi du message", status: "error"}))
        })

    }

    return (
        <>
            <PageHeader title="Contact"/>
            <div className="container">
                <div className="bg-white p-8">
                    <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
                        handleSubmit(e)
                    }}>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="select-subject">Sujet *</Form.Label>
                            <Select
                                options={CONTACT_OBJECTS}
                                value={selectSubject ? CONTACT_OBJECTS.find((item) => item.key === selectSubject) : null}
                                getOptionLabel={(item: ChoiceItem) => item.label}
                                getOptionValue={(item: ChoiceItem) => item.key.toString()}
                                id="select-subject"
                                onChange={(e) => e && setSelectSubject(e.key)}/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="input-email">Votre courriel*</Form.Label>
                            <Form.Control type="email" required value={email} id="input-email"
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                              setEmail(e.target.value)
                                          }}/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label className="d-block text-start" htmlFor="input-message">Message *</Form.Label>
                            <Editor value={contentEmail}
                                    placeholder="Veuillez saisir le contenu du mail qui sera envoyé" id="input-message"
                                    onChange={(e) => {
                                        setContentEmail(e)
                                    }}/>
                        </Form.Group>
                        <div className="mt-8 text-center">
                            <button type="submit" className="btn btn-light">Envoyer <i className="mai ms-2">send</i>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </>

    )
}
