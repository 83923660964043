import {Link} from 'react-router-dom'
import {Mandate} from "../../api/MandateAPI"
import {Committee} from "../../api/CommitteeAPI"
import ItemCommitteeMandates from "./ItemCommitteeMandates";
import {EditCommittee} from "./EditCommittee";
import {useAppSelector} from "../../redux/Hooks";
import {RootState} from "../../redux/Store";
import {ROLES_ADMIN} from "../../api/UserAPI";
import {hasRole} from "../../utils/UserTools";

export default function ItemCommittee(props: {committee: Committee, context?: string}) {
    const mandates = useAppSelector((state: RootState) => state.mandate.items)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    let docLink = <></>
    if (props.committee.canViewDocs || hasRole(ROLES_ADMIN, roles)) {
        docLink = <Link to={`/documents/${props.committee.slug}`} className="btn btn-light">Accéder aux documents<i className="mai ms-2">arrow_forward</i></Link>
    }

    const sortedMandates = sortMandatesByCommittee(mandates)

    return (
        <div className="p-4 bg-white" style={{ borderTop: '4px solid ' + props.committee.color }}>
            <div className="row row-sm">
                <div className="col">
                    <h3 className="fw-medium mb-3">{props.committee.title}</h3>
                </div>
                <div className="col-auto">
                    <ItemCommitteeMandates committee={props.committee} mandates={sortedMandates[props.committee.id]} />
                </div>
            </div>

            {props.committee.content}

            <div className="row row-sm row-sm-h align-items-center mt-4">
                <div className="col">{docLink}</div>
                {(()=> {
                    if (props.context !== "documents") {
                        return <div className="col-auto">
                            <EditCommittee committee={props.committee} />
                        </div>
                    }
                })()}
            </div>
        </div>
    )
}

function sortMandatesByCommittee(mandates: Mandate[]) {
    let sortedMandates: Mandate[][] = []
    if(mandates !== undefined && mandates !== null && mandates.length > 0){
        mandates.forEach((mandate) => {
            if (sortedMandates[mandate.committee.id] === undefined) {
                sortedMandates[mandate.committee.id] = []
            }
            sortedMandates[mandate.committee.id].push(mandate)
        })
    }
    return sortedMandates
}
