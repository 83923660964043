import React, { Fragment, useEffect, useState } from "react"
import {ExpectedDocument} from "../../../api/ExpectedDocumentAPI"
import {Document, TYPE_CAT_PV} from "../../../api/DocumentAPI"
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {Form} from 'react-bootstrap'
import Select, {MultiValue} from "react-select"
import {setPvUsers} from "../../../redux/DocumentUser/DocumentUserSlice";

export default function ZonePv(props: {expectedDocument?: ExpectedDocument}) {
    const dispatch = useAppDispatch()
    const documents = useAppSelector((state: RootState) => state.meetingForm.documents)
    const committeeMandates = useAppSelector((state: RootState) => state.committee.mandates)

    const [selectedOptions, setSelectedOptions] = useState<{ value: string, label: string }[]>([])
    const [currentDocuments, setCurrentDocuments] = useState<Document[]>([])

    useEffect(() => {
        setCurrentDocuments(documents.filter((item) => item && item.expectedDocument && item.expectedDocument.id === props.expectedDocument?.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents])

    useEffect(() => {
        const selectedUsers:{ value: string, label: string }[] = []
        const tempPvUsers: number[] = []
        currentDocuments.forEach((currentDocument) => {
            currentDocument.documentUsers?.forEach((documentUser) => {
                if (documentUser.user) {
                    const mandate = committeeMandates.find((item) => item.user.id === documentUser.user.id)
                    if (mandate !== undefined && documentUser.user.id && !selectedUsers.some((selectedUser) => selectedUser.value === String(documentUser.user.id))) {
                        selectedUsers.push({
                            value: String(documentUser.user.id),
                            label: mandate.user.firstname + " " + mandate.user.lastname
                        })
                        tempPvUsers.push(mandate.user.id)
                    }
                }
            })
        })

        dispatch(setPvUsers(tempPvUsers))
        setSelectedOptions(selectedUsers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocuments])

    if (props.expectedDocument === undefined ||
        props.expectedDocument.category === undefined ||
        props.expectedDocument.category.type !== TYPE_CAT_PV ||
        !props.expectedDocument.available ||
        currentDocuments.length ===0
    ) {
        return <Fragment />
    }

    function updateDocumentUsers(users: MultiValue<{ value: string, label: string}>) {
        const tempPvUsers: number[] = []
        let selectedUsers:{ value: string, label: string }[] = []

        users.forEach((user) => {
            const member = membersList.find((item) => user.value === item.value)
            if (member) {
                selectedUsers.push(member)
                tempPvUsers.push(Number(user.value))
            }
        })

        dispatch(setPvUsers(tempPvUsers))
        setSelectedOptions(selectedUsers)
    }

    let membersList: { value: string, label: string}[] = []
    committeeMandates.forEach((mandate) => {
        const memberValue = mandate.user.id
        if (!membersList.find((member) => member.value === String(memberValue))) {
            membersList.push({
                value: String(memberValue),
                label: mandate.user.firstname + " " + mandate.user.lastname
            })
        }
    })

    return <Form.Group className="mt-4">
        <Form.Label htmlFor={`input-members`}>Sélectionner les commissaires-administrateurs devant signer le PV</Form.Label>
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={selectedOptions}
            options={membersList}
            isMulti={true}
            isClearable={true}
            hideSelectedOptions={false}
            onChange={(e) => updateDocumentUsers(e)}
        />
    </Form.Group>
}
