import {SyntheticEvent, useEffect, useState} from "react"
import {useNavigate, useParams, useLocation} from "react-router-dom"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {addAssistant, editAssistant, fetchUserById, UserPostData} from "../../api/UserAPI"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {ResponseInterface} from "../../utils/CommonInterfaces"
import {Form, Row, Col} from "react-bootstrap"
import {checkpassword} from "../../utils/UserTools"
import {textPassword} from "../../utils/Data"
import ProfileForm from "./ProfileForm"
import {fetchCommittees} from "../../api/CommitteeAPI"
import {resetAllFields, setProperty} from "../../redux/User/AddUserSlice"

export default function AddAssistantForm(props: {
    editModeAssistant: boolean,
    pageProfil?: boolean
}) {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useAppDispatch()
    const userId = useAppSelector((state: RootState) => state.app.userId)
    const addUser = useAppSelector((state: RootState) => state.addUser)

    // Gestion du mot de passe sur la page Profil
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [classInputPassword1, setClassInputPassword1] = useState("")
    const [classInputPassword2, setClassInputPassword2] = useState("")
    const [password1Valid, setPassword1Valid] = useState(false)
    const [password2Valid, setPassword2Valid] = useState(false)
    const [hidePassword, setHidePassword] = useState<boolean>(true)
    const [originalEmail, setOriginalEmail] = useState<string>("")

    const location = useLocation()

    useEffect(() => {
        if (location.pathname.indexOf("editer-assistant") !== -1 || location.pathname.indexOf("mon-profil") !== -1) {
            dispatch(setProperty({property: "editModeAssistant", value: true}))
            getUser()
        } else {
            dispatch(resetAllFields({}))
        }

        dispatch(fetchCommittees({}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const getUser = async () => {
        if (params.id !== undefined || props?.pageProfil) {
            let id_user = props?.pageProfil ? userId : Number(params.id)
            let result = await dispatch(fetchUserById({id_user: id_user}))

            if (result.payload !== undefined) {
                let user = result.payload.user
                if (user !== undefined) {
                    dispatch(setProperty({property: "lastname", value: user.lastname}))
                    dispatch(setProperty({property: "firstname", value: user.firstname}))
                    dispatch(setProperty({property: "email", value: user.email}))
                    dispatch(setProperty({property: "profilActif", value: user.active}))
                    dispatch(setProperty({property: "photo", value: user.filePath}))
                    dispatch(setProperty({property: "dpoValidatedAt", value: user.dpoValidatedAt}))
                    dispatch(setProperty({property: "cguValidatedAt", value: user.cguValidatedAt}))
                    dispatch(setProperty({property: "dpoValidated", value: user.dpoValidated}))
                    dispatch(setProperty({property: "cguValidated", value: user.cguValidated}))
                    dispatch(setProperty({
                        property: "civilite",
                        value: (user.civility === 0 ? "Madame" : "Monsieur")
                    }))
                    dispatch(setProperty({property: "committees", value: user.committees}))
                    dispatch(setProperty({property: "roles", value: user.roles}))
                    user.email && setOriginalEmail(user.email)
                }
            } else {
                return navigate("/annuaire")
            }
        }
    }

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        dispatch(displayMessage({text: "Enregistrement en cours"}))
        let error = false

        // On vérifie les mots de passe si une saisie a été faite
        if (password !== "" || password2 !== "") {
            if (password !== password2) {
                dispatch(displayMessage({text: "Les 2 mots de passe ne sont pas identiques", status: "error"}))
                error = true
            } else if (!password1Valid || !password2Valid) {
                dispatch(displayMessage({text: "Le mot de passe n'est pas pas valide", status: "error"}))
                error = true
            }
        }

        if (!error) {
            const alert = props.pageProfil && addUser.email !== originalEmail

            let role = "ROLE_ASSIST"
            switch (addUser.profil) {
                case "membre-codir":
                    role = "ROLE_CODIR"
                    break
                case "webmaster":
                    role = "ROLE_ADMIN"
                    break
            }

            let response: ResponseInterface

            let userData: UserPostData = {
                id: props.pageProfil ? userId : Number(params.id),
                lastname: addUser.lastname,
                firstname: addUser.firstname,
                email: addUser.email,
                active: addUser.profilActif,
                filePath: addUser.photo,
                civility: (addUser.civilite === "Monsieur"),
                committees: addUser.committees,
            }
            if (!props.pageProfil) {
                userData.roles = [
                    role
                ]
            } else if (password !== "" && password2 !== "") {
                userData.password = password
            }

            if (props.editModeAssistant) {
                response = await dispatch(editAssistant({
                    user: userData
                }))
            } else {
                response = await dispatch(addAssistant({
                    user: userData
                }))
            }

            if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
                dispatch(displayMessage({text: "Enregistrement effectué avec succès", status: "success"}))
                dispatch(resetAllFields({}))
                if (alert) {
                    return navigate("/s-identifier")
                } else {
                    return navigate("/annuaire")
                }
            } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
                dispatch(displayMessage({text: "Echec de l'enregistrement", status: "error"}))

            }
        } else {
            dispatch(displayMessage({text: "Vous n'est plus connecté", status: "error"}))
        }
    }

    return (
        <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            handleSubmit(e)
        }}>
            <Row>
                <ProfileForm type="other" pageProfil={props.pageProfil} originalEmail={originalEmail}/>
            </Row>

            {props.pageProfil && <>
                <Row>
                    <Col lg={6}>
                        <Form.Label>Votre mot de passe</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={hidePassword ? "password" : "text"}
                                value={password}
                                id="input-password"
                                className={classInputPassword1}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        checkpassword(
                                            {
                                                value: e.target.value,
                                                input: "password1",
                                                password1: password,
                                                password2: password2,
                                                setPassword: setPassword,
                                                setPassword2: setPassword2,
                                                setPassword1Valid: setPassword1Valid,
                                                setPassword2Valid: setPassword2Valid,
                                                setClassInputPassword1: setClassInputPassword1,
                                                setClassInputPassword2: setClassInputPassword2
                                            }
                                        )
                                    }
                                    setPassword(e.target.value)
                                }}
                            />
                            <button className="input-group-text" onClick={(e) => {
                                e.preventDefault()
                                setHidePassword(!hidePassword)
                            }}><span
                                className="mai">{hidePassword ? "visibility" : "lock"}</span>
                            </button>
                        </div>
                        <div className="form-text mb-0 help-text">{textPassword}</div>
                    </Col>
                    <Col lg={6}>
                        <Form.Label>Confirmez votre mot de passe</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={hidePassword ? "password" : "text"}
                                value={password2}
                                id="input-password-repeat"
                                className={classInputPassword2}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        checkpassword(
                                            {
                                                value: e.target.value,
                                                input: "password2",
                                                password1: password,
                                                password2: password2,
                                                setPassword: setPassword,
                                                setPassword2: setPassword2,
                                                setPassword1Valid: setPassword1Valid,
                                                setPassword2Valid: setPassword2Valid,
                                                setClassInputPassword1: setClassInputPassword1,
                                                setClassInputPassword2: setClassInputPassword2
                                            }
                                        )
                                    }
                                    setPassword2(e.target.value)
                                }}
                            />
                            <button className="input-group-text" onClick={(e) => {
                                e.preventDefault()
                                setHidePassword(!hidePassword)
                            }}><span
                                className="mai">{hidePassword ? "visibility" : "lock"}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </>}

            <div className="text-center mt-3 pt-4 pb-4 bg-white sticky-bottom">
                <button className="btn btn-light" type="submit">
                    <i className="mai me-2">save</i> Enregistrer
                </button>
            </div>
        </Form>
    )
}
