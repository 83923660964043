import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react"
import {setCommitteesSelected} from "../../redux/User/TrombinoscopeSlice"
import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import Select from "react-select"
import {Committee} from "../../api/CommitteeAPI"
import {data} from "../../utils/Data"
import {updateSelectCommittee, updateSelectRoles, updateSelectStatus} from "../../utils/Functions"
import {CommitteeSelectItem, SelectOption} from "../../utils/CommonInterfaces"
import {Modal, Form} from "react-bootstrap"
import {downloadExportUser} from "../../api/UserAPI"
import {ChoiceItem} from "../../utils/ChoiceList"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"
import {getFormattedLocalisedDate} from "../../utils/Date"

interface Props {
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>
}

export default function PopinExportUsersForm({show, setShow}: Props) {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.tromb.committees)
    const committeesSelected = useAppSelector((state: RootState) => state.tromb.committeesSelected)
    const rolesSelected = useAppSelector((state: RootState) => state.tromb.rolesSelected)
    const statusSelected = useAppSelector((state: RootState) => state.tromb.statusSelected)

    const [format, setFormat] = useState("base_complete")
    const [selectedCollege, setSelectedCollege] = useState<{ key: number, label: string } | undefined>()
    const [groupedSyndicates, setGroupedSyndicates] = useState<{
        label: string,
        options: { key: string, label: string }[]
    }[]>([])
    const [selectedSyndicates, setSelectedSyndicates] = useState<{ key: string, label: string }[]>([])

    useEffect(() => {
        const tempGroupedSyndicates: { label: string, options: { key: string, label: string }[] }[] = []
        COLLEGES.forEach((college) => {
            if (!selectedCollege || selectedCollege.key === college.key) {
                const tempSyndicates: { key: string, label: string }[] = []
                SYNDICATES[college.key].forEach((syndicate) => {
                    tempSyndicates.push({key: `${college.key}-${syndicate.key}`, label: syndicate.label})
                })
                tempGroupedSyndicates.push({
                    label: college.label,
                    options: tempSyndicates
                })
            }
        })
        setGroupedSyndicates(tempGroupedSyndicates)
    }, [selectedCollege])

    // Données du select multiple d'instances
    let optionsSelectCommittee: CommitteeSelectItem[] = []

    if (committees !== undefined) {
        committees.forEach((committee: Committee) => {
            optionsSelectCommittee.push({
                value: committee.id, label: committee.title !== undefined ? committee.title : "", color: committee.color
            })
        })
    }

    let defaultValueSelectCommittee: CommitteeSelectItem[] = []

    if (committeesSelected.length > 0) {
        committeesSelected.forEach((committee) => {
            defaultValueSelectCommittee.push({
                value: committee.id, label: committee.title !== undefined ? committee.title : "", color: committee.color
            })
        })
    }

    // Données du select multiple de fonctions

    let optionsSelectRoles: ChoiceItem[] = []

    if (committees !== undefined) {
        data.MANDATE_ROLES.forEach((role) => {
            optionsSelectRoles.push({
                key: role.key, label: role.label
            })
        })
    }

    let defaultValueSelectRoles: ChoiceItem[] = []

    if (rolesSelected.length > 0) {
        rolesSelected.forEach((role, index) => {
            defaultValueSelectRoles.push({
                key: index, label: role.label
            })
        })
    }
    // Données du select multiple de statuts

    let optionsSelectStatus: SelectOption[] = []

    if (committees !== undefined) {
        data.STATUS.forEach((status, index) => {
            optionsSelectStatus.push({
                value: index, label: status
            })
        })
    }

    let defaultValueSelectStatus: SelectOption[] = []

    if (statusSelected.length > 0) {
        statusSelected.forEach((status, index) => {
            defaultValueSelectStatus.push({
                value: index, label: status.label
            })
        })
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const syndicates: { key: number, label: string }[] = []
        selectedSyndicates.forEach((item) => {
            const values = item.key.split("-")
            syndicates.push({key: parseInt(values[1]), label: item.label})
        })

        let data = {
            committees: committeesSelected,
            roles: rolesSelected,
            statuses: statusSelected,
            format: format,
            college: selectedCollege ?? null,
            syndicates: syndicates
        }
        const promiseGetFile = downloadExportUser(data)
        promiseGetFile.then((data) => {
            const file = new Blob(
                [data],
                {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
            )

            const filename = "liste_commissaires_administrateurs_" + getFormattedLocalisedDate(new Date(), "yyyy-MM-dd-HH-mm")
            const fileURL = URL.createObjectURL(file)

            const link = document.createElement("a")
            link.href = fileURL
            link.download = filename
            link.click()
        })
        setShow(false)
    }

    function handleClose() {
        dispatch(setCommitteesSelected([]))
        setShow(false)
    }

    return <Modal show={show} className="modal-confirm" size="sm" backdropClassName="modal-backdrop-confirm"
                  onHide={() => handleClose()}>
        <Modal.Header closeButton>
            <Modal.Title as="h3">Export des membres</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Modal.Body className="text-primary">
                <Form.Group className="mb-4">
                    <Form.Label>Instances</Form.Label>
                    <Select
                        defaultValue={[...defaultValueSelectCommittee]}
                        isMulti
                        name="committees[]"
                        options={optionsSelectCommittee}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Toutes les instances"
                        onChange={(e) => {
                            updateSelectCommittee([...e], committeesSelected, committees, dispatch)
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Colleges</Form.Label>
                    <Select
                        isClearable={true}
                        value={selectedCollege}
                        options={COLLEGES}
                        getOptionLabel={(item: { key: number, label: string }) => item.label}
                        getOptionValue={(item: { key: number, label: string }) => item.key.toString()}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Tous les collèges"
                        onChange={(e) => {
                            setSelectedCollege(e !== null ? e : undefined)
                            setSelectedSyndicates([])
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Syndicats</Form.Label>
                    <Select
                        isMulti
                        name="syndicates[]"
                        value={selectedSyndicates}
                        options={groupedSyndicates}
                        getOptionLabel={(item: { key: string, label: string }) => item.label}
                        getOptionValue={(item: { key: string, label: string }) => item.key.toString()}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Tous les syndicats"
                        onChange={(e) => {
                            e.forEach((item) => {
                                const values = item.key.split("-")
                                !selectedCollege && setSelectedCollege(COLLEGES.find((item) => item.key === parseInt(values[0])))
                            })

                            const tempSyndicates: { key: string, label: string }[] = []
                            e.forEach((item) => {
                                tempSyndicates.push(item)
                            })
                            setSelectedSyndicates(tempSyndicates)
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Fonctions</Form.Label>
                    <Select
                        defaultValue={[...defaultValueSelectRoles]}
                        isMulti
                        name="roles[]"
                        options={optionsSelectRoles}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Toutes les fonctions"
                        onChange={(e) => {
                            updateSelectRoles([...e], rolesSelected, dispatch)
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Statuts</Form.Label>
                    <Select
                        defaultValue={[...defaultValueSelectStatus]}
                        isMulti
                        name="status[]"
                        options={optionsSelectStatus}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Tous les statuts"
                        onChange={(e) => {
                            updateSelectStatus([...e], statusSelected, dispatch)
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label className="d-block">Format *</Form.Label>
                    {[
                        ["base_complete", `Base complète`],
                        ["par_admin", `Par commissaire-administrateur`],
                        ["par_mandature", `Par mandature`],
                    ].map((data, index) => (
                        <Form.Check
                            key={index}
                            type="radio"
                            label={data[1]}
                            name="format"
                            className="form-check-inline"
                            value={data[0]}
                            id={`default-${data[0]}`}
                            checked={format === data[0]}
                            onChange={() => {
                                setFormat(data[0])
                            }}
                        />
                    ))}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="submit" className="btn btn-light">
                            <i className="mai fs-lg me-2">check</i> Valider
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Form>
    </Modal>
}
