import {createAsyncThunk} from "@reduxjs/toolkit"
import {Options, toQuery} from "../utils/Options"
import {axiosInstance, axiosInstanceJustJson, axiosInstanceMultipart, axiosInstancePatch} from "../utils/http-common"
import {FULL_CONTENTS_API, FULL_IMAGE_CONTENT_API} from "../utils/Data"
import {ValidationErrors} from "../utils/ValidationErrors"

const AxiosInstance = axiosInstance()
const AxiosInstanceMultipart = axiosInstanceMultipart()
const AxiosInstanceJson = axiosInstanceJustJson()
const AxiosInstancePatch = axiosInstancePatch()

export const TYPE_CONTENT_NEWS = 1
export const TYPE_CONTENT_PAGE = 2

export interface Content {
    id?: number;
    title: string;
    content: string;
    filePath: string;
    videoLink?: string;
    published: boolean;
    type?: number;
    createdDate?: string;
}

interface ResponseActualities {
    response: Content
}

interface ResponseFetchActualities {
    "hydra:member": Content[]
    "hydra:totalItems"?: number
}

export const fetchContents = createAsyncThunk<ResponseFetchActualities, { options?: Options }>(
    "contents/fetchAll",
    async (contentData) => {
        const queryOptions = contentData.options ?? {}
        queryOptions["order[createdDate]"] = "desc"
        const {data} = (await AxiosInstance.get(
            `${FULL_CONTENTS_API}` + toQuery(queryOptions)
        ))

        return data
    }
)

export const addActuality = createAsyncThunk<ResponseActualities, { actuality: Content }, {
    rejectValue: ValidationErrors
}>("actuality/addActuality", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.post(FULL_CONTENTS_API, JSON.stringify(dt.actuality))).data

    return {
        response: response
    }

})

export const updateActuality = createAsyncThunk<ResponseActualities, { actuality: Content }, {
    rejectValue: ValidationErrors
}>("actuality/updateActuality", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstancePatch.patch(FULL_CONTENTS_API + "/" + dt.actuality.id, JSON.stringify(dt.actuality))).data

    return {
        response: response
    }

})

export const deleteActuality = createAsyncThunk<ResponseActualities, { actuality: Content }, {
    rejectValue: ValidationErrors
}>("actuality/deleteActuality", async (dt, {rejectWithValue}) => {

    const response = (await AxiosInstanceJson.delete(FULL_CONTENTS_API + "/" + dt.actuality.id)).data

    return {
        response: response
    }

})

export const setPublished = createAsyncThunk<ResponseActualities, {
    actuality: Content,
    published: boolean
}, { rejectValue: ValidationErrors }>("actuality/setPublished", async (dt, {rejectWithValue}) => {

    let newActuality = {...dt.actuality}
    newActuality.published = dt.published

    const response = (await AxiosInstancePatch.patch(FULL_CONTENTS_API + "/" + dt.actuality.id, JSON.stringify(newActuality))).data

    return {
        response: response
    }

})

export const uploadImage = async (data: FormData) => {
    return await AxiosInstanceMultipart.post(FULL_IMAGE_CONTENT_API, data)
}