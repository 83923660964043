import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {Modal} from "react-bootstrap"
import {Committee, deleteCommittee} from "../../api/CommitteeAPI"
import {setModalDeleteCommitteeShow, setShow} from "../../redux/Committee/CommitteeSlice"

export default function ConfirmDeleteModal(props: { committee: Committee }) {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.committee.modalDeleteCommitteeShow)

    function delCommittee() {
        props.committee.title && dispatch(deleteCommittee({
            committeeTitle: props.committee.title,
            committeeId: props.committee.id
        }))
        dispatch(setShow(false))
        closeModal()
    }

    function closeModal() {
        dispatch(setModalDeleteCommitteeShow(false))
    }

    return <Modal size="sm" className="modal-confirm" show={show} onHide={() => closeModal()}
                  backdropClassName="modal-backdrop-confirm">
        <Modal.Header>
            <Modal.Title as="h3">
                <i className="mai me-2">warning</i> Confirmation requise
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Êtes vous sûr de vouloir supprimer cette instance ?</p>
        </Modal.Body>
        <Modal.Footer>
            <div className="row row-sm row-sm-h align-items-center">
                <div className="col-auto">
                    <button type="button" className="btn btn-light" onClick={() => closeModal()}>Non</button>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-danger" onClick={() => delCommittee()}>Oui</button>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
}
