import React, {useEffect, useMemo, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, {DateClickArg} from "@fullcalendar/interaction"
import {
    fetchMeetings,
    Meeting,
    MEETING_TYPE_ALERT,
    MEETING_TYPE_ALERT_LABEL,
    MEETING_TYPE_ALERT_USER,
    MEETING_TYPE_ALERT_USER_LABEL
} from "../../api/MeetingAPI"
import {resetItems, setModalAdminShow, setModalAdminType, setShow} from "../../redux/Meeting/MeetingSlice"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import MeetingPopin from "./MeetingPopin"
import {RootState} from "../../redux/Store"
import calendarCustomViewConfig from "./CalendarCustomViewConfig"
import {hasRole} from "../../utils/UserTools"
import {
    ROLES_MANAGE_MEETING,
    ROLES_VIEW_ALERT_CONTRIB,
    ROLES_VIEW_ALERT_NOTIF,
    ROLES_VIEW_MEETINGS
} from "../../api/UserAPI"
import {isActiveInMeetingCommittee, isReplacement} from "../../utils/Functions"
import {hexToRGBA, darkOrLightColor} from "../../utils/Color"
import {Options} from "../../utils/Options"
import {setMeetingDate, setMeetingTime, setSelectedMeeting} from "../../redux/Meeting/MeetingFormSlice"
import ConfirmPvApprouval from "../Document/ConfirmPvApprouval"
import {format} from "date-fns"
import {getFormattedLocalisedDate} from "../../utils/Date"
import {Committee} from "../../api/CommitteeAPI"
import {Mandate} from "../../api/MandateAPI"
import {USERS_API} from "../../utils/Data"

// On restitue les séances sur le calendrier
// On regarde si on doit filtrer sur published = true
// @todo ajouter le booleanFilter sur la propriété published de Meeting (ne filtre pas actuellement)
interface propsEvent {
    id?: string,
    title?: string,
    start?: Date,
    time: string,
    backgroundColor?: string,
    place?: string,
    videoLink?: string,
    committee?: string,
    documentForAll?: boolean,
    type?: string,
    roles?: string[],
    meeting?: Meeting,
    attendance?: string,
    special?: boolean
}

type Props = {
    selectedCommittees: Committee[],
    mandates: Mandate[]
}

export default function Calendar({selectedCommittees, mandates}: Props) {
    const dispatch = useAppDispatch()
    const filters = useAppSelector((state) => state.committee.filters)
    const meetings = useAppSelector((state: RootState) => state.meeting.items)
    const userMeetings = useAppSelector((state: RootState) => state.meeting.userMeetings)
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const userId = useAppSelector((state) => state.app.userId)
    const show = useAppSelector((state) => state.documentUser.showConfirm)
    const [refDates, setRefDates] = useState<Date[]>([])

    const renderedEvents: propsEvent[] = useMemo<propsEvent[]>(() => {
        let tempRenderedEvents: propsEvent[] = []
        meetings.forEach((meeting) => {
            if (meeting.meetingOccurrences !== undefined && (hasRole(ROLES_VIEW_MEETINGS, roles) || meeting.published)) {
                const isActiveInCommitteeFlag = (hasRole(ROLES_VIEW_MEETINGS, roles) || isActiveInMeetingCommittee(mandates, meeting) || isReplacement(meeting.meetingUsers, userId))
                meeting.meetingOccurrences.forEach((occurrence) => {

                    const displayedTitle = getTitle(meeting, occurrence.type)
                    const tempMeetingUser = userMeetings.find((item) => item.meeting.id === meeting.id && item.user.id === userId)
                    const attendance = tempMeetingUser && tempMeetingUser.attending

                    if (displayedTitle !== null && isActiveInCommitteeFlag) {
                        tempRenderedEvents.push({
                            id: String(meeting.id),
                            title: displayedTitle,
                            start: occurrence.date,
                            time: occurrence?.date ? getFormattedLocalisedDate(new Date(occurrence.date), "HH:mm") : "",
                            backgroundColor: meeting.committee.color,
                            place: meeting.place,
                            videoLink: meeting.videoLink,
                            committee: meeting.committee.title,
                            documentForAll: meeting.committee.documentForAll,
                            type: occurrence.type,
                            roles: roles,
                            meeting: meeting,
                            attendance: attendance,
                            special: meeting.special
                        })
                    }
                })
            }
        })
        return tempRenderedEvents
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetings, roles])

    useEffect(() => {
        if (filters.length === 0 || selectedCommittees.length === 0) {
            dispatch(resetItems())
            return
        }
        if (refDates.length === 0) return

        let options: Options = {
            "withReplacedBy": true,
            "meetingOccurrences.date[after]": format(refDates[0], "yyyy-MM-dd"),
            "meetingOccurrences.date[before]": format(refDates[1], "yyyy-MM-dd")
        }
        if (filters.length <= selectedCommittees.length) {
            options = {
                ...options,
                "committee.id[]": filters,
            }
        }
        dispatch(fetchMeetings({options: options}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, selectedCommittees, refDates])

    function getTitle(meeting: Meeting, type: string) {
        switch (type) {
            case MEETING_TYPE_ALERT:
                if (hasRole(ROLES_VIEW_ALERT_CONTRIB, roles)) {
                    return MEETING_TYPE_ALERT_LABEL
                }
                return null
            case MEETING_TYPE_ALERT_USER:
                if (hasRole(ROLES_VIEW_ALERT_NOTIF, roles) && !hasRole(ROLES_VIEW_ALERT_CONTRIB, roles)) {
                    return MEETING_TYPE_ALERT_USER_LABEL
                }
                return null
            default:
                return meeting.committee.title
        }
    }

    const handleClickEvent = (meetingId: number) => {
        dispatch(setShow(true))
        dispatch(setSelectedMeeting(meetings.find((item) => item.id === Number(meetingId))))
    }

    const handleClickDay = (info: DateClickArg) => {
        if (!hasRole(ROLES_MANAGE_MEETING, roles)) {
            return
        }
        dispatch(setModalAdminShow(true))
        dispatch(setModalAdminType("creation"))
        dispatch(setMeetingDate(getFormattedLocalisedDate(info.date, "MM/dd/yyyy")))
        dispatch(setMeetingTime("09:00"))
    }

    function renderEventContent(eventInfo: {
        event: { id: string, title: string, backgroundColor: string, special: boolean },
        timeText: string
    }) {
        const currentEvent: propsEvent | undefined = renderedEvents.find((item: propsEvent) => item.id === eventInfo.event.id)

        return <span
            className={`text-truncate py-1 px-2 w-100 fs-xs d-flex align-items-center rounded-2 text-${(currentEvent?.special ? darkOrLightColor(eventInfo.event.backgroundColor) : "dark")}`}
            style={{backgroundColor: currentEvent?.special ? eventInfo.event.backgroundColor : hexToRGBA(eventInfo.event.backgroundColor, 0.1)}}
            onClick={() => handleClickEvent(Number(eventInfo.event.id))}>
            <i className="mai me-1"
               style={{color: (currentEvent?.special ? darkOrLightColor(eventInfo.event.backgroundColor) : eventInfo.event.backgroundColor)}}>
                {
                    currentEvent?.special ? "star" : (
                        eventInfo.event.title === MEETING_TYPE_ALERT_LABEL || eventInfo.event.title === MEETING_TYPE_ALERT_USER_LABEL ?
                            "notifications" : "circle"
                    )
                }
            </i>
            <div className="me-1">{eventInfo.timeText}</div>
            <div className="text-truncate fw-bold">{eventInfo.event.title}</div>
        </span>
    }

    const sortedEvents = renderedEvents.sort((a: propsEvent, b: propsEvent) =>
        a.start && b.start && (a.start < b.start) ? -1 : 1,
    )

    return <>
        <FullCalendar
            timeZone="Europe/Paris"
            locale="fr"
            firstDay={1}
            plugins={[dayGridPlugin, interactionPlugin, calendarCustomViewConfig]}
            initialView="dayGridMonth"
            height="auto"
            headerToolbar={{
                left: "prev,next,today",
                center: "title",
                right: "dayGridMonth,dayGridWeek,dayGridDay,custom"
            }}
            buttonText={{
                today: "Aujourd'hui",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                custom: "Liste"
            }}
            datesSet={(arg) => setRefDates([arg.start, arg.end])}
            dateClick={handleClickDay}
            events={sortedEvents}
            eventClassNames="smooth-calendar-fade"
            eventContent={renderEventContent}
        />
        <MeetingPopin/>
        <ConfirmPvApprouval show={show}/>
    </>
}
