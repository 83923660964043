import LoadingSpinner from "../LoadingSpinner";
import Masonry from "react-masonry-css";
import ItemCommittee from "./ItemCommittee";
import {useAppSelector} from "../../redux/Hooks";
import {RootState} from "../../redux/Store";

export default function CommitteesList() {
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const loading = useAppSelector((state: RootState) => state.committee.loading)

    return <>{
        loading ?
            <LoadingSpinner/> :
            <Masonry className="row" columnClassName="col" breakpointCols={{default: 3, 1200: 1}}>
                {(
                    committees.map((committee) => (
                        <div key={committee.id} className="mb-5">
                            <ItemCommittee committee={committee}/>
                        </div>
                    ))
                )}
            </Masonry>
    }</>
}
