import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {Modal} from "react-bootstrap"
import {setProperty} from "../../redux/User/AddUserSlice"

export default function PopinCopyPeriodConfirm() {

    const dispatch = useAppDispatch()
    const periodToClose = useAppSelector((state: RootState) => state.addUser.periodToClose)
    const viewCopyPeriodPopin = useAppSelector((state: RootState) => state.addUser.viewCopyPeriodPopin)

    return (
        <Modal show={viewCopyPeriodPopin} className="modal-confirm" size="sm"
               backdropClassName="modal-backdrop-confirm">
            <Modal.Header>
                <Modal.Title as="h3">Duplication de période</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-primary">
                <p>Cette action entraine automatiquement la clôture de la période active en cours et de mandats
                    liées.</p>
                <p>Confirmez votre choix ?</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="row row-sm row-sm-h">
                    <div className="col-auto">
                        <button type="button" className="btn btn-light" onClick={() => {
                            dispatch(setProperty({property: "periodToClose", value: periodToClose}))
                            dispatch(setProperty({property: "viewCopyPeriodPopin", value: false}))
                            setTimeout(() => {
                                dispatch(setProperty({property: "showCopyPeriodLightbox", value: true}))
                            }, 50)
                            dispatch(setProperty({property: "editMode", value: false}))
                        }}>
                            <i className="mai me-2">check</i> Confirmer
                        </button>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-light" onClick={() => {
                            dispatch(setProperty({property: "viewCopyPeriodPopin", value: false}))
                            dispatch(setProperty({
                                property: "periodToClose", value: {
                                    dateStart: "",
                                    dateEnd: "",
                                    active: true,
                                    mandates: []
                                }
                            }))

                            dispatch(setProperty({property: "showCopyPeriodLightbox", value: false}))
                        }}>
                            <i className="mai me-2 text-danger">cancel</i> Annuler
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
