import React, {useEffect, useState} from "react"
import {
    setDisplayPdfDownloadLink,
    setOnlyActivePopinLabels,
    setCommitteesSelected
} from "../../redux/User/TrombinoscopeSlice"
import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import Select from "react-select"
import {Committee} from "../../api/CommitteeAPI"
import {updateSelectCommittee, updateSelectRoles, updateSelectStatus} from "../../utils/Functions"
import {CommitteeSelectItem} from "../../utils/CommonInterfaces"
import {PDFDownloadLink} from "@react-pdf/renderer"
import {PdfLabelUser} from "./PdfLabelUser"
import {fetchUsersForLabel} from "../../api/UserAPI"
import {Form, Spinner} from "react-bootstrap"
import {data} from "../../utils/Data"
import {ChoiceItem} from "../../utils/ChoiceList"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"

export default function PopinPrintLabelsForm() {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.tromb.committees)
    const committeesSelected = useAppSelector((state: RootState) => state.tromb.committeesSelected)
    const rolesSelected = useAppSelector((state: RootState) => state.tromb.rolesSelected)
    const statusSelected = useAppSelector((state: RootState) => state.tromb.statusSelected)
    const onlyActivePopinLabels = useAppSelector((state: RootState) => state.tromb.onlyActivePopinLabels)
    const optionsStatus: readonly CommitteeSelectItem[] = useAppSelector((state: RootState) => state.tromb.optionsStatus)
    const usersLabel = useAppSelector((state: RootState) => state.tromb.usersLabel)
    const displayPdfDownloadLink = useAppSelector((state: RootState) => state.tromb.displayPdfDownloadLink)
    const loadingUserLabels = useAppSelector((state: RootState) => state.tromb.loadingUserLabels)

    const [selectedCollege, setSelectedCollege] = useState<{ key: number, label: string } | undefined>()
    const [groupedSyndicates, setGroupedSyndicates] = useState<{
        label: string,
        options: { key: string, label: string }[]
    }[]>([])
    const [selectedSyndicates, setSelectedSyndicates] = useState<{ key: string, label: string }[]>([])

    useEffect(() => {
        const tempGroupedSyndicates: { label: string, options: { key: string, label: string }[] }[] = []
        COLLEGES.forEach((college) => {
            if (!selectedCollege || selectedCollege.key === college.key) {
                const tempSyndicates: { key: string, label: string }[] = []
                SYNDICATES[college.key].forEach((syndicate) => {
                    tempSyndicates.push({key: `${college.key}-${syndicate.key}`, label: syndicate.label})
                })
                tempGroupedSyndicates.push({
                    label: college.label,
                    options: tempSyndicates
                })
            }
        })
        setGroupedSyndicates(tempGroupedSyndicates)
    }, [selectedCollege])

    let optionsSelectCommittee: CommitteeSelectItem[] = []

    if (committees !== undefined) {
        committees.forEach((committee: Committee) => {
            optionsSelectCommittee.push({
                value: committee.id, label: committee.title !== undefined ? committee.title : "", color: committee.color
            })
        })
    }

    const getUsersForLabels = async () => {
        const syndicates: { key: number, label: string }[] = []
        selectedSyndicates.forEach((item) => {
            const values = item.key.split("-")
            syndicates.push({key: parseInt(values[1]), label: item.label})
        })

        dispatch(fetchUsersForLabel({
            committeesSelected: committeesSelected,
            rolesSelected: rolesSelected.map(item => {
                return {value: item.key, label: item.label}
            }),
            statusSelected: statusSelected,
            onlyActivePopinLabels: onlyActivePopinLabels,
            college: selectedCollege,
            syndicates: syndicates
        }))
        dispatch(setDisplayPdfDownloadLink(true))
        setTimeout(() => {
            let element: HTMLElement = document.getElementsByClassName("download-pdf-users-label-link")[0] as HTMLElement
            if (element) {
                element.click()
                dispatch(setDisplayPdfDownloadLink(false))
            }
        }, 1000)
    }

    return (
        <div className="modal modal-confirm fade" id="modal-print-labels-form">
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        getUsersForLabels()
                    }}>
                        <div className="modal-header">
                            <h3 className="modal-title">
                                IMPRIMER LES ÉTIQUETTES
                            </h3>
                            <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => {
                                dispatch(setCommitteesSelected([]))
                            }}/>
                        </div>
                        <div className="modal-body">
                            <Form.Group className="mb-4">
                                <Form.Label>Instances</Form.Label>
                                <Select
                                    defaultValue={[]}
                                    isMulti
                                    name="committees[]"
                                    // @ts-ignore
                                    options={optionsSelectCommittee}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(e) => {
                                        updateSelectCommittee([...e], committeesSelected, committees, dispatch)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Colleges</Form.Label>
                                <Select
                                    isClearable={true}
                                    value={selectedCollege}
                                    options={COLLEGES}
                                    getOptionLabel={(item: { key: number, label: string }) => item.label}
                                    getOptionValue={(item: { key: number, label: string }) => item.key.toString()}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Tous les collèges"
                                    onChange={(e) => {
                                        setSelectedCollege(e !== null ? e : undefined)
                                        setSelectedSyndicates([])
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Syndicats</Form.Label>
                                <Select
                                    isMulti
                                    name="syndicates[]"
                                    value={selectedSyndicates}
                                    options={groupedSyndicates}
                                    getOptionLabel={(item: { key: string, label: string }) => item.label}
                                    getOptionValue={(item: { key: string, label: string }) => item.key}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Tous les syndicats"
                                    onChange={(e) => {
                                        e.forEach((item) => {
                                            const values = item.key.split("-")
                                            !selectedCollege && setSelectedCollege(COLLEGES.find((item) => item.key === parseInt(values[0])))
                                        })

                                        const tempSyndicates: { key: string, label: string }[] = []
                                        e.forEach((item) => {
                                            tempSyndicates.push(item)
                                        })
                                        setSelectedSyndicates(tempSyndicates)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Fonctions</Form.Label>
                                <Select
                                    defaultValue={[]}
                                    isMulti
                                    name="fonctions[]"
                                    options={data.MANDATE_ROLES}
                                    getOptionLabel={(item: ChoiceItem) => item.label}
                                    getOptionValue={(item: ChoiceItem) => item.key.toString()}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Toutes les fonctions"
                                    onChange={(e) => {
                                        updateSelectRoles([...e], rolesSelected, dispatch)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Statuts</Form.Label>
                                <Select
                                    defaultValue={[]}
                                    isMulti
                                    name="statuts[]"
                                    // @ts-ignore
                                    options={optionsStatus}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Tous les statuts"
                                    onChange={(e) => {
                                        updateSelectStatus([...e], statusSelected, dispatch)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Check type="switch" label="Uniquement les commissaires-administrateurs actifs"
                                            checked={onlyActivePopinLabels} onChange={() => {
                                    dispatch(setOnlyActivePopinLabels(!onlyActivePopinLabels))
                                }}/>
                            </Form.Group>
                        </div>
                        <div className="modal-footer mt-4">
                            {(() => {
                                if (displayPdfDownloadLink) {
                                    return <PDFDownloadLink
                                        className="btn btn-light d-none download-pdf-users-label-link"
                                        document={<PdfLabelUser users={usersLabel}/>}
                                        fileName="etiquettes_commissaires_administrateurs.pdf">
                                        <i className="mai me-2 fs-lg">check</i> Valider
                                    </PDFDownloadLink>
                                }
                            })()}

                            <button type="submit" className="btn btn-light">
                                <i className="mai me-2 fs-lg">check</i> Valider
                            </button>
                            {loadingUserLabels &&
                                <Spinner animation="border" variant="primary" size="sm"/>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
