import type {RootState} from "../../redux/Store"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import {
    resetData,
    setCommitteesSelected,
    setMoreEmails,
    setRolesSelected,
    setStatusSelected
} from "../../redux/Emailing/EmailingSlice"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {hexToRGBA} from "../../utils/Color"
import {sendEmails} from "../../api/EmailingAPI"

export default function PopinConfirmSendMail() {

    const dispatch = useAppDispatch()
    const committees = useAppSelector((state: RootState) => state.emailing.committees)
    const committeesSelected = useAppSelector((state: RootState) => state.emailing.committeesSelected)
    const rolesSelected = useAppSelector((state: RootState) => state.emailing.rolesSelected)
    const statusSelected = useAppSelector((state: RootState) => state.emailing.statusSelected)
    const subject = useAppSelector((state: RootState) => state.emailing.subject)
    const contentEmail = useAppSelector((state: RootState) => state.emailing.contentEmail)
    const nbuserRecipients = useAppSelector((state: RootState) => state.emailing.nbuserRecipients)
    const moreEmails = useAppSelector((state: RootState) => state.emailing.moreEmails)

    const sendEmail = async () => {

        dispatch(displayMessage({text: "Vos emails sont en cours d'envoi",}))

        let response = await dispatch(sendEmails({
            committees: committeesSelected,
            roles: rolesSelected,
            statuses: statusSelected,
            moreEmails: moreEmails ? moreEmails.split(new RegExp("[\r\n]", "gm")) : [],
            subject: subject,
            message: contentEmail
        }))

        if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "fulfilled") {
            dispatch(displayMessage({text: "Vos emails ont été envoyés avec succès", status: "success"}))

            // On réinitialise les champs du formulaire d'envoi des emails
            dispatch(resetData())
            const contentEmailing = document.getElementById("editor-instances")
            if (contentEmailing && contentEmailing.firstElementChild) {
                contentEmailing.firstElementChild.innerHTML = ""
            }
            dispatch(setCommitteesSelected([]))
            dispatch(setStatusSelected([]))
            dispatch(setRolesSelected([]))
            dispatch(setMoreEmails(""))
        } else if (response.meta.requestStatus !== undefined && response.meta.requestStatus === "rejected") {
            dispatch(displayMessage({text: "Echec de l'envoi des emails", status: "error"}))
        }
        document.getElementById("btn-close-form-confirm-send-email")?.click()

    }

    let committeeView = []
    let committeeToDisplay = []
    if (committeesSelected.length > 0) {
        committeeToDisplay = committeesSelected
    } else {
        committeeToDisplay = committees
    }
    committeeView = committeeToDisplay.map((committee, index) => {
        return <div className="col-auto" key={index}>
            <div className="badge text-dark"
                 style={{backgroundColor: hexToRGBA(committee.color ? committee.color : "", 0.1)}}>
                <i className="mai me-1" style={{color: committee.color}}>circle</i>
                {committee.title}
            </div>
        </div>
    })

    let rolesView: string[] = []
    if (rolesSelected.length > 0) {
        rolesSelected.forEach((role) => {
            rolesView.push(role.label)
        })
    }

    let statusView: string[] = []
    if (statusSelected.length > 0) {
        statusSelected.forEach((status) => {
            statusView.push(status.label)
        })
    }

    return (
        <div className="modal modal-confirm fade" id="modal-confirm-email-form" data-bs-backdrop="static" tabIndex={-1}>
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirmation d'Envoi</h5>
                    </div>
                    <div className="modal-body">
                        <p>Vous êtes sur le point d'envoyer un courriel
                            à {nbuserRecipients} {Number(nbuserRecipients) > 1 ? "destinataires" : "destinataire"}.</p>
                        <p>Nous vous rappelons la liste de diffusion réalisée :</p>
                        <div className="row row-sm row-sm-h">
                            <div className="col-4">Instances :</div>
                            <div className="col-8">
                                <div className="row row-sm row-sm-h">
                                    {committeeView}
                                </div>
                            </div>
                            <div className="col-4">Fonctions :</div>
                            <div className="col-8"><span
                                className="fw-medium">{rolesView.length > 0 ? rolesView.join(", ") : "Toutes les fonctions"}</span>
                            </div>
                            <div className="col-4">Statuts :</div>
                            <div className="col-8"><span
                                className="fw-medium">{statusView.length > 0 ? statusView.join(", ") : "Tous les statuts"}</span>
                            </div>
                            <div className="col-4">Destinataires supplémentaires :</div>
                            <div className="col-8"><span
                                className="fw-medium">{moreEmails ? moreEmails.replace(new RegExp("[\r\n]", "gm"), ", ") : "Aucun destinataire supplémentaire"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row row-sm row-sm-h">
                            <div className="col-auto">
                                <button type="button" className="btn btn-light" id="btn-close-form-confirm-send-email"
                                        data-bs-dismiss="modal">
                                    <i className="mai fs-lg text-danger me-2">close</i>Annuler
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-light" onClick={() => {
                                    sendEmail()
                                }}><i className="mai fs-lg me-2">check</i>Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
