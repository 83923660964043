import {useAppSelector} from "../../../redux/Hooks";
import {ATTENDING_AVAILABLE, ATTENDING_VISIO} from "../../../api/MeetingUserAPI";

export function RenderedAttendanceButton(props: {type: string, typeCurrentAttendance?: string, shortway?: boolean}) {
    const userCivility = useAppSelector((state) => state.app.userCivility)

    let label: string
    let iconLabel: string
    let iconColor: string

    switch (props.type) {
        case ATTENDING_AVAILABLE:
            label = 'Confirmer ma présence sur site'
            iconLabel = 'check_circle'
            iconColor = 'text-success'
            if (props.typeCurrentAttendance === props.type) {
                label = 'Présence confirmée'
            }
            break;
        case ATTENDING_VISIO:
            label = 'Confirmer ma participation en visio'
            iconLabel = 'headset_mic'
            iconColor = 'text-primary'
            if (props.typeCurrentAttendance === props.type) {
                label = 'Présence confirmée en visio'
            }
            break;
        default:
            label = 'Être excusé'
            iconLabel = 'cancel'
            iconColor = 'text-danger'
            if (props.typeCurrentAttendance === props.type) {
                label = 'Je suis excusé'
            }
            if (!userCivility) {
                label += 'e'
            }
    }

    return <div className="d-flex align-items-center">
        <i className={(props.shortway ? iconColor : '') + ' mai me-2'}>{iconLabel}</i>{label}
    </div>
}
