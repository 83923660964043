import {SyntheticEvent, useEffect, ReactNode, useState, useMemo} from "react"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {textPassword} from "../../utils/Data"
import {useNavigate, useParams} from "react-router-dom"
import {addAdmin, fetchUserById, updateAdmin, updatePeriodsAdmin} from "../../api/UserAPI"
import {Mandate, Period, User} from "../../api/MandateAPI"
import {formatDateToDisplay, checkValidMandate, formatDateInputDate, checkMandateFinished} from "../../utils/Functions"
import {hasRole, getRolesStr, checkpassword, handleTelChange} from "../../utils/UserTools"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Form, Row, Col} from "react-bootstrap"
import ItemPeriodTitle from "../Trombinoscope/ItemPeriodTitle"
import {sortPeriods} from "../../utils/Sorting"
import Tooltip from "../../utils/Tooltip"
import Select from "react-select"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"
import {ChoiceItem} from "../../utils/ChoiceList"
import ProfileForm from "./ProfileForm"
import {resetAllFields, setProperty} from "../../redux/User/AddUserSlice"

export default function AddAdminForm(props: { editMode: boolean, pageProfil?: boolean }) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const params = useParams()
    const addUser = useAppSelector((state: RootState) => state.addUser)
    const userId = useAppSelector((state: RootState) => state.app.userId)
    const roles = useAppSelector((state: RootState) => state.app.roles)

    // Gestion du mot de passe sur la page Profil
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [classInputPassword1, setClassInputPassword1] = useState("")
    const [classInputPassword2, setClassInputPassword2] = useState("")
    const [password1Valid, setPassword1Valid] = useState(false)
    const [password2Valid, setPassword2Valid] = useState(false)
    const [hidePassword, setHidePassword] = useState<boolean>(true)
    const [currentEditingUser, setCurrentEditingUser] = useState<User>()
    const [originalEmail, setOriginalEmail] = useState<string>("")

    // Si je suis sur la page de Mon profil, je ne peux voir que certains champs et n'en éditer que certains
    let readonly = useMemo(() => {
        return props.pageProfil
    }, [props.pageProfil])

    const getUser = async () => {
        if (params.id !== undefined || (props.pageProfil !== undefined && props.pageProfil)) {
            const id_user = props.pageProfil !== undefined && props.pageProfil ? userId : Number(params.id)
            const result = await dispatch(fetchUserById({id_user}))

            if (result.payload !== undefined) {
                const user = result.payload.user
                user?.id && setCurrentEditingUser({
                    id: user.id,
                    lastname: user.lastname,
                    firstname: user.firstname
                })
                if (user !== undefined) {
                    user.email && setOriginalEmail(user.email)
                    dispatch(setProperty({"property": "lastname", "value": user.lastname}))
                    dispatch(setProperty({"property": "firstname", "value": user.firstname}))
                    dispatch(setProperty({"property": "email", "value": user.email}))
                    dispatch(setProperty({"property": "authDiffEmail", "value": user.authDiffEmail}))
                    dispatch(setProperty({property: "dpoValidatedAt", value: user.dpoValidatedAt}))
                    dispatch(setProperty({property: "cguValidatedAt", value: user.cguValidatedAt}))
                    dispatch(setProperty({property: "dpoValidated", value: user.dpoValidated}))
                    dispatch(setProperty({property: "cguValidated", value: user.cguValidated}))
                    dispatch(setProperty({
                        "property": "civilite",
                        "value": user.civility === undefined ? "" : (user.civility === 0 ? "Madame" : "Monsieur")
                    }))
                    dispatch(setProperty({"property": "photo", "value": user.filePath ?? ""}))
                    dispatch(setProperty({"property": "profilActif", "value": user.active}))
                    dispatch(setProperty({"property": "college", "value": user.college ?? ""}))
                    dispatch(setProperty({"property": "adresse", "value": user.address1 ?? ""}))
                    dispatch(setProperty({
                        "property": "authDiffAddress",
                        "value": user.authDiffAddress === undefined ? false : user.authDiffAddress ?? false
                    }))
                    dispatch(setProperty({"property": "codePostal", "value": user.zipcode ?? ""}))
                    dispatch(setProperty({"property": "ville", "value": user.city ?? ""}))
                    dispatch(setProperty({"property": "tel1", "value": handleTelChange(user.phone1) ?? ""}))
                    dispatch(setProperty({
                        "property": "authDifftel1",
                        "value": user.authDifftel1 === undefined ? false : user.authDifftel1 ?? false
                    }))
                    dispatch(setProperty({"property": "tel2", "value": handleTelChange(user.phone2) ?? ""}))
                    dispatch(setProperty({
                        "property": "authDiffTel2",
                        "value": user.authDiffTel2 === undefined ? false : user.authDiffTel2 ?? false
                    }))
                    dispatch(setProperty({"property": "organisationSyndicale", "value": user.syndicate ?? ""}))

                    if (user.mandates !== undefined && user.mandates.length > 0) {
                        dispatch(setProperty({"property": "mandates", "value": user.mandates}))
                        dispatch(setProperty({"property": "mandatesToSend", "value": user.mandates}))
                    }
                    if (user.periods !== undefined && user.periods.length > 0) {
                        dispatch(setProperty({"property": "periods", "value": user.periods}))
                    }
                }
            } else {
                navigate("/annuaire")
            }
        }
    }

    useEffect(() => {
        if (props.editMode) {
            getUser()
        } else {
            dispatch(resetAllFields({}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        let error = false

        // On vérifie les mots de passe si une saisie a été faite
        if (password !== "" || password2 !== "") {
            if (password !== password2) {
                dispatch(displayMessage({text: "Les 2 mots de passe ne sont pas identiques", status: "error"}))
                error = true
            } else if (!password1Valid || !password2Valid) {
                dispatch(displayMessage({text: "Le mot de passe n'est pas pas valide", status: "error"}))
                error = true
            }
        }

        // On vérifie que le collège et l'organisation syndicale sont bien renseignés
        if (addUser.college === undefined) {
            dispatch(displayMessage({text: "Merci de renseigner le collège", status: "error"}))
            error = true
        }
        if (addUser.organisationSyndicale === undefined) {
            dispatch(displayMessage({text: "Merci de renseigner l'organisation syndicale", status: "error"}))
            error = true
        }

        if (!error && !addUser.imageUserLoading) {
            const dataUser = {
                id: props.pageProfil !== undefined && props.pageProfil ? userId : Number(params.id),
                civility: (addUser.civilite === "Monsieur"),
                lastname: addUser.lastname,
                firstname: addUser.firstname,
                filePath: addUser.photo,
                picture: addUser.photo,
                address1: addUser.adresse,
                zipcode: addUser.codePostal,
                city: addUser.ville,
                phone1: addUser.tel1.replaceAll(" ", ""),
                phone2: addUser.tel2.replaceAll(" ", ""),
                college: addUser.college,
                syndicate: addUser.organisationSyndicale,
                passwordSet: true,
                authDiffAddress: addUser.authDiffAddress,
                authDifftel1: addUser.authDifftel1,
                authDiffTel2: addUser.authDiffTel2,
                active: addUser.profilActif,
                email: addUser.email,
                authDiffEmail: addUser.authDiffEmail,
                roles: [
                    "ROLE_USER"
                ],
                periods: addUser.periods,
                password
            }
            if (!props.editMode) {
                const result = await dispatch(addAdmin({
                    user: dataUser
                }))

                if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "fulfilled") {
                    dispatch(displayMessage({text: "Utilisateur ajouté avec succès", status: "success"}))
                    dispatch(resetAllFields({}))

                    if (props.pageProfil) {
                        return navigate("/s-identifier")
                    } else {
                        return navigate("/annuaire")
                    }
                } else if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "rejected") {
                    dispatch(displayMessage({text: "Echec de l'ajout du membre", status: "error"}))
                }
            } else {
                const result = await dispatch(updateAdmin({
                    user: dataUser
                }))

                if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "fulfilled") {
                    dispatch(displayMessage({text: "Mise à jour faite avec succès", status: "success"}))
                    if (props.pageProfil === undefined || !props.pageProfil) {
                        dispatch(resetAllFields({}))
                        navigate("/annuaire")
                    }
                } else if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "rejected") {
                    dispatch(displayMessage({text: "Echec de la mise à jour", status: "error"}))
                }
            }
        }
    }

    const addNewMandate = async (mandate: Mandate, periodToEdit: Period) => {
        let newPeriods = []
        const newPeriodToEdit: Period = {...periodToEdit, mandates: [...periodToEdit.mandates, mandate]}
        for (let period of addUser.periods) {
            if (period.id === newPeriodToEdit.id) {
                newPeriods.push(newPeriodToEdit)
            } else {
                newPeriods.push(period)
            }
        }

        const result = await dispatch(updatePeriodsAdmin({
            user: {
                id: Number(params.id),
                periods: newPeriods
            }
        }))
        dispatch(setProperty({"property": "periods", "value": newPeriods}))
        if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "fulfilled") {
            dispatch(displayMessage({
                text: "La période de mandats a été modifiée avec succès !",
                status: "success"
            }))
        } else if (result.meta.requestStatus !== undefined && result.meta.requestStatus === "rejected") {
            dispatch(displayMessage({
                text: "Echec de la modification de la période de mandats",
                status: "error"
            }))
            return false
        }
    }

    let periodsContent: ReactNode = <></>
    let periodOpen: boolean = false

    if (addUser.periods.length > 0) {
        const sortedPeriods = sortPeriods(addUser.periods)

        periodsContent = sortedPeriods.map((period, index: number) => {
            if (period.mandates.length > 0) {
                if (!period.dateEnd || period.dateEnd === "") {
                    periodOpen = true
                }

                let mandatesToDisplay = [...period.mandates]

                mandatesToDisplay.sort((a, b) => {
                    let fa = a.committee.title.toLowerCase(),
                        fb = b.committee.title.toLowerCase()

                    if (fa > fb) {
                        return 1
                    }

                    if (fa < fb) {
                        return -1
                    }

                    return 0
                })

                return <div key={index} className="bg-light p-4 mb-3">
                    <div className="row row-sm row-sm-h align-items-center mb-2">
                        <div className="col-auto">
                            <ItemPeriodTitle period={period}/>
                        </div>
                        {!readonly && <div className="col-auto">
                            <button className="btn-less text-primary" onClick={(e) => {
                                e.preventDefault()
                                dispatch(setProperty({"property": "showModalAddPeriod", "value": true}))
                                dispatch(setProperty({"property": "editPeriodMode", "value": true}))
                                dispatch(setProperty({"property": "periodToEdit", "value": period}))
                                dispatch(setProperty({
                                    "property": "dateStartPeriod",
                                    "value": formatDateInputDate(period.dateStart)
                                }))
                                dispatch(setProperty({"property": "dateEndPeriod", "value": period.dateEnd}))
                            }}>
                                <i className="mai fs-md">edit</i>
                            </button>
                        </div>}
                        {props.editMode && !readonly &&
                            <div className="col-auto">
                                <a href="/" onClick={(e) => {
                                    e.preventDefault()
                                    dispatch(setProperty({property: "periodToClose", value: period}))
                                    if (!period.dateEnd || period.dateEnd === "") {
                                        dispatch(setProperty({property: "viewCopyPeriodPopin", value: true}))
                                        setTimeout(() => {
                                            document.getElementById("btn-show-confirm-modal-copy-period")?.click()
                                        }, 10)
                                    } else {
                                        dispatch(setProperty({property: "viewCopyPeriodPopin", value: false}))
                                        setTimeout(() => {
                                            dispatch(setProperty({property: "showCopyPeriodLightbox", value: true}))
                                        }, 50)

                                        dispatch(setProperty({property: "editMode", value: false}))
                                    }
                                }}>
                                    <i className="mai fs-md">content_copy</i>
                                </a>
                            </div>
                        }
                        {!readonly && <div className="col-auto">
                            <button className="btn-less text-primary" onClick={(e) => {
                                e.preventDefault()
                                dispatch(setProperty({
                                    property: "periods",
                                    value: addUser.periods.filter((item) => item.id !== period.id)
                                }))
                                dispatch(setProperty({property: "dateStartPeriode", value: ""}))
                                dispatch(setProperty({property: "dateEndPeriode", value: ""}))
                                dispatch(setProperty({property: "mandates", value: []}))
                                dispatch(setProperty({
                                    property: "period", value: {
                                        dateStart: "",
                                        dateEnd: "",
                                        active: true,
                                        mandates: []
                                    }
                                }))
                            }}>
                                <i className="mai fs-md">delete</i>
                            </button>
                        </div>}
                    </div>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Instance</th>
                            <th>Statut</th>
                            <th>Fonction(s)</th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mandatesToDisplay.map((mandate: Mandate, index: number) => {
                            return <tr key={index}
                                       className={!checkValidMandate(mandate) && props.editMode ? "text-main-gray" : ""}>
                                <td>
                                    <div className="d-flex align-items-center fw-medium">
                                        <i className="mai fs-md me-2" style={{
                                            color: mandate.committee.color,
                                            opacity: checkMandateFinished(mandate) ? 0.5 : 1
                                        }}>circle</i>
                                        {mandate.committee.title}
                                    </div>
                                </td>
                                <td>{mandate.status === 0 ? "Titulaire" : "Suppléant"}</td>
                                <td>{getRolesStr(mandate.roles)}</td>
                                <td>{formatDateToDisplay(mandate.dateStart)}</td>
                                <td>{mandate.dateEnd !== null && formatDateToDisplay(mandate.dateEnd)}</td>
                                <td>{props.editMode && !readonly &&
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault()
                                        if (!mandate.dateEnd || mandate.dateEnd === "" || mandatesToDisplay.some(item => (item.dateEnd === null || item.dateEnd === undefined) && item.committee.id === mandate.committee.id && item.id !== mandate.id)) {
                                            dispatch(setProperty({
                                                property: "viewMandateDuplicationWarning",
                                                value: true
                                            }))
                                        } else if (currentEditingUser) {
                                            const newMandate: Mandate = {
                                                dateStart: mandate.dateEnd,
                                                dateEnd: null,
                                                status: mandate.status,
                                                roles: mandate.roles,
                                                user: currentEditingUser,
                                                committee: mandate.committee
                                            }

                                            addNewMandate(newMandate, period)
                                        }
                                    }}>
                                        <i className="mai fs-md">content_copy</i>
                                    </a>
                                }</td>
                            </tr>
                        })}

                        </tbody>
                    </table>
                </div>
            }
            return null
        })
    }

    return (
        <Form onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            handleSubmit(e)
        }} onClick={() => {
            dispatch(setProperty({"property": "usersByName", "value": []}))
        }}>
            <Row>
                <ProfileForm type="admin" pageProfil={props.pageProfil} originalEmail={originalEmail}/>
                <Col lg={4}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-college">Collège *</Form.Label>
                        <Select
                            id="input-college"
                            placeholder="Choisir ..."
                            options={COLLEGES}
                            getOptionLabel={(item: ChoiceItem) => item.label}
                            getOptionValue={(item: ChoiceItem) => item.key.toString()}
                            isDisabled={readonly}
                            value={COLLEGES.find((item) => item.key === addUser.college)}
                            onChange={(e) => {
                                e !== null && dispatch(setProperty({"property": "college", "value": e.key}))
                                dispatch(setProperty({"property": "organisationSyndicale", "value": undefined}))
                            }}/>
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-syndic">Organisation syndicale *</Form.Label>
                        <Select
                            id="input-syndic"
                            options={addUser.college !== undefined ? SYNDICATES[addUser.college] : []}
                            getOptionLabel={(item: ChoiceItem) => item.label}
                            getOptionValue={(item: ChoiceItem) => item.key.toString()}
                            placeholder="Choisir ..."
                            isDisabled={readonly || addUser.college === undefined || SYNDICATES[addUser.college].length === 0}
                            value={addUser.college !== undefined ? SYNDICATES[addUser.college].find((item) => item.key === addUser.organisationSyndicale) : undefined}
                            onChange={(e) => {
                                e !== null && dispatch(setProperty({
                                    "property": "organisationSyndicale",
                                    "value": e.key
                                }))
                            }}/>
                    </Form.Group>
                </Col>
            </Row>
            <h3 className="text-primary mt-6">Adresse</h3>
            <Row>
                <Col lg={12}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-address">Adresse</Form.Label>
                        <Form.Control type="text" value={addUser.adresse} id="input-address" onChange={(e) => {
                            dispatch(setProperty({"property": "adresse", "value": e.target.value}))
                        }}/>
                        <Form.Check
                            type="checkbox"
                            id="auth-diff-adresse-radio"
                            label="Autorisation de diffusion"
                            name="auth-diff-adresse"
                            className="mt-2"
                            checked={addUser.authDiffAddress}
                            onChange={() => {
                                dispatch(setProperty({
                                    "property": "authDiffAddress",
                                    "value": !addUser.authDiffAddress
                                }))
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-zipcode">Code postal</Form.Label>
                        <Form.Control type="text" value={addUser.codePostal} id="input-zipcode" onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/gi, "")
                            if (value.length <= 5) {
                                dispatch(setProperty({"property": "codePostal", "value": e.target.value}))
                            }
                        }}/>
                    </Form.Group>
                </Col>
                <Col lg={9}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-city">Ville</Form.Label>
                        <Form.Control type="text" value={addUser.ville} id="input-city" onChange={(e) => {
                            dispatch(setProperty({"property": "ville", "value": e.target.value}))
                        }}/>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-tel1">Téléphone 1 *</Form.Label>
                        <Form.Control
                            type="tel" required pattern="(?:[\s]*\d{2}){5}" value={addUser.tel1} id="input-tel1"
                            placeholder="-- -- -- -- --" onChange={(e) => {
                            dispatch(setProperty({"property": "tel1", "value": handleTelChange(e.target.value)}))
                        }
                        }/>
                        <Form.Check
                            type="checkbox"
                            id="auth-diff-tel1-radio"
                            label="Autorisation de diffusion"
                            name="auth-diff-tel1"
                            checked={addUser.authDifftel1}
                            className="mt-2"
                            onChange={() => {
                                dispatch(setProperty({"property": "authDifftel1", "value": !addUser.authDifftel1}))
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor="input-tel2">Téléphone 2</Form.Label>
                        <Form.Control
                            pattern="(?:[\s]*\d{2}){5}"
                            type="text" value={addUser.tel2} id="input-tel2" placeholder="-- -- -- -- --"
                            onChange={(e) => {
                                dispatch(setProperty({"property": "tel2", "value": handleTelChange(e.target.value)}))
                            }
                            }/>
                        <Form.Check
                            type="checkbox"
                            id="auth-diff-tel2-radio"
                            label="Autorisation de diffusion"
                            name="auth-diff-tel2"
                            checked={addUser.authDiffTel2}
                            className="mt-2"
                            onChange={() => {
                                dispatch(setProperty({"property": "authDiffTel2", "value": !addUser.authDiffTel2}))
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>

            {props.pageProfil && <>
                <Row>
                    <Col lg={6}>
                        <Form.Label>Votre mot de passe</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={hidePassword ? "password" : "text"}
                                value={password}
                                id="input-password"
                                className={classInputPassword1}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        checkpassword(
                                            {
                                                value: e.target.value,
                                                input: "password1",
                                                password1: password,
                                                password2,
                                                setPassword,
                                                setPassword2,
                                                setPassword1Valid,
                                                setPassword2Valid,
                                                setClassInputPassword1,
                                                setClassInputPassword2
                                            }
                                        )
                                    }
                                    setPassword(e.target.value)
                                }}
                            />
                            <button className="input-group-text" onClick={(e) => {
                                e.preventDefault()
                                setHidePassword(!hidePassword)
                            }}><span
                                className="mai">{hidePassword ? "visibility" : "lock"}</span>
                            </button>
                        </div>
                        <div className="form-text mb-0 help-text">{textPassword}</div>
                    </Col>
                    <Col lg={6}>
                        <Form.Label>Confirmez votre mot de passe</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={hidePassword ? "password" : "text"}
                                value={password2}
                                id="input-password-repeat"
                                className={classInputPassword2}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        checkpassword(
                                            {
                                                value: e.target.value,
                                                input: "password2",
                                                password1: password,
                                                password2,
                                                setPassword,
                                                setPassword2,
                                                setPassword1Valid,
                                                setPassword2Valid,
                                                setClassInputPassword1,
                                                setClassInputPassword2
                                            }
                                        )
                                    }
                                    setPassword2(e.target.value)
                                }}
                            />
                            <button className="input-group-text" onClick={(e) => {
                                e.preventDefault()
                                setHidePassword(!hidePassword)
                            }}><span
                                className="mai">{hidePassword ? "visibility" : "lock"}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </>}

            <h3 className="text-primary mt-6">Mandats</h3>
            {periodsContent}
            {(hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)) &&
                <Tooltip message={`${periodOpen ? "Une période mandats est déjà ouverte" : ""}`}>
                    <button
                        className={`btn-less${periodOpen ? " text-disabled-gray text-hover-disabled-gray" : " text-hover-primary"}`}
                        disabled={periodOpen}
                        onClick={(e) => {
                            e.preventDefault()
                            dispatch(setProperty({"property": "mandates", "value": []}))
                            dispatch(setProperty({"property": "dateStartPeriod", "value": formatDateInputDate()}))
                            dispatch(setProperty({"property": "dateEndPeriod", "value": ""}))
                            dispatch(setProperty({"property": "showModalAddPeriod", "value": true}))
                            dispatch(setProperty({"property": "editPeriodMode", "value": false}))
                        }}>
                        <i className={`mai fs-md${periodOpen ? " text-disabled-gray" : " text-primary"}`}>add</i>
                        <span className="fw-semibold ms-2">Ajouter une période de mandats</span>
                    </button>
                </Tooltip>
            }

            <div className="text-center mt-3 pt-4 pb-4 bg-white sticky-bottom">
                <button type="submit" className="btn btn-light">
                    <i className="mai me-2">save</i> Enregistrer
                </button>
            </div>
        </Form>
    )
}
