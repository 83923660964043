import React from "react"
import {useAppSelector, useAppDispatch} from "../redux/Hooks"
import {useNavigate} from "react-router-dom"
import type {RootState} from "../redux/Store"
import {Link} from "react-router-dom"
import {toggleMenu} from "../redux/Navigation/SidebarSlice"
import {ReactComponent as ComputerClick} from "../_images/icons/computer-click.svg"
import {ReactComponent as AvatarIcon} from "../_images/icons/avatar.svg"
import {ReactComponent as EnvelopeIcon} from "../_images/icons/envelope.svg"
import {Col, Row} from "react-bootstrap"
import {authLogout} from "../services/auth"
import {getCookie, removeCookie} from "../utils/cookies"
import {data} from "../utils/Data"

export default function Header() {

    const dispatch = useAppDispatch()
    const firstname = useAppSelector((state: RootState) => state.app.firstname)
    const lastname = useAppSelector((state: RootState) => state.app.lastname)
    const menuAndClick = useAppSelector((state: RootState) => state.sidebar.menuAndClick)
    const appState = useAppSelector((state) => state.app.state)
    const user = useAppSelector(state => state.app.user)
    const navigate = useNavigate()
    const token = getCookie("accessToken")

    const redirectUserAccount = () => {
        if (token) {
            return navigate("/mon-profil")
        }
    }

    const logout = () => {
        const dataLogout = {
            accessToken: getCookie("accessToken") || "",
            refreshToken: getCookie("refreshToken") || ""
        }
        dispatch(authLogout(dataLogout))
        return navigate(`/s-identifier`)
    }

    const connectAdminAsAdmin = () => {
        const url: string = `/switch-user/${getCookie("adminToken")}/${getCookie("adminUsername")}`
        removeCookie("adminToken")
        removeCookie("adminUsername")
        return navigate(url)
    }

    return (
        <header className="header">
            <Row className="row-0">
                {user.id && appState === "app" &&
                    <Col xs="auto">
                        <button onClick={() => {
                            dispatch(toggleMenu())
                        }} className={menuAndClick + "open-menu border-0"}>
                            <i className="mai d-none d-lg-block">keyboard_double_arrow_right</i>
                            <span className="mai mai-menu d-lg-none">menu</span>
                        </button>
                    </Col>
                }
                <Col>
                    <div className="header-base">
                        <div className="container-header">
                            <Row className="row-sm justify-content-between align-items-center h-100">
                                <Col xs="auto" className="h-100">
                                    <Link to={token ? "/accueil" : "/s-identifier"} className="logo">
                                        <img
                                            src={data.context === "dev" ? require("../_images/logo-bas.svg").default : require("../_images/logo.svg").default}
                                            alt="transitions-pro"
                                            className="img-fluid-height" width="252" height="108"/>
                                    </Link>
                                </Col>
                                {user.id && appState === "app" &&
                                    <Col className="h-100">
                                        <Row className="gx-3 gx-lg-4 align-items-center justify-content-end h-100">
                                            {
                                                getCookie("adminToken") && <Col xs="auto">
                                                    <button className="btn btn-less text-danger"
                                                            onClick={() => connectAdminAsAdmin()}>
                                                        <Row className="row-sm">
                                                            <Col xs="auto"><span
                                                                className="mai text-size-21px">swap_horiz</span></Col>
                                                            <Col xs="auto">Webmaster</Col>
                                                        </Row>
                                                    </button>
                                                </Col>
                                            }
                                            <Col xs="auto" className="d-none d-sm-block">
                                                <a className="row row-sm align-items-center text-dark text-hover-primary fill-hover-primary"
                                                   href="https://atnet.transitionspro-idf.fr/" target="_blank"
                                                   rel="noreferrer">
                                                    <Col xs="auto"><ComputerClick/></Col>
                                                    <Col xs="auto">ATNET</Col>
                                                </a>
                                            </Col>
                                            <Col xs="auto" className="text-end">
                                                <div className="row row-sm row-sm-h align-items-center">
                                                    <Col xs="auto" className="d-none d-lg-block">
                                                        <EnvelopeIcon/>
                                                    </Col>
                                                    <Col>
                                                        <Link to={"/contact"} className="d-block">
                                                            <span className="text-dark text-hover-primary">
                                                                Contactez-nous
                                                            </span>
                                                        </Link>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xs="auto">
                                                <div className="dropdown">
                                                    <button className="btn-less" id="dropdownMenuButton" type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        <Row className="row-sm row-sm-h">
                                                            <Col xs="auto"><AvatarIcon/></Col>
                                                            <Col className="d-none d-lg-block">
                                                                <span className="d-block fs-xs">Bonjour,</span>
                                                                <span
                                                                    className="d-block fs-xs text-uppercase fw-medium">{firstname} {lastname}</span>
                                                            </Col>
                                                        </Row>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-end"
                                                         aria-labelledby="dropdownMenuButton">
                                                        <button className="dropdown-item" onClick={() => {
                                                            redirectUserAccount()
                                                        }}>
                                                            <Row className="row-sm flex-nowrap align-items-center">
                                                                <Col xs="auto"><i className="mai fs-md">person</i></Col>
                                                                <Col>Compte utilisateur</Col>
                                                            </Row>
                                                        </button>

                                                        <button className="dropdown-item" onClick={() => {
                                                            logout()
                                                        }}>
                                                            <Row className="row-sm flex-nowrap align-items-center">
                                                                <Col xs="auto"><i className="mai fs-md">logout</i></Col>
                                                                <Col>Déconnexion</Col>
                                                            </Row>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </header>
    )
}
