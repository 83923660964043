import React from "react"
import { useAppDispatch } from "../../../redux/Hooks"
import { addDocument } from "../../../redux/Meeting/MeetingFormSlice"

export function ItemDocumentAddButton(props: {type?: number, label?: string}) {
    const dispatch = useAppDispatch()

    function addElement() {
        dispatch(addDocument({type: props.type}))
    }

    return <div className="mt-4">
        <button type="button" className="btn-less link text-dark text-hover-primary" onClick={() => addElement()}>
            <i className="mai me-2 fs-md text-primary">add</i> {props.label ?? 'Ajouter un document'}
        </button>
    </div>
}
