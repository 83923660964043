import {Meeting, MEETING_TYPE_MAIN} from "../../api/MeetingAPI"
import {Document} from "../../api/DocumentAPI"
import {setCommitteeSelected} from "../../redux/Committee/CommitteeSlice"
import {
    setDocuments,
    setMeetingDate,
    setMeetingTime,
    setPlace,
    setPublished,
    setLunchAvailable,
    setSelectedMeeting,
    setSpecial,
    setVideoLink
} from "../../redux/Meeting/MeetingFormSlice"
import {setModalAdminShow, setModalAdminType} from "../../redux/Meeting/MeetingSlice"
import {hasRole} from "../../utils/UserTools"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {ROLES_MANAGE_MEETING} from "../../api/UserAPI"
import {fetchMandatesForCommittee} from "../../api/MandateAPI"
import {getFormattedLocalisedDate} from "../../utils/Date"

export function EditMeetingButton(props: { meeting: Meeting, shortway?: boolean, documents?: Document[] }) {
    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)

    function setSelected(meeting: Meeting) {
        dispatch(fetchMandatesForCommittee({
            options: {
                "committee.id": meeting.committee.id,
                "dateStart[before]": meeting.title,
                "dateEnd[after]": meeting.title,
                "period.user.active": true
            }
        }))
        let time: string = "09:00"
        let date: string = getFormattedLocalisedDate(new Date(), "MM/dd/yyyy")
        meeting.meetingOccurrences.forEach((mo) => {
            if (mo.type === MEETING_TYPE_MAIN && mo.date) {
                date = getFormattedLocalisedDate(new Date(mo.date), "MM/dd/yyyy")
                time = getFormattedLocalisedDate(new Date(mo.date), "HH:mm")
            }
        })
        dispatch(setCommitteeSelected(meeting.committee))
        dispatch(setSelectedMeeting(meeting))
        props.meeting.documents && dispatch(setDocuments(props.meeting.documents))
        dispatch(setPublished(meeting.published))
        dispatch(setLunchAvailable(meeting.lunchAvailable))
        dispatch(setSpecial(meeting.special))
        dispatch(setMeetingDate(date))
        dispatch(setMeetingTime(time))
        dispatch(setPlace(meeting.place))
        dispatch(setVideoLink(meeting.videoLink))
        dispatch(setModalAdminShow(true))
        dispatch(setModalAdminType("edition"))
    }

    if (props.meeting && hasRole(ROLES_MANAGE_MEETING, roles)) {
        if (props.shortway) {
            return <button className="btn btn-icon btn-sm text-primary" onClick={() => setSelected(props.meeting)}>
                <span className="mai fs-lg">edit_calendar</span>
            </button>
        }

        return <button className="btn btn-primary" onClick={() => setSelected(props.meeting)}>
            <span className="mai fs-md me-2">edit</span> Modifier la séance
        </button>
    }
    return <></>
}
