import {Mandate, Period} from "../api/MandateAPI"
import {User} from "../api/UserAPI"
import {Committee} from "../api/CommitteeAPI"
import {Meeting} from "../api/MeetingAPI"
import {setCommitteesSelected, setRolesSelected, setStatusSelected} from "../redux/User/TrombinoscopeSlice"
import {CommitteeSelectItem, SelectOption} from "./CommonInterfaces"
import {data} from "./Data"
import {ChoiceItem} from "./ChoiceList"
import {getFormattedLocalisedDate, getToday} from "./Date"
import {MeetingUser} from "../api/MeetingUserAPI"
import {format} from "date-fns"

// On vérifie que le commissaire-administrateur peut accéder à une séance et aux documents
export function isActiveInMeetingCommittee(mandates: Mandate[], meeting: Meeting): boolean {

    let isActive = false
    mandates.forEach((mandate) => {
        if (mandate.committee.id === meeting.committee.id) {
            if (mandate.dateStart !== undefined &&
                meeting.title !== undefined &&
                getFormattedLocalisedDate(new Date(mandate.dateStart), "yyyy-MM-dd") <= getFormattedLocalisedDate(new Date(meeting.title), "yyyy-MM-dd")
            ) {
                if (mandate.dateEnd === undefined || mandate.dateEnd === null || (getFormattedLocalisedDate(new Date(mandate.dateEnd), "yyyy-MM-dd") > getFormattedLocalisedDate(new Date(meeting.title), "yyyy-MM-dd"))) {
                    isActive = true
                }
            }
        }
    })

    return isActive
}

// On vérifie que
export function isReplacement(meetingUsers: MeetingUser[], userId: number): boolean {
    let replacement = false

    meetingUsers.forEach(meetingUser => {
        if (meetingUser.replacedBy?.id === userId) {
            replacement = true
        }
    })

    return replacement
}

// On vérifie que le commissaire-administrateur peut accéder aux documents d'une année
// Condition : avoir un mandat actif dans l'année
export function isActiveInYearCommittee(mandates: Mandate[], committeeId: number, year: number): boolean {
    let isActive = false
    mandates.forEach((mandate) => {
        if (mandate.committee.id === committeeId) {
            if ((mandate.dateStart !== undefined &&
                    getFormattedLocalisedDate(new Date(mandate.dateStart), "yyyy") <= year.toString()) &&
                (mandate.dateEnd === undefined || mandate.dateEnd === null ||
                    getFormattedLocalisedDate(new Date(mandate.dateEnd), "yyyy") >= year.toString())
            ) {
                isActive = true
            }
        }
    })

    return isActive
}

// On vérifie que le commissaire-administrateur a un mandat actif dans l'instance
export function isActiveInCommittee(mandates: Mandate[], userId: number, committee: Committee): boolean {

    let isActive = false
    // Je suis actif si j'ai un mandat actif pour cett instance
    mandates.forEach((mandate) => {
        if (mandate.committee.id === committee.id && mandate.user.id === userId) {
            if (mandate.dateStart !== undefined &&
                getFormattedLocalisedDate(new Date(mandate.dateStart), "yyyy-MM-dd") <= getToday()
            ) {
                isActive = true
            }
        }
    })

    return isActive
}

// On récupère l'instance par son slug dans la liste des instances
export function getCommitteeBySlug(props: {
    slug: string | undefined | null,
    committees: Committee[]
}): Committee | null {
    if (props.slug === undefined || props.slug === null || props.committees === null) {
        return null
    }

    let committee = null
    props.committees.forEach((com) => {
        if (props.slug !== undefined && props.slug !== null && com.slug === props.slug && com.canViewDocs) {
            committee = com
        }
    })
    return committee
}


export const checkValidMandate = (mandate: Mandate) => { // Vérifie si le mandat en paramètre est toujours valide à la date d'aujourd'hui
    let mandateValid = false
    let dateNbDayNow = Math.floor((Date.now() / 1000 + 7200) / 86400)

    let dt = mandate.dateStart !== undefined ? mandate.dateStart : (mandate.date_start !== undefined ? mandate.date_start : "")
    let dateStartTimestamp = new Date(dt).getTime() / 1000
    let dateStartNbDay = Math.floor(dateStartTimestamp / 86400)

    let dateEnd = mandate.dateEnd !== undefined ? (mandate.dateEnd !== null ? mandate.dateEnd : "") : (mandate.date_end !== undefined && mandate.date_end !== null ? mandate.date_end : "")

    if (dateEnd !== "") {
        let dateEndTimestamp = new Date(dateEnd).getTime() / 1000
        let dateEndNbDay = Math.floor(dateEndTimestamp / 86400)
        if (dateStartNbDay <= dateNbDayNow && dateEndNbDay >= dateNbDayNow) {
            mandateValid = true
        }
    } else {
        if (dateStartNbDay <= dateNbDayNow) {
            mandateValid = true
        }
    }

    return mandateValid
}

export const checkMandateFinished = (mandate: Mandate) => { // Vérifie si le mandat en paramètre est toujours valide à la date d'aujourd'hui

    let mandateFinished = false

    let dateEnd = mandate.dateEnd !== undefined ? (mandate.dateEnd !== undefined && mandate.dateEnd !== null ? mandate.dateEnd : "") : (mandate.date_end !== undefined && mandate.date_end !== null ? mandate.date_end : "")

    if (dateEnd !== undefined && dateEnd !== "" && dateEnd !== null) {
        let dateEndTimestamp = new Date(dateEnd).getTime() / 1000
        let dateEndNbDay = Math.floor(dateEndTimestamp / 86400)

        let dateNbDayNow = Math.floor((Date.now() / 1000 + 7200) / 86400)

        if (dateEndNbDay <= dateNbDayNow) {
            mandateFinished = true
        }
    }

    return mandateFinished
}

export const checkValidPeriod = (period: Period) => { // Vérifie si la période de mandats en paramètre est toujours valide à la date d'aujourd'hui

    let periodValid = false

    let dateSTartTimestamp = new Date(period.dateStart).getTime() / 1000
    let dateStartNbDay = Math.floor(dateSTartTimestamp / 86400)

    let dateNbDayNow = Math.floor((Date.now() / 1000 + 7200) / 86400)

    if (period.dateEnd !== undefined && period.dateEnd !== "" && period.dateEnd !== null) {
        let dateEndTimestamp = new Date(period.dateEnd).getTime() / 1000
        let dateEndNbDay = Math.floor(dateEndTimestamp / 86400)

        if (dateStartNbDay <= dateNbDayNow && dateEndNbDay >= dateNbDayNow) {
            periodValid = true
        }
    } else {
        if (dateStartNbDay <= dateNbDayNow) {
            periodValid = true
        }
    }

    return periodValid
}

export const formatDateInputDate = (date: string | Date = "") => { // Formate une date comme ceci : YYYY-MM-DD (Pour les input date)
    let dateStr = new Date()
    if (date !== "") {
        dateStr = new Date(date)
    }
    return dateStr.getFullYear() + "-" + (dateStr.getMonth() + 1 > 9 ? dateStr.getMonth() + 1 : "0" + (dateStr.getMonth() + 1)) + "-" + (dateStr.getDate() > 9 ? dateStr.getDate() : "0" + (dateStr.getDate()))
}

export const formatDateToDisplay = (date: string | Date = "") => { // Format une date comme ceci : DD-MM-YYYY

    if (date === "") {
        return ""
    }
    let dateStr = new Date()
    if (date !== "") {
        dateStr = new Date(date)
    }

    return (dateStr.getDate() > 9 ? dateStr.getDate() : "0" + (dateStr.getDate())) + "/" + (dateStr.getMonth() + 1 > 9 ? dateStr.getMonth() + 1 : "0" + (dateStr.getMonth() + 1)) + "/" + dateStr.getFullYear()
}

export const getDateEndLastMandate = (mandates: Mandate[]) => {

    let datesEndMandates = []

    for (let mandate of mandates) {
        if (typeof mandate.dateEnd === "string") {
            let dateEndMandateTimestamp = Math.floor(new Date(mandate.dateEnd).getTime() / 1000)
            datesEndMandates.push(dateEndMandateTimestamp)
        }
    }

    return Math.max(...datesEndMandates)
}

interface RoleContent {
    value: string | number
}

export const hasRolesInCommittees = (user: User, committees: Committee[], roles: RoleContent[]) => { // Vérifie si l'user saisi en 1er argument à au moins un role saisi en 3ème argument dans les instances saisies en 2ème argument


    if (user.mandates !== undefined && user.mandates.length > 0) {
        for (let mandate of user.mandates) {
            for (let committee of committees) {
                if (mandate.committee.id === committee.id && checkValidMandate(mandate)) {
                    for (let role of roles) {
                        for (let r of mandate.roles) {
                            if (r === role.value) {
                                return true
                            }
                        }
                    }
                }
            }
        }
    } else {
        return false
    }

    return false

}

interface StatusContent {
    value?: string | number
}

export const hasStatusInCommittees = (user: User, committees: Committee[], status: StatusContent[]) => {

    if (user.mandates !== undefined && user.mandates.length > 0) {
        for (let mandate of user.mandates) {
            for (let committee of committees) {
                let statusOK = false
                for (let st of status) {
                    if (st.value === mandate.status) {
                        statusOK = true
                    }
                }
                // status.includes(mandate.status)
                if (mandate.committee.id === committee.id && statusOK && checkValidMandate(mandate)) {
                    return true
                }
            }
        }
    } else {
        return false
    }

    return false

}

export const commiteeInCommittees = (committee: Committee, committees: Committee[]) => {

    for (let com of committees) {
        if (com.id === committee.id) {
            return true
        }
    }

    return false

}

export const hexToRGBA = (hex: string, alpha = 1): string => {
    if (alpha > 1 || alpha < 0) {
        throw new Error("alpha is not correct!")
    }

    const red = parseInt(hex.slice(1, 3), 16)
    const green = parseInt(hex.slice(3, 5), 16)
    const blue = parseInt(hex.slice(5, 7), 16)

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

interface IDispatch {
    payload: object;
    type: string;
}

export const updateSelectCommittee = (
    comsSelected: CommitteeSelectItem[],
    committeesSelected: Committee[],
    committees: Committee[],
    dispatch: (arg: IDispatch) => (IDispatch)) => {

    let modif: string = ""

    if (comsSelected.length > committeesSelected.length) {
        modif = "add"
    } else if (comsSelected.length < committeesSelected.length) {
        modif = "delete"
    }
    let newCommittees: Committee[] = []

    if (modif === "add") { // Si on a ajouté une instance dans le select
        for (let committeeSelected of comsSelected) {
            for (let committee of committees) {
                if (committee.id === committeeSelected.value) {
                    newCommittees.push(committee)
                }
            }
        }
        dispatch(setCommitteesSelected([...newCommittees]))
    }

    if (modif === "delete") { // Si on a supprimé une instance dans le select
        for (let committee of comsSelected) {
            // let exist = false
            for (let com of committeesSelected) {
                if (com.id === committee.value) {
                    newCommittees.push(com)
                }
            }
        }
        dispatch(setCommitteesSelected([...newCommittees]))
    }
}

export const updateSelectRoles = (
    rlsSelected: ChoiceItem[],
    rolesSelected: ChoiceItem[],
    dispatch: (arg: IDispatch) => (IDispatch)) => {

    let modif: string = ""

    if (rlsSelected.length > rolesSelected.length) {
        modif = "add"
    } else if (rlsSelected.length < rolesSelected.length) {
        modif = "delete"
    }
    let newRoles: ChoiceItem[] = []

    if (modif === "add") { // Si on a ajouté une fonction dans le select multiple
        for (let roleSelected of rlsSelected) {
            data.MANDATE_ROLES.forEach((role) => {
                if (role.key === roleSelected.key) {
                    newRoles.push({key: role.key, label: role.label})
                }
            })

        }

        dispatch(setRolesSelected([...newRoles]))
    }

    if (modif === "delete") { // Si on a supprimé une fonction dans le select multiple
        data.MANDATE_ROLES.forEach((status) => {
            for (let rl of rlsSelected) {
                if (Number(rl.key) === status.key) {
                    newRoles.push({key: rl.key, label: status.label})
                }
            }
        })
        dispatch(setRolesSelected([...newRoles]))
    }
}

export const updateSelectStatus = (
    stsSelected: SelectOption[], // Statuts que l'on a dans le select après le change
    statusSelected: SelectOption[], // Statuts que l'on a dans le select avant le change
    dispatch: (arg: IDispatch) => (IDispatch)) => {
    let modif: string = ""
    if (stsSelected.length > statusSelected.length) {
        modif = "add"
    } else if (stsSelected.length < statusSelected.length) {
        modif = "delete"
    }
    let newStatus: SelectOption[] = []

    if (modif === "add") { // Si on a ajouté un statut dans le select multiple
        for (let stSelected of stsSelected) {
            data.STATUS.forEach((status, key) => {
                if (Number(key) === Number(stSelected.value)) {
                    newStatus.push({value: key, label: status})
                }
            })
        }
        dispatch(setStatusSelected([...newStatus]))
    }

    if (modif === "delete") { // Si on a supprimé un statut dans le select multiple
        data.STATUS.forEach((status, ks) => {
            for (let st of stsSelected) {
                if (Number(st.value) === Number(ks)) {
                    newStatus.push({value: st.value, label: status})
                }
            }
        })
        dispatch(setStatusSelected([...newStatus]))
    }
}
