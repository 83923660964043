import React from "react"
import {Document, displayDocument} from "../../api/DocumentAPI"
import {DOC_TO_SIGN} from "../../api/CommitteeAPI"
import ItemDocumentUser from "./ItemDocumentUser"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {useAppDispatch} from "../../redux/Hooks"
import {Meeting} from "../../api/MeetingAPI"
import {Row, Col} from "react-bootstrap"

export default function ItemDocumentByMeeting(props: { doc: Document, meeting: Meeting, context: string }) {
    const dispatch = useAppDispatch()
    let typeCategory: number | null = null

    // On affiche la catégorie du document attendu si le document est relié à un document attendu
    if (props.doc.expectedDocument !== undefined && props.doc.expectedDocument.category !== undefined) {
        typeCategory = props.doc.expectedDocument.category.type
    }

    let title = props.doc.title
    if ((title === undefined || title === "") && props.doc.fileOriginalName) {
        title = props.doc.fileOriginalName
    }

    return <div
        className={`list-group-item list-group-item-main-gray${props.context !== "calendar" ? "-light" : ""} py-2 px-3 rounded-pill mt-2`}
        key={props.doc.id}>
        <Row className="row-xs row-xs-h align-items-center">
            <Col xs="auto">
                {
                    props.doc.fileExtension === "pdf"
                        ? <i className="mai fs-lg text-primary">picture_as_pdf</i>
                        : (props.doc.fileExtension === "zip"
                                ? <i className="mai fs-lg text-primary">folder_zip</i>
                                : <i className="mai fs-lg text-primary">table_view</i>
                        )
                }
            </Col>
            <Col>
                <Row className="row-xs row-xs-h align-items-xl-center">
                    <Col xl>{title}</Col>
                    {
                        typeCategory === DOC_TO_SIGN &&
                        <ItemDocumentUser doc={props.doc} meeting={props.meeting}/>
                    }
                </Row>
            </Col>
            <Col lg="auto">
                <Row className="row-xs row-xs-h align-items-center justify-content-center">
                    <Col xs="auto">
                        <i className="mai text-primary cursor-pointer" onClick={() => {
                            getFile(props.doc, "view").catch(() => {
                                dispatch(displayMessage({
                                    text: "Echec de la récupération du document",
                                    status: "error"
                                }))
                            })
                        }}>visibility</i>
                    </Col>
                    <Col xs="auto">
                        <i className="mai text-primary cursor-pointer" onClick={() => {
                            getFile(props.doc, "dwn").catch(() => {
                                dispatch(displayMessage({
                                    text: "Echec de la récupération du document",
                                    status: "error"
                                }))
                            })
                        }}>download</i>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

// On va chercher le fichier à afficher ou télécharger.
// Si l'utilisateur a les droits, on lui renvoie le fichier.
export function getFile(doc: Document, action: string) {
    const promiseGetFile = displayDocument(action, doc.id)
    let mimeType = "application/pdf"
    if (doc.fileExtension === "xls") {
        mimeType = "application/vnd.ms-excel"
    } else if (doc.fileExtension === "xlsx") {
        mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    } else if (doc.fileExtension === "zip") {
        mimeType = "application/zip"
    }
    return promiseGetFile.then((data) => {
        const file = new Blob(
            [data],
            {type: mimeType}
        )

        const filePath = doc.filePath ? doc.filePath.split("/") : undefined

        let filename = doc.filename
        if (filePath !== undefined) {
            filename = filePath[filePath.length - 1]
        }
        const fileURL = URL.createObjectURL(file)

        const link = document.createElement("a")
        link.href = fileURL

        if (action === "view") {
            link.target = "_blank"
        } else if (filename !== undefined) {
            link.download = filename
        }
        link.click()
    })
}
