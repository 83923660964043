import {createAsyncThunk} from "@reduxjs/toolkit"
import {axiosInstance} from "../utils/http-common"
import {FULL_MANDATE_API} from "../utils/Data"
import {Options, toQuery} from "../utils/Options"
import {getToday} from "../utils/Date"

const AxiosInstance = axiosInstance()

export interface Mandate {
    id?: number;
    dateStart: Date | string;
    date_start?: Date | string;
    dateEnd?: Date | string | null;
    date_end?: Date | string;
    status?: number | string;
    roles: number[];
    user: User;
    committee: Committee;
    createdDate?: string;
    updatedDate?: string;
    period?: {
        user: {
            active: boolean
        }
    }
}

export interface Period {
    id?: number;
    dateStart: Date | string;
    date_start?: Date | string;
    dateEnd?: Date | string | null;
    date_end?: Date | string;
    active: boolean;
    mandates: Mandate[];
}

export interface User {
    id: number,
    lastname: string,
    firstname: string,
    enabled?: boolean,
    picture?: string,
    college?: number,
    syndicate?: number,
    periods?: Period[]
}

interface Committee {
    id: number,
    title: string,
    slug: string,
    onlyReplacedByCommitteeMembers?: boolean,
    color?: string
}

export const fetchMandates = createAsyncThunk<Mandate[]>(
    "mandates/fetchAll",
    async () => {
        const {data} = (await AxiosInstance.get(`${FULL_MANDATE_API}` + toQuery({"dateEnd[after]": getToday()})))
        return data["hydra:member"]
    }
)

export const fetchMandatesForCommittee = createAsyncThunk<Mandate[], { options?: Options }>(
    "mandates/fetchForCommittee",
    async (mandateData) => {
        const {data} = (await AxiosInstance.get(`${FULL_MANDATE_API}` + toQuery(mandateData.options)))

        return data["hydra:member"]
    }
)

export const fetchMandatesWithOption = createAsyncThunk<{ data: Mandate[], context?: string }, {
    options?: Options,
    context?: string
}>(
    "mandates/fetchWithOption",
    async (mandateData) => {
        const {data} = (await AxiosInstance.get(`${FULL_MANDATE_API}` + toQuery(mandateData.options)))

        return {
            data: data["hydra:member"],
            context: mandateData.context
        }
    }
)

export const fetchUserMandates = createAsyncThunk<Mandate[], { options?: Options }>(
    "mandates/fetchUserWithOption",
    async (mandateData) => {
        if (mandateData.options !== undefined) {
            mandateData.options["dateEnd[after]"] = getToday()
        }
        const {data} = (await AxiosInstance.get(`${FULL_MANDATE_API}` + toQuery(mandateData.options)))
        return data["hydra:member"]
    }
)
