import {Meeting} from "../../../api/MeetingAPI"
import {useAppDispatch} from "../../../redux/Hooks"
import {setMeetingSelected, setModalShow, setTypeAttendance} from "../../../redux/Meeting/MeetingSlice"
import {RenderedAttendanceButton} from "./RenderedAttendanceButton"

export function ValidAttendanceButton(props: {
    meeting: Meeting,
    type: string,
    shortway?: boolean,
    typeCurrentAttendance?: string,
    disabled?: boolean
}) {
    const dispatch = useAppDispatch()

    function setSelected() {
        dispatch(setMeetingSelected(props.meeting.id))
        dispatch(setTypeAttendance(props.type))
        dispatch(setModalShow(true))
    }

    if (props.disabled) {
        return <button
            className={`btn cursor-default btn-main-gray-light ${props.shortway ? "dropdown-item" : "py-3 px-4"}`}
        >
            <RenderedAttendanceButton type={props.type} shortway={props.shortway}
                                      typeCurrentAttendance={props.typeCurrentAttendance}/>
        </button>
    }

    return <button
        className={`btn ${(props.typeCurrentAttendance === props.type) ? "btn-primary" : "btn-light"} ${props.shortway ? "dropdown-item" : "py-3 px-4"}`}
        onClick={() => setSelected()}
    >
        <RenderedAttendanceButton type={props.type} shortway={props.shortway}
                                  typeCurrentAttendance={props.typeCurrentAttendance}/>
    </button>
}
