import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {hasRole} from "../../utils/UserTools"
import {setAttendanceShow} from "../../redux/Committee/CommitteeAttendanceFormSlice"
import {RootState} from "../../redux/Store"
import {ROLES_MANAGE_MEETING} from "../../api/UserAPI"

/* Feuille de présence */
export function EditCommitteeAttendance() {
    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)

    if (hasRole(ROLES_MANAGE_MEETING, roles)) {
        return <button className="btn btn-lg btn-icon rounded-circle btn-tertiary" onClick={() => {
            dispatch(setAttendanceShow(true))
        }}>
            <i className="mai fs-lg">checklist</i>
        </button>
    }
    return <></>
}
