import {useEffect, useRef} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useAppDispatch} from "../redux/Hooks"
import Loading from "../components/Loading"
import {connectUser} from "../redux/User/UserSlice"
import {getUserByToken} from "../api/UserAPI"
import {getCookie, setCookie} from "../utils/cookies"
import {authLogout} from "../services/auth"

export default function SwitchUser() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const requestToken: string | undefined = params.token
    const username: string | undefined = params.username
    const mounted = useRef()

    useEffect(() => {
        !mounted.current && componentDidMount()
    })

    const componentDidMount = () => {
        const dataLogout = {
            accessToken: getCookie("accessToken") || "",
            refreshToken: getCookie("refreshToken") || ""
        }
        Promise
            .all([
                dispatch(authLogout(dataLogout)),
                requestToken && username && dispatch(connectUser({token: requestToken, username: username}))
            ])
            .then(() => {
                requestToken && setCookie("accessToken", requestToken)
                requestToken && dispatch(getUserByToken({token: requestToken}))
            })
            .then(() => {
                navigate("/accueil")
            })
        // @ts-ignore
        mounted.current = true
    }

    return <Loading/>
}
