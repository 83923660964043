import {useEffect} from "react"
import {useAppSelector, useAppDispatch} from "../redux/Hooks"
import AddAssistantForm from "./AddUser/AddAssistantForm"
import AddAdminForm from "./AddUser/AddAdminForm"
import type {RootState} from "../redux/Store"
import {setPage} from "../redux/User/UserSlice"
import {isAdmin} from "../utils/UserTools"
import PageHeader from "./PageHeader"
import {Link} from "react-router-dom"
import {format} from "date-fns"
import {fr} from "date-fns/locale"

export default function UserProfile() {

    const dispatch = useAppDispatch()
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const user = useAppSelector((state: RootState) => state.app.user)

    useEffect(() => {
        dispatch(setPage("my-profile"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageHeader title="Mon profil" breadcrumb/>
            <div className="container">
                <div className="block">
                    <h3 className="text-primary">Informations générales</h3>
                    {
                        isAdmin(roles) ?
                            <AddAdminForm editMode={true} pageProfil={true}/> :
                            <AddAssistantForm editModeAssistant={true} pageProfil={true}/>
                    }

                    <p className="text-main-gray fst-italic">J’ai déclaré avoir pris connaissance et accepter sans
                        réserves les
                        règles
                        de <Link target="_blank" to={"/conditions-generales-d-utilisation"}>confidentialité et
                            de protection des données
                            personnelles</Link> le {user.dpoValidatedAt && format(new Date(user.dpoValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}.
                    </p>
                    <p className="text-main-gray fst-italic">J’ai déclaré avoir pris connaissance et
                        accepter sans
                        réserves
                        les <Link target="_blank" to={"/conditions-generales-d-utilisation"}>conditions générales
                            d’utilisation</Link> le {user.cguValidatedAt && format(new Date(user.cguValidatedAt), "d MMMM yyyy à H'h'mm", {locale: fr})}.
                    </p>
                </div>
            </div>
        </>
    )
}
