import ZoneCalendar from "../../components/Meeting/ZoneCalendar"
import PageHeader from "../../components/PageHeader"
import {AddMeetingButton} from "./AddMeetingButton"
import AttendanceLightbox from "../Committee/AttendanceLightbox"
import {EditCommitteeAttendance} from "../Committee/EditCommitteeAttendance"

export default function Agenda() {
    document.body.classList.add("page-agenda")

    return (
        <>
            <PageHeader title="Calendrier" breadcrumb>
                <div className="row row-sm row-sm-h align-items-center">
                    <div className="col-auto"><EditCommitteeAttendance/></div>
                    <div className="col-auto"><AddMeetingButton/></div>
                </div>
            </PageHeader>
            <div className="container">
                <ZoneCalendar/>
            </div>
            <AttendanceLightbox/>
        </>
    )
}
