export const CONTACT_OBJECTS = [
    {
        key: 'cpi',
        label: "Ma demande concerne une commission paritaire (CPI)",
    },
    {
        key: 'ca',
        label: "Ma demande concerne une Instance (CA, Bureau...)",
    },
    {
        key: 'cléa',
        label: "Ma demande concerne un jury CLéA",
    },
    {
        key: 'perso',
        label: "Ma demande concerne une modification sur mes informations personnelles (Nom, prénom, adresse, mail, téléphone,..)",
    },
    {
        key: 'atnet',
        label: "Ma demande concerne ATnet",
    },
    {
        key: 'coherence',
        label: "Ma demande concerne une commission Cohérence et Qualité",
    },
    {
        key: 'other',
        label: "J'ai une autre demande",
    }
]
export const CONTACT_EMAILS = [
    {
        key: 'cpi',
        emails: [
            "supportcommission@transitionspro-idf.fr"
        ]
    },
    {
        key: 'ca',
        emails: [
            "instancescabureau@transitionspro-idf.fr"
        ]
    },
    {
        key: 'cléa',
        emails: [
            "f.gomes@transitionspro-idf.fr"
        ]
    },
    {
        key: 'perso',
        emails: [
            "instancescabureau@transitionspro-idf.fr"
        ]
    },
    {
        key: 'atnet',
        emails: [
            "supportcommission@transitionspro-idf.fr"
        ]
    },
    {
        key: 'coherence',
        emails: [
            "i.arnal@transitionspro-idf.fr"
        ]
    },
    {
        key: 'other',
        emails: [
            "instancescabureau@transitionspro-idf.fr"
        ]
    }
]



