import {Link} from "react-router-dom"
import {useAppSelector} from "../../redux/Hooks"
import {isActiveInYearCommittee} from "../../utils/Functions"
import {RootState} from "../../redux/Store"
import {Committee} from "../../api/CommitteeAPI"

export default function ItemDocumentsByYearBlock(props: { committee: Committee | null, link: string, year: number }) {
    const mandates = useAppSelector((state: RootState) => state.mandate.items)

    if (!props.committee || (!props.committee.canViewDocs && !(isActiveInYearCommittee(mandates, props.committee.id, props.year)))) {
        return <></>
    }

    return <div className="col-12">
        <div className="block mb-0">
            <div className="row row-sm row-sm-h justify-content-between align-items-center">
                <div className="col-lg-auto">
                    <div className="row row-sm-row-sm-h">
                        <div className="col-auto"><i className="mai text-tertiary fs-lg">folder_open</i></div>
                        <div className="col">
                            <h2 className="mb-0 fs-lg fw-medium">
                                <Link to={props.link}>
                                    {props.year}
                                </Link>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="row row-sm row-sm-h align-items-center">
                        <div className="col-auto">
                            <Link to={props.link}
                                  className="btn btn-sm btn-light">Accéder aux documents <i className="mai ms-2">arrow_forward</i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
