import {setFilters} from "../../redux/Committee/CommitteeSlice"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {Committee} from "../../api/CommitteeAPI"
import {setLoadingFilter} from "../../redux/Meeting/MeetingSlice"
import {Col, Row, Form, Spinner} from "react-bootstrap"

export default function ItemMeetingSidebar(props: { committee: Committee, filters: number[] }) {
    const dispatch = useAppDispatch()
    const loadingFilter = useAppSelector((state) => state.meeting.loadingFilter)

    function handleFilterChange(id: number) {
        dispatch(setLoadingFilter(id))

        // On gère les filtres
        let tempFilters: number[] = []
        const index = props.filters.findIndex((item) => item === id)
        props.filters.forEach((item) => {
            if (item !== id) {
                tempFilters.push(item)
            }
        })
        if (index < 0) {
            tempFilters.push(id)
        }
        dispatch(setFilters(tempFilters))
    }

    return <Col xs={12} key={props.committee.id}>
        <div className="bg-light px-3 py-2">
            <Row className="row-sm align-items-center flex-nowrap">
                <Col xs="auto" className={`${loadingFilter !== props.committee.id ? "d-block" : "d-none"}`}>
                    <div className="rounded-circle d-block"
                         style={{backgroundColor: props.committee.color, width: "0.8rem", height: "0.8rem"}}/>
                </Col>
                <Col xs="auto" className={`${loadingFilter === props.committee.id ? "d-block" : "d-none"}`}>
                    <Spinner animation="grow" size="sm" role="status" style={{color: props.committee.color}}/>
                </Col>
                <Col className="fs-sm fw-medium">{props.committee.title}</Col>
                <Col xs="auto">
                    <Form.Check
                        type="switch"
                        name="input-filters"
                        checked={(props.filters.find(item => item === props.committee.id)) !== undefined}
                        onChange={() => {
                            handleFilterChange(props.committee.id)
                        }}
                    />
                </Col>
            </Row>
        </div>
    </Col>
}
