import "bootstrap"
import {Routes, Route} from "react-router-dom"
import "./App.scss"
import type {RootState} from "./redux/Store"
import {useAppSelector} from "./redux/Hooks"
import SwitchUser from "./components/SwitchUser"
import Login from "./components/Login"
import SetPassword from "./components/SetPassword"
import Committee from "./components/Committee/Committee"
import Agenda from "./components/Meeting/Agenda"
import Documents from "./components/Document/Document"
import DocumentByMeeting from "./components/Document/DocumentByMeeting"
import Actuality from "./components/Actuality/Actuality"
import Toolkit from "./components/Toolkit/Toolkit"
import Home from "./components/Home"
import Trombinoscope from "./components/Trombinoscope/Trombinoscope"
import AddAdmin from "./components/AddUser/AddAdmin"
import EditAdmin from "./components/AddUser/EditAdmin"
import AddAssistant from "./components/AddUser/AddAssistant"
import EditAssistant from "./components/AddUser/EditAssistant"
import Emailing from "./components/Emailing/Emailing"
import ConditionsOfUse from "./components/ConditionsOfUse"
import Contact from "./components/Emailing/Contact"
import UserProfile from "./components/UserProfile"
import {hasRole} from "./utils/UserTools"
import SmsList from "./components/Sms/SmsList"
import {getCookie} from "./utils/cookies"
import React, {Fragment} from "react"
import Layout from "./components/Layout"

export default function App() {
    const roles = useAppSelector((state: RootState) => state.app.roles)
    const token = getCookie("accessToken")

    let content = <Routes>
        <Route path={`/`} element={<Home/>}/>
        <Route path={`/s-identifier`} element={<Login/>}/>
        <Route path={`/nouveau-compte/set-password`} element={<SetPassword/>}/>
        <Route path={`/set-password`} element={<SetPassword forgottenPassword/>}/>
        <Route path={`/accueil`} element={<Home/>}/>
        <Route path={`/agenda`} element={<Agenda/>}/>
        <Route path={`/annuaire`} element={<Trombinoscope/>}/>
        <Route path={`/documents`} element={<Documents/>}/>
        <Route path={`/documents/:slug`} element={<Documents/>}/>
        <Route path={`/documents/:slug/:year`} element={<DocumentByMeeting/>}/>
        <Route path={`/boite-a-outils`} element={<Toolkit/>}/>
        <Route path={`/annuaire`} element={<Trombinoscope/>}/>
        <Route path={`/mon-profil`} element={<UserProfile/>}/>
        <Route path={`/instances`} element={<Committee/>}/>
        <Route path={`/sms`} element={<SmsList/>}/>
        <Route path={`/contact`} element={<Contact/>}/>
        <Route path={`/conditions-generales-d-utilisation`} element={<ConditionsOfUse/>}/>
        {(() => {
            if (hasRole(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], roles)) {
                return <Fragment>
                    <Route path={`/actualites-infos`} element={token ? <Actuality/> : <Login/>}/>
                    <Route path={`/ajout-admin`} element={token ? <AddAdmin/> : <Login/>}/>
                    <Route path={`/ajout-assistant`} element={token ? <AddAssistant/> : <Login/>}/>
                    <Route path={`/editer-assistant/:id`} element={token ? <EditAssistant/> : <Login/>}/>
                    <Route path={`/editer-admin/:id`} element={token ? <EditAdmin/> : <Login/>}/>
                </Fragment>
            }
        })()}
        <Route path={`/emailing`} element={token ? <Emailing/> : <Login/>}/>

        <Route path={`/switch-user/:token/:username`} element={<SwitchUser/>}/>
    </Routes>

    return <Layout>{content}</Layout>
}
