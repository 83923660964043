import {
    setAttendanceShow,
    setAttendanceDate,
    setAttendanceCommittee, setAttendanceMembers, resetAttendanceMembers
} from "../../redux/Committee/CommitteeAttendanceFormSlice"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {Form, Modal} from "react-bootstrap"
import DatePicker from "react-datepicker"
import {registerLocale} from "react-datepicker"
import {fetchMandatesWithOption, Mandate} from "../../api/MandateAPI"
import {PDFDownloadLink} from "@react-pdf/renderer"
import Pdf from "../Pdf"
import fr from "date-fns/locale/fr"
import Select, {MultiValue} from "react-select"
import {getFormattedLocalisedDate, getToday} from "../../utils/Date"

registerLocale("fr", fr)

export default function AttendanceLightbox() {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state: RootState) => state.committeeAttendanceForm.attendanceShow)
    const attendanceDate = useAppSelector((state: RootState) => state.committeeAttendanceForm.attendanceDate)
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const attendanceMandates = useAppSelector((state: RootState) => state.committeeAttendanceForm.attendanceMandates)
    const attendanceCommittee = useAppSelector((state: RootState) => state.committeeAttendanceForm.attendanceCommittee)
    const attendanceMembers = useAppSelector((state: RootState) => state.committeeAttendanceForm.attendanceMembers)

    // Sélection d'une instance et affichage des membres de l'instance
    const handleCommitteeChange = (value: string) => {
        dispatch(setAttendanceCommittee({committees, value}))
        dispatch(fetchMandatesWithOption({
            context: "committee-attendance",
            options: {
                "committee.id": parseInt(value),
                "dateEnd[after]": getToday()
            }
        }))
        dispatch(resetAttendanceMembers())
    }

    const handleMembersChange = (mandates: MultiValue<{ value: Mandate, label: string }>) => {
        let attendanceMembers: Mandate[] = []
        mandates.forEach((mandate) => {
            attendanceMembers.push(mandate.value)
        })
        dispatch(setAttendanceMembers(attendanceMembers))
    }

    let membersList: { value: Mandate, label: string }[] = []
    if (attendanceCommittee !== null) {
        attendanceMandates.forEach((mandate) => {
            membersList.push({
                value: mandate,
                label: mandate.user.firstname + " " + mandate.user.lastname
            })
        })
    }

    // Date de la réunion
    let selectedDate = new Date()
    if (attendanceDate !== "") {
        selectedDate = new Date(attendanceDate)
    }
    const handleDateChange = (date: Date | null) => {
        dispatch(setAttendanceDate(date ? getFormattedLocalisedDate(date, "yyyy-MM-dd") : ""))
    }

    return <div>
        <Modal className="modal-form" show={show} scrollable onHide={() => dispatch(setAttendanceShow(false))}>
            <Form className="h-100 d-flex flex-column">
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Feuille de présence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-primary mb-3">Générer une feuille de présence</h3>
                    <div className="mb-4">
                        <Form.Label htmlFor="input-date-seance">Date de la séance *</Form.Label>
                        <DatePicker locale="fr" dateFormat="dd-MM-yyyy" selected={selectedDate} className="form-control"
                                    id="input-date-seance" onChange={(date) => handleDateChange(date)}/>
                    </div>
                    <div className="mb-4">
                        <Form.Label htmlFor="input-choix-instance">Choix de l'instance *</Form.Label>
                        <Form.Select aria-label="Choisir l'instance" id="input-choix-instance"
                                     onChange={(e) => handleCommitteeChange(e.target.value)}>
                            <option value={0} key={0}>Choisir l'instance</option>
                            {
                                committees.map((committee) => {
                                    return <option value={committee.id} key={committee.id}>{committee.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                    <div className="mb-4">
                        <Form.Label>Cochez les personnes présentes</Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={membersList}
                            isMulti={true}
                            hideSelectedOptions={false}
                            onChange={(e) => handleMembersChange(e)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PDFDownloadLink className="btn btn-light" document={<Pdf members={attendanceMembers}
                                                                              date={getFormattedLocalisedDate(selectedDate, "yyyy-MM-dd")}
                                                                              committee={attendanceCommittee}/>}
                                     fileName="feuille_de_presence.pdf">
                        <i className="mai me-2 fs-md">check</i>Générer la feuille de présence
                    </PDFDownloadLink>
                </Modal.Footer>
            </Form>
        </Modal>
    </div>
}
