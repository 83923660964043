import {Meeting} from "../../../api/MeetingAPI"
import {useAppSelector} from "../../../redux/Hooks"
import {RootState} from "../../../redux/Store"
import {hasRole} from "../../../utils/UserTools"
import {ROLES_MANAGE_MEETING, ROLES_VIEW_ALL_DOCUMENTS} from "../../../api/UserAPI"
import {isActiveInCommittee} from "../../../utils/Functions"
import DocumentsByMeetingContainer from "../../Document/DocumentsByMeetingContainer"
import {getZipFile} from "../../../services/export"

export function MeetingDocuments(props: { meeting: Meeting, shortway?: boolean }) {
    const roles = useAppSelector((state) => state.app.roles)
    const userId = useAppSelector((state) => state.app.userId)
    const mandates = useAppSelector((state: RootState) => state.mandate.items)
    const documents = useAppSelector((state: RootState) => state.meetingForm.documents)

    if (props.meeting === undefined) {
        return <></>
    }

    if (props.meeting && documents.length > 0 && ((props.meeting.committee && props.meeting.committee.documentForAll) ||
        hasRole(ROLES_MANAGE_MEETING, roles) ||
        hasRole(ROLES_VIEW_ALL_DOCUMENTS, roles) ||
        isActiveInCommittee(mandates, userId, props.meeting.committee)
    )) {
        return <div className={`bg-main-gray-light ${props.shortway ? "" : "p-3"} mb-2`}>
            <div className="row row-xs row-sm-h align-items-center">
                <div className="col-12">
                    <div className="row row-xs align-items-center mb-3">
                        <div className="col-auto"><span className="maio fs-lg text-main-gray">folder</span></div>
                        <div className="col fw-bold lh-1">
                            Pièces
                        </div>
                    </div>
                    <DocumentsByMeetingContainer context="calendar" meeting={props.meeting} documents={documents}/>
                </div>
                <div className="col-auto"><span className="mai fs-lg text-primary">download</span></div>
                <div className="col">
                    <button onClick={() => getZipFile(props.meeting)} className="btn-less fw-bold text-hover-primary lh-1">
                        Télécharger toutes les pièces
                    </button>
                </div>
            </div>
        </div>
    }
    return <></>
}
