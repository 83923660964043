import React, {Fragment, useEffect, useState} from "react"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import ItemMeetingSidebar from "./ItemMeetingSidebar"
import {Committee} from "../../api/CommitteeAPI"
import {Col, Form, Row} from "react-bootstrap"
import {setFilters} from "../../redux/Committee/CommitteeSlice"

type Props = {
    selectedCommittees: Committee[]
}

export default function MeetingSidebar({selectedCommittees}: Props) {
    const dispatch = useAppDispatch()
    const filters = useAppSelector((state) => state.committee.filters)
    const [selectAllCommittees, setSelectAllCommittees] = useState<boolean>(true)

    useEffect(() => {
        setSelectAllCommittees(filters.length === selectedCommittees.length)
    }, [filters, selectedCommittees])

    const handleSelectAll = (selectAll: boolean) => {
        dispatch(setFilters(selectAll ? selectedCommittees.map((item) => {
            return item.id
        }) : []))
    }

    return <Fragment>
        <h3 className="mb-2">Filtre par</h3>
        <Row className="row-xs-h">
            <Col className="mb-3" xs={12} key={0}>
                <div className="bg-light px-3 py-2">
                    <Row className="row-sm align-items-center flex-nowrap">
                        <Col className="fs-sm fw-medium">Tout sélectionner</Col>
                        <Col xs="auto">
                            <Form.Check
                                type="switch"
                                name="input-filters"
                                checked={selectAllCommittees}
                                onChange={() => {
                                    handleSelectAll(!selectAllCommittees)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>

            {(
                selectedCommittees.map((committee: Committee, index) => {
                    return <ItemMeetingSidebar key={committee.id} committee={committee} filters={filters}/>
                })
            )}
        </Row>
    </Fragment>
}
