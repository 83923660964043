import ItemDocumentByMeeting from "./ItemDocumentByMeeting"
import {
    Document,
    TYPE_CAT_CONVOCATION,
    TYPE_CAT_OTHER,
    TYPE_CAT_PV
} from "../../api/DocumentAPI"
import {Meeting} from "../../api/MeetingAPI"
import {useEffect, useState} from "react"

interface documentsMeetingContainer {
    context: string,
    meeting: Meeting,
    documents?: Document[]
}

export default function DocumentsByMeetingContainer({context, meeting, documents}: documentsMeetingContainer) {
    const [meetingDocuments, setMeetingDocuments] = useState<Document[]>([])
    const [meetingConvocations, setMeetingConvocations] = useState<Document[]>([])
    const [meetingPvs, setMeetingPvs] = useState<Document[]>([])

    useEffect(() => {
        if (documents) {
            setMeetingDocuments(documents.filter((item) => (!item.expectedDocument || (item.expectedDocument && item.expectedDocument.category && item.expectedDocument.category.type === TYPE_CAT_OTHER)) && (!item.publishedDate || new Date(item.publishedDate) <= new Date())))
            setMeetingConvocations(documents.filter((item) => item.expectedDocument && item.expectedDocument.category && item.expectedDocument.category.type === TYPE_CAT_CONVOCATION && (!item.publishedDate || new Date(item.publishedDate) <= new Date())))
            setMeetingPvs(documents.filter((item) => item.expectedDocument && item.expectedDocument.category && item.expectedDocument.category.type === TYPE_CAT_PV && (!item.publishedDate || new Date(item.publishedDate) <= new Date())))
        }
    }, [documents])


    return <div className="list-group list-group-flush mt-3">
        {
            meetingConvocations.length > 0 && <p className="fw-light text-secondary mb-2">Convocation</p>
        }
        {
            meetingConvocations.map((document) => {
                return <ItemDocumentByMeeting context={context} key={document.id} doc={document} meeting={meeting}/>
            })
        }
        {
            meetingDocuments.length > 0 &&
            <p className={`fw-light text-secondary mb-2${meetingConvocations.length > 0 ? " mt-4" : ""}`}>Dossier</p>
        }
        {
            meetingDocuments.map((document) => {
                return <ItemDocumentByMeeting context={context} key={document.id} doc={document} meeting={meeting}/>
            })
        }
        {
            meetingPvs.length > 0 &&
            <p className={`fw-light text-secondary mb-2${meetingConvocations.length > 0 || meetingDocuments.length > 0 ? " mt-4" : ""}`}>Procès-verbal</p>
        }
        {
            meetingPvs.map((document) => {
                return <ItemDocumentByMeeting context={context} key={document.id} doc={document} meeting={meeting}/>
            })
        }
    </div>
}
