import {Dispatch, SetStateAction, useEffect} from "react"
import {Modal, Button} from "react-bootstrap"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {createSms, createSmsUser} from "../../api/SmsAPI"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {COMMITTEE_API, MEETING_API, SMS_API} from "../../utils/Data"
import {resetStatus} from "../../redux/Sms/SmsSlice"

interface Props {
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    nb: number,
    date: string,
    committee: string,
    committeeId?: number,
    meetingId?: number,
    texte: string,
    users: { value: string, label: string }[],
    setShowModal: Dispatch<SetStateAction<boolean>>
}

export function ConfirmSms({
                               show,
                               setShow,
                               nb,
                               date,
                               committee,
                               committeeId,
                               meetingId,
                               users,
                               texte,
                               setShowModal
                           }: Props) {
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.sms.status)
    const message = useAppSelector((state) => state.sms.message)
    const item = useAppSelector((state) => state.sms.item)

    useEffect(() => {
        if (status) {
            dispatch(displayMessage({text: message, status: status}))
            dispatch(resetStatus())
        }
        setShow(false)
        if (status !== "error") {
            setShowModal(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    useEffect(() => {
        item && users.map((user) => {
            dispatch(createSmsUser({
                fields: {
                    "user": user.value,
                    "sms": SMS_API + "/" + item.id
                }
            }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const submitData = () => {
        dispatch(createSms({
            fields: {
                "committee": COMMITTEE_API + "/" + committeeId,
                "meeting": MEETING_API + "/" + meetingId,
                "title": texte,
                "total": users.length,
            }
        }))
    }

    return (
        <Modal show={show} className="modal-confirm" size="sm" backdropClassName="modal-backdrop-confirm"
               onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="h3">Confirmation d'envoi des SMS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{`Vous être sur le point d’envoyer un sms à ${nb} personne${nb > 1 ? "s" : ""} sur la séance du ${date} de l’instance ${committee}.`}</p>
                <p>Confirmez-vous votre envoi ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" type="button" onClick={() => setShow(false)}>
                    <i className="mai text-primary me-2">close</i>Annuler
                </Button>
                <Button variant="light" type="button" onClick={() => submitData()}>
                    <i className="mai text-primary me-2">save</i>Valider
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
