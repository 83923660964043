import {createSlice} from "@reduxjs/toolkit"
import {fetchMandatesWithOption, Mandate} from "../../api/MandateAPI"
import {Committee} from "../../api/CommitteeAPI"

export interface Member {
    id: number,
    civility?: boolean,
    firstname: string,
    lastname: string,
    syndicate?: string | number
}

export interface CommitteeState {
    loading: boolean,
    attendanceShow: boolean,
    attendanceDate: string,
    attendanceCommittee?: Committee,
    attendanceMandates: Mandate[],
    attendanceMembers: Member[]
}

const initialState: CommitteeState = {
    loading: false,
    attendanceShow: false,
    attendanceDate: "",
    attendanceCommittee: undefined,
    attendanceMandates: [],
    attendanceMembers: []
}

export const CommitteeAttendanceFormSlice = createSlice({
    name: "committeeAttendanceForm",
    initialState,
    reducers: {
        setAttendanceShow: (state, action) => {
            state.attendanceShow = action.payload
            state.attendanceMandates = []
        },
        setAttendanceDate: (state, action) => {
            state.attendanceDate = action.payload
        },
        setAttendanceCommittee: (state, action) => {
            state.attendanceCommittee = action.payload.committees.find((item: {
                id: number;
            }) => item.id === parseInt(action.payload.value))
            state.attendanceMembers = []
        },
        setAttendanceMembers: (state, action) => {
            let newArray: { id: number, firstname: string, lastname: string }[] = []
            action.payload.forEach((mandate: Mandate) => {
                newArray.push({
                    id: mandate.user.id,
                    firstname: mandate.user.firstname,
                    lastname: mandate.user.lastname
                })
            })
            state.attendanceMembers = newArray
        },
        resetAttendanceMembers: (state) => {
            state.attendanceMembers = []
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchMandatesWithOption.pending, (state) => {
                    state.loading = true
                })
                .addCase(fetchMandatesWithOption.fulfilled, (state, action) => {
                    state.loading = false
                    if (action.payload.context === "committee-attendance") {
                        state.attendanceMandates = action.payload.data
                    }
                })
                .addCase(fetchMandatesWithOption.rejected, (state) => {
                    state.loading = false
                })
        }
})


export const {
    setAttendanceShow,
    setAttendanceDate,
    setAttendanceCommittee,
    setAttendanceMembers,
    resetAttendanceMembers
} = CommitteeAttendanceFormSlice.actions

export default CommitteeAttendanceFormSlice.reducer
