import {data} from "./Data"
import {passwordStrength} from "check-password-strength"

export function avatar(filepath?: string, civility?: number) {
    if (filepath === undefined || filepath === "" || filepath === null) {
        if (civility !== undefined) {
            if (civility === data.CIVILITIES.FEMALE) {
                return require("../_images/avatars/female.svg").default
            }

            return require("../_images/avatars/male.svg").default
        }

        return require("../_images/avatars/neutral.svg").default
    }

    return data.host + filepath
}

export function getRolesStr(roles: number[]) {
    let rolesStr: string[] = []
    roles.forEach((role) => {
        const itemRole = data.MANDATE_ROLES.find((item) => item.key === role)
        itemRole && rolesStr.push(itemRole.label)
    })

    return rolesStr.join(", ")
}

// On vérifie que l'utilisateur a les rôles demandés
export function hasRole(roles_allowed: string[], user_roles: string[]) {
    let hasrole: boolean = false
    roles_allowed.forEach((role_allowed) => {
        user_roles.forEach((user_role) => {
            if (user_role === role_allowed) {
                hasrole = true
            }
        })
    })
    return hasrole
}

// On vérifie que l'utilisateur est un commissaire-administrateur
export function isAdmin(roles: string[]) {
    return roles.length === 0 || !hasRole(["ROLE_ASSIST", "ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_CODIR"], roles)
}

interface IDataPassword {
    value: string,
    input: string,
    password1: string,
    password2: string,
    setPassword: (password: string) => void,
    setPassword2: (password2: string) => void,
    setPassword1Valid: (password1Valid: boolean) => void,
    setPassword2Valid: (password2Valid: boolean) => void,
    setClassInputPassword1: (classInputPassword1: string) => void,
    setClassInputPassword2: (classInputPassword2: string) => void
}

// Fonction de vérification de mot de passe
export function checkpassword(data: IDataPassword) {

    const strenght = passwordStrength(data.value).value
    let valid = (strenght === "Medium" || strenght === "Strong") && data.value.length >= 12

    if (data.input === "password1") {
        data.setPassword(data.value)
        if (valid) {
            data.setClassInputPassword1("is-valid")
            data.setPassword1Valid(true)
            if (data.password2 === data.value) {
                data.setClassInputPassword2("is-valid")
                data.setPassword2Valid(true)
            }
        } else {
            if (data.value !== "") {
                data.setClassInputPassword1("is-invalid")
                data.setClassInputPassword2("is-invalid")
            }
            data.setPassword1Valid(false)
            data.setPassword2Valid(false)
        }
    } else {
        data.setPassword2(data.value)
        if (data.value !== data.password1 || !valid) {
            data.setClassInputPassword2("is-invalid")
            data.setPassword2Valid(false)
        } else {
            data.setClassInputPassword2("is-valid")
            data.setPassword2Valid(true)
        }
    }
}

export const handleTelChange = (value?: string) => {
    return value ? value.replace(/\s/g, "").substring(0, 10).replace(/(.{2})/g, "$1 ").trimEnd() : ""
}
