import {createSlice} from "@reduxjs/toolkit"
import {
    addTool,
    createDocumentCategory,
    deleteTool,
    deleteDocumentCategory,
    Document,
    DocumentCategory,
    fetchDocumentCategories,
    updateAllToolCategories,
    updateDocumentCategory,
    updateTool
} from "../../api/DocumentAPI";

export interface ToolkitState {
    toolCategories: DocumentCategory[],
    showModalAddToolCategory: boolean,
    title: string,
    editMode: boolean,
    toolCategorySelected: DocumentCategory | null,
    showModalAddTool: boolean,
    editModeTool: boolean,
    toolSelected: Document | null,
    idTool: number,
    oldIdTool: number,
    titleTool: string,
    publishedTool: boolean,
    filename: string,
    filePath: string,
    extensionFileTool: string,
    toolCategorySelectId: string,
    viewUnpublishedTools: boolean,
    stateCategoryList: string,
    inEditForm: boolean,
    displayPopinDeleteCategoryConfirm: boolean,
    displayPopinDeleteToolConfirm: boolean,
    fileToolLoading: boolean
}

const initialState: ToolkitState = {
    toolCategories: [],
    showModalAddToolCategory: false,
    title: '',
    editMode: false,
    toolCategorySelected: null,
    showModalAddTool: false,
    editModeTool: false,
    toolSelected: null,
    idTool: 0,
    oldIdTool: 0,
    titleTool: '',
    publishedTool: true,
    filename: '',
    filePath: '',
    extensionFileTool: '',
    toolCategorySelectId: '',
    viewUnpublishedTools: true,
    stateCategoryList: 'loading',
    inEditForm: false,
    displayPopinDeleteCategoryConfirm: false,
    displayPopinDeleteToolConfirm: false,
    fileToolLoading: false
}

export const ToolkitSlice = createSlice({
    name: 'toolkit',
    initialState,
    reducers: {
        setShowModalAddToolCategory: (state, action) => {
            state.showModalAddToolCategory = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setToolCategories: (state, action) => {
            state.toolCategories = action.payload
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload
        },
        setToolCategorySelected: (state, action) => {
            state.toolCategorySelected = action.payload
        },
        setShowModalAddTool: (state, action) => {
            state.showModalAddTool = action.payload
        },
        setToolSelected: (state, action) => {
            state.toolSelected = action.payload
        },
        setTitleTool: (state, action) => {
            state.titleTool = action.payload
        },
        setPublishedTool: (state, action) => {
            state.publishedTool = action.payload
        },
        setFileName: (state, action) => {
            state.filename = action.payload
        },
        setExtensionFileTool: (state, action) => {
            state.extensionFileTool = action.payload
        },
        setToolCategorySelectId: (state, action) => {
            state.toolCategorySelectId = action.payload
        },
        setEditModeTool: (state, action) => {
            state.editModeTool = action.payload
        },
        setViewUnpublishedTools: (state, action) => {
            state.viewUnpublishedTools = action.payload
        },
        setIdTool: (state, action) => {
            state.idTool = action.payload
        },
        setFilePath: (state, action) => {
            state.filePath = action.payload
        },
        setOldIdTool: (state, action) => {
            state.oldIdTool = action.payload
        },
        setInEditForm: (state, action) => {
            state.inEditForm = action.payload
        },
        setDisplayPopinDeleteCategoryConfirm: (state, action) => {
            state.displayPopinDeleteCategoryConfirm = action.payload
        },
        setDisplayPopinDeleteToolConfirm: (state, action) => {
            state.displayPopinDeleteToolConfirm = action.payload
        },
        resetToolFields: (state) => {
            state.titleTool = ''
            state.publishedTool = true
            state.filename = ''
            state.filePath = ''
            state.extensionFileTool = ''
            state.toolCategorySelectId = ''
        },
        setFileToolLoading: (state, action) => {
            state.fileToolLoading = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
            .addCase(fetchDocumentCategories.pending, (state, action) => {
                state.stateCategoryList = 'loading'
            })
            .addCase(fetchDocumentCategories.fulfilled, (state, action) => {
                state.stateCategoryList = ''
                state.toolCategories = action.payload
            })
            .addCase(createDocumentCategory.fulfilled, (state, action) => {
                state.title = ''
                state.showModalAddToolCategory = false
                state.toolCategories.push(action.payload)
            })
            .addCase(createDocumentCategory.rejected, (state) => {
                state.showModalAddToolCategory = false
            })
            .addCase(updateAllToolCategories.rejected, (state) => {
                state.showModalAddToolCategory = false
            })
            .addCase(updateDocumentCategory.fulfilled, (state, action) => {
                state.title = ''
                state.showModalAddToolCategory = false

                let newTcs = []
                for(let tc of state.toolCategories){
                    let newTc = {...tc}
                    if(tc.id === action.payload.id){
                        newTc.title = action.payload.title
                    }
                    newTcs.push(newTc)
                }
                state.toolCategories = [...newTcs]
            })
            .addCase(updateDocumentCategory.rejected, (state) => {
                state.showModalAddToolCategory = false
            })
            .addCase(deleteDocumentCategory.fulfilled, (state) => {
                document.getElementById('btn-close-popin-confirm-delete-category')?.click()
                state.displayPopinDeleteCategoryConfirm = false
                state.showModalAddToolCategory = false
                state.editMode = false
                let newTcs = []
                for(let tc of state.toolCategories){
                    if(state.toolCategorySelected !== null && tc.id !== state.toolCategorySelected.id){
                        newTcs.push(tc)
                    }
                }
                state.toolCategories = [...newTcs]
            })
            .addCase(deleteDocumentCategory.rejected, (state) => {
                document.getElementById('btn-close-popin-confirm-delete-category')?.click()
                state.displayPopinDeleteCategoryConfirm = false
                state.showModalAddToolCategory = false
                state.editMode = false
            })
            .addCase(addTool.fulfilled, (state) => {
                state.showModalAddTool = false
            })
            .addCase(addTool.rejected, (state) => {
                state.showModalAddTool = false
            })
            .addCase(updateTool.fulfilled, (state) => {
                state.showModalAddTool = false
                state.toolCategorySelectId = ''
            })
            .addCase(updateTool.rejected, (state) => {
                state.showModalAddTool = false
            })
            .addCase(deleteTool.fulfilled, (state, action) => {

                if(state.inEditForm){
                    document.getElementById('btn-close-popin-confirm-delete-tool')?.click()
                    state.displayPopinDeleteToolConfirm = false
                    state.showModalAddTool = false
                    state.editModeTool = false

                    let toolSelected = {...action.payload.response}
                    let newToolCategories: DocumentCategory[] = []

                    state.toolCategories.forEach((toolCategory: DocumentCategory, i:number)=>{
                        if(toolSelected !== null && typeof toolSelected.documentCategory === 'string' && Number(toolCategory.id) === Number(toolSelected.documentCategory.replace('/api/document_categories/', ''))){
                            let newToolCategory: DocumentCategory = {...toolCategory}
                            newToolCategory.documents = []
                            if(toolCategory.documents !== undefined){
                                toolCategory.documents.forEach((tool: Document, i:number)=>{
                                    if(newToolCategory.documents !== undefined && Number(tool.id) !== Number(toolSelected.id)){
                                        newToolCategory.documents.push(tool)
                                    }
                                })
                            }
                            newToolCategories.push(newToolCategory)
                        }else{
                            newToolCategories.push(toolCategory)
                        }

                    })
                    state.toolCategories = [...newToolCategories]
                    state.inEditForm = false
                }

            })

    }
})

export const {
               resetToolFields,
               setShowModalAddToolCategory,
               setTitle,
               setToolCategories,
               setEditMode,
               setToolCategorySelected,
               setShowModalAddTool,
               setToolSelected,
               setTitleTool,
               setPublishedTool,
               setFileName,
               setExtensionFileTool,
               setToolCategorySelectId,
               setEditModeTool,
               setViewUnpublishedTools,
               setIdTool,
               setFilePath,
               setOldIdTool,
               setInEditForm,
               setDisplayPopinDeleteCategoryConfirm,
               setDisplayPopinDeleteToolConfirm,
               setFileToolLoading
            } = ToolkitSlice.actions

export default ToolkitSlice.reducer
