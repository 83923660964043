import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from "react-bootstrap";

interface Props {
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>
}

export default function PopinHistoric({show, setShow}: Props) {
    return <Modal show={show} scrollable={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title as="h3">Export des membres</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p><strong>Marc BOURGEOIS</strong></p>
            <p>Bureau
                (19/11/2020 - 31/12/2022)<br/>
                C.A.
                (19/11/2020 - 31/12/2022)<br/>
                CPIR 2
                (19/11/2020 - 31/12/2022)<br/>
                Commission de recours
                (19/11/2020 - 31/12/2022)<br/>
                Chef de file
                (19/11/2020 - 31/12/2022)<br/>
                Commission cohérence & qualité
                (19/11/2020 - 31/12/2022)<br/>
                Bureau
                (01/01/2020 - 18/11/2020)<br/>
                C.A.
                (01/01/2020 - 18/11/2020)<br/>
                CPIR 2
                (01/01/2020 - 18/11/2020)<br/>
                Commission de recours
                (01/01/2020 - 18/11/2020)<br/>
                Chef de file
                (01/01/2020 - 18/11/2020)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 18/11/2020)<br/>
                Bureau
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (24/05/2017 - 31/12/2019)<br/>
                Chef de file
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                Bureau
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                Commission de recours
                (26/03/2015 - 24/05/2017)<br/>
                Chef de file
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                Bureau
                (01/01/2014 - 25/03/2015)<br/>
                C.A.
                (01/01/2014 - 25/03/2015)<br/>
                CPIR 2
                (01/01/2014 - 25/03/2015)<br/>
                Commission de recours
                (01/01/2014 - 25/03/2015)<br/>
                Chef de file
                (01/01/2014 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (01/01/2014 - 25/03/2015)<br/>
                Bureau
                (28/03/2013 - 31/12/2013)<br/>
                C.A.
                (28/03/2013 - 31/12/2013)<br/>
                CPIR 2
                (28/03/2013 - 31/12/2013)<br/>
                Commission de recours
                (28/03/2013 - 31/12/2013)<br/>
                Chef de file
                (28/03/2013 - 31/12/2013)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 31/12/2013)<br/>
                Bureau
                (24/03/2011 - 27/03/2013)<br/>
                C.A.
                (24/03/2011 - 27/03/2013)<br/>
                CPIR 2
                (24/03/2011 - 27/03/2013)<br/>
                Commission de recours
                (24/03/2011 - 27/03/2013)<br/>
                Chef de file
                (24/03/2011 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (24/03/2011 - 27/03/2013)<br/>
                Bureau
                (19/11/2008 - 23/03/2011)<br/>
                C.A.
                (19/11/2008 - 23/03/2011)<br/>
                CPIR 2
                (19/11/2008 - 23/03/2011)<br/>
                Commission de recours
                (19/11/2008 - 23/03/2011)<br/>
                Chef de file
                (19/11/2008 - 23/03/2011)<br/>
                Commission cohérence & qualité
                (19/11/2008 - 23/03/2011)</p>
            <hr/>
            <p><strong>Jean-Philippe CAM</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 2
                (01/01/2008 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Arnaud DUBUISSON</strong></p>
            <p>CPIR 3
                (depuis le 01/01/2020)<br/>
                CPIR 3
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 3
                (01/01/2008 - 27/03/2013)<br/>
            </p>
            <hr/>
            <p><strong>Anne FABIANI DAVETTE</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (24/03/2011 - 27/03/2013)<br/>
                C.A.
                (10/09/2008 - 23/03/2011)<br/>
            </p>
            <hr/>
            <p><strong>Gabriel GOLDBERG</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/02/2018 - 31/12/2019)<br/>
                CPIR 1
                (01/02/2018 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (01/02/2018 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 28/02/2018)<br/>
                CPIR 1
                (24/05/2017 - 28/02/2018)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 28/02/2018)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 1
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (01/01/2008 - 27/03/2013)<br/>
                CPIR 1
                (01/01/2008 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (01/01/2008 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Irène IMOLA-GUILLAUME</strong></p>
            <p>C.A.
                (depuis le 17/03/2021)<br/>
                CPIR 3
                (depuis le 17/03/2021)<br/>
                C.A.
                (01/01/2020 - 16/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 16/03/2021)<br/>
                C.A.
                (29/03/2019 - 31/12/2019)<br/>
                CPIR 3
                (29/03/2019 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 29/03/2019)<br/>
                CPIR 3
                (24/05/2017 - 29/03/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (18/04/2013 - 25/03/2015)<br/>
                CPIR 3
                (18/04/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 17/04/2013)<br/>
                CPIR 3
                (28/03/2013 - 17/04/2013)<br/>
                C.A.
                (24/03/2011 - 27/03/2013)<br/>
                CPIR 3
                (24/03/2011 - 27/03/2013)<br/>
                C.A.
                (01/01/2008 - 23/03/2011)<br/>
                CPIR 3
                (01/01/2008 - 23/03/2011)<br/>
            </p>
            <hr/>

            <p><strong>Christophe JURKEW</strong></p>
            <p>Bureau
                (depuis le 24/02/2022)<br/>
                C.A.
                (depuis le 24/02/2022)<br/>
                CPIR 2
                (depuis le 24/02/2022)<br/>
                Commission de recours
                (depuis le 24/02/2022)<br/>
                Chef de file
                (depuis le 24/02/2022)<br/>
                Commission cohérence & qualité
                (depuis le 24/02/2022)<br/>
                Jury CléA
                (depuis le 24/02/2022)<br/>
                Commission financière
                (depuis le 24/02/2022)<br/>
                Bureau
                (02/03/2021 - 09/02/2022)<br/>
                C.A.
                (02/03/2021 - 09/02/2022)<br/>
                CPIR 2
                (02/03/2021 - 09/02/2022)<br/>
                Commission de recours
                (02/03/2021 - 09/02/2022)<br/>
                Chef de file
                (02/03/2021 - 09/02/2022)<br/>
                Commission cohérence & qualité
                (02/03/2021 - 09/02/2022)<br/>
                Jury CléA
                (02/03/2021 - 09/02/2022)<br/>
                Commission financière
                (02/03/2021 - 09/02/2022)<br/>
                Bureau
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                Commission de recours
                (01/01/2020 - 01/03/2021)<br/>
                Chef de file
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                Jury CléA
                (01/01/2020 - 01/03/2021)<br/>
                Commission financière
                (01/01/2020 - 01/03/2021)<br/>
                Bureau
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (24/05/2017 - 31/12/2019)<br/>
                Chef de file
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                Jury CléA
                (24/05/2017 - 31/12/2019)<br/>
                Commission financière
                (24/05/2017 - 31/12/2019)<br/>
                Bureau
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                Commission de recours
                (26/03/2015 - 24/05/2017)<br/>
                Chef de file
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                Jury CléA
                (26/03/2015 - 24/05/2017)<br/>
                Commission financière
                (26/03/2015 - 24/05/2017)<br/>
                Bureau
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
                Commission de recours
                (28/03/2013 - 25/03/2015)<br/>
                Chef de file
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
                Jury CléA
                (28/03/2013 - 25/03/2015)<br/>
                Commission financière
                (28/03/2013 - 25/03/2015)<br/>
                Bureau
                (24/03/2011 - 27/03/2013)<br/>
                C.A.
                (24/03/2011 - 27/03/2013)<br/>
                CPIR 2
                (24/03/2011 - 27/03/2013)<br/>
                Commission de recours
                (24/03/2011 - 27/03/2013)<br/>
                Chef de file
                (24/03/2011 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (24/03/2011 - 27/03/2013)<br/>
                Jury CléA
                (24/03/2011 - 27/03/2013)<br/>
                Commission financière
                (24/03/2011 - 27/03/2013)<br/>
                Bureau
                (28/03/2007 - 23/03/2011)<br/>
                C.A.
                (28/03/2007 - 23/03/2011)<br/>
                CPIR 2
                (28/03/2007 - 23/03/2011)<br/>
                Commission de recours
                (28/03/2007 - 23/03/2011)<br/>
                Chef de file
                (28/03/2007 - 23/03/2011)<br/>
                Commission cohérence & qualité
                (28/03/2007 - 23/03/2011)<br/>
                Jury CléA
                (28/03/2007 - 23/03/2011)<br/>
                Commission financière
                (28/03/2007 - 23/03/2011)<br/>

            </p>
            <hr/>

            <p><strong>Franck MARTIGNONI</strong></p>
            <p>Bureau
                (depuis le 02/03/2021)<br/>
                C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission de recours
                (depuis le 02/03/2021)<br/>
                Chef de file
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                Bureau
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission de recours
                (01/01/2020 - 01/03/2021)<br/>
                Chef de file
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                Bureau
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (24/05/2017 - 31/12/2019)<br/>
                Chef de file
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                Bureau
                (14/03/2017 - 24/05/2017)<br/>
                C.A.
                (14/03/2017 - 24/05/2017)<br/>
                CPIR 1
                (14/03/2017 - 24/05/2017)<br/>
                Commission de recours
                (14/03/2017 - 24/05/2017)<br/>
                Chef de file
                (14/03/2017 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (14/03/2017 - 24/05/2017)<br/>
                Bureau
                (26/03/2015 - 14/03/2017)<br/>
                C.A.
                (26/03/2015 - 14/03/2017)<br/>
                CPIR 1
                (26/03/2015 - 14/03/2017)<br/>
                Commission de recours
                (26/03/2015 - 14/03/2017)<br/>
                Chef de file
                (26/03/2015 - 14/03/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 14/03/2017)<br/>
                Bureau
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                Commission de recours
                (28/03/2013 - 25/03/2015)<br/>
                Chef de file
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
                Bureau
                (28/03/2007 - 27/03/2013)<br/>
                C.A.
                (28/03/2007 - 27/03/2013)<br/>
                CPIR 1
                (28/03/2007 - 27/03/2013)<br/>
                Commission de recours
                (28/03/2007 - 27/03/2013)<br/>
                Chef de file
                (28/03/2007 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (28/03/2007 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Farid HOUACHE</strong></p>
            <p>Bureau
                (depuis le 07/12/2022)<br/>
                C.A.
                (depuis le 07/12/2022)<br/>
                CPIR 2
                (depuis le 07/12/2022)<br/>
                Commission de recours
                (depuis le 07/12/2022)<br/>
                Chef de file
                (depuis le 07/12/2022)<br/>
                Commission cohérence & qualité
                (depuis le 07/12/2022)<br/>
                Bureau
                (02/03/2021 - 06/12/2022)<br/>
                C.A.
                (02/03/2021 - 06/12/2022)<br/>
                CPIR 2
                (02/03/2021 - 06/12/2022)<br/>
                Commission de recours
                (02/03/2021 - 06/12/2022)<br/>
                Chef de file
                (02/03/2021 - 06/12/2022)<br/>
                Commission cohérence & qualité
                (02/03/2021 - 06/12/2022)<br/>
                Bureau
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                Commission de recours
                (01/01/2020 - 01/03/2021)<br/>
                Chef de file
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                Bureau
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (24/05/2017 - 31/12/2019)<br/>
                Chef de file
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                Bureau
                (13/05/2015 - 24/05/2017)<br/>
                C.A.
                (13/05/2015 - 24/05/2017)<br/>
                CPIR 2
                (13/05/2015 - 24/05/2017)<br/>
                Commission de recours
                (13/05/2015 - 24/05/2017)<br/>
                Chef de file
                (13/05/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (13/05/2015 - 24/05/2017)<br/>
            </p>
            <hr/>

            <p><strong>Françoise SOWIZRAL</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 2
                (26/03/2009 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Sylvain VAREECKE</strong></p>
            <p>Bureau
                (depuis le 01/01/2022)<br/>
                C.A.
                (depuis le 01/01/2022)<br/>
                CPIR 3
                (depuis le 01/01/2022)<br/>
                Commission de recours
                (depuis le 01/01/2022)<br/>
                Chef de file
                (depuis le 01/01/2022)<br/>
                Commission financière
                (depuis le 01/01/2022)<br/>
                Bureau
                (01/01/2020 - 01/01/2022)<br/>
                C.A.
                (01/01/2020 - 01/01/2022)<br/>
                CPIR 3
                (01/01/2020 - 01/01/2022)<br/>
                Commission de recours
                (01/01/2020 - 01/01/2022)<br/>
                Chef de file
                (01/01/2020 - 01/01/2022)<br/>
                Commission financière
                (01/01/2020 - 01/01/2022)<br/>
                Bureau
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (24/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (24/05/2017 - 31/12/2019)<br/>
                Chef de file
                (24/05/2017 - 31/12/2019)<br/>
                Commission financière
                (24/05/2017 - 31/12/2019)<br/>
                Bureau
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                Commission de recours
                (26/03/2015 - 24/05/2017)<br/>
                Chef de file
                (26/03/2015 - 24/05/2017)<br/>
                Commission financière
                (26/03/2015 - 24/05/2017)<br/>
                Bureau
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 3
                (28/03/2013 - 25/03/2015)<br/>
                Commission de recours
                (28/03/2013 - 25/03/2015)<br/>
                Chef de file
                (28/03/2013 - 25/03/2015)<br/>
                Commission financière
                (28/03/2013 - 25/03/2015)<br/>
                Bureau
                (30/03/2005 - 27/03/2013)<br/>
                C.A.
                (30/03/2005 - 27/03/2013)<br/>
                CPIR 3
                (30/03/2005 - 27/03/2013)<br/>
                Commission de recours
                (30/03/2005 - 27/03/2013)<br/>
                Chef de file
                (30/03/2005 - 27/03/2013)<br/>
                Commission financière
                (30/03/2005 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Nathalie FAINAN</strong></p>
            <p>Comité direction
                (depuis le 31/12/2012)<br/>
                DG
                (depuis le 31/12/2012)<br/>
                DDC
                (depuis le 31/12/2012)<br/>
                Webmaster
                (depuis le 31/12/2012)<br/>
                Comité direction
                (01/01/2008 - 30/12/2012)<br/>
                DG
                (01/01/2008 - 30/12/2012)<br/>
                DDC
                (01/01/2008 - 30/12/2012)<br/>
                Webmaster
                (01/01/2008 - 30/12/2012)<br/>
            </p>
            <hr/>

            <p><strong>Cécile JOVIGNOT</strong></p>
            <p>Pôle engagement
                (depuis le 01/01/2008)<br/>
                SEE
                (depuis le 01/01/2008)<br/>
            </p>
            <hr/>

            <p><strong>Olivier BRIAND</strong></p>
            <p>Webmaster
                (depuis le 01/01/2008)<br/>
            </p>
            <hr/>

            <p><strong>Isabelle ARNAL</strong></p>
            <p>Webmaster
                (depuis le 01/01/2008)<br/>
            </p>
            <hr/>

            <p><strong>Astrid DE VAUBLANC</strong></p>
            <p>Webmaster
                (depuis le 01/01/2008)<br/>
            </p>
            <hr/>

            <p><strong>Paulin VINGATARAMIN</strong></p>
            <p>CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (16/09/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (16/09/2015 - 24/05/2017)<br/>
                CPIR 1
                (26/03/2015 - 15/09/2015)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 15/09/2015)<br/>
                CPIR 1
                (01/01/2014 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (01/01/2014 - 25/03/2015)<br/>
                CPIR 1
                (28/03/2013 - 31/12/2013)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 31/12/2013)<br/>
                CPIR 1
                (24/03/2011 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (24/03/2011 - 27/03/2013)<br/>
                CPIR 1
                (15/12/2010 - 23/03/2011)<br/>
                Commission cohérence & qualité
                (15/12/2010 - 23/03/2011)<br/>
                CPIR 1
                (18/11/2009 - 15/12/2010)<br/>
                Commission cohérence & qualité
                (18/11/2009 - 15/12/2010)<br/>
            </p>
            <hr/>

            <p><strong>Philippe GEBAROWSKI</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 3
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>

                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (29/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (29/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (29/05/2017 - 31/12/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 3
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (24/03/2011 - 27/03/2013)<br/>
                CPIR 3
                (24/03/2011 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (24/03/2011 - 27/03/2013)<br/>
                C.A.
                (01/04/2010 - 23/03/2011)<br/>
                CPIR 3
                (01/04/2010 - 23/03/2011)<br/>
                Commission cohérence & qualité
                (01/04/2010 - 23/03/2011)<br/>
            </p>
            <hr/>

            <p><strong>Brigitte WAINTRAUB</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 2
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>

                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (21/02/2019 - 31/12/2019)<br/>
                CPIR 2
                (21/02/2019 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (21/02/2019 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 20/02/2019)<br/>
                CPIR 2
                (24/05/2017 - 20/02/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 20/02/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (29/05/2014 - 25/03/2015)<br/>
                CPIR 2
                (29/05/2014 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (29/05/2014 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Frédéric BAUDURET</strong></p>
            <p>CPIR 1
                (depuis le 01/01/2020)<br/>
                CPIR 1
                (29/03/2019 - 31/12/2019)<br/>
                CPIR 1
                (24/05/2017 - 29/03/2019)<br/>
                CPIR 1
                (26/03/2015 - 24/05/2017)<br/>
            </p>
            <hr/>

            <p><strong>Lana KAIS</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
                CPIR 3
                (depuis le 01/01/2020)<br/>
                C.A.
                (29/03/2019 - 31/12/2019)<br/>
                CPIR 3
                (29/03/2019 - 31/12/2019)<br/>
                C.A.
                (01/03/2018 - 29/03/2019)<br/>
                CPIR 3
                (01/03/2018 - 29/03/2019)<br/>
                C.A.
                (24/05/2017 - 28/02/2018)<br/>
                CPIR 3
                (24/05/2017 - 28/02/2018)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (18/04/2013 - 25/03/2015)<br/>
                CPIR 3
                (18/04/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 17/04/2013)<br/>
                CPIR 3
                (28/03/2013 - 17/04/2013)<br/>
                C.A.
                (04/07/2012 - 27/03/2013)<br/>
                CPIR 3
                (04/07/2012 - 27/03/2013)<br/>
                C.A.
                (24/03/2011 - 03/07/2012)<br/>
                CPIR 3
                (24/03/2011 - 03/07/2012)<br/>
            </p>
            <hr/>

            <p><strong>Chantal SCHALLER</strong></p>
            <p>Bureau
                (depuis le 01/01/2022)<br/>
                C.A.
                (depuis le 01/01/2022)<br/>
                CPIR 1
                (depuis le 01/01/2022)<br/>
                CPIR 2
                (depuis le 01/01/2022)<br/>
                CPIR 3
                (depuis le 01/01/2022)<br/>
                Commission de recours
                (depuis le 01/01/2022)<br/>
                Chef de file
                (depuis le 01/01/2022)<br/>
                Commission cohérence & qualité
                (depuis le 01/01/2022)<br/>
                Jury CléA
                (depuis le 01/01/2022)<br/>
                Commission financière
                (depuis le 01/01/2022)<br/>

                Bureau
                (02/03/2021 - 01/01/2022)<br/>
                C.A.
                (02/03/2021 - 01/01/2022)<br/>
                CPIR 1
                (02/03/2021 - 01/01/2022)<br/>
                CPIR 2
                (02/03/2021 - 01/01/2022)<br/>
                CPIR 3
                (02/03/2021 - 01/01/2022)<br/>
                Commission de recours
                (02/03/2021 - 01/01/2022)<br/>
                Chef de file
                (02/03/2021 - 01/01/2022)<br/>
                Commission cohérence & qualité
                (02/03/2021 - 01/01/2022)<br/>
                Jury CléA
                (02/03/2021 - 01/01/2022)<br/>
                Commission financière
                (02/03/2021 - 01/01/2022)<br/>
                Bureau
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 01/03/2021)<br/>
                Commission de recours
                (01/01/2020 - 01/03/2021)<br/>
                Chef de file
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                Jury CléA
                (01/01/2020 - 01/03/2021)<br/>
                Commission financière
                (01/01/2020 - 01/03/2021)<br/>
                Bureau
                (29/05/2017 - 31/12/2019)<br/>
                C.A.
                (29/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (29/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (29/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (29/05/2017 - 31/12/2019)<br/>
                Commission de recours
                (29/05/2017 - 31/12/2019)<br/>
                Chef de file
                (29/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (29/05/2017 - 31/12/2019)<br/>
                Jury CléA
                (29/05/2017 - 31/12/2019)<br/>
                Commission financière
                (29/05/2017 - 31/12/2019)<br/>
                Bureau
                (19/04/2017 - 24/05/2017)<br/>
                C.A.
                (19/04/2017 - 24/05/2017)<br/>
                CPIR 1
                (19/04/2017 - 24/05/2017)<br/>
                CPIR 2
                (19/04/2017 - 24/05/2017)<br/>
                CPIR 3
                (19/04/2017 - 24/05/2017)<br/>
                Commission de recours
                (19/04/2017 - 24/05/2017)<br/>
                Chef de file
                (19/04/2017 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (19/04/2017 - 24/05/2017)<br/>
                Jury CléA
                (19/04/2017 - 24/05/2017)<br/>
                Commission financière
                (19/04/2017 - 24/05/2017)<br/>
                Bureau
                (25/01/2017 - 18/04/2017)<br/>
                C.A.
                (25/01/2017 - 18/04/2017)<br/>
                CPIR 1
                (25/01/2017 - 18/04/2017)<br/>
                CPIR 2
                (25/01/2017 - 18/04/2017)<br/>
                CPIR 3
                (25/01/2017 - 18/04/2017)<br/>
                Commission de recours
                (25/01/2017 - 18/04/2017)<br/>
                Chef de file
                (25/01/2017 - 18/04/2017)<br/>
                Commission cohérence & qualité
                (25/01/2017 - 18/04/2017)<br/>
                Jury CléA
                (25/01/2017 - 18/04/2017)<br/>
                Commission financière
                (25/01/2017 - 18/04/2017)<br/>
                Bureau
                (26/03/2015 - 24/01/2017)<br/>
                C.A.
                (26/03/2015 - 24/01/2017)<br/>
                CPIR 1
                (26/03/2015 - 24/01/2017)<br/>
                CPIR 2
                (26/03/2015 - 24/01/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/01/2017)<br/>
                Commission de recours
                (26/03/2015 - 24/01/2017)<br/>
                Chef de file
                (26/03/2015 - 24/01/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/01/2017)<br/>
                Jury CléA
                (26/03/2015 - 24/01/2017)<br/>
                Commission financière
                (26/03/2015 - 24/01/2017)<br/>
                Bureau
                (28/03/2013 - 25/03/2015)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 3
                (28/03/2013 - 25/03/2015)<br/>
                Commission de recours
                (28/03/2013 - 25/03/2015)<br/>
                Chef de file
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
                Jury CléA
                (28/03/2013 - 25/03/2015)<br/>
                Commission financière
                (28/03/2013 - 25/03/2015)<br/>
                Bureau
                (25/10/2011 - 27/03/2013)<br/>
                C.A.
                (25/10/2011 - 27/03/2013)<br/>
                CPIR 1
                (25/10/2011 - 27/03/2013)<br/>
                CPIR 2
                (25/10/2011 - 27/03/2013)<br/>
                CPIR 3
                (25/10/2011 - 27/03/2013)<br/>
                Commission de recours
                (25/10/2011 - 27/03/2013)<br/>
                Chef de file
                (25/10/2011 - 27/03/2013)<br/>
                Commission cohérence & qualité
                (25/10/2011 - 27/03/2013)<br/>
                Jury CléA
                (25/10/2011 - 27/03/2013)<br/>
                Commission financière
                (25/10/2011 - 27/03/2013)<br/>
                Bureau
                (24/03/2011 - 24/10/2011)<br/>
                C.A.
                (24/03/2011 - 24/10/2011)<br/>
                CPIR 1
                (24/03/2011 - 24/10/2011)<br/>
                CPIR 2
                (24/03/2011 - 24/10/2011)<br/>
                CPIR 3
                (24/03/2011 - 24/10/2011)<br/>
                Commission de recours
                (24/03/2011 - 24/10/2011)<br/>
                Chef de file
                (24/03/2011 - 24/10/2011)<br/>
                Commission cohérence & qualité
                (24/03/2011 - 24/10/2011)<br/>
                Jury CléA
                (24/03/2011 - 24/10/2011)<br/>
                Commission financière
                (24/03/2011 - 24/10/2011)<br/>
            </p>
            <hr/>

            <p><strong>Céline GIROT</strong></p>
            <p>CPIR 1
                (depuis le 01/01/2020)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 1
                (06/04/2011 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Philippe MOSNIER</strong></p>
            <p>CPIR 1
                (depuis le 01/01/2020)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 1
                (11/05/2011 - 27/03/2013)<br/>
            </p>
            <hr/>

            <p><strong>Gilles NICOLI</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (29/05/2014 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Guy BOUCABEILLE</strong></p>
            <p>CPIR 2
                (depuis le 18/02/2022)<br/>

                CPIR 2
                (01/01/2020 - 08/02/2022)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (05/03/2014 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Gladys DUL</strong></p>
            <p>Pôle engagement
                (depuis le 01/10/2013)<br/>
            </p>
            <hr/>

            <p><strong>Béatrice BOUDET</strong></p>
            <p>CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>

                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 1
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Rémi DANIEL</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 2
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (29/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (29/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (29/05/2017 - 31/12/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (28/03/2013 - 25/03/2015)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (28/03/2013 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Christian LIMON</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 2
                (28/03/2013 - 25/03/2015)<br/>
            </p>
            <hr/>

            <p><strong>Annie PASZKIEWICZ</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 3
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>

                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                C.A.
                (26/03/2015 - 24/05/2017)<br/>
                CPIR 3
                (26/03/2015 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (26/03/2015 - 24/05/2017)<br/>
                C.A.
                (05/03/2014 - 25/03/2015)<br/>
                CPIR 3
                (05/03/2014 - 25/03/2015)<br/>
                Commission cohérence & qualité
                (05/03/2014 - 25/03/2015)<br/>
            </p>
            <hr/>


            <p><strong>Christelle BERNE</strong></p>
            <p>DDC
                (depuis le 20/04/2016)<br/>
                Webmaster
                (depuis le 20/04/2016)<br/>
            </p>
            <hr/>

            <p><strong>Anne-Marie CELIGOJ</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                Jury CléA
                (depuis le 02/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                Jury CléA
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (13/03/2019 - 31/12/2019)<br/>
                CPIR 1
                (13/03/2019 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (13/03/2019 - 31/12/2019)<br/>
                Jury CléA
                (13/03/2019 - 31/12/2019)<br/>
                C.A.
                (24/05/2017 - 13/03/2013)<br/>
                CPIR 1
                (24/05/2017 - 13/03/2013)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 13/03/2013)<br/>
                Jury CléA
                (24/05/2017 - 13/03/2013)<br/>
                C.A.
                (15/09/2016 - 24/05/2017)<br/>
                CPIR 1
                (15/09/2016 - 24/05/2017)<br/>
                Commission cohérence & qualité
                (15/09/2016 - 24/05/2017)<br/>
                Jury CléA
                (15/09/2016 - 24/05/2017)<br/>
            </p>
            <hr/>

            <p><strong>Odile FREMIN</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 2
                (depuis le 02/03/2021)<br/>
                CPIR 3
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (13/02/2018 - 31/12/2019)<br/>
                CPIR 2
                (13/02/2018 - 31/12/2019)<br/>
                CPIR 3
                (13/02/2018 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (13/02/2018 - 31/12/2019)<br/>
                C.A.
                (25/01/2018 - 12/02/2018)<br/>
                CPIR 2
                (25/01/2018 - 12/02/2018)<br/>
                CPIR 3
                (25/01/2018 - 12/02/2018)<br/>
                Commission cohérence & qualité
                (25/01/2018 - 12/02/2018)<br/>
                C.A.
                (24/05/2017 - 24/01/2018)<br/>
                CPIR 2
                (24/05/2017 - 24/01/2018)<br/>
                CPIR 3
                (24/05/2017 - 24/01/2018)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 24/01/2018)<br/>
            </p>
            <hr/>

            <p><strong>ANNIE CERBELLE</strong></p>
            <p>Webmaster
                (depuis le 19/06/2017)<br/>
            </p>
            <hr/>

            <p><strong>Gérard LEDUC</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (depuis le 02/03/2021)<br/>
                CPIR 2
                (depuis le 02/03/2021)<br/>
                CPIR 3
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>

                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 3
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 1
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 2
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
            </p>
            <hr/>

            <p><strong>Pierre-Alain KERNINON</strong></p>
            <p>C.A.
                (depuis le 02/01/2020)<br/>
                CPIR 3
                (depuis le 02/01/2020)<br/>
                Commission cohérence & qualité
                (depuis le 02/01/2020)<br/>
                Jury CléA
                (depuis le 02/01/2020)<br/>

                C.A.
                (24/05/2017 - 31/12/2019)<br/>
                CPIR 3
                (24/05/2017 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (24/05/2017 - 31/12/2019)<br/>
                Jury CléA
                (24/05/2017 - 31/12/2019)<br/>
            </p>
            <hr/>

            <p><strong>Françoise LANNEZVAL</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Pascal COYO</strong></p>
            <p>Bureau
                (depuis le 01/01/2022)<br/>
                C.A.
                (depuis le 01/01/2022)<br/>
                Commission de recours
                (depuis le 01/01/2022)<br/>
                Chef de file
                (depuis le 01/01/2022)<br/>
                Jury CléA
                (depuis le 01/01/2022)<br/>
                Commission financière
                (depuis le 01/01/2022)<br/>

                Bureau
                (01/01/2020 - 01/01/2022)<br/>
                C.A.
                (01/01/2020 - 01/01/2022)<br/>
                Commission de recours
                (01/01/2020 - 01/01/2022)<br/>
                Chef de file
                (01/01/2020 - 01/01/2022)<br/>
                Jury CléA
                (01/01/2020 - 01/01/2022)<br/>
                Commission financière
                (01/01/2020 - 01/01/2022)<br/>
                Bureau
                (17/07/2018 - 31/12/2019)<br/>
                C.A.
                (17/07/2018 - 31/12/2019)<br/>
                Commission de recours
                (17/07/2018 - 31/12/2019)<br/>
                Chef de file
                (17/07/2018 - 31/12/2019)<br/>
                Jury CléA
                (17/07/2018 - 31/12/2019)<br/>
                Commission financière
                (17/07/2018 - 31/12/2019)<br/>
                Bureau
                (25/01/2018 - 16/07/2018)<br/>
                C.A.
                (25/01/2018 - 16/07/2018)<br/>
                Commission de recours
                (25/01/2018 - 16/07/2018)<br/>
                Chef de file
                (25/01/2018 - 16/07/2018)<br/>
                Jury CléA
                (25/01/2018 - 16/07/2018)<br/>
                Commission financière
                (25/01/2018 - 16/07/2018)<br/>
            </p>
            <hr/>

            <p><strong>Fabrice COLLINET</strong></p>
            <p>CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (12/09/2018 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (12/09/2018 - 31/12/2019)<br/>
            </p>
            <hr/>

            <p><strong>Nathalie LE DISERT</strong></p>
            <p>C.A.
                (depuis le 02/03/2021)<br/>
                CPIR 1
                (depuis le 02/03/2021)<br/>
                Commission cohérence & qualité
                (depuis le 02/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 1
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (05/09/2019 - 31/12/2019)<br/>
                CPIR 1
                (05/09/2019 - 31/12/2019)<br/>
                Commission cohérence & qualité
                (05/09/2019 - 31/12/2019)<br/>
            </p>
            <hr/>

            <p><strong>Valérie GUILLOTIN</strong></p>
            <p>Bureau
                (01/01/2022 - 28/07/2022)<br/>
                C.A.
                (01/01/2022 - 28/07/2022)<br/>
                CPIR 2
                (01/01/2022 - 28/07/2022)<br/>
                Commission de recours
                (01/01/2022 - 28/07/2022)<br/>
                Chef de file
                (01/01/2022 - 28/07/2022)<br/>
                Commission cohérence & qualité
                (01/01/2022 - 28/07/2022)<br/>
                Bureau
                (02/03/2021 - 01/01/2022)<br/>
                C.A.
                (02/03/2021 - 01/01/2022)<br/>
                CPIR 2
                (02/03/2021 - 01/01/2022)<br/>
                Commission de recours
                (02/03/2021 - 01/01/2022)<br/>
                Chef de file
                (02/03/2021 - 01/01/2022)<br/>
                Commission cohérence & qualité
                (02/03/2021 - 01/01/2022)<br/>
                Bureau
                (01/01/2020 - 01/03/2021)<br/>
                C.A.
                (01/01/2020 - 01/03/2021)<br/>
                CPIR 2
                (01/01/2020 - 01/03/2021)<br/>
                Commission de recours
                (01/01/2020 - 01/03/2021)<br/>
                Chef de file
                (01/01/2020 - 01/03/2021)<br/>
                Commission cohérence & qualité
                (01/01/2020 - 01/03/2021)<br/>
            </p>
            <hr/>

            <p><strong>Yves GICQUEL</strong></p>
            <p>CPIR 3
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Joy MOREAUX</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Virginie FAIVET</strong></p>
            <p>CPIR 3
                (depuis le 18/10/2022)<br/>
                CPIR 3
                (01/01/2020 - 07/10/2021)<br/>
            </p>
            <hr/>

            <p><strong>Jean-Marc PARIS</strong></p>
            <p>Bureau
                (depuis le 08/04/2022)<br/>
                C.A.
                (depuis le 08/04/2022)<br/>
                CPIR 1
                (depuis le 08/04/2022)<br/>
                Commission de recours
                (depuis le 08/04/2022)<br/>
                Bureau
                (01/01/2020 - 06/04/2022)<br/>
                C.A.
                (01/01/2020 - 06/04/2022)<br/>
                CPIR 1
                (01/01/2020 - 06/04/2022)<br/>
                Commission de recours
                (01/01/2020 - 06/04/2022)<br/>
            </p>
            <hr/>

            <p><strong>Sébastien GIRAUD</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Sandrine DELFOUR</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Adhal BARA</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
                CPIR 3
                (depuis le 01/01/2020)<br/>
                Jury CléA
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Nicole MICHON</strong></p>
            <p>C.A.
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Claire GUELMANI</strong></p>
            <p>C.A.
                (01/01/2020 - 16/11/2022)<br/>
            </p>
            <hr/>

            <p><strong>Yann VIGUIE</strong></p>
            <p>CPIR 2
                (depuis le 01/01/2020)<br/>
            </p>
            <hr/>

            <p><strong>Stéphane MAAS</strong></p>
            <p>DG
                (depuis le 04/02/2020)<br/>
                Consultation
                (depuis le 04/02/2020)<br/>
            </p>

        <hr/>
        <p><strong>Hélèna WACRENIER</strong></p>
        <p>Pôle engagement
            (depuis le 26/03/2020)<br/>
            SEE
            (depuis le 26/03/2020)<br/>
        </p>
    <hr/>

    <p><strong>Mustapha LEKBIR</strong></p>
    <p>C.A.
        (depuis le 02/03/2021)<br/>
        CPIR 1
        (depuis le 02/03/2021)<br/>
        C.A.
        (29/06/2020 - 01/03/2021)<br/>
        CPIR 1
        (29/06/2020 - 01/03/2021)<br/>
    </p>
    <hr/>

    <p><strong>Thomas BOURDON</strong></p>
    <p>Pôle engagement
        (depuis le 01/07/2020)<br/>
        SEE
        (depuis le 01/07/2020)<br/>
    </p>
    <hr/>

    <p><strong>Benoit SOLLIER</strong></p>
    <p>Comité direction
        (depuis le 19/10/2020)<br/>
    </p>
    <hr/>

    <p><strong>Hervé RIVALLAND</strong></p>
    <p>Comité direction
        (depuis le 27/11/2020)<br/>
    </p>
    <hr/>

    <p><strong>Mona DEMARCHELIER</strong></p>
    <p>Comité direction
        (depuis le 27/11/2020)<br/>
    </p>
    <hr/>

    <p><strong>Laurent LE GALL</strong></p>
    <p>CPIR 2
        (17/03/2021 - 16/11/2022)<br/>
    </p>
    <hr/>

    <p><strong>Sonia BOUKHOLDA</strong></p>
    <p>CPIR 1
        (depuis le 12/05/2021)<br/>
    </p>
    <hr/>

    <p><strong>Isabel TALAIA</strong></p>
    <p>C.A.
        (depuis le 14/05/2021)<br/>
    </p>
    <hr/>

    <p><strong>Sylvie DESCHAMPS</strong></p>
    <p>CPIR 3
        (depuis le 07/10/2021)<br/>
    </p>
    <hr/>

    <p><strong>Raphaëlle
    GRANDPIERRE</strong></p>
    <p>CPIR
    3
    (depuis
    le 07 / 10 / 2021
)<br/>
    </p>
    <hr/>

    <p><strong>Cédric DELAYEN</strong></p>
    <p>C.A.
        (depuis le 17/11/2021)<br/>
        CPIR 3
        (depuis le 17/11/2021)<br/>
    </p>
    <hr/>

    <p><strong>Grégory DECLERCQ</strong></p>
    <p>C.A.
        (depuis le 21/12/2022)<br/>
        CPIR 1
        (depuis le 21/12/2022)<br/>
        C.A.
        (17/11/2021 - 22/12/2022)<br/>
        CPIR 1
        (17/11/2021 - 22/12/2022)<br/>
    </p>
    <hr/>

    <p><strong>Berthe SOUDET</strong></p>
    <p>Bureau
        (depuis le 03/12/2021)<br/>
        C.A.
        (depuis le 03/12/2021)<br/>
        CPIR 3
        (depuis le 03/12/2021)<br/>
        Commission de recours
        (depuis le 03/12/2021)<br/>
        Chef de file
        (depuis le 03/12/2021)<br/>
        Jury CléA
        (depuis le 03/12/2021)<br/>
    </p>
    <hr/>

    <p><strong>Françoise GOMES</strong></p>
    <p>SEE
        (depuis le 14/12/2021)<br/>
    </p>
    <hr/>

    <p><strong>Anne-Sylvie GREGOIRE</strong></p>
    <p>CPIR 2
        (depuis le 08/02/2022)<br/>
    </p>
    <hr/>

    <p><strong>Philippe PANNIER</strong></p>
    <p>C.A.
        (depuis le 14/09/2022)<br/>
    </p>
    <hr/>

    <p><strong>Jean-Luc CIRODE</strong></p>
    <p>CPIR 1
        (depuis le 14/09/2022)<br/>
        CPIR 2
        (depuis le 14/09/2022)<br/>
        CPIR 3
        (depuis le 14/09/2022)<br/>
        Commission cohérence & qualité
        (depuis le 14/09/2022)<br/>
    </p>
    <hr/>

    <p><strong>Grégory THOMAS</strong></p>
    <p>CPIR 2
        (depuis le 17/11/2022)<br/>
    </p>
    <hr/>

    <p><strong>Axel CHANGARNIER</strong></p>
    <p>C.A.
        (depuis le 17/11/2022)<br/>
    </p>
    <hr/>

    <p><strong>Pape NDENE NDIAYE</strong></p>
    <p>C.A.
        (depuis le 21/12/2022)<br/>
        CPIR 1
        (depuis le 21/12/2022)<br/>
    </p>
    <hr/>

    <p><strong>Karine GUIRAUD</strong></p>
    <p>C.A.
        (depuis le 21/12/2022)<br/>
        CPIR 2
        (depuis le 21/12/2022)<br/>
    </p>
    <hr/>

    <p><strong>Sabrina PIRES</strong></p>
    <p>Webmaster
        (depuis le 02/01/2022)<br/>
    </p>
    <hr/>

    <p><strong>Sédalom FOLLY</strong></p>
    <p>Bureau
        (depuis le 18/01/2023)<br/>
        C.A.
        (depuis le 18/01/2023)<br/>
        Commission de recours
        (depuis le 18/01/2023)<br/>
    </p>
</Modal.Body>
</Modal>
}
