import {createSlice} from "@reduxjs/toolkit"
import {fetchCommittees, Committee} from "../../api/CommitteeAPI"
import {SelectOption} from "../../utils/CommonInterfaces"

export interface EmailingState {
    committees: Committee[],
    committeesSelected: Committee[],
    rolesSelected: SelectOption[],
    statusSelected: SelectOption[],
    showModal: boolean,
    subject: string,
    contentEmail: string,
    nbuserRecipients: number | string,
    messageConfirmPopinEmail: string,
    errorMessageConfirmPopinEmail: boolean,
    moreEmails?: string
}

const initialState: EmailingState = {
    committees: [],
    committeesSelected: [],
    rolesSelected: [],
    statusSelected: [],
    showModal: false,
    subject: "",
    contentEmail: "",
    nbuserRecipients: "XXX",
    messageConfirmPopinEmail: "",
    errorMessageConfirmPopinEmail: false
}

export const EmailingSlice = createSlice({
    name: "emailing",
    initialState,
    reducers: {
        resetData: (state) => {
            state.subject = ""
            state.contentEmail = ""
        },
        setCommitteesSelected: (state, action) => {
            state.committeesSelected = action.payload
        },
        setRolesSelected: (state, action) => {
            state.rolesSelected = action.payload
        },
        setStatusSelected: (state, action) => {
            state.statusSelected = action.payload
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload
        },
        setSubject: (state, action) => {
            state.subject = action.payload
        },
        setContentEmail: (state, action) => {
            state.contentEmail = action.payload
        },
        setNbuserRecipients: (state, action) => {
            state.nbuserRecipients = action.payload
        },
        setErrorMessageConfirmPopinEmail: (state, action) => {
            state.errorMessageConfirmPopinEmail = action.payload
        },
        setMoreEmails: (state, action) => {
            state.moreEmails = action.payload
        }

    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchCommittees.fulfilled, (state, action) => {
                    state.committees = action.payload
                })
        }
})

export const {
    resetData,
    setCommitteesSelected,
    setStatusSelected,
    setRolesSelected,
    setShowModal,
    setSubject,
    setContentEmail,
    setNbuserRecipients,
    setErrorMessageConfirmPopinEmail,
    setMoreEmails
} = EmailingSlice.actions

export default EmailingSlice.reducer
