import {ReactNode} from "react"
import {Col, Modal, Row} from "react-bootstrap"
import {checkValidMandate} from "../../utils/Functions"
import {useAppSelector, useAppDispatch} from "../../redux/Hooks"
import type {RootState} from "../../redux/Store"
import {setShow} from "../../redux/User/TrombinoscopeSlice"
import {formatDateToDisplay} from "../../utils/Functions"
import {avatar, getRolesStr, handleTelChange, isAdmin} from "../../utils/UserTools"
import {Period, Mandate} from "../../api/MandateAPI"
import {sortPeriods} from "../../utils/Sorting"
import ItemPeriodTitle from "./ItemPeriodTitle"
import {COLLEGES, SYNDICATES} from "../../constants/UserData"

export default function LightBoxUser() {

    const dispatch = useAppDispatch()
    const userSelected = useAppSelector((state: RootState) => state.tromb.userSelected)
    const loadingMandates = useAppSelector((state: RootState) => state.tromb.loadingMandates)
    const show = useAppSelector((state: RootState) => state.tromb.show)

    let statut: string = ""
    let college: { key: number, label: string } | undefined = COLLEGES.find((item) => item.key === userSelected.college)
    let syndicat: { key: number, label: string } | undefined

    if (college) {
        syndicat = SYNDICATES[college.key].find((item) => item.key === userSelected.syndicate)
        statut += "Collège " + college.label + (syndicat ? " / " + syndicat.label : "")
    }

    let contentPeriods: ReactNode = <p>Aucun mandat...</p>
    let contentTable: ReactNode | string = <></>

    if (userSelected.periods !== undefined && userSelected.periods.length > 0) {
        const periods = sortPeriods(userSelected.periods)
        contentPeriods = periods.map((period: Period, index: number) => {
            let mandateValid: boolean = false
            if (period.mandates !== undefined && period.mandates.length > 0) {
                contentTable = period.mandates.map((mandat: Mandate, index: number) => {
                    mandateValid = true
                    let status = "-"
                    if (Number(mandat.status) === 0) status = "Titulaire"
                    if (Number(mandat.status) === 1) status = "Suppléant "
                    let dateStart = ""
                    if (mandat.dateStart !== undefined) {
                        dateStart = formatDateToDisplay(mandat.dateStart)
                    } else if (mandat.date_start !== undefined && mandat.date_start !== null) {
                        dateStart = formatDateToDisplay(mandat.date_start)
                    }
                    let dateEnd: string = ""
                    if (mandat.dateEnd !== undefined && mandat.dateEnd !== null) {
                        dateEnd = formatDateToDisplay(mandat.dateEnd)
                    } else if (mandat.date_end !== undefined && mandat.date_end !== null) {
                        dateEnd = formatDateToDisplay(mandat.date_end)
                    }
                    return <tr key={index} className={!checkValidMandate(mandat) ? "text-main-gray" : ""}>
                        <td>
                            <div className="d-flex align-items-center fw-medium">
                                <i className="mai fs-md me-2" style={{color: mandat.committee.color}}>circle</i>
                                {mandat.committee.title}
                            </div>
                        </td>
                        <td>{status}</td>
                        <td>{getRolesStr(mandat.roles)}</td>
                        <td>{dateStart}</td>
                        <td>{dateEnd}</td>
                    </tr>
                })
                if (!mandateValid) { // Si tout les mandats ont éxpirés ou n'ont pas encore commencé
                    contentTable = "<div class=\"empty-value\">Aucun mandat en cours de validité</div>"
                }

            } else if (!loadingMandates) {
                contentTable = "<div class=\"empty-value\">Aucun mandat</div>"
            }

            if (mandateValid) {
                return <div key={index} className="bg-light p-4 mb-3">
                    <h6 className="fw-medium fs-md">
                        <ItemPeriodTitle period={period}/>
                    </h6>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Instance</th>
                            <th>Statut</th>
                            <th>Fonction</th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                        </tr>
                        </thead>
                        <tbody>
                        {contentTable}
                        </tbody>
                    </table>
                </div>
            }
            return <></>

        })
    }

    let roleUser = ""
    if (isAdmin(userSelected.roles)) {
        roleUser = "ADMINISTRATEUR.TRICE - COMMISSAIRE"
    } else if (userSelected.roles.includes("ROLE_ADMIN")) {
        roleUser = "WEBMASTER"
    } else if (userSelected.roles.includes("ROLE_CODIR")) {
        roleUser = "MEMBRE ATPRO"
    } else if (userSelected.roles.includes("ROLE_ASSIST")) {
        roleUser = "ASSISTANTE"
    }

    return (
        <Modal show={show} scrollable onHide={() => dispatch(setShow(false))}>
            <Modal.Header closeButton>
                <Modal.Title as="h3">{roleUser}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="bg-main-gray-light p-4">
                    <Row className="row-sm row-sm-h align-items-center">
                        <Col xs="auto">
                            <img src={avatar(userSelected.filePath, Number(userSelected.civility))} className="avatar"
                                 alt={userSelected.firstname + " " + userSelected.lastname} width="100"/>
                        </Col>
                        <Col>
                            <h2 className="text-primary mb-0">{Number(userSelected.civility) === 1 ? "Monsieur" : "Madame"} {userSelected.firstname} {userSelected.lastname}</h2>
                            {
                                statut &&
                                <span className="text-main-gray fw-medium">{statut}</span>
                            }
                        </Col>
                    </Row>
                </div>
                <Row className="row-sm row-sm-h mt-4">
                    {[
                        {
                            data: "email",
                            value: userSelected.email !== undefined && userSelected.email !== null ? userSelected.email : "",
                            icon: "mail"
                        },
                        {
                            data: "phone1",
                            value: userSelected.phone1 !== undefined && userSelected.phone1 !== null ? handleTelChange(userSelected.phone1) : "",
                            icon: "phone_android"
                        },
                        {
                            data: "address",
                            value: (userSelected.address1 !== undefined && userSelected.address1 !== null ? userSelected.address1 : "") + " "
                                + (userSelected.zipcode !== undefined && userSelected.zipcode !== null ? userSelected.zipcode : "") + " "
                                + (userSelected.city !== undefined && userSelected.city !== null ? userSelected.city : ""),
                            icon: "home"
                        },
                        {data: "phone2", value: handleTelChange(userSelected.phone2), icon: "call"}
                    ].map((data, index) => {
                        let uninformed: boolean = false
                        if ((data.data === "email" && !userSelected.authDiffEmail) ||
                            (data.data === "phone1" && !userSelected.authDifftel1) ||
                            (data.data === "address" && !userSelected.authDiffAddress) ||
                            (data.data === "phone2" && !userSelected.authDiffTel2)
                        ) {
                            uninformed = true
                        }
                        return <Col lg={6} key={index} className="d-flex">
                            <i className={"mai fs-lg me-2 " + (data.value !== undefined && data.value !== "" && !uninformed ? "text-primary" : "text-main-gray")}>{data.icon}</i>
                            <span>{data.value !== undefined && data.value !== null && data.value.trim() !== "" && !uninformed ? data.value : "Non renseigné"}</span>
                        </Col>
                    })}
                </Row>
                <h3 className="mt-6 mb-3 text-primary">Derniers mandats</h3>
                {contentPeriods}
            </Modal.Body>
        </Modal>
    )
}
