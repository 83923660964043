import {useEffect, useMemo, useState} from "react"
import {Form} from "react-bootstrap"
import DatePicker from "react-datepicker"
import { addMandate, addRole, deleteMandate, setDateEndMandate, setDateStartMandate, setStatusMandate, setProperty } from "../../redux/User/AddUserSlice"
import {Mandate} from "../../api/MandateAPI"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {data} from "../../utils/Data"
import {getFormattedLocalisedDate} from "../../utils/Date"

export default function ItemMandate(props: { mandate: Mandate, index: number }) {
    const dispatch = useAppDispatch()
    const periodDateStart = useAppSelector((state) => state.addUser.dateStartPeriod)
    const periodDateEnd = useAppSelector((state) => state.addUser.dateEndPeriod)
    const editPeriodMode = useAppSelector((state: RootState) => state.addUser.editPeriodMode)
    const periodToEdit = useAppSelector((state) => state.addUser.periodToEdit)
    const mandates = useAppSelector((state) => state.addUser.mandates)

    const [dateStart, setDateStart] = useState<Date | null>(null)
    const [dateEnd, setDateEnd] = useState<Date | null>(null)

    const dateStartPeriod = editPeriodMode && periodToEdit ? new Date(periodToEdit.dateStart) : new Date(periodDateStart)
    const dateEndPeriod = editPeriodMode && periodToEdit && periodToEdit.dateEnd && periodToEdit.dateEnd !== "" ? new Date(periodToEdit.dateEnd) :
        periodDateEnd ? new Date(periodDateEnd) : null

    const dateStartMandate = useMemo(() => {
        let previousMandates = [...mandates.filter(item => item.committee.id === props.mandate.committee.id && item.id !== props.mandate.id)]
        previousMandates = [...previousMandates, ...(periodToEdit ? periodToEdit.mandates.filter(item => item.committee.id === props.mandate.committee.id && item.id !== props.mandate.id) : [])]
        let firstAvailableDate: Date | undefined

        previousMandates.forEach(item => {
            if (item.dateEnd && (!firstAvailableDate || firstAvailableDate > new Date(item.dateEnd))) {
                firstAvailableDate = new Date(item.dateEnd)
            }
        })
        return firstAvailableDate || dateStartPeriod
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mandate])

    useEffect(() => {
        const dateTempStart = new Date(props.mandate.dateStart) > new Date(dateStartPeriod) ? new Date(props.mandate.dateStart) : new Date(dateStartPeriod)
        dispatch(setDateStartMandate({
            dateStart: getFormattedLocalisedDate(dateTempStart, "yyyy-MM-dd"),
            indexMandate: props.index
        }))

        if (props.mandate.dateEnd !== undefined && props.mandate.dateEnd !== null && props.mandate.dateEnd !== "") {
            const dateTempEnd = new Date(props.mandate.dateEnd) > new Date(dateStartPeriod) ? new Date(props.mandate.dateEnd) : null
            dateTempEnd !== null && dispatch(setDateEndMandate({
                dateEnd: getFormattedLocalisedDate(dateTempEnd, "yyyy-MM-dd"),
                indexMandate: props.index
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const dateTempStart = new Date(props.mandate.dateStart) > new Date(dateStartPeriod) ? new Date(props.mandate.dateStart) : new Date(dateStartPeriod)
        props.mandate.dateStart !== "" && setDateStart(dateTempStart)
        /*dispatch(setDateStartMandate({
            dateStart: getFormattedLocalisedDate(dateTempStart, "yyyy-MM-dd"),
            indexMandate: props.index
        }))*/

        if (props.mandate.dateEnd !== undefined && props.mandate.dateEnd !== null && props.mandate.dateEnd !== "") {
            const dateTempEnd = new Date(props.mandate.dateEnd) > new Date(dateStartPeriod) ? new Date(props.mandate.dateEnd) : null
            setDateEnd(dateTempEnd)
            /*dateTempEnd !== null && dispatch(setDateEndMandate({
                dateEnd: getFormattedLocalisedDate(dateTempEnd, "yyyy-MM-dd"),
                indexMandate: props.index
            }))*/
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mandate])

    const handleDuplicate = () => {
        if (!dateEnd) {
            dispatch(setProperty({property: "viewMandateDuplicationWarning", value: true}))
            return
        }

        const newMandate: Mandate = {
            dateStart: getFormattedLocalisedDate(dateEnd, "yyyy-MM-dd"),
            dateEnd: null,
            status: props.mandate.status,
            roles: props.mandate.roles,
            user: props.mandate.user,
            committee: props.mandate.committee
        }

        dispatch(addMandate(newMandate))
    }


    return <div id={`mandate-${props.index}`} key={props.index} className="bg-light p-4 mb-3">
        <div className="row row-sm mb-4">
            <div className="col-10">
                <h3>{props.mandate.committee.title}</h3>
            </div>
            <div className="col-2 text-end">
                <button onClick={() => handleDuplicate()} className="text-primary btn-less">
                    <i className="mai fs-md">content_copy</i>
                </button>
            </div>
            <div className="col-auto">
                <Form.Label>Du *</Form.Label>
                <DatePicker
                    locale="fr"
                    id={`date-start-${props.index}`}
                    selected={dateStart}
                    required={true}
                    onChange={(date) => {
                        dispatch(setDateStartMandate({
                            dateStart: date ? getFormattedLocalisedDate(date, "yyyy-MM-dd") : null,
                            indexMandate: props.index
                        }))
                        setDateStart(date)
                    }}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholderText={"jj/mm/aaaa"}
                    minDate={dateStartMandate}
                    maxDate={dateEndPeriod}
                />
            </div>
            <div className="col-auto">
                <Form.Label>Au</Form.Label>
                <DatePicker
                    locale="fr"
                    id={`date-end-${props.index}`}
                    selected={dateEnd}
                    onChange={(date) => {
                        dispatch(setDateEndMandate({
                            dateEnd: date ? getFormattedLocalisedDate(date, "yyyy-MM-dd") : null,
                            indexMandate: props.index
                        }))
                        setDateEnd(date)
                    }}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholderText={"jj/mm/aaaa"}
                    minDate={dateStart}
                    maxDate={dateEndPeriod}
                />
            </div>
        </div>
        <Form.Group className="mb-4">
            <Form.Label>Statut *</Form.Label>
            <div className="row row-sm col-form-label">
                {[
                    {label: "Titulaire", value: 0},
                    {label: "Suppléant", value: 1}
                ].map((data) => (
                    <div key={`default-status-${props.index}-${data.value}`} className="col-auto">
                        <Form.Check
                            type="radio"
                            label={data.label}
                            name={"status-" + props.index}
                            id={`default-status-${props.index}-${data.value}`}
                            checked={Number(props.mandate.status) === Number(data.value)}
                            onChange={() => {
                                dispatch(setStatusMandate({status: data.value, indexMandate: props.index}))
                            }}
                        />
                    </div>
                ))}
            </div>
        </Form.Group>
        <Form.Group className="mb-4">
            <Form.Label>Fonction *</Form.Label>
            <div className="row row-sm row-sm-h mt-0">
                {data.MANDATE_ROLES.map((role) => (
                    <div key={`default-role-${props.index}-${role.key}`} className="col-sm-4">
                        <Form.Check
                            type="checkbox"
                            label={role.label}
                            id={`default-role-${props.index}-${role.key}`}
                            checked={!!props.mandate.roles.includes(role.key)}
                            onChange={(e) => {
                                dispatch(addRole({role: role.key, indexMandate: props.index}))
                            }}
                        />
                    </div>
                ))}
            </div>
        </Form.Group>
        <button type="button" className="btn-less link text-danger" onClick={() => {
            dispatch(deleteMandate({mandateIndex: props.index, committeeId: props.mandate.committee.id}))
        }}><i className="mai fs-md me-2">delete_outline</i> Supprimer
        </button>
    </div>
}
