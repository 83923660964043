import {useAppSelector} from "../../redux/Hooks";
import LoadingSpinner from "../LoadingSpinner";
import {RootState} from "../../redux/Store";
import ItemDocumentsByCommitteeBlock from "./ItemDocumentsByCommitteeBlock"

export default function DocumentsByCommittee() {
    const committees = useAppSelector((state: RootState) => state.committee.items)
    const loading = useAppSelector((state) => state.meeting.loading)
    const link = `/documents/`

    // On veut afficher x documents par instance
    return <>
        {
        loading ?
            <LoadingSpinner/> :
        (
            committees.map((committee) => (
                committee.canViewDocs && <ItemDocumentsByCommitteeBlock key={`committee-${committee.id}`} committee={committee} link={`${link}${committee.slug}`} />
            ))
        )
        }
    </>
}
